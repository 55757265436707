/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box,
  Button,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { ConnectMergeIntegrationButton } from "../ConnectMergeIntegrationButton";
import { IntegrationCardProps } from "./types";
import { useCurrentAccount } from "hooks";

export function IntegrationCard({
  handleToggleIntegration,
  integration,
  setCustomAuthorizationDialogOpen,
  setOauthDialogOpen,
  setSelectedIntegration,
}: IntegrationCardProps) {

  const {featureFlags} = useCurrentAccount();

  const renderCardFooter = () => {
    /* 
      Card footer for connected integrations
    */
    if (integration.id) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={integration.active}
                color="secondary"
                onClick={() => {
                  if (handleToggleIntegration) {
                    handleToggleIntegration(integration);
                  }
                }}
              />
            }
            label={integration.active ? "Active" : "Paused"}
          />

          <Button
            color="primary"
            onClick={() => {
              setSelectedIntegration(integration);
            }}
            variant="outlined"
          >
            {" "}
            MANAGE{" "}
          </Button>
        </Box>
      );
    }
    /* 
      Card footer for webhook card
    */
    if (integration.provider === "webhook") {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            padding: "16px",
          }}
        >
          <Button
            color="primary"
            onClick={() => {
              if (setCustomAuthorizationDialogOpen) {
                setCustomAuthorizationDialogOpen(true);
              }
              setSelectedIntegration(integration);
            }}
            variant="outlined"
          >
            <AddIcon color="primary" /> WEBHOOK{" "}
          </Button>
        </Box>
      );
    }
    /* 
      Card footer for available integrations
    */
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          padding: "16px",
        }}
      >
        {integration.strategy === "merge" && (
          <ConnectMergeIntegrationButton
            integration={integration}
            setSelectedIntegration={setSelectedIntegration}
          />
        )}
        {integration.strategy === "custom" && (
          <Button
            onClick={() => {
              if (setCustomAuthorizationDialogOpen) {
                setCustomAuthorizationDialogOpen(true);
              }
              setSelectedIntegration(integration);
            }}
            sx={{ color: "primary", paddingX: "16px", paddingY: "6px" }}
            variant="outlined"
          >
            {" "}
            CONNECT{" "}
          </Button>
        )}
        {integration.strategy === "oauth" && (
          <Button
            onClick={() => {
              if (setOauthDialogOpen) {
                setOauthDialogOpen(true);
              }
              setSelectedIntegration(integration);
            }}
            sx={{ color: "primary", paddingX: "16px", paddingY: "6px" }}
            variant="outlined"
          >
            {" "}
            CONNECT{" "}
          </Button>
        )}
      </Box>
    );
  };

  const renderHealthCheckIcon = () => {
    const { isHealthy } = integration;
    return (
      <Tooltip
        placement="bottom"
        // This slotProps moves the tooltip closer to the icon
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -14],
                },
              },
            ],
          },
        }}
        title={
          isHealthy
            ? "No critical errors detected"
            : "Critical errors detected, please check activity report"
        }
      >
        {/* Luke TODO: Create click handler for button that will route user to health check page. E.g. history.push(healtchCheckPageRoute) */}
        <IconButton color={isHealthy ? "success" : "warning"}>
          {isHealthy ? <CheckCircleOutlineIcon /> : <WarningAmberIcon />}
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Grid size={{ xs: 12, sm: 12, md: 6, lg: 4 }} minWidth="288px">
      <Box
        sx={{
          border: "1px solid",
          borderColor: "rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "8px",
            width: "100%",
          }}
        >
          <Tooltip
            placement="top"
            title={integration.providerName}
            // This slotProps moves the tooltip closer to the text
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <Typography
              sx={{
                paddingTop: "8px",
                paddingLeft: "8px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              variant="h5"
            >
              {integration.providerName}
            </Typography>
          </Tooltip>
          {integration.id &&
          featureFlags.integrationsFrameworkHealthCheck &&
          integration.hasOwnProperty("isHealthy") &&
          integration.active
            ? renderHealthCheckIcon()
            : null}
        </Box>
        {renderCardFooter()}
      </Box>
    </Grid>
  );
}
