import { Dialog as BaseDialog, Slide, SlideProps } from "@mui/material";
import {
  ForwardedRef,
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
} from "react";
import { Props, DialogRef, State } from "./types";
import { usePromise } from "components/hooks";

function TransitionRef(props: SlideProps, ref: Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />;
}

const Transition = forwardRef(TransitionRef);

function DialogComponent<T, R>(
  {
    children,
    defaultCloseValue,
    onClose,
    TransitionComponent,
    maxWidth,
    ...props
  }: Props<T, R>,
  ref: ForwardedRef<DialogRef<T, R>>,
) {
  const { isOpen, open, close, parameters } = usePromise<T, R>();

  const value = useMemo<State<T, R>>(() => {
    return { close, parameters };
  }, [close, parameters]);

  useImperativeHandle(ref, () => {
    return {
      open,
    };
  });

  return (
    <BaseDialog
      open={isOpen}
      TransitionComponent={TransitionComponent ?? Transition}
      {...props}
      onClose={(...closeProps) => {
        const should = onClose?.(...closeProps) ?? true;

        if (should) {
          close(defaultCloseValue)();
        }
      }}
      sx={{
        zIndex: 0,
        "& .MuiPaper-root": {
          margin: 0,
          maxWidth: maxWidth || "65rem",
        },
      }}
    >
      {typeof children === "function" ? children(value) : children}
    </BaseDialog>
  );
}

/**
 * Forward Dialog as generic
 *
 * @link https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
 * @link https://fettblog.eu/typescript-react-generic-forward-refs/
 */
export const Dialog = forwardRef(DialogComponent) as <T, R>(
  props: Omit<Parameters<typeof DialogComponent<T, R>>[0], "ref"> & {
    ref: Ref<DialogRef<T, R>>;
  },
) => ReturnType<typeof DialogComponent<T, R>>;
