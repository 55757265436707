import { useCallback } from "react";
import { Box, DialogContent, DialogContentText } from "@mui/material";
import { useHistory } from "react-router-dom";

import { ConfirmCancelModalProps } from "../types";
import { DialogFooter } from "components/DialogFooter";
import { SimpleDialog } from "components/SimpleDialog";

function ConfirmCancelModal({
  pageTitle,
  open,
  onClose,
}: ConfirmCancelModalProps) {
  const history = useHistory();

  const confirmCancelHandler = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <SimpleDialog
      title={`Leave ${pageTitle} keyword page?`}
      open={open}
      handleClose={onClose}
    >
      <DialogContent sx={{ padding: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <DialogContentText
            aria-label="Confirm Cancel Message"
            variant="body1"
            sx={{ color: "text.primary" }}
          >
            You're about to exit the {pageTitle} keyword automation. Unfinished
            work will not be saved.
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogFooter
        confirmText="Yes"
        onConfirm={confirmCancelHandler}
        onCancel={onClose}
        buttonPadding="8px 8px"
      />
    </SimpleDialog>
  );
}

export default ConfirmCancelModal;
