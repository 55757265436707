import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Field, useFormikContext } from "formik";
import { enqueueSnackbar } from "notistack";
import { SubKeywordFormProps } from "../types";
import { archiveKeyword } from "../api";
import KeywordFormSequenceSelect from "./KeywordFormSequenceSelect";
import AutomaticMessageIndicator from "components/AutomaticMessageIndicator";
import MessageInputFormRow from "components/MessageInputFormRow";
import { useCurrentAccount } from "hooks";
import { useAutomationNavLinks } from "features/Automation/hooks/useAutomationNavLinks";
import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import SavedRepliesOption from "components/MessageInputOptions/SavedRepliesOption";

const MessageWrapper = styled(Box)(({ theme }) => {
  return {
    margin: "1rem 0 1rem 0",
    border: `1px solid ${theme.palette.customColors.enabledBorder}`,
    borderRadius: "8px",
  };
});

function SubKeywordForm({
  index,
  currentAccount,
  sequences,
  isMobile,
  savedKeyword,
}: SubKeywordFormProps) {
  const { touched, values, setFieldValue } = useFormikContext();
  const keywordField = `keywordAnswers[${index}][keyword]`;

  const account = useCurrentAccount();
  const [_sequenceId, setSequenceId] = useState(
    values.keywordAnswers[index].sequenceId,
  );
  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: account.shortenedLinkPreviewUrl,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (event) => {
    void setFieldValue(keywordField, event.target.value.toUpperCase());
  };

  const handleDelete = async () => {
    const updatedValues = values.keywordAnswers.filter((keywordAnswer, i) => {
      let filter = i !== index;
      if (i === index) {
        archiveKeyword(currentAccount, keywordAnswer.id).catch((error) => {
          enqueueSnackbar(
            "Something went wrong deleting keyword answer. Please try again.",
            {
              variant: "error",
            },
          );
          console.error(
            "An error occured when deleting keyword answer:",
            error,
          );
          filter = true;
        });
        setIsModalOpen(false);
      }
      return filter;
    });

    void setFieldValue("keywordAnswers", updatedValues);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onClick = (event) => {
    event.preventDefault();

    if (values.keywordAnswers[index].id) {
      setIsModalOpen(true);
    } else {
      void handleDelete();
    }
  };

  const hasSavedSubKeyword =
    savedKeyword?.subKeywords && savedKeyword.subKeywords[index] !== undefined;

  const { sequencesEnabled } = useAutomationNavLinks();

  const sizeExceededError = values.keywordAnswers[index]?.keyword?.length > 20;
  const matchesRootKeywordError =
    values.keywordAnswers[index]?.keyword === values.keyword && values.keyword;
  const matchesSubKeywordError = values.keywordAnswers.some(
    (keywordAnswer: any, i: number) => {
      return (
        keywordAnswer.keyword &&
        keywordAnswer.keyword === values.keywordAnswers[index].keyword &&
        i !== index
      );
    },
  );
  const answerError =
    sizeExceededError || matchesRootKeywordError || matchesSubKeywordError;
  const answerHelperText = sizeExceededError
    ? `${values.keywordAnswers[index]?.keyword?.length} of 20 characters`
    : matchesRootKeywordError
      ? "Keyword answer cannot be the same as the keyword trigger"
      : matchesSubKeywordError
        ? "Keyword answer already in use"
        : "";

  const { conversationMaxMessageLength } = account.settings;

  const maxCharacterLength =
    conversationMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH;

  return (
    <Accordion
      square={false}
      sx={(theme) => {
        return {
          width: "auto",
          boxShadow: "none",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: "4px",
          marginLeft: isMobile ? "0" : "1rem",
          backgroundColor: theme.palette.customColors.backgrounds.secondary,
          "&:before": {
            display: "none",
          },
        };
      }}
      disableGutters
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: "row-reverse",
          height: "56px",
          paddingRight: "8px",
          "& .MuiAccordionSummary-content": {
            justifyContent: "space-between",
          },
        }}
      >
        <Typography
          variant="h5"
          data-testid="keyword-answer-accordion"
          sx={{ marginLeft: "1rem", alignSelf: "center" }}
        >
          {hasSavedSubKeyword
            ? `Keyword answer: ${values.keywordAnswers[index].keyword}`
            : "Keyword answer"}
        </Typography>
        <IconButton onClick={onClick}>
          <CloseIcon />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => {
          return {
            padding: "1rem",
            borderTop: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            borderRadius: "0 0 4px 4px",
          };
        }}
      >
        <Box
          sx={{
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Typography
            component="span"
            variant="h6"
            sx={{
              alignSelf: isMobile ? "flex-start" : "center",
              marginBottom: answerError ? "20px" : 0,
            }}
          >
            If a contact replies with...
          </Typography>
          <Field name={keywordField} type="text" style={{ display: "inline" }}>
            {({ field }) => {
              return (
                <TextField
                  {...field}
                  fieldName={field.name}
                  hiddenLabel
                  value={values.keywordAnswers[index].keyword || ""}
                  onChange={handleChange}
                  data-testid="keyword-trigger"
                  error={answerError}
                  helperText={answerHelperText}
                  id="keyword-trigger"
                  placeholder="Keyword answer"
                  type="text"
                  variant="outlined"
                  sx={{
                    width: { xs: "100%", sm: "65%", md: "75%" },
                    padding: "8px 0px 8px 0px",
                    paddingRight: isMobile ? "0px" : "8px",
                  }}
                />
              );
            }}
          </Field>
        </Box>

        <Typography variant="h6">Then send this message:</Typography>
        <MessageWrapper>
          <MessageInputFormRow
            attachmentFieldName={`keywordAnswers[${index}][attachments]`}
            attachmentField={values.keywordAnswers[index]?.attachments ?? []}
            data-testid="keyword-answer-auto-response"
            currentAccount={account}
            errors={{}}
            values={values}
            fieldName={`keywordAnswers[${index}][responseBody]`}
            isKeyword
            isDisabled={false}
            hideUserPersonalization={false}
            placeholder="Message"
            maxChars={maxCharacterLength}
            SavedRepliesOption={SavedRepliesOption}
            setFieldValue={setFieldValue}
            setShortenedLink={setShortenedLink}
            shortenedLink={shortenedLink}
            touched={touched}
            messageFieldOutlineActive={false}
            formPadding="12px 16px 8px 16px"
            segmentCountActive={false}
            editorWrapperPadding="0px"
          />
        </MessageWrapper>
        <Box sx={{ marginLeft: "0.25rem" }}>
          <AutomaticMessageIndicator />
        </Box>
        {sequencesEnabled && (
          <KeywordFormSequenceSelect
            account={account}
            keyword={values.keywordAnswers[index].keyword}
            enrollInSequence={values.keywordAnswers[index].enrollInSequence}
            sequenceId={values.keywordAnswers[index].sequenceId}
            setSequenceId={setSequenceId}
            sequenceFieldName={`keywordAnswers[${index}][sequenceId]`}
            enrollInSequenceFieldName={`keywordAnswers[${index}][enrollInSequence]`}
            setFieldValue={setFieldValue}
            sequences={sequences}
            searchWidth="498px"
            isMobile={isMobile}
          />
        )}
      </AccordionDetails>
      <ConfirmationModal
        title="Delete keyword answer?"
        message={`Incoming messages containing ${values.keywordAnswers[index].keyword} will no longer receive the auto-response.`}
        buttonText="Delete"
        cancellationHandler={handleCloseModal}
        confirmationHandler={handleDelete}
        confirmStyles={{ color: "error", variant: "text" }}
        isOpen={isModalOpen}
      />
    </Accordion>
  );
}

export default SubKeywordForm;
