/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable import/no-extraneous-dependencies */
import isEmpty from "lodash/isEmpty";
import { fromUnixTime, isBefore } from "date-fns";
import { createUserManager } from "redux-oidc";
import { WebStorageStateStore, InMemoryWebStorage } from "oidc-client";
import { oAuthWindowName } from "@tesseract/legacy/app/features/Authentication/constants";
import { ENVIRONMENTS } from "@tesseract/legacy/app/constants/environments";
import { ChromeWindow } from "@tesseract/legacy/app/hooks/useChromeExtension";
import { CHROME_EXTENSION_ID } from "@tesseract/legacy/app/constants/chromeExtensionIds";

declare global {
  interface Window {
    nuauth?: {
      client_id: string;
    };
  }
}

const extensionID =
  process.env.NODE_ENV === ENVIRONMENTS.development
    ? CHROME_EXTENSION_ID.development
    : CHROME_EXTENSION_ID.production;

let config = {};

/* eslint-disable-next-line func-names */
(function () {
  config = {
    authority: `${window.location.origin}/.well-known/openid-configuration`,
    automaticSilentRenew: true,
    client_id: window.nuauth ? window.nuauth.client_id : "",
    redirect_uri: `${window.location.origin}/callback`,
    response_type: "id_token token",
    scope: "openid profile",
    silent_redirect_uri: `${window.location.origin}/silent_renew`,
    stateStore: new WebStorageStateStore({ store: localStorage }),
    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
  };
})();

// create a user manager instance
const userManager = createUserManager(config);
userManager.events.addSilentRenewError(() => {
  window.location.reload();
});

userManager.events.addUserLoaded(() => {
  // Send a message to the sidePanel, received in messageHandler.ts (tesseract-sidepanel repo)
  (window as ChromeWindow).chrome?.runtime?.sendMessage(extensionID, {
    type: "logIn",
  });

  // window.name assigned in features/Authentication/containers/LoginScreen/components/Main.js
  if (window.name === oAuthWindowName) {
    window.close();
  }
});
userManager.events.addUserUnloaded(() => {
  // Send a message to the sidePanel, received in messageHandler.ts (tesseract-sidepanel)
  setTimeout(() => {
    (window as ChromeWindow).chrome?.runtime?.sendMessage(extensionID, {
      type: "logOut",
    });
    // Delay needed to allow time for auth event to complete before iframe is reloaded
  }, 1000);
});

export { userManager };

const sessionStorageHelper = {
  setItem: ({ item, value }: any) => {
    if (window.sessionStorage && value !== undefined) {
      window.sessionStorage.setItem(item, JSON.stringify(value));
    }
  },
  getItem: (item: any, { fallback } = { fallback: undefined }) => {
    if (!window.sessionStorage) return fallback;
    const sessionItem = window.sessionStorage.getItem(item);

    return sessionItem ? JSON.parse(sessionItem) : fallback;
  },
};

const redirectToLoginScreen = () => {
  return userManager.signinRedirect({
    data: {
      redirectUrl: window.location.pathname,
    },
  });
};

const checkAndRefreshUser = async () => {
  try {
    const user = await userManager.getUser();
    const accessToken = sessionStorageHelper.getItem("access_token");
    if (
      !accessToken &&
      (!user || isBefore(fromUnixTime(user.expires_at), new Date()))
    ) {
      await userManager.signinSilent();
    }
  } catch {
    userManager.clearStaleState();
    return redirectToLoginScreen();
  }
};

const getItem = (item: any, { fallback } = { fallback: undefined }) => {
  const localStorageItem = localStorage.getItem(item);

  return localStorageItem ? JSON.parse(localStorageItem) : fallback;
};

const isLoggedOutUser = () => {
  return (
    window.location.pathname === "/logged-out" &&
    isEmpty(getItem("refresh_token"))
  );
};

const isResettingPassword = () => {
  return window.location.pathname.includes("reset_password");
};

const inLoginFlow = () => {
  return ["/callback", "/login", "/auth/reset_password"].includes(
    document.location.pathname,
  );
};

export const login = async () => {
  if (isResettingPassword()) {
    return null;
  }

  if (isLoggedOutUser()) {
    return null;
  }

  if (!inLoginFlow()) {
    return checkAndRefreshUser();
  }

  return null;
};
