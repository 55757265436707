import {
  actionChannel,
  all,
  call,
  delay,
  flush,
  fork,
  put,
  select,
  takeLatest,
  throttle,
} from "redux-saga/effects";
import { buffers } from "redux-saga";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { normalize } from "normalizr";
import { fromJS } from "immutable";
import Honeybadger from "@honeybadger-io/js";

import { enqueueSnackbar } from "notistack";
import actionTypes from "./actionTypes";
import { baseUrl, getOptions } from "utils/apiConfig";
import { actionGenerators as typingIndicatorsActionGenerators } from "features/EntryPoint/containers/TypingIndicators/state";
import { makeRequest } from "utils/generateContainerState/generateSagas";
import { selectors as conversationFilterCollectionSelectors } from "features/Inbox/containers/ConversationFilterCollection/state";
import { selectors as contactModalSelectors } from "features/ContactModal/state";
import {
  selectUser,
  selectRoute,
} from "features/EntryPoint/containers/App/selectors";
import { updateRecords } from "features/EntryPoint/containers/App/actions";
import * as schema from "schema";
import request from "utils/request";
import playInboundAudioNotification from "utils/playInboundAudioNotification";
import getPathnameArray from "utils/getPathnameArray";
import { SnackbarLinkText } from "components/Snackbar/SnackbarLinkText";
import { redirectTo } from "utils/redirect/redirect";

const { selectShowOtherFilters, selectShowCampaignFilters } =
  conversationFilterCollectionSelectors;

const REQUEST_THROTTLE = 10 * 1000;

export const getEndpointsToFetch = ({
  data,
  pathname,
  search = "",
  campaignFiltersShown,
  otherFiltersShown,
  onInbox,
  activeMinboxConversation,
}) => {
  const { accounts = [], conversations = [] } = data;
  const accountSlugs = accounts.map((accountId) => {
    return accountId.replace("/accounts/", "");
  });
  const result = accountSlugs.map((accountSlug) => {
    return {
      url: `/navbar/${accountSlug}/badges`,
      schema: schema.badgeCollection,
    };
  });
  if (
    activeMinboxConversation &&
    conversations.includes(activeMinboxConversation)
  ) {
    result.push({
      url: activeMinboxConversation,
      schema: schema.conversation,
    });
  }
  if (!onInbox) return result;
  const [
    ,
    accountSlug,
    ,
    activeConversationFilterSlug,
    activeConversationSlug,
  ] = getPathnameArray(pathname);
  if (!accountSlugs.includes(accountSlug)) return result;
  result.push({
    url: `/${accountSlug}/conversation_filters`,
    schema: schema.conversationFilterCollection,
  });
  if (otherFiltersShown) {
    result.push({
      url: `/${accountSlug}/conversation_filters?type=other`,
      schema: schema.conversationFilterCollection,
    });
  }
  if (campaignFiltersShown) {
    result.push({
      url: `/${accountSlug}/conversation_filters?type=campaign`,
      schema: schema.conversationFilterCollection,
    });
  }
  result.push({
    url: `/${accountSlug}/conversation_filters/${activeConversationFilterSlug}/conversations${search}`,
    schema: schema.conversationCollection,
  });
  const conversationSlugs = data.conversations.map((id) => {
    return id.slice(id.lastIndexOf("/") + 1);
  });
  if (
    conversationSlugs.includes(activeConversationSlug) ||
    conversationSlugs.length === 0
  ) {
    result.push({
      url: `/conversations/${activeConversationSlug}`,
      schema: schema.conversation,
    });
  }
  return result;
};

export function* fetchEndpoint({ url, options, schemaType, retry = false }) {
  try {
    const record = yield call(request, url, options);
    const { entities } = normalize(record, schemaType);
    return entities;
  } catch (error) {
    if (!retry && /failed to fetch/i.test(error.message)) {
      return yield fetchEndpoint({ url, options, schemaType, retry: true });
    }
    Honeybadger.notify(error, {
      context: {
        user_id: get(global, ["TextUs", "currentUser"]),
        user_email: get(global, ["TextUs", "currentUserEmail"]),
        occured_in_extension: window.self !== window.top,
        url,
      },
      tags: "Pusher, Fetch Endpoint",
    });
    return null;
  }
}

export function* campaignUpdatedSaga({ data }) {
  const immutableRoute = yield select(selectRoute);
  const route = immutableRoute.toJS();
  const feature = get(route, ["location", "pathname"], "").split("/")[2];
  if (feature === "campaigns") {
    try {
      const campaign = yield makeRequest({
        url: data.id,
        method: "GET",
      });
      const { entities } = normalize(campaign, schema.campaign);
      yield put(updateRecords(entities));
    } catch (error) {
      Honeybadger.notify(error, {
        context: {
          user_id: get(global, ["TextUs", "currentUser"]),
          user_email: get(global, ["TextUs", "currentUserEmail"]),
          occured_in_extension: window.self !== window.top,
          data,
        },
        tags: "Pusher, Campaign Update",
      });
    }
  }
}

export function* inboxUpdatedSaga({ data }) {
  const user = yield select(selectUser);
  const immutableRoute = yield select(selectRoute);
  const route = immutableRoute.toJS();
  const pathname = get(route, ["location", "pathname"], "");
  const search = get(route, ["location", "search"], "");
  const onInbox =
    pathname.includes("/inbox") && !pathname.includes("/inbox-settings");

  const onCRMCard = pathname.includes("/c/");

  const otherFiltersShown =
    onInbox && !onCRMCard ? yield select(selectShowOtherFilters) : false;
  const campaignFiltersShown =
    onInbox && !onCRMCard ? yield select(selectShowCampaignFilters) : false;
  const contactModalContainer = yield select(
    contactModalSelectors.selectContactModalContainer,
  );
  const activeMinboxConversation = get(contactModalContainer, [
    "substate",
    "activeConversation",
  ]);
  const endpointsToFetch = yield call(getEndpointsToFetch, {
    data,
    pathname,
    search,
    campaignFiltersShown,
    otherFiltersShown,
    onInbox,
    activeMinboxConversation,
  });
  const options = yield getOptions({ method: "GET", user });
  const entitiesList = yield all(
    endpointsToFetch.map(({ url, schema: schemaType }) => {
      return fetchEndpoint({ url: `${baseUrl}${url}`, options, schemaType });
    }),
  );
  const records = entitiesList
    .filter((entities) => {
      return entities !== null;
    })
    .reduce((prev, entities) => {
      return fromJS(entities).mergeDeep(fromJS(prev)).toJS();
    }, {});
  if (!isEmpty(records)) {
    yield put(updateRecords(records, { mergeFirstPage: true }));
  }
}

export function* accountUpdatedSaga() {
  try {
    const accountNavbar = yield makeRequest({
      url: "/navbar",
      method: "GET",
    });
    const { entities } = normalize(accountNavbar, schema.accountNavbar);
    yield put(updateRecords(entities));
  } catch (error) {
    Honeybadger.notify(error, {
      context: {
        user_id: get(global, ["TextUs", "currentUser"]),
        user_email: get(global, ["TextUs", "currentUserEmail"]),
        occured_in_extension: window.self !== window.top,
      },
      tags: "Pusher, Account Plan Update",
    });
  }
}

const createBatchUpdatedSaga = (type) => {
  return function* batchContactUpdatedSaga(actions) {
    const records = actions.reduce((prev, { data }) => {
      const { entities } = normalize(data, schema[type]);
      return fromJS(entities).mergeDeep(fromJS(prev)).toJS();
    }, {});
    if (!isEmpty(records)) {
      yield put(updateRecords(records));
    }
  };
};

function* batchCampaignRecipientUpdatedSaga(actions) {
  const immutableRoute = yield select(selectRoute);
  const route = immutableRoute.toJS();
  const feature = get(route, ["location", "pathname"], "").split("/")[2];
  const records = actions.reduce((prev, { data }) => {
    const { entities } = normalize(data, schema.campaignRecipient);
    return fromJS(entities).mergeDeep(fromJS(prev)).toJS();
  }, {});
  if (!isEmpty(records) && feature === "campaigns") {
    yield put(updateRecords(records));
  }
}

export function* importUpdatedSaga({ data }) {
  const { entities } = normalize(data, schema.contactImport);
  yield put(updateRecords(entities));
  const accountSlug = data.sourceAccount.split("/")[2];
  const isExtension = window.top !== window.self;
  const importPathname = isExtension
    ? `/${accountSlug}/contacts/import:${data.id.split("/")[2]}`
    : `/${accountSlug}${data.id}`;
  const extensionImportPathname = `/${accountSlug}/contact_imports/extension`;
  const contactFilterPathname = data.contactFilter.replace(
    "contact_filters",
    "contacts",
  );
  if (
    data.state === "processed" &&
    ![importPathname, extensionImportPathname, contactFilterPathname].includes(
      document.location.pathname,
    )
  ) {
    // If user is still on find numbers page, redirect to contacts list.
    if (window.location.pathname.includes("find_numbers")) {
      return redirectTo(importPathname);
    }

    // If user is not on find numbers page, show snackbar.
    // this doesnt do anything - it sends a notice to the redux store but redux doesnt do anything with it. Its necessary to make saga put work.
    const renderSnackbar = () => {
      enqueueSnackbar(
        `${data.recordCount - data.failureCount} numbers imported.`,
        {
          variant: "info",
          action: <SnackbarLinkText to={importPathname}>VIEW</SnackbarLinkText>,
        },
      );
      return { type: "ENQUEUE_SNACKBAR" };
    };
    yield put(renderSnackbar());
  }
  return null;
}

function* clientResponseStartedSaga({ data }) {
  yield put(typingIndicatorsActionGenerators.addTypingIndicator(data));
  yield put(
    typingIndicatorsActionGenerators.eventuallyClearTypingIndicator(data),
  );
}

function* clientResponseEndedSaga({ data }) {
  yield put(typingIndicatorsActionGenerators.removeTypingIndicator(data));
}

function* conversationUpdatedSaga({ data }) {
  const { pathname } = document.location;
  const conversationSlug = data.id.split("/")[2];
  if (pathname.includes("/inbox") && pathname.includes(conversationSlug)) {
    try {
      const conversation = yield makeRequest({
        url: data.id,
        method: "GET",
      });
      const { entities } = normalize(conversation, schema.conversation);
      yield put(updateRecords(entities));
    } catch (error) {
      Honeybadger.notify(error, {
        context: {
          user_id: get(global, ["TextUs", "currentUser"]),
          user_email: get(global, ["TextUs", "currentUserEmail"]),
          occured_in_extension: window.self !== window.top,
          data,
        },
        tags: "Pusher, Conversation Update",
      });
    }
  }
}

function* contactCollectionUpdatedSaga({ data: { contacts } }) {
  const { pathname, search } = document.location;
  const [, currentAccountSlug, currentFeature, currentFilterSlug] =
    pathname.split("/");
  const [, updatedAccountSlug, , updatedFilterSlug] = contacts.split("/");
  if (
    currentAccountSlug !== updatedAccountSlug &&
    currentFeature !== "contacts" &&
    currentFilterSlug !== updatedFilterSlug
  )
    return null;
  try {
    const contactCollectionId = `/${currentAccountSlug}/contact_filters/${currentFilterSlug}/contacts${
      search ? `?${search.replace("?", "")}` : ""
    }`;
    const contactCollection = yield makeRequest({
      url: contactCollectionId,
      method: "GET",
    });
    const { entities } = normalize(contactCollection, schema.contactCollection);
    yield put(updateRecords(entities));
  } catch (error) {
    Honeybadger.notify(error, {
      context: {
        user_id: get(global, ["TextUs", "currentUser"]),
        user_email: get(global, ["TextUs", "currentUserEmail"]),
        occured_in_extension: window.self !== window.top,
        contacts,
      },
      tags: "Pusher, Contact Collection Update",
    });
  }
  return null;
}

export function* handleInboundMessageAudioSaga() {
  return playInboundAudioNotification();
}

/* eslint-disable func-names */
const createUpdateSaga = (type) => {
  return function* ({ data }) {
    const { entities } = normalize(data, schema[type]);
    yield put(updateRecords(entities));
  };
};

/* eslint-disable func-names */
const batch = (ms, pattern, task, ...args) => {
  return fork(function* () {
    const batchChannel = yield actionChannel(pattern, buffers.expanding(1));

    while (true) {
      const actions = yield flush(batchChannel);
      if (actions && actions.length > 0) {
        yield fork(task, ...args, actions);
      }
      yield delay(ms);
    }
  });
};

function* saga() {
  yield all([
    throttle(REQUEST_THROTTLE, actionTypes.INBOX_UPDATED, inboxUpdatedSaga),
    takeLatest(actionTypes.CAMPAIGN_UPDATED, campaignUpdatedSaga),
    takeLatest(actionTypes.CONVERSATION_UPDATED, conversationUpdatedSaga),
    takeLatest(actionTypes.UPDATED, accountUpdatedSaga),
    takeLatest(actionTypes.IMPORT_UPDATED, importUpdatedSaga),
    takeLatest(actionTypes.CLIENT_RESPONSE_STARTED, clientResponseStartedSaga),
    takeLatest(actionTypes.CLIENT_RESPONSE_ENDED, clientResponseEndedSaga),
    takeLatest(
      actionTypes.CONTACT_COLLECTION_UPDATED,
      contactCollectionUpdatedSaga,
    ),
    takeLatest(
      actionTypes.ACCOUNT_ANALYTIC_CREATED,
      createUpdateSaga("accountAnalytics"),
    ),
    takeLatest(
      actionTypes.USER_ANALYTIC_CREATED,
      createUpdateSaga("userAnalytics"),
    ),
    batch(
      REQUEST_THROTTLE,
      actionTypes.CONTACT_UPDATED,
      createBatchUpdatedSaga("contact"),
    ),
    batch(
      REQUEST_THROTTLE,
      actionTypes.CONTACT_PHONE_UPDATED,
      createBatchUpdatedSaga("contactPhone"),
    ),
    batch(
      REQUEST_THROTTLE,
      actionTypes.MESSAGE_UPDATED,
      createBatchUpdatedSaga("message"),
    ),
    batch(
      REQUEST_THROTTLE,
      actionTypes.CAMPAIGN_RECIPIENT_UPDATED,
      batchCampaignRecipientUpdatedSaga,
    ),
    takeLatest(
      actionTypes.INBOUND_MESSAGE_AUDIO,
      handleInboundMessageAudioSaga,
    ),
  ]);
}

export default saga;
