import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ContactFilter, ContactImport } from "@tesseract/core";
import { fetchPreviousImports } from "../api/fetchPreviousImports";
import ContactsSideNav from "./ContactsSideNav";
import { useCurrentAccount } from "hooks";
import { useContactFilterCollection } from "features/Contacts/hooks/useContactFilterCollection";

const DEFAULT_FILTER_SLUGS = new Set([
  "account",
  "blocked",
  "opted-out",
  "never_messaged",
  "unmessageable",
  "recently-deleted",
  "all",
]);

export default function Root() {
  const [previousImports, setPreviousImports] =
    useState<ContactImport.Collection | null>(null);
  const currentAccount = useCurrentAccount();
  const { contactFilterCollection, deleteContactFilterHandler } =
    useContactFilterCollection();

  useEffect(() => {
    if (!currentAccount.slug) {
      return;
    }

    const fetchPreviousImportsHandler = async () => {
      try {
        const response = await fetchPreviousImports(currentAccount.slug);

        if (response.ok) {
          const data: ContactImport.Collection = await response.json();
          setPreviousImports(data);
        }
      } catch {
        console.error("Error fetching previous imports:");
      }
    };

    void fetchPreviousImportsHandler();
  }, [currentAccount.slug]);

  const handleDelete = (filter: ContactFilter.Model) => {
    return (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      void deleteContactFilterHandler(filter);
    };
  };

  const savedFilters = useMemo(() => {
    if (
      contactFilterCollection?.members &&
      contactFilterCollection.members.length > 0
    ) {
      return contactFilterCollection.members?.filter(
        (filter: { slug: string }) => {
          return !DEFAULT_FILTER_SLUGS.has(filter.slug);
        },
      );
    }
    return [];
  }, [contactFilterCollection]);

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column nowrap",
        height: "100%",
        overflow: "auto",
      }}
    >
      <ContactsSideNav
        currentAccount={currentAccount}
        savedFilters={savedFilters}
        previousImports={previousImports?.members}
        handleDelete={handleDelete}
      />
    </Box>
  );
}
