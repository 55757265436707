import { Box } from "@mui/material";
import CollapsibleNavListItem from "./CollapsibleNavListItem";
import NavListItemButton from "./NavListItemButton";
import { PreviousImportsSectionProps } from "./types";

export default function PreviousImportsSection({
  currentAccount,
  imports,
  isOpen = false,
}: PreviousImportsSectionProps) {
  const dateOptions = {
    month: "short", // Short month name (e.g., Feb)
    day: "numeric", // Numeric day (e.g., 21)
    year: "numeric", // Numeric year (e.g., 2024)
    hour: "numeric", // Numeric hour (e.g., 16)
    minute: "2-digit", // 2-digit minute (e.g., 39)
    hour12: true, // 12-hour time format
  };

  return (
    <CollapsibleNavListItem isOpen={isOpen} primary="Previous imports">
      {imports.map((contactImport: any) => {
        const count = contactImport.recordCount - contactImport.failureCount;

        const dateTime = new Date(contactImport.createdAt)
          .toLocaleDateString("en-US", dateOptions)
          .replace(/(\w+) (\d+), (\d+)/, "$1. $2, $3"); // Add period after month.

        // If no filename was given, the import is most likely from Find Numbers.
        const findNumbersText = `Find Numbers - ${dateTime}`;

        const primaryText = contactImport.originalFilename || findNumbersText;

        const secondaryText =
          count === 1 ? `${count} contact` : `${count} contacts`;

        const contactImportId = contactImport.id.split("/").at(-1);

        return (
          <NavListItemButton
            key={contactImport.id}
            primary={primaryText}
            secondary={secondaryText}
            to={`/${currentAccount.slug}/contacts/import:${contactImportId}`}
            nested
          />
        );
      })}
    </CollapsibleNavListItem>
  );
}
