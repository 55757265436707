import PropTypes from "prop-types";
import { Formik, Field, Form, useFormikContext } from "formik";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useEffect } from "react";
import MessageBodyVariants from "./MessageBodyVariants";
import { CampaignsProValidationSchema } from "formHelpers/validationSchemas";
import Loader from "components/Loader";
import Portal from "components/Portal";
import SavedRepliesOption from "components/MessageInputOptions/SavedRepliesOption";

import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";

function CampaignProForm({
  additionalRecipientsRequired,
  attachments,
  currentAccount,
  currentUser,
  disableNextButton,
  editCampaign,
  handleAddVariant,
  handleDeleteVariant,
  handleSubmit,
  messageTemplates,
  onFormChange,
  title,
}) {
  const { campaignMaxMessageLength } = currentAccount.settings;
  const maxCharacterLength =
    campaignMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH;
  const initialValues = { attachments, messageTemplates, title };

  const root = document.querySelector("#textus-NewCampaign-NextButton");

  const mobileRoot = document.querySelector(
    "#mobile-textus-NewCampaign-NextButton",
  );

  const editRoot = document.querySelector(
    "#edit-textus-NewCampaign-NextButton",
  );

  function AutoUpdateParent() {
    const { values } = useFormikContext();

    useEffect(() => {
      // TODO: Remove this line after campaignsResponsive is live
      if (!onFormChange) return;

      onFormChange(values);
    }, [values]);

    return null;
  }

  const characterCountExceeded = ({ messageTemplates: templates }) => {
    return templates.find(({ body, signatureActive }) => {
      const signature = currentUser?.signature?.content ?? "";
      const totalLength = signatureActive
        ? signature.length + body.length
        : body.length;
      return totalLength > maxCharacterLength;
    });
  };

  return (
    <Box
      display="flex"
      flex="1 1 auto"
      flexDirection="column"
      minHeight="0"
      px="1.5rem"
      width="100%"
      sx={{ paddingTop: { xs: "2rem", sm: "0rem" } }}
    >
      <Box display="flex" justifyContent="space-around" mb={2}>
        <Chip label="Campaigns Pro" color="primary" />
      </Box>
      <Typography align="center" variant="h4" fontWeight={700} mb="0.5rem">
        Create message
      </Typography>
      <Typography
        align="center"
        component="p"
        variant="subtitle1"
        color="textSecondary"
        pb="1rem"
      >
        {editCampaign
          ? "Edit the title and the messages for your campaign."
          : "Enter an easy to identify title and up to 3 messages that will be evenly sent to your recipient list."}
      </Typography>
      <Box m="1rem auto" maxWidth="600px" p="0">
        <Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={CampaignsProValidationSchema(maxCharacterLength)}
            validateOnMount={CampaignsProValidationSchema(maxCharacterLength)}
          >
            {({
              errors,
              isSubmitting,
              isValid,
              setFieldValue,
              touched,
              values,
            }) => {
              return (
                <Form id="textus-CampaignProForm">
                  <Field name="title" type="text">
                    {({ field }) => {
                      return (
                        <TextField
                          {...field}
                          autoFocus
                          data-testid="textus-Campaigns-title"
                          error={touched.title && !!errors.title}
                          fullWidth
                          helperText={touched.title && errors.title}
                          inputProps={{ "data-lpignore": true }}
                          label="Title"
                          id="textus-Campaigns-title"
                          placeholder="Best Campaign Ever"
                          type="text"
                          variant="outlined"
                          style={{
                            marginBottom:
                              touched.title && errors.title ? "0" : "1.4rem",
                          }}
                        />
                      );
                    }}
                  </Field>
                  <MessageBodyVariants
                    additionalRecipientsRequired={additionalRecipientsRequired}
                    currentAccount={currentAccount}
                    currentUser={currentUser}
                    editCampaign={editCampaign}
                    errors={errors}
                    handleAddVariant={handleAddVariant}
                    handleDeleteVariant={handleDeleteVariant}
                    SavedRepliesOption={SavedRepliesOption}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                  />
                  {root && (
                    <Portal root={root}>
                      <Button
                        aria-label="Next Button"
                        color="primary"
                        data-testid="campaign-title-and-message-confirmed"
                        disabled={
                          !isValid ||
                          isSubmitting ||
                          characterCountExceeded(values) ||
                          disableNextButton
                        }
                        form="textus-CampaignProForm"
                        type="submit"
                        variant="contained"
                      >
                        <Loader isLoading={isSubmitting}>Next</Loader>
                      </Button>
                    </Portal>
                  )}
                  {mobileRoot && (
                    <Portal root={mobileRoot}>
                      <Button
                        aria-label="Next Button"
                        color="primary"
                        data-testid="campaign-title-and-message-confirmed"
                        disabled={
                          !isValid ||
                          isSubmitting ||
                          characterCountExceeded(values)
                        }
                        form="textus-CampaignProForm"
                        type="submit"
                        variant="text"
                        size="small"
                      >
                        <Loader isLoading={isSubmitting}>Next</Loader>
                      </Button>
                    </Portal>
                  )}
                  {editRoot && (
                    <Portal root={editRoot}>
                      <Button
                        aria-label="Next Button"
                        color="primary"
                        data-testid="campaign-title-and-message-confirmed"
                        disabled={
                          !isValid ||
                          isSubmitting ||
                          characterCountExceeded(values)
                        }
                        form="textus-CampaignProForm"
                        type="submit"
                        variant="text"
                        size="small"
                      >
                        <Loader isLoading={isSubmitting}>Next</Loader>
                      </Button>
                    </Portal>
                  )}
                  <AutoUpdateParent />
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

CampaignProForm.propTypes = {
  additionalRecipientsRequired: PropTypes.bool.isRequired,
  attachments: PropTypes.array,
  currentAccount: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  disableNextButton: PropTypes.bool,
  editCampaign: PropTypes.bool,
  handleAddVariant: PropTypes.func.isRequired,
  handleDeleteVariant: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  messageTemplates: PropTypes.array,
  mobileView: PropTypes.bool,
  onFormChange: PropTypes.func,
  title: PropTypes.string,
};

export default CampaignProForm;
