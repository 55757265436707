import { useState, useRef, useEffect } from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { formatInTimeZone } from "date-fns-tz";
import CSSTransition from "react-transition-group/CSSTransition";
import { useSnackbar } from "notistack";
import { textUsApiV3 as api } from "services";
import { Account } from "models/Account";
import Avatar from "components/Avatar";
import { getAccountTimeZone } from "utils/date";
import { useCurrentUser, useTimestampJump } from "hooks";

interface Note {
  id: string;
  contact: string;
  content: string;
  createdAt: string;
  user: { name: string; id: string };
}

const InlineTextArea = styled("textarea")();

const maxNoteLength = 420;

function EditNote({
  editableNote,
  handleNoteChange,
}: {
  editableNote: string;
  handleNoteChange: (note: string) => void;
}) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    // fouces on the textarea with the cursor at the end
    if (textareaRef.current) {
      textareaRef.current.value = editableNote;
      textareaRef.current.focus();
    }
  }, [editableNote, textareaRef]);

  return (
    <form>
      <Box
        sx={(theme) => {
          return {
            border: `2px dashed ${theme.palette.yellow[300]}`,
            width: "100%",
          };
        }}
      >
        <InlineTextArea
          ref={textareaRef}
          data-testid="note-inline-compose-field"
          sx={{
            width: "100%",
            minHeight: "40px",
            border: 0,
            padding: "10px",
            lineHeight: "24px",
            outline: "none",
            "&:focused": {
              border: 0,
              outline: "none",
            },
            "&:hover": {
              border: 0,
              outline: "none",
            },
          }}
          name="notes"
          placeholder="Notes are only visible to your team."
          value={editableNote}
          cols={100}
          onChange={(e) => {
            if (e.target.value.length <= maxNoteLength) {
              handleNoteChange(e.target.value);
            }
          }}
        />
      </Box>
    </form>
  );
}

function NoteAvatar({
  note,
  shouldCollapse,
}: {
  note: Note;
  shouldCollapse: boolean;
}) {
  return (
    <Box
      sx={{
        minWidth: "33px",
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-end",
      }}
    >
      {!shouldCollapse && (
        <Avatar subject={note.user} tooltip={note.user.name} />
      )}
    </Box>
  );
}

export default function TimelineNote({
  note,
  currentAccount,
}: {
  note: Note;
  currentAccount: Account;
}) {
  const [deleted, setDeleted] = useState(false);
  const [editableNote, setEditableNote] = useState(note.content);
  const [editing, setEditing] = useState(false);
  const timeZone = getAccountTimeZone(currentAccount);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const { resetSidebar } = useTimestampJump();

  const handleSubmit = () => {
    if (editableNote === note.content) {
      return setEditing(false);
    }

    if (editableNote.replaceAll(/\s/g, "") === "") {
      setEditableNote(note.content);
      enqueueSnackbar("Note cannot be empty. Please delete instead.", {
        variant: "error",
      });
      return setEditing(false);
    }

    return api
      .patch(note.id, {
        ...note,
        content: editableNote,
      })
      .then(() => {
        enqueueSnackbar("Note saved.", { variant: "info" });
        resetSidebar();
        return setEditing(false);
      })
      .catch((error) => {
        console.error(error);
        return enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const handleCancel = () => {
    setEditableNote(note.content);
    setEditing(false);
  };

  const handleDelete = () => {
    api
      .destroy(note.id)
      .then(() => {
        setDeleted(true);
        resetSidebar();
        return enqueueSnackbar("Note deleted.", { variant: "info" });
      })
      .catch((error) => {
        console.error(error);
        return enqueueSnackbar("There was a problem saving the note", {
          variant: "error",
        });
      });
  };

  if (deleted) {
    return null;
  }

  return (
    <CSSTransition key={note.id} timeout={600} enter exit={false}>
      <Box
        id={note.id}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px 15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
            maxWidth: "75%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "10px",
            }}
          >
            <Box sx={{ display: "flex", gap: "5px" }}>
              {(editing && (
                <EditNote
                  editableNote={editableNote}
                  handleNoteChange={setEditableNote}
                />
              )) || (
                <Box
                  sx={(theme) => {
                    return {
                      borderRadius: "5px",
                      bgcolor: theme.palette.notes.background,
                      border: `1px solid ${theme.palette.notes.border}`,
                      padding: "10px 15px",
                      display: "flex",
                      alignItems: "end",
                      lineHeight: "25px",
                      fontStyle: "italic",
                      fontSize: theme.typography.body2.fontSize,
                      wordBreak: "break-word",
                    };
                  }}
                >
                  {editableNote}
                </Box>
              )}
              <NoteAvatar note={note} shouldCollapse={false} />
            </Box>
            <Box
              sx={(theme) => {
                return {
                  color: theme.palette.text.secondary,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: theme.typography.caption.fontSize,
                };
              }}
            >
              {(editing && (
                <>
                  <Button
                    onClick={handleSubmit}
                    sx={{
                      padding: "3px 4px",
                      minWidth: "auto",
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={handleCancel}
                    sx={{
                      padding: "3px 4px",
                      minWidth: "auto",
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )) ||
                (note.user.id === currentUser.id && (
                  <>
                    <Button
                      onClick={() => {
                        return setEditing(true);
                      }}
                      sx={{
                        padding: "3px 4px",
                        minWidth: "auto",
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={handleDelete}
                      sx={(theme) => {
                        return {
                          color: theme.palette.error.main,
                          padding: "3px 4px",
                          minWidth: "auto",
                        };
                      }}
                    >
                      Delete
                    </Button>
                    <Typography>|</Typography>
                  </>
                ))}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <StickyNote2Icon
                  sx={{
                    marginRight: "5px",
                    fontSize: "95%",
                  }}
                />
                Internal note
              </Box>
              <Typography>|</Typography>
              <Typography
                sx={(theme) => {
                  return {
                    fontSize: theme.typography.caption.fontSize,
                  };
                }}
              >
                {formatInTimeZone(
                  new Date(note.createdAt),
                  timeZone,
                  "h:mm a z",
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </CSSTransition>
  );
}
