import { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, FormHelperText, Typography, Grid2 as Grid } from "@mui/material";
import Recipients from "../../../sharedComponents/Recipients";
import RecipientsExclusionSection from "../../../sharedComponents/RecipientsExclusionSection";
import { CampaignHeader } from "../CampaignHeader";
import { SelectRecipients } from "./SelectRecipients";
import { StepSection } from "./StepSection";
import { CampaignRecipientsProps } from "./types";
import { SelectGroup } from "./SelectGroup";
// @ts-expect-error Typescript seems to not want to import SVGs but its definitely there.
import MultipleSuccessGraphic from "utils/images/confirmation-checkmark.svg";

import NewCampaignContactFilter from "features/Campaigns/containers/NewCampaignContactFilter";
import NewCampaignContactCollection from "features/Campaigns/containers/NewCampaignContactCollection";

export default function CampaignRecipients({
  activeStep,
  campaignType,
  contactCollectionId,
  contactFilterId,
  contactImport,
  currentAccount,
  dailyCampaignRecipients,
  exclusionTimeInput,
  getRecipientCount,
  recipients,
  selectGroup,
  setCampaignRecipients,
  setGroup,
  setExclusionTimeInput,
  setSelectGroup,
}: CampaignRecipientsProps) {
  // ==== FIELDS ==== //
  const recipientCount = getRecipientCount();
  const error =
    recipientCount > dailyCampaignRecipients &&
    "Number of campaign recipients exceeds maximum daily limit for account.";

  // ==== HOOKS ==== //
  useEffect(() => {
    if (campaignType === "recurring" && activeStep === 1) {
      setSelectGroup(true);
    }
  }, [activeStep, campaignType, setSelectGroup]);

  const showRecipientsContent = Boolean(
    recipients.length > 0 ||
      contactFilterId ||
      (contactImport &&
        contactImport.contactFilter &&
        contactImport?.state === "processed") ||
      contactCollectionId,
  );

  const { featureFlags } = currentAccount;
  // const { recordCount, failureCount } = contactImport;
  // Temp values until API is ready
  const recordCount = 100;
  const failureCount = 10;
  const successCount = recordCount - failureCount;

  const Success = styled.div`
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    justify-content: center;
    margin: 0 auto 2rem;
    max-width: 400px;
    min-height: 0;
    padding: 20px;
    text-align: center;
    a {
      margin: 10px 0;
    }
  `;

  const ImportCategory = styled(Typography)`
    text-align: left;
  `;

  const ImportValue = styled(Typography)`
    text-align: right;
  `;

  // ==== METHODS ==== //
  const handleExclusionTimeChange = (timeInput: number) => {
    return setExclusionTimeInput(timeInput);
  };

  // ==== RENDERS ==== //
  const showRecipientsInnerContent = (): JSX.Element | null => {
    if (recipients.length > 0) {
      return <Recipients recipients={recipients} />;
    }

    if (
      contactFilterId ||
      (contactImport &&
        contactImport.contactFilter &&
        contactImport?.state === "processed")
    ) {
      const filterId =
        typeof contactImport?.contactFilter === "string"
          ? contactImport?.contactFilter
          : contactImport?.contactFilter?.id;
      const campaignContactFilterId = contactFilterId || filterId;

      return (
        <NewCampaignContactFilter
          contactFilterId={campaignContactFilterId}
          currentUrl={document.location.href}
        />
      );
    }

    if (contactCollectionId) {
      return (
        <NewCampaignContactCollection
          contactCollectionId={contactCollectionId}
        />
      );
    }

    return null;
  };

  // Step 2: Select recipients > Select group
  if (selectGroup) {
    return (
      <SelectGroup
        exclusionTimeInput={exclusionTimeInput}
        handleExclusionTimeChange={handleExclusionTimeChange}
        setGroup={setGroup}
        setCampaignRecipients={setCampaignRecipients}
        currentAccount={currentAccount}
      />
    );
  }

  if (showRecipientsContent) {
    return (
      <StepSection>
        <Box
          margin="0 auto"
          sx={{
            maxWidth: { xs: "288px", sm: "536px", md: "712px" },
            width: "100%",
          }}
        >
          <CampaignHeader
            title="Select recipients"
            subtitle="Confirm contacts to receive your campaign."
          />

          {featureFlags.dedupeContacts && (
            <Success>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <img
                      alt="Contact Import Success"
                      src={MultipleSuccessGraphic}
                    />
                    <Typography variant="h6">Import successful!</Typography>
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Grid container spacing={0.5}>
                    <Grid size={9}>
                      <ImportCategory variant="body1">
                        Total contacts
                      </ImportCategory>
                    </Grid>
                    <Grid size={3}>
                      <ImportValue variant="body1">
                        <strong>{successCount}</strong>
                      </ImportValue>
                    </Grid>
                    <Grid size={9}>
                      <ImportCategory variant="body1">New</ImportCategory>
                    </Grid>
                    <Grid size={3}>
                      <ImportValue variant="body1">
                        <strong>{Math.floor(successCount * 0.75)}</strong>
                      </ImportValue>
                    </Grid>
                    <Grid size={9}>
                      <ImportCategory variant="body1">Updated</ImportCategory>
                    </Grid>
                    <Grid size={3}>
                      <ImportValue variant="body1">
                        <strong>{Math.ceil(successCount * 0.25)}</strong>
                      </ImportValue>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Success>
          )}

          <RecipientsExclusionSection
            exclusionTimeInput={exclusionTimeInput}
            handleExclusionTimeChange={handleExclusionTimeChange}
          />

          <Typography color="textSecondary" fontSize="14px" marginBottom="16px">
            <b>Please note:</b> When this Campaign is sent, a message will not
            attempt to send to any number that has since opted out.
          </Typography>

          {error && (
            <FormHelperText
              error
              sx={{
                fontSize: "14px",
                marginBottom: "16px",
              }}
            >
              {error}
            </FormHelperText>
          )}
        </Box>

        <Box
          sx={{
            height: "100%",
            margin: { xs: "0", sm: "0 auto" },
            maxWidth: { xs: "100%", sm: "536px", md: "712px" },
            width: "100%",
          }}
        >
          {showRecipientsInnerContent()}
        </Box>
      </StepSection>
    );
  }

  // Step 2: Select recipients
  return (
    <SelectRecipients
      campaignType={campaignType}
      contactImport={contactImport}
      setSelectGroup={setSelectGroup}
    />
  );
}

CampaignRecipients.propTypes = {
  activeStep: PropTypes.number.isRequired,
  campaignType: PropTypes.string.isRequired,
  contactCollectionId: PropTypes.string,
  contactFilterId: PropTypes.string,
  contactImport: PropTypes.object,
  currentAccount: PropTypes.object.isRequired,
  dailyCampaignRecipients: PropTypes.number.isRequired,
  exclusionTimeInput: PropTypes.number,
  getRecipientCount: PropTypes.func.isRequired,
  recipients: PropTypes.array.isRequired,
  selectGroup: PropTypes.bool.isRequired,
  setCampaignRecipients: PropTypes.func.isRequired,
  setExclusionTimeInput: PropTypes.func.isRequired,
  setGroup: PropTypes.func.isRequired,
  setSelectGroup: PropTypes.func.isRequired,
};
