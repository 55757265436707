import { IconButton, Tooltip } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { HealthCheckIconButtonProps } from "./types";

export function HealthCheckIconButton({
  integration,
}: HealthCheckIconButtonProps) {
  const { isHealthy } = integration ?? {};
  return (
    <Tooltip
      placement="bottom"
      // This slotProps moves the tooltip closer to the icon
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      title={
        isHealthy
          ? "No critical errors detected"
          : "Critical errors detected, please check activity report"
      }
    >
      {/* Luke TODO: Create click handler for button that will route user to health check page. E.g. history.push(healtchCheckPageRoute) */}
      <IconButton color={isHealthy ? "success" : "warning"}>
        {isHealthy ? <CheckCircleOutlineIcon /> : <WarningAmberIcon />}
      </IconButton>
    </Tooltip>
  );
}
