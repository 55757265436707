import { useHistory } from "react-router";
import {
  List,
  Divider,
  IconButton,
  Tooltip,
  useTheme,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import { Apartment, CloudUpload, Delete, Merge } from "@mui/icons-material";
import IconWithTheme from "./IconWithTheme";
import CollapsibleNavListItem from "./CollapsibleNavListItem";
import { ContactsSideNavProps, NavListItemProps } from "./types";
import ContactImportsListItem from "./ContactImportsListItem";
import NavListItemButton from "./NavListItemButton";
import PreviousImportsSection from "./PreviousImportsSection";
import { DeleteIcon } from "icons";

// ==== NAV DATA ==== //
const newAccountNavListItems: NavListItemProps[] = [
  {
    to: "groups",
    primary: "Groups",
  },
  {
    to: "segments",
    primary: "Segments",
  },
];
const accountNavListItems: NavListItemProps[] = [
  {
    to: "blocked",
    primary: "Blocked contacts",
  },
  {
    to: "opted-out",
    primary: "Opted out contacts",
  },
  {
    to: "never_messaged",
    primary: "Never messaged",
  },
  {
    to: "unmessageable",
    primary: "Invalid recipients",
  },
];

const BTN_LEFT_PADDING = "16px";

export default function ContactsSideNav({
  currentAccount,
  previousImports = [],
  savedFilters,
  handleDelete,
}: ContactsSideNavProps) {
  const {
    featureFlags: { restoreContacts, newContactSearch, contactSegmentation },
    effectiveRole,
  } = currentAccount;
  const theme = useTheme();
  const history = useHistory();
  if (
    restoreContacts &&
    ["admin", "support"].includes(effectiveRole) &&
    !accountNavListItems.some((item) => item.to === "recently-deleted")
  ) {
    accountNavListItems.push({
      to: "recently-deleted",
      primary: "Recently removed",
    });
  }

  // ==== RENDER ==== //
  return (
    <Box
      sx={
        contactSegmentation
          ? {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignContent: "space-between",
              height: "100%",
              paddingTop: "0px",
            }
          : {
              paddingTop: "0px",
            }
      }
    >
      <Box>
        <List sx={{ paddingLeft: "0px", width: "100%" }}>
          {/* All contacts */}
          <NavListItemButton
            {...(contactSegmentation
              ? {
                  icon: (
                    <Apartment sx={{ color: theme.palette.grayscale[700] }} />
                  ),
                }
              : {})}
            primary="All contacts"
            to="all"
            buttonSx={{ paddingLeft: BTN_LEFT_PADDING }}
          />

          {/* On this account */}
          <CollapsibleNavListItem primary="On this account" to="account">
            {contactSegmentation
              ? newAccountNavListItems.map((item) => {
                  return (
                    <NavListItemButton
                      key={item.primary as string}
                      primary={item.primary}
                      handleClick={() =>
                        history.push(`/${currentAccount.slug}/${item.to}`)
                      }
                      nested
                    />
                  );
                })
              : accountNavListItems.map((item) => {
                  return (
                    <NavListItemButton
                      key={item.primary as string}
                      {...item}
                      nested
                    />
                  );
                })}
          </CollapsibleNavListItem>

          {/* Saved searches */}
          {savedFilters.length > 0 && (
            <CollapsibleNavListItem isOpen={false} primary="Saved searches">
              {savedFilters.map((filter: any) => {
                return (
                  <NavListItemButton
                    key={filter.id}
                    primary={filter.title}
                    to={
                      newContactSearch
                        ? filter.id
                        : decodeURIComponent(filter.slug)
                    }
                    nested
                    showSecondaryOnActive
                    showSecondaryOnHover
                    secondaryAction={
                      <Tooltip title="Delete Saved Search">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          data-testid={`delete-saved-search-${filter.id}`}
                          onClick={(event) => {
                            handleDelete(filter)(event);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                );
              })}
            </CollapsibleNavListItem>
          )}
        </List>
      </Box>

      {contactSegmentation ? (
        // this is strictly here to prevent directly nesting <li> elements
        <List sx={{ paddingLeft: "0px" }}>
          <Divider />
          {/* Manage contacts */}
          {currentAccount.contactsEnabled && (
            <ListItem>
              <ListItemText
                primary="Manage Contacts"
                sx={{
                  "& .MuiTypography-root": {
                    textTransform: "uppercase",
                    fontSize: "12px",
                    fontWeight: "700",
                  },
                  paddingLeft: "0px",
                }}
              />
            </ListItem>
          )}
          {/* contact imports */}
          <NavListItemButton
            icon={<IconWithTheme IconComponent={CloudUpload} />}
            primary="Contact imports"
            handleClick={() =>
              history.push(`/${currentAccount.slug}/contact_imports`)
            }
            buttonSx={{ paddingLeft: BTN_LEFT_PADDING }}
          />

          {/* detected duplicates */}
          <NavListItemButton
            icon={<IconWithTheme IconComponent={Merge} />}
            primary="Detected duplicates"
            handleClick={() =>
              history.push(`/${currentAccount.slug}/duplicate_contacts`)
            }
            buttonSx={{ paddingLeft: BTN_LEFT_PADDING }}
          />
          {/* recently removed */}
          <NavListItemButton
            icon={<IconWithTheme IconComponent={Delete} />}
            primary="Recently removed"
            handleClick={() =>
              history.push(`/${currentAccount.slug}/recently_removed`)
            }
            buttonSx={{ paddingLeft: BTN_LEFT_PADDING }}
          />
        </List>
      ) : (
        <Box>
          <Divider />
          <List>
            {/* Contact imports */}
            {currentAccount.contactsEnabled && (
              <ContactImportsListItem currentAccount={currentAccount} />
            )}

            {/* Previous imports */}
            <PreviousImportsSection
              isOpen={false}
              imports={previousImports}
              currentAccount={currentAccount}
            />
          </List>
        </Box>
      )}
    </Box>
  );
}
