import { useState } from "react";
import { Box, Button, Slider, Typography } from "@mui/material";

import type { FormikHelpers } from "formik";
import type { Account } from "@tesseract/core";

import { useAccountSettingsSubmit } from "../hooks";
import BranchAccountWarning from "./BranchAccountWarning";
import LockForm from "./LockForm";
import {
  MAX_MESSAGE_LENGTHS_OPTIONS,
  DEFAULT_MAX_MESSAGE_LENGTH,
} from "constants/defaults";
import PageHeader from "components/Page/PageHeader";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";

type FormValues = {
  settings: {
    [key: string]: {
      value?: string | number | boolean;
      locked?: boolean;
    };
  };
};
interface CharacterLimitProps {
  account: Account.Raw;
  handleSubmit: (
    values: FormValues,
    actions: Partial<FormikHelpers<object>>,
    successMessage?: string,
  ) => void;
  location: Location;
  toggleSidebar: () => boolean;
}

function CharacterLimit({
  account,
  handleSubmit,
  location,
  toggleSidebar,
}: CharacterLimitProps) {
  const { handleSubmit: handleSettingsSubmit } = useAccountSettingsSubmit();

  const { campaignMaxMessageLength, conversationMaxMessageLength } =
    account.settings;

  const [conversationLength, setConversationLength] = useState(
    conversationMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH,
  );
  const [campaignLength, setCampaignLength] = useState(
    campaignMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH,
  );

  const fromCampaign = !!location.pathname.includes("/campaign");
  const isParent = account.familyCount > 1;
  const messageLengthOptions = MAX_MESSAGE_LENGTHS_OPTIONS;
  const settingName = fromCampaign
    ? "campaignMaxMessageLength"
    : "conversationMaxMessageLength";

  const { locked } = account.settings[settingName];

  const handleValueChange = (event: Event, value: number | number[]) => {
    return fromCampaign
      ? setCampaignLength(Number(value))
      : setConversationLength(Number(value));
  };

  const valueLabelFormat = (value: number) => {
    return (
      messageLengthOptions.find((option) => {
        return option.value === value;
      })?.valueLabel || `${value} Characters`
    );
  };

  const handleLock = (values: FormValues, actions: FormikHelpers<object>) => {
    handleSettingsSubmit(
      {
        settings: {
          [settingName]: {
            locked: values.settings[settingName].locked,
          },
        },
      },
      actions,
    );
  };

  return (
    <SettingsPageWrapper
      aria-label={`${
        fromCampaign ? "Campaign" : "Message"
      } Character Limit Setting`}
    >
      <PageHeader title="Character limit" toggleSidebar={toggleSidebar}>
        <LockForm
          account={account}
          handleSubmit={handleLock}
          settings={[settingName]}
        />
      </PageHeader>
      <SettingsPageContent
        aria-label={`${
          fromCampaign ? "Campaign" : "Message"
        } Character Limit Settings Content`}
        overflow="auto"
      >
        {isParent && (
          <BranchAccountWarning subAccountCount={account.familyCount - 1} />
        )}
        <Box>
          <Typography variant="h5" color="primary" gutterBottom>{`${
            fromCampaign ? "Campaign" : "Message"
          } Character Limit`}</Typography>
          <Typography>{`Set character limit for all ${
            fromCampaign ? "campaign" : "conversation"
          } messages sent from this account and its child accounts.`}</Typography>
          <Slider
            disabled={locked}
            aria-label="Max Character Length Slider"
            aria-valuetext={`${
              fromCampaign ? campaignLength : conversationLength
            } characters`}
            value={
              fromCampaign ? Number(campaignLength) : Number(conversationLength)
            }
            marks={messageLengthOptions}
            min={messageLengthOptions[0].value}
            max={messageLengthOptions.at(-1)?.value}
            onChange={handleValueChange}
            step={null}
            valueLabelDisplay="auto"
            valueLabelFormat={valueLabelFormat}
            sx={{ mt: "10%" }}
          />
          <Button
            aria-label="Save"
            disabled={locked}
            color="primary"
            onClick={() => {
              return handleSubmit(
                {
                  settings: {
                    conversationMaxMessageLength: { value: conversationLength },
                    campaignMaxMessageLength: { value: campaignLength },
                  },
                },
                { setErrors: () => {}, setSubmitting: () => {} },
              );
            }}
            type="submit"
            variant="contained"
            sx={{ mt: "20%", ml: "90%" }}
          >
            Save
          </Button>
        </Box>
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

export default CharacterLimit;
