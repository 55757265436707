import { Box, Typography, useTheme } from "@mui/material";
import { NoResultsScreenProps } from "./types";
import BlankSearchIcon from "icons/BlankSearchIcon";
import NoResultsIcon from "icons/NoResultsIcon";
import EmptyFolderIcon from "icons/EmptyFolderIcon";

function NoResultsScreen({
  source,
  active,
  descriptor,
  padding,
  noItemsCreated = false,
  noItemsVerb,
}: NoResultsScreenProps) {
  const textOptions = {
    noSearchResults: `No matching ${descriptor || ""} ${source} found.`,
    noItemsCreated: `No ${source} ${noItemsVerb ?? "created"} yet.`,
    campaigns: "Search by campaign name.",
    contacts: "You can search by name or number to find an individual contact.",
    conversations: "You can search by name, number, or conversation content.",
    sequences: "Search by sequence name.",
    groups: "Search by group name.",
    keywords: "Search by keyword name.",
  };

  const theme = useTheme();

  const emptyTable = noItemsCreated && !active; // Table is empty, no search entered

  const renderIcon = () => {
    if (emptyTable) {
      return <EmptyFolderIcon mode={theme.palette.mode} />;
    }

    return active ? (
      <NoResultsIcon mode={theme.palette.mode} /> // Search entered, no results found
    ) : (
      <BlankSearchIcon mode={theme.palette.mode} /> // No search entered
    );
  };

  const renderText = () => {
    if (emptyTable) {
      return textOptions.noItemsCreated;
    }

    return active ? textOptions.noSearchResults : textOptions[source];
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding={padding || "1.5rem 1rem"}
      height="100%"
      width="100%"
    >
      {renderIcon()}
      <Typography
        variant="body2"
        align="center"
        pt="1rem"
        sx={{ color: theme.palette.text.secondary }}
      >
        {renderText()}
      </Typography>
    </Box>
  );
}

export { NoResultsScreen };
