import {
  Box,
  Checkbox,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { ConfirmationModalProps } from "./types";
import { DialogFooter } from "components/DialogFooter";
import { SimpleDialog } from "components/SimpleDialog";

function ConfirmationModal({
  additionalMessage,
  boldText,
  confirmStyles,
  buttonText = "Confirm",
  cancellationHandler,
  confirmationHandler,
  doNotAskAgainText,
  isOpen,
  localStorageKey,
  message,
  title,
}: ConfirmationModalProps) {
  const makeTextBold = () => {
    return <strong>{boldText}</strong>;
  };

  return (
    <SimpleDialog open={isOpen} title={title} handleClose={cancellationHandler}>
      <DialogContent sx={{ padding: "1rem" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <DialogContentText
            aria-label="Confirmation Message"
            variant="body1"
            color="text.primary"
            marginBottom="1rem"
          >
            {message}
            {boldText && makeTextBold()}
            {additionalMessage}
          </DialogContentText>
          <Typography variant="body1" fontWeight={700}>
            Are you sure you want to continue?
          </Typography>
        </Box>
      </DialogContent>
      {doNotAskAgainText ? (
        <DialogContent
          aria-label="Preference Message"
          color="text.primary"
          sx={{
            padding: "0.5rem 1rem",
            background: (theme) => {
              return theme.palette.background.default;
            },
            borderTop: "1px solid #0000001F",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                onChange={(event) => {
                  if (localStorageKey) {
                    localStorage.setItem(
                      localStorageKey,
                      event.target.checked.toString(),
                    );
                  }
                }}
              />
            }
            label={`Do not ask me again about ${doNotAskAgainText}`}
          />
        </DialogContent>
      ) : null}
      <DialogFooter
        confirmColor={confirmStyles?.color}
        confirmText={buttonText}
        confirmVariant={confirmStyles?.variant}
        onConfirm={confirmationHandler}
        onCancel={cancellationHandler}
      />
    </SimpleDialog>
  );
}

export default ConfirmationModal;
