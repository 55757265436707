import { Avatar, Box, useMediaQuery, Theme } from "@mui/material";
import { textUsColors } from "@tesseract/theme/src/palettes/colors";
import { DirectoryTreeProps } from "./types";

function DirectoryTree({
  icon,
  iconPadding,
  rootComponent,
  childComponents,
  optionalHeight,
  optionalLastChildMargin,
}: DirectoryTreeProps) {
  
  const isMobile = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const renderIcon = () => {
    return (
      <Box
        sx={{
          paddingTop: iconPadding || "0px",
          display: "flex",
          flexDirection: "row",
          height: "36px",
          width: "100%",
          backgroundColor: (theme) => {
            return theme.palette.background.paper;
          },
        }}
      >
        <Avatar
          sx={(theme) => ({
            bgcolor: "blueGrey.800",
            width: "32px",
            height: "32px",
            color: theme.palette.common.white,
          })}
        >
          {icon}
        </Avatar>
      </Box>
    );
  };

  const renderChildren = () => {
    const style = {
      marginLeft: "15px",
      borderStyle: isMobile ? "none" : "none none solid solid",
      borderRadius: "0 0 0 0.5rem",
      borderColor: textUsColors.grayscale[400],
      width: "73px",
      height: optionalHeight ?? "30px",
    };

    const lastChildStyle = {
      marginTop: optionalLastChildMargin
        ? `calc(-1000000px + ${optionalLastChildMargin})`
        : "calc(-1000000px + 19px)",
      height: "1000000px",
      zIndex: "-1",
    };

    return childComponents.map((child, index) => {
      const key = `keyword${index}`;
      return (
        <Box
          key={key}
          sx={{
            display: isMobile ? "block" : "grid",
            gridTemplateColumns: "calc(2rem + 40px) 1fr",
            width: "100%",
            marginBottom: isMobile ? "0px" : "16px",
          }}
        >
          <Box
            sx={
              index === childComponents.length - 1
                ? {
                    ...style,
                    ...lastChildStyle,
                  }
                : style
            }
          />
          {child}
        </Box>
      );
    });
  };

  return (
    <Box
      data-testid="directory-tree"
      sx={{
        display: isMobile ? "flex" : "grid",
        flexDirection: "column",
        gridTemplateColumns: "32px 32px 1fr",
        columnGap: "8px",
        rowGap: isMobile ? "0px" : "16px",
        overflow: "hidden",
        marginBottom: "500px",
      }}
    >
      {isMobile ? null : renderIcon()}
      <Box
        sx={{
          display: "grid",
          gridArea: "1 / 2 / 2 / 4",
        }}
      >
        {rootComponent}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gridArea: "2 / 1 / 4 / 4",
        }}
      >
        {renderChildren()}
      </Box>
    </Box>
  );
}

export { DirectoryTree };
