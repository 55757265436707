import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { normalize } from "normalizr";
import { ContactFilter } from "@tesseract/core";
import {
  deleteContactFilter,
  deleteContactFilterV4,
  fetchContactFilterCollection,
  fetchContactFilterCollectionV4,
} from "../api";
import {
  createContactFilter,
  createContactFilterV4,
} from "../api/createContactFilter";
import * as schema from "schema";
import { UPDATE_RECORDS } from "features/EntryPoint/containers/App/constants";
import { useCurrentAccount } from "hooks";
import denormalizeWithShape from "utils/denormalizeWithShape";

function useContactFilterCollection() {
  const [contactFilterCollection, setContactFilterCollection] =
    useState<ContactFilter.Collection>({} as ContactFilter.Collection);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { slug, featureFlags } = useCurrentAccount();
  const dispatch = useDispatch();

  const records = useSelector((state: DefaultRootState & { get: any }) => {
    return {
      ...state.get("global").get("records").toJS(),
    };
  });

  // Disconnected components use this hook, which means they have discrete instances.
  // Each instance needs a way to access a shared state, so subscribe to the redux store.
  useEffect(() => {
    if (Object.keys(records || {}).length === 0) {
      return;
    }

    const shape = {
      members: [],
    };

    const denormalized = denormalizeWithShape({
      id: `/${slug}/contact_filters`,
      records,
      shape,
    });

    if (
      denormalized?.members &&
      contactFilterCollection.members &&
      denormalized.members.length !== contactFilterCollection.members.length
    ) {
      // update the local state for each consumer
      setContactFilterCollection(denormalized);
    }
  }, [records, slug, contactFilterCollection?.members]);

  const reshapeV4Data = useCallback(
    (data: any) => {
      return {
        "@context": "/contexts/hydra:Collection.jsonld",
        "@type": "hydra:Collection",
        id: `/${slug}/contact_filters`,
        members: data.items,
        totalItems: data.items.length,
      } as ContactFilter.Collection;
    },
    [slug],
  );

  const sendFetchRequest = useCallback(async () => {
    if (!slug) return;

    setIsLoading(true);

    let data: ContactFilter.Collection;

    if (featureFlags?.newContactSearch) {
      const res = await fetchContactFilterCollectionV4(
        `/${slug}/contact_filters`,
      );
      const raw = await res.json();
      data = reshapeV4Data(raw);
    } else {
      const res = await fetchContactFilterCollection(
        `/${slug}/contact_filters`,
      );
      data = await res.json();
    }

    setContactFilterCollection(data);
    const { entities } = normalize(data, schema.contactFilterCollection);

    dispatch({
      type: UPDATE_RECORDS,
      records: entities,
    });

    setIsLoading(false);
  }, [slug, dispatch, reshapeV4Data, featureFlags?.newContactSearch]);

  const createContactFilterHandler = useCallback(
    async (id: string, data: { savedPath: string; title: string }) => {
      if (featureFlags?.newContactSearch) {
        await createContactFilterV4(id, data);
        await sendFetchRequest();
      } else {
        await createContactFilter(id, data);
        await sendFetchRequest();
      }
    },
    [sendFetchRequest, featureFlags?.newContactSearch],
  );

  const deleteContactFilterHandler = useCallback(
    async (filter: ContactFilter.Model) => {
      if (featureFlags?.newContactSearch) {
        const contactFilterId = `/${slug}/contact_filters/${filter.id}`;
        await deleteContactFilter(contactFilterId);
      } else {
        await deleteContactFilterV4(filter.id);
      }
      await sendFetchRequest();
    },
    [sendFetchRequest, featureFlags?.newContactSearch, slug],
  );

  // Fetch the contact filter collection on mount
  useEffect(() => {
    void sendFetchRequest();
  }, [sendFetchRequest, slug]);

  return {
    contactFilterCollection,
    sendFetchRequest,
    createContactFilterHandler,
    deleteContactFilterHandler,
    isLoading,
  };
}

export { useContactFilterCollection };
