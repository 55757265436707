import { Account } from "@tesseract/core";
import { textUsApiV4 } from "services";

export const updateKeyword = (
  currentAccount: Account.Raw,
  id: string,
  body: any,
): Promise<Response> => {
  return textUsApiV4.patch(
    `/${currentAccount.slug}/automation_keywords/${id}`,
    body,
  );
};
