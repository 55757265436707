import { useFormikContext } from "formik";
import { SUBKEYWORD_DEFAULT_MAX_LENGTH } from "../constants";
import RootKeywordForm from "./RootKeywordForm";
import SubKeywordForm from "./SubKeywordForm";
import AddKeywordAnswerButton from "./AddKeywordAnswerButton";
import { KeyIcon } from "icons";
import { DirectoryTree } from "components/DirectoryTree";

function KeywordFormDirectoryTree({
  currentAccount,
  sequences,
  isMobile,
  savedKeyword,
}: {
  sequences: any[];
}) {
  const { values } = useFormikContext();

  const addKeywordAnswerButton = (disabled: boolean) => {
    return <AddKeywordAnswerButton disabled={disabled} />;
  };

  const childComponents = () => {
    const { keywordAnswers } = values;
    const keywordAnswersComponents = keywordAnswers.map(
      (_keywordAnswer: any, index: number) => {
        const key = `keywordAnswer${index}`;
        return (
          <SubKeywordForm
            key={key}
            index={index}
            currentAccount={currentAccount}
            sequences={sequences}
            isMobile={isMobile}
            savedKeyword={savedKeyword}
          />
        );
      },
    );

    const disabled = keywordAnswers.length >= SUBKEYWORD_DEFAULT_MAX_LENGTH;

    return [...keywordAnswersComponents, addKeywordAnswerButton(disabled)];
  };

  const icon = <KeyIcon sx={{ fontSize: 20 }} />;

  return (
    <DirectoryTree
      icon={icon}
      rootComponent={
        <RootKeywordForm sequences={sequences} isMobile={isMobile} />
      }
      childComponents={childComponents()}
    />
  );
}

export default KeywordFormDirectoryTree;
