import { textUsApiV3, textUsApiV4 } from "services";

function createContactFilter(
  id: string,
  data: {
    savedPath: string;
    title: string;
  },
): Promise<Response> {
  return textUsApiV3.post(id, data);
}

function createContactFilterV4(
  id: string,
  data: {
    savedPath: string;
    title: string;
  },
): Promise<Response> {
  return textUsApiV4.post(id, data);
}

export { createContactFilter, createContactFilterV4 };
