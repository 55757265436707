import { compose } from "redux";
import { SyntheticEvent, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid2 as Grid,
  IconButton,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { CampaignCollection } from "../CampaignCollection";
import { CampaignOverviewCollection } from "../CampaignOverviewCollection";
import { CampaignOverviewAnalytics } from "../CampaignOverviewAnalytics";
import { CampaignLandingProps } from "./types";
import withRecord from "higherOrderComponents/withRecord";

import {
  campaignFilterSlugs,
  campaignLandingTabs,
  campaignTypes,
} from "features/Campaigns/constants";
import Loader from "components/Loader";
import PageHeader from "components/Page/PageHeader";
import { CloudDownloadIcon } from "icons";
import { TabPanel } from "components/TabPanel";
import { useCampaignExport } from "features/Campaigns/hooks";

function CampaignLanding({
  campaignFilter,
  currentAccount,
  toggleSidebar,
}: CampaignLandingProps) {
  // ==== FIELDS ==== //
  const { overview, oneTime, recurring } = campaignLandingTabs;
  const { all, allScheduled } = campaignFilterSlugs;
  const { multiUser } = currentAccount;

  const allCampaigns = [
    {
      displayCampaignType: true,
      emptyState: "No campaigns scheduled",
      header: "Upcoming",
      sendVerbiage: "Sending",
      slug: allScheduled,
    },
    {
      displayCampaignType: false,
      emptyState: "No campaigns sent",
      header: "Previously sent",
      sendVerbiage: "Sent",
      slug: all,
    },
  ];

  // ==== HOOKS ==== //
  const { isLoading, handleExport } = useCampaignExport(campaignFilter);
  const [currentTab, setCurrentTab] = useState(0);
  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  // ==== METHODS ==== //
  const handleChange = (event: SyntheticEvent, newTabValue: number) => {
    setCurrentTab(newTabValue);
  };

  // ==== RENDER ==== //
  return (
    <Box
      display="flex"
      flexDirection="column"
      fontSize="14px"
      height="100%"
      margin="0 auto"
      position="relative"
      color="text.primary"
    >
      <PageHeader title={campaignFilter.title} toggleSidebar={toggleSidebar}>
        <Box flex="0 0 auto" pr="10px">
          {mobileScreen ? (
            <Tooltip title="Export data">
              <IconButton
                onClick={handleExport}
                data-testid="campaign-data-exported"
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Button
              data-test="ExportCampaignFilterButton"
              data-testid="campaign-data-exported"
              color="primary"
              onClick={handleExport}
              variant="outlined"
            >
              <Loader isLoading={isLoading}>Export Data</Loader>
            </Button>
          )}
        </Box>
      </PageHeader>
      <Box
        display="flex"
        flex="1 1 auto"
        flexDirection="column"
        height="50vh"
        position="relative"
      >
        <Tabs
          aria-label="Overview, One Time, and Recurring Tabs"
          data-testid="OverviewAndOneTimeTabs"
          indicatorColor="primary"
          onChange={handleChange}
          textColor="primary"
          variant="scrollable"
          scrollButtons={mobileScreen}
          allowScrollButtonsMobile
          value={currentTab}
        >
          <Tab aria-label="Overview" label="Overview" value={overview} />
          <Tab
            aria-label="One-time"
            label="One-time"
            id="OneTimeTab"
            value={oneTime}
          />

          <Tab aria-label="Recurring" label="Recurring" value={recurring} />
        </Tabs>
        <Divider />
        <Box height="100%" width="100%" sx={{ overflowX: "auto" }}>
          {currentTab === overview ? <CampaignOverviewAnalytics /> : null}
          <TabPanel value={currentTab} index={0}>
            <Box m="1rem 2rem">
              <Box my="1.5rem" aria-label="Past and Scheduled Campaigns">
                <Grid container spacing={3}>
                  {allCampaigns.map(
                    ({
                      displayCampaignType,
                      emptyState,
                      header,
                      sendVerbiage,
                      slug,
                    }) => {
                      return (
                        <CampaignOverviewCollection
                          campaignFilter={campaignFilter}
                          ariaLabel={header}
                          campaignCollectionId={`${currentAccount.campaignFilters}/${slug}/campaigns`}
                          currentAccount={currentAccount}
                          displayCampaignType={displayCampaignType}
                          emptyState={emptyState}
                          header={header}
                          key={slug}
                          sendVerbiage={sendVerbiage}
                        />
                      );
                    },
                  )}
                </Grid>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <CampaignCollection
              campaignCollectionId={`${currentAccount.campaignFilters}/${all}/campaigns`}
              campaignType={campaignTypes.oneTime}
              currentAccount={currentAccount}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <CampaignCollection
              campaignCollectionId={`${currentAccount.campaignFilters}/${all}/campaigns`}
              campaignType={campaignTypes.recurringInstance}
              currentAccount={currentAccount}
            />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
}

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "Campaigns/containers/CampaignLanding",
    shape: { campaigns: {} },
    type: "campaignFilter",
  }),
)(CampaignLanding);
