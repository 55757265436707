import { Component, useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Button, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { formatInTimeZone } from "date-fns-tz";
import { useDispatch } from "react-redux";
import NoteForm from "./NoteForm";
import List from "components/List";
import Avatar from "components/Avatar";
import { DeleteIcon, EditIcon, ChevronRightFilled } from "icons";
import { getAccountTimeZone } from "utils/date";
import { useTimestampJump } from "hooks";

const Wrapper = styled.div`
  font-size: 1rem;
  width: 100%;
`;

const EmptySection = styled.div`
  padding: 15px 20px;
`;

const ListItem = styled.li`
  font-size: 0.8rem;
  padding: 15px 10px 16px 20px;
  width: 100%;
  border-bottom: solid 1px
    ${(props) => {
      return props.theme.colors.divider;
    }};
  &:last-child {
    border-bottom: none;
  }
`;

const ListItemHeader = styled.header`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`;

const ListItemAvatar = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;
`;

const ListItemText = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
`;

const ListItemAction = styled.div`
  flex: 0 0 40px;
`;

const ListItemContent = styled.div`
  font-size: 0.8rem;
  margin-top: 10px;
  margin-right: 10px;
`;

const NewNoteRow = styled.div`
  padding: 0 20px 20px 20px;
  text-align: right;
`;

function NoteListItemHeader({
  note,
  account,
  noteBeingEdited,
  handleDelete,
  timelineJump,
  featureFlag,
}) {
  const { setTimestampJump } = useTimestampJump();
  const accountTimeZone = getAccountTimeZone(account);

  const cursorStyle =
    featureFlag && noteBeingEdited !== note.id && note.conversation
      ? { cursor: "pointer" }
      : { cursor: "default" };
  return (
    <Box
      sx={cursorStyle}
      onClick={() => {
        if (
          (featureFlag && noteBeingEdited === note.id) ||
          !note.conversation ||
          !timelineJump
        ) {
          return;
        }

        setTimestampJump({
          timestamp: note.createdAt,
          memberId: note.id,
          conversationId: note.conversation,
          accountSlug: account.slug,
          memberSlug: note.accountSlug,
        });
      }}
    >
      <ListItemHeader>
        <ListItemAvatar>
          <Avatar subject={note.user} />
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="body2" color="text.primary">
            {note.user.name}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {formatInTimeZone(
              new Date(note.updatedAt),
              accountTimeZone,
              "MM/dd/yyyy hh:mm a z",
            )}
          </Typography>
        </ListItemText>
        <ListItemAction>
          {featureFlag && note.conversation && noteBeingEdited !== note.id && (
            <IconButton data-testid="view-in-conversation-button">
              <Tooltip title="View in conversation">
                <ChevronRightFilled size="medium" />
              </Tooltip>
            </IconButton>
          )}

          {noteBeingEdited === note.id && (
            <Tooltip title="Delete Note">
              <IconButton
                data-testid="delete-note-button"
                onClick={handleDelete(note.id)}
                size="large"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </ListItemAction>
      </ListItemHeader>
    </Box>
  );
}

NoteListItemHeader.propTypes = {
  note: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  noteBeingEdited: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  timelineJump: PropTypes.bool,
  featureFlag: PropTypes.bool,
};

export class RootClass extends Component {
  static propTypes = {
    activeConversationId: PropTypes.string,
    contactNoteCollection: PropTypes.object.isRequired,
    contactNoteCollectionId: PropTypes.string.isRequired,
    createContactNoteRequest: PropTypes.func.isRequired,
    currentAccount: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    deleteContactNoteRequest: PropTypes.func.isRequired,
    fetchContactNoteCollectionRequest: PropTypes.func.isRequired,
    updateContactNoteRequest: PropTypes.func.isRequired,
    timelineJump: PropTypes.bool,
    triggerNewNote: PropTypes.string,
    cancelNewNote: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      noteBeingEdited: null,
    };
  }

  handleEdit = (noteId) => {
    return () => {
      this.props.cancelNewNote();
      this.setState({ noteBeingEdited: noteId });
    };
  };

  handleDelete = (noteId) => {
    return () => {
      const {
        contactNoteCollectionId,
        deleteContactNoteRequest,
        fetchContactNoteCollectionRequest,
      } = this.props;
      deleteContactNoteRequest(noteId, null, {
        successCallback: () => {
          const member = document.querySelector(`#${CSS.escape(noteId)}`);
          if (member) {
            member.style.display = "none";
          }
          fetchContactNoteCollectionRequest(contactNoteCollectionId, null, {
            successCallback: () => {
              this.setState({ noteBeingEdited: undefined });
            },
          });
        },
      });
    };
  };

  renderNote = (note) => {
    return (
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ overflowWrap: "break-word" }}
      >
        {note.content}
      </Typography>
    );
  };

  renderNoteForm = (note) => {
    const {
      activeConversationId,
      contactNoteCollectionId,
      createContactNoteRequest,
      fetchContactNoteCollectionRequest,
      updateContactNoteRequest,
      currentAccount,
    } = this.props;
    const { requestUrl, submitHandler } = note
      ? { requestUrl: note.id, submitHandler: updateContactNoteRequest }
      : {
          requestUrl: contactNoteCollectionId,
          submitHandler: createContactNoteRequest,
        };
    return (
      <ListItemContent>
        <NoteForm
          currentAccount={currentAccount}
          conversationId={activeConversationId}
          contactNoteCollectionId={contactNoteCollectionId}
          fetchContactNoteCollectionRequest={fetchContactNoteCollectionRequest}
          note={note}
          cancelEdit={this.handleEdit(undefined)}
          requestUrl={requestUrl}
          submitHandler={submitHandler}
        />
      </ListItemContent>
    );
  };

  render() {
    const {
      currentAccount,
      contactNoteCollection,
      currentUser,
      triggerNewNote,
    } = this.props;
    const { noteBeingEdited } = this.state;
    const accountTimeZone = getAccountTimeZone(currentAccount);
    const formatPhoneNumber = (number) => {
      return number.slice(-10).replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
    };
    return (
      <Wrapper data-testid="notes-section">
        {contactNoteCollection.totalItems === 0 && (
          <EmptySection>There are no notes for this contact</EmptySection>
        )}
        <List>
          {contactNoteCollection.members
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((note) => {
              return (
                <ListItem data-testid="note-list-item" key={note.id}>
                  <NoteListItemHeader
                    note={note}
                    account={currentAccount}
                    noteBeingEdited={noteBeingEdited}
                    handleDelete={this.handleDelete}
                    timelineJump={this.props.timelineJump}
                    featureFlag={currentAccount.featureFlags?.notesInTimeline}
                  />
                  <Box sx={{ display: "flex", mb: 1 }}>
                    <Box sx={{ flexGrow: 1, wordBreak: "break-word" }}>
                      {noteBeingEdited === note.id
                        ? this.renderNoteForm(note)
                        : this.renderNote(note)}
                    </Box>
                    <Box
                      sx={{
                        marginTop: "auto",
                        flexShrink: 1,
                      }}
                    >
                      {note.user.id === currentUser.id &&
                        noteBeingEdited !== note.id && (
                          <Tooltip title="Edit Note">
                            <IconButton
                              data-testid="edit-note-button"
                              onClick={this.handleEdit(note.id)}
                              size="large"
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Box>
                  </Box>
                  {noteBeingEdited !== note.id && note.conversation && (
                    <Typography
                      sx={{ fontStyle: "italic" }}
                      variant="caption"
                      color="text.caption"
                    >
                      In conversation with {formatPhoneNumber(note.phoneNumber)}
                    </Typography>
                  )}
                </ListItem>
              );
            })}
          {(noteBeingEdited === "new" || triggerNewNote) && (
            <ListItem key="new">
              <ListItemHeader>
                <ListItemAvatar>
                  <Avatar subject={currentUser} />
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant="body2" color="text.primary">
                    {currentUser.name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {formatInTimeZone(
                      new Date(),
                      accountTimeZone,
                      "MM/dd/yyyy hh:mm a z",
                    )}
                  </Typography>
                </ListItemText>
                <ListItemAction />
              </ListItemHeader>
              {this.renderNoteForm()}
            </ListItem>
          )}
        </List>
        {/* // TODO: Remove this section when notesInTimeline feature flag is removed */}
        {!noteBeingEdited && !currentAccount.featureFlags?.notesInTimeline && (
          <NewNoteRow>
            <Button
              data-testid="create-note-button"
              variant="outlined"
              color="primary"
              onClick={this.handleEdit("new")}
            >
              Create Note
            </Button>
          </NewNoteRow>
        )}
      </Wrapper>
    );
  }
}

export default function Root(props) {
  const { timestampJump, setTimestampJump } = useTimestampJump();

  useEffect(() => {
    if (timestampJump.resetSidebar) {
      props.fetchContactNoteCollectionRequest(props.contactNoteCollectionId);
      setTimestampJump({ resetSidebar: false });
    }
  }, [timestampJump, props]);
  return <RootClass {...props} />;
}
