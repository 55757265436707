import assert from "assert";
import { userManager } from "@tesseract/core";
import { stringifyUrl } from "query-string";
import { headers } from "constants/apiConstants";
import { Account as AccountType } from "models/Account";

export const fetchKeywordSubscribers = async ({
  currentAccount,
  keywordId,
  next,
  query,
}: {
  currentAccount: AccountType;
  keywordId: string;
  next?: string;
  query?: { searchTerm: string; dateRange: [Date | null, Date | null] };
}): Promise<Response> => {
  const user = await userManager.getUser();
  assert(user?.access_token);

  const SEARCH_PARAM_OPTIONS = [
    "q[nameCont]",
    "q[createdAtLteq]",
    "q[createdAtGteq]",
  ] as const;

  const [name, createdAtGteq, createdAtLteq] = SEARCH_PARAM_OPTIONS;

  const params: { [key: string]: string } = {
    [name]: query?.searchTerm ?? "",
    [createdAtLteq]: query?.dateRange[0]?.toString() ?? "",
    [createdAtGteq]: query?.dateRange[1]?.toString() ?? "",
  };

  const url =
    next ||
    stringifyUrl({
      url: `/api/v4/${currentAccount.slug}/automation_keywords/${keywordId}/subscribers`,
      query: { ...params },
    });

  const request = new Request(url, {
    method: "GET",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
  });

  return fetch(request);
};
