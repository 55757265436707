import { Component, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, Box } from "@mui/material";

import ContactCard from "./components/ContactCard";
import ContactNotes from "./containers/ContactNotes";
import ContactTags from "./containers/ContactTags/ContactTags";
import ConversationCollection from "./containers/ConversationCollection";
import { AddIcon } from "icons";
import ExpansionPanel from "components/ExpansionPanel";
import ExpansionPanelSummary from "components/ExpansionPanel/Summary";
import ExpansionPanelDetails from "components/ExpansionPanel/Details";
import { ContactSyncDetailsAccordion } from "features/ContactSync/ContactSyncDetailsAccordion/ContactSyncDetailsAccordion";
import { getIntegrationDataKeys } from "features/ContactSync/utils/getIntegrationDataKeys";
import { getFormattedIntegrationName } from "features/ContactSync/utils/getFormattedIntegrationName";
import Logo from "components/Logo";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

const CardWrapper = styled.div`
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  flex: 0 0 auto;
`;

const AdditionalDetails = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;

class ContactSidebar extends Component {
  static propTypes = {
    activeConversationId: PropTypes.string,
    conversation: PropTypes.object,
    closeModal: PropTypes.func,
    contact: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
    expanded: PropTypes.string,
    handleConversationClick: PropTypes.func.isRequired,
    handleEditClick: PropTypes.func,
    setActiveView: PropTypes.func,
    timelineJump: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded,
      yourConversationsCount: undefined,
      otherConversationsCount: undefined,
      triggerNewNote: null,
    };
  }

  getAccountConversationCollectionId = () => {
    const { contact, currentAccount } = this.props;
    const conversationCollectionId =
      typeof contact.conversations === "string"
        ? contact.conversations
        : contact.conversations.id;
    return `${conversationCollectionId}?account=${currentAccount.id}`;
  };

  getOtherConversationCollectionId = () => {
    const { contact, currentAccount } = this.props;
    const conversationCollectionId =
      typeof contact.conversations === "string"
        ? contact.conversations
        : contact.conversations.id;
    return `${conversationCollectionId}?excludeAccount=${currentAccount.id}`;
  };

  setConversationCount = (key) => {
    return (count) => {
      this.setState({ [key]: count });
    };
  };

  handleChange = (panel) => {
    return (event, expanded) => {
      this.setState({
        expanded: expanded ? panel : undefined,
      });
    };
  };

  renderContactSyncAccordions = () => {
    const integrations = getIntegrationDataKeys(this.props.contact);
    if (integrations.length > 0) {
      return integrations.map((integration) => {
        const formattedIntegrationName =
          getFormattedIntegrationName(integration);
        return (
          <ContactSyncDetailsAccordion
            key={integration}
            contactSyncData={this.props.contact.data[integration]}
            expanded={this.state.expanded}
            handleExpansion={this.handleChange}
            integrationName={formattedIntegrationName}
          />
        );
      });
    }
    return null;
  };

  render() {
    const {
      activeConversationId,
      conversation,
      closeModal,
      contact,
      currentAccount,
      handleConversationClick,
      handleEditClick,
      setActiveView,
    } = this.props;
    const { expanded, yourConversationsCount, otherConversationsCount } =
      this.state;
    const tags = get(contact, ["data", "tags"], []) || [];
    const messagingEnabled = currentAccount.phoneNumbers.length > 0;
    return (
      <Wrapper>
        <CardWrapper>
          <ContactCard
            contact={contact}
            conversation={conversation}
            currentAccount={currentAccount}
            handleConversationClick={handleConversationClick}
            handleEditClick={handleEditClick}
            closeModal={closeModal}
          />
        </CardWrapper>
        <AdditionalDetails>
          {this.renderContactSyncAccordions()}
          <ExpansionPanel
            square
            expanded={expanded === "tags"}
            onChange={this.handleChange("tags")}
          >
            <ExpansionPanelSummary
              data-testid="tags"
              expandIcon={<ExpandMoreIcon />}
            >
              {`Tags (${tags.length})`}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ContactTags
                account={currentAccount}
                closeModal={closeModal}
                contact={contact}
                contactTagsId={`/${currentAccount.slug}/contacts/tags`}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            square
            expanded={expanded === "notes"}
            onChange={this.handleChange("notes")}
          >
            <Box
              sx={{
                position: "relative",
              }}
            >
              <ExpansionPanelSummary
                data-testid="notes"
                expandIcon={<ExpandMoreIcon />}
              >
                {`Notes${
                  typeof contact.notes === "string"
                    ? " (0)"
                    : ` (${contact.notes.totalItems})`
                }`}
              </ExpansionPanelSummary>
              {currentAccount.featureFlags.notesInTimeline && (
                <IconButton
                  data-testid="create-note-button"
                  variant="outlined"
                  color="primary"
                  sx={{
                    position: "absolute",
                    right: "5px",
                    top: 0,
                  }}
                  onClick={(e) => {
                    this.setState({ triggerNewNote: "new", expanded: "notes" });
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
            <ExpansionPanelDetails>
              <ContactNotes
                activeConversationId={activeConversationId}
                timelineJump={this.props.timelineJump}
                triggerNewNote={this.state.triggerNewNote}
                cancelNewNote={() => this.setState({ triggerNewNote: null })}
                contactNoteCollectionId={
                  typeof contact.notes === "string"
                    ? contact.notes
                    : contact.notes.id
                }
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {messagingEnabled && (
            <ExpansionPanel
              square
              expanded={expanded === "yourConversations"}
              onChange={this.handleChange("yourConversations")}
            >
              <ExpansionPanelSummary
                expandIcon={
                  yourConversationsCount >= 0 ? (
                    <ExpandMoreIcon />
                  ) : (
                    <Logo
                      animate
                      width={20}
                      color="transparent"
                      dotColor="primary"
                    />
                  )
                }
              >
                {`Your conversations${
                  yourConversationsCount ? ` (${yourConversationsCount})` : ""
                }`}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <ConversationCollection
                  activeConversationId={activeConversationId}
                  conversationCollectionType="yourConversations"
                  conversationCollectionId={this.getAccountConversationCollectionId()}
                  setActiveConversation={handleConversationClick}
                  setConversationCount={this.setConversationCount(
                    "yourConversationsCount",
                  )}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
          <ExpansionPanel
            data-testid="other-conversations"
            square
            expanded={expanded === "otherConversations"}
            onChange={this.handleChange("otherConversations")}
          >
            <ExpansionPanelSummary
              data-testid="other-conversations-summary"
              expandIcon={
                otherConversationsCount >= 0 ? (
                  <ExpandMoreIcon />
                ) : (
                  <Logo
                    animate
                    width={20}
                    color="transparent"
                    dotColor="primary"
                  />
                )
              }
            >
              {`Other conversations${
                otherConversationsCount ? ` (${otherConversationsCount})` : ""
              }`}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ConversationCollection
                data-testid={this.getOtherConversationCollectionId()}
                activeConversationId={activeConversationId}
                conversationCollectionType="otherConversations"
                conversationCollectionId={this.getOtherConversationCollectionId()}
                setActiveConversation={handleConversationClick}
                setConversationCount={this.setConversationCount(
                  "otherConversationsCount",
                )}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </AdditionalDetails>
      </Wrapper>
    );
  }
}

export default ContactSidebar;
