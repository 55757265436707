import { Box, Button, Divider, Theme, useMediaQuery } from "@mui/material";
import { DialogFooterProps } from "./types";

function DialogFooter({
  altButton,
  cancelText = "Cancel",
  confirmDataTestId = "confirm",
  confirmText = "Confirm",
  confirmVariant = "contained",
  confirmColor = "primary",
  isConfirmDisabled = false,
  hideCancelOnMobile = false,
  buttonPadding = "6px 8px",
  onCancel,
  onConfirm,
  ...props
}: DialogFooterProps) {
  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const hideCancel = hideCancelOnMobile && mobileScreen;

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxHeight="10rem"
      className="dialog-footer"
    >
      <Divider />
      <Box
        display="flex"
        justifyContent="space-between"
        padding="1rem"
        width="100%"
        {...props}
        sx={(theme) => {
          return {
            [theme.breakpoints.down("sm")]: {
              flexFlow: "column nowrap",
              gap: "16px",
            },
          };
        }}
      >
        {altButton ? <Box>{altButton || null}</Box> : null}
        <Box
          display="flex"
          gap={1}
          flexDirection="row"
          sx={(theme) => {
            return {
              flex: "1 1 auto",
              justifyContent: "flex-end",

              [theme.breakpoints.down("sm")]: {
                width: hideCancel ? "100%" : "auto",
              },
            };
          }}
        >
          {!hideCancel && (
            <Button
              onClick={onCancel}
              data-testid="cancel"
              sx={{
                padding: buttonPadding,
              }}
            >
              {cancelText}
            </Button>
          )}

          <Button
            color={confirmColor}
            variant={confirmVariant}
            data-testid={confirmDataTestId}
            aria-label="Confirm Button"
            disabled={isConfirmDisabled}
            {...(onConfirm
              ? { onClick: onConfirm }
              : {
                  type: "submit",
                })}
            sx={(theme) => {
              return {
                padding: confirmVariant === "text" ? buttonPadding : "6px 16px",
                [theme.breakpoints.down("sm")]: {
                  width: hideCancel ? "100%" : "auto",
                },
              };
            }}
          >
            {confirmText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export { DialogFooter };
