import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useState } from "react";
import { NavListItemButtonProps } from "./types";
import NavLinkWithTheme from "./NavLinkWithTheme";

export default function NavListItemButton({
  primary,
  secondary,
  to,
  icon,
  nested = false,
  showSecondaryOnActive = false,
  showSecondaryOnHover = false,
  secondaryAction,
  sx = null,
  buttonSx = {},
  handleClick,
}: NavListItemButtonProps) {
  const [isHovering, setIsHovering] = useState(false);

  const showSecondary =
    (!showSecondaryOnActive && !showSecondaryOnHover) ||
    (showSecondaryOnActive && window.location.href.includes(to)) ||
    (showSecondaryOnHover && isHovering);

  const listItemInner = (
    <ListItemButton
      data-testid="nav-list-item-button"
      onClick={handleClick}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      selected={window.location.href.includes(to)}
      sx={(theme) => ({
        paddingLeft: nested === false ? "8px" : "40px",
        "&.Mui-selected": {
          background: theme.palette.lightBlue.A12,
          "& .MuiTypography-root": {
            fontWeight: 700,
          },
        },
        ...(buttonSx as object), // Spread the buttonSx here
      })}
    >
      {icon}
      <ListItemText primary={primary} secondary={secondary} />
    </ListItemButton>
  );

  // <ul> + <ol> must have a <li> as a direct child. 
  // either wrap the inner content in an <a> or pass as is.
  const listItem = to ? (
    <ListItem
      disablePadding
      secondaryAction={showSecondary ? secondaryAction : undefined}
      {...(sx ? { sx } : {})}
    >
      <NavLinkWithTheme to={to} style={{ width: "100%" }}>
        {listItemInner}
      </NavLinkWithTheme>
    </ListItem>
  ) : (
    <ListItem
      disablePadding
      secondaryAction={showSecondary ? secondaryAction : undefined}
      {...(sx ? { sx } : {})}
    >
      {listItemInner}
    </ListItem>
  );

  return listItem;
}
