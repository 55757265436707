import { useState, useEffect } from "react";
import snakeCase from "lodash/snakeCase";
import kebabCase from "lodash/kebabCase";
import { Formik, Form } from "formik";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import {
  ScheduleAnalyticsReportModalProps,
  SendFrequencyKeys,
  DayToSendKeys,
  SelectValue,
  ReportValues,
} from "../models/AnalyticsModels";
import {
  dayToSendOptions,
  sendFrequencyOptions,
  timePeriods,
} from "../constants/DateOptions";
import { getFields } from "../utils/ModalFields";
import FormikSelect from "components/FormikSelect";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";

import { AnalyticsReportsSchema } from "formHelpers/validationSchemas";

import createUUID from "utils/uuid";

function ScheduleAnalyticsReportModal({
  accountList = [],
  fullScreen,
  handleClose,
  initialValues,
  navigateToReportList,
  open,
  scheduleReport,
  title,
  updateReport,
}: ScheduleAnalyticsReportModalProps) {
  const [sendFrequency, setSendFrequency] = useState<SelectValue[] | []>([]);
  const [dayToSend, setDayToSend] = useState<SelectValue[] | []>([]);
  const isEditForm = Object.values(initialValues).find(Boolean);

  const updatedTimePeriods =
    title === "Message Usage"
      ? timePeriods.filter(({ name }) => {
          return name === "Previous Full Month";
        })
      : timePeriods;

  const updatedAccountList =
    title === "Message Usage" ? [accountList[1]] : accountList;

  const fields = getFields({
    accountList: updatedAccountList,
    dayToSend,
    sendFrequency,
    timePeriods: updatedTimePeriods,
  });

  const updateDependentFields = (
    parentField: string,
    value: SendFrequencyKeys | DayToSendKeys,
  ) => {
    if (parentField === "timePeriod") {
      const fieldOptions = sendFrequencyOptions[value as SendFrequencyKeys];
      setSendFrequency(fieldOptions);
      setDayToSend([]);
    } else if (parentField === "sendFrequency") {
      const dayToSendKey = snakeCase(value.match(/FREQ=(\w+);/)?.[1]);
      const fieldOptions =
        dayToSendOptions[snakeCase(value) as DayToSendKeys] ??
        dayToSendOptions[dayToSendKey as DayToSendKeys];
      setDayToSend(fieldOptions);
    }
  };

  useEffect(() => {
    if (isEditForm) {
      const { timePeriod, sendFrequency: sendFrequencyValue } = initialValues;
      updateDependentFields("timePeriod", timePeriod);
      updateDependentFields("sendFrequency", sendFrequencyValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleSubmit = (values: ReportValues) => {
    return isEditForm ? updateReport(values) : scheduleReport(values);
  };

  return (
    <Modal
      ariaLabel="Analytics Report Scheduling Modal"
      closeModal={handleClose}
      fullScreen={fullScreen}
      transitionIn={open}
    >
      <ModalHeader closeModal={handleClose}>{`${
        isEditForm ? "Edit" : "Schedule"
      } a ${title} Report`}</ModalHeader>
      <ModalContent maxWidth="100%">
        {isEditForm && (
          <IconButton
            onClick={navigateToReportList}
            aria-label={`Navigate to Scheduled ${title} Reports`}
            size="large"
          >
            <ArrowBackIcon
              color="primary"
              style={{
                display: "flex",
                flex: "0 0 auto",
              }}
            />
          </IconButton>
        )}
        <Box minWidth="400px">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={AnalyticsReportsSchema}
          >
            {({ dirty, isValid, values, setFieldValue }) => {
              return (
                <Form>
                  <Box>
                    {fields.map(
                      ({
                        dependentFieldOptions,
                        name,
                        items,
                        label,
                        required,
                        parentField,
                      }) => {
                        return (
                          <Box my={2} key={createUUID()}>
                            <FormikSelect
                              dependentFieldOptions={dependentFieldOptions}
                              handleChange={
                                parentField ? updateDependentFields : false
                              }
                              items={items}
                              label={label}
                              name={name}
                              required={required}
                              setFieldValue={setFieldValue}
                              values={values}
                            />
                          </Box>
                        );
                      },
                    )}
                    <Box
                      display="flex"
                      flex="0 0 auto"
                      justifyContent="flex-end"
                      mt={{ xs: "0px", sm: "40px" }}
                    >
                      <Button
                        aria-label={`Schedule ${title} Report`}
                        data-testid={`${kebabCase(title)}-report-scheduled`}
                        color="primary"
                        disabled={!dirty || !isValid}
                        type="submit"
                        variant="contained"
                      >
                        Schedule
                      </Button>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default ScheduleAnalyticsReportModal;
