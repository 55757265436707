/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ContentCopy, Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { HealthCheckIconButton } from "../HealthCheckIconButton";
import { IntegrationDetailsGeneralTabProps } from "./types";
import { SettingsCard, SettingsCardContent } from "features/Settings";
import { useCurrentAccount } from "hooks";

interface BullhornDatacenter {
  apiHost: string;
  authHost: string;
  name: string;
}

export function IntegrationDetailsGeneralTab({
  initialIntegrationValues,
  integrationState,
  setUpdatedIntegrationData,
  updatedIntegrationData,
}: IntegrationDetailsGeneralTabProps) {
  // ==== STATE ==== //
  const [showSecretKey, setShowSecretKey] = useState<boolean>(false);

  // ==== HOOKS ==== //
  // Luke TODO: Remove featureFlags once released
  const { featureFlags } = useCurrentAccount();
  const { enqueueSnackbar } = useSnackbar();
  const largeScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up("lg");
  });

  // ==== VARIABLES ==== //
  const { config: initialValues } = initialIntegrationValues ?? {};
  const { config: authenticationState } = integrationState ?? {};

  // ==== FUNCTIONS ==== //
  // Bullhorn specific logic
  const initialBullhornDatacenterValue = (options: any[]) => {
    const initialDatacenter = initialValues?.apiHost ?? "";
    return options.findIndex((option: BullhornDatacenter) => {
      return option.apiHost === initialDatacenter;
    });
  };

  // Bullhorn specific logic
  const handleBullhornDatacenterChange = (event: any, options: any[]) => {
    const index = Number(event.target.value);
    setUpdatedIntegrationData({
      ...updatedIntegrationData,
      config: {
        ...updatedIntegrationData.config,
        apiHost: options[index].apiHost,
        authHost: options[index].authHost,
      },
    });
  };

  // ==== JSX FUNCTIONS ==== //
  // Bullhorn specific logic
  const renderBullhornDatacenterOptions = (options: any[]) => {
    return options.map((option: BullhornDatacenter, i: number) => {
      return (
        <option key={option.apiHost} value={i}>
          {option.name}
        </option>
      );
    });
  };

  // Greenhouse specific logic
  const renderGreenhouseSubdomainText = () => {
    return (
      <>
        <Typography variant="body2">
          To use candidate IDs in TextUs as hyperlinks, please enter your
          Greenhouse URL subdomain. You can determine what your subdomain is by
          signing into Greenhouse and looking at your browser's address bar.
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontStyle: "italic",
          }}
        >
          Example: The subdomain in https://app4.greenhouse.io/ would be{" "}
          <b>app4</b>
        </Typography>
      </>
    );
  };

  // Greenhouse specific logic
  const renderGreenhouseWebhookSection = () => {
    return (
      <SettingsCardContent
        sx={{
          "&:last-child": {
            paddingY: "16px",
          },
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ paddingBottom: "24px" }}>
          Incoming webhooks
        </Typography>
        <Typography
          variant="body2"
          sx={{
            paddingBottom: "16px",
          }}
        >
          Use these credentials to keep TextUs up to date with Greenhouse.
        </Typography>
        <TextField
          key="endpointUrl"
          fullWidth
          label="Endpoint URL"
          onChange={(event) => {
            setUpdatedIntegrationData({
              ...updatedIntegrationData,
              config: {
                ...updatedIntegrationData.config,
                endpointUrl: event.target.value,
              },
            });
          }}
          sx={{
            paddingBottom: "8px",
          }}
          type="text"
          defaultValue={initialValues?.endpointUrl ?? ""}
          variant="outlined"
        />
        <Box sx={{ display: "flex", paddingBottom: "16px" }}>
          <Button
            color="primary"
            variant="text"
            sx={{
              marginLeft: "auto",
            }}
            onClick={() => {
              navigator.clipboard.writeText(
                updatedIntegrationData?.config?.endpointUrl,
              );
              enqueueSnackbar("Endpoint URL copied.", {
                variant: "info",
              });
            }}
          >
            <ContentCopy
              sx={{
                marginRight: "8px",
              }}
            />
            Copy URL
          </Button>
        </Box>
        <TextField
          key="endpointSecretKey"
          fullWidth
          label="Secret key"
          onChange={(event) => {
            setUpdatedIntegrationData({
              ...updatedIntegrationData,
              config: {
                ...updatedIntegrationData.config,
                endpointSecretKey: event.target.value,
              },
            });
          }}
          sx={{
            paddingBottom: "8px",
          }}
          type={showSecretKey ? "text" : "password"}
          defaultValue={initialValues?.endpointSecretKey ?? ""}
          variant="outlined"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowSecretKey(!showSecretKey);
                  }}
                  edge="end"
                >
                  {showSecretKey ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: "flex" }}>
          <Button
            color="primary"
            variant="text"
            sx={{
              marginLeft: "auto",
            }}
            onClick={() => {
              navigator.clipboard.writeText(
                updatedIntegrationData?.config?.endpointSecretKey,
              );
              enqueueSnackbar("Secret key copied.", {
                variant: "info",
              });
            }}
          >
            <ContentCopy
              sx={{
                marginRight: "8px",
              }}
            />
            Copy Secret
          </Button>
        </Box>
      </SettingsCardContent>
    );
  };

  // Renders the health check setting card. Only renders for integrations with health check feature.
  const renderHealthCheckSection = () => {
    // Luke TODO: Remove test data once BE work is complete.
    const testData = {
      contactsCreated: "45,000",
      messagesLogged: "3,000",
    };
    const { active, isHealthy } = updatedIntegrationData ?? {};
    // Luke TODO: Remove feature flag once released
    return isHealthy && featureFlags.integrationsFrameworkHealthCheck ? (
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: largeScreen ? "row" : "column",
          paddingBottom: "16px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            minWidth: largeScreen ? "18rem" : "0rem",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Activity
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <SettingsCard sx={{ width: "100%", minWidth: "288px" }}>
            <SettingsCardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                "&:last-child": {
                  paddingY: "16px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography variant="h5">Health check</Typography>
                  <Typography variant="caption">Updated {}</Typography>
                </Box>
                {active ? (
                  <HealthCheckIconButton integration={updatedIntegrationData} />
                ) : (
                  <Chip color="default" label="Paused" size="small" />
                )}
              </Box>
              <Divider sx={{ marginY: "8px" }} />
              <Typography color="primary" variant="dataSmall">
                {testData.contactsCreated}
              </Typography>
              <Typography color="textSecondary" variant="body1">
                contacts created (last 7 days)
              </Typography>
              <Typography
                color="primary"
                variant="dataSmall"
                sx={{ paddingTop: "16px" }}
              >
                {testData.messagesLogged}
              </Typography>
              <Typography color="textSecondary" variant="body1">
                messages logged (last 7 days)
              </Typography>
              <Button
                size="medium"
                variant="outlined"
                sx={{ alignSelf: "flex-end", marginTop: "24px" }}
              >
                VIEW ACTIVITY
              </Button>
            </SettingsCardContent>
          </SettingsCard>
        </Box>
      </Box>
    ) : null;
  };

  // Renders the necessary authentication fields for custom integrations.
  const renderAuthenticationFields = () => {
    return Object.entries(authenticationState).map((field) => {
      const dataKey = field[0];
      const { type, label } = field[1];
      if (type === "text") {
        return (
          <TextField
            key={dataKey}
            fullWidth
            InputProps={{
              readOnly: dataKey === "webhookSecret",
            }}
            label={label}
            onChange={(event) => {
              setUpdatedIntegrationData({
                ...updatedIntegrationData,
                config: {
                  ...updatedIntegrationData.config,
                  [dataKey]: event.target.value,
                },
              });
            }}
            sx={{
              paddingBottom: "16px",
            }}
            type="text"
            defaultValue={initialValues[dataKey]}
            variant="outlined"
          />
        );
      }
      if (type === "select") {
        const { options } = field[1];
        return (
          <TextField
            fullWidth
            key={dataKey}
            label={label}
            onChange={(event) => {
              if (
                dataKey === "datacenter" &&
                integrationState?.provider === "bullhorn"
              ) {
                handleBullhornDatacenterChange(event, options);
              }
            }}
            select
            SelectProps={{
              native: true,
              inputProps: { "aria-label": "Datacenter" },
            }}
            sx={{
              paddingBottom: "16px",
            }}
            defaultValue={
              dataKey === "datacenter" &&
              integrationState?.provider === "bullhorn"
                ? initialBullhornDatacenterValue(options)
                : undefined
            }
            variant="outlined"
          >
            <option key="placeholder" aria-label="Empty" value="" />
            {dataKey === "datacenter" &&
              integrationState?.provider === "bullhorn" &&
              renderBullhornDatacenterOptions(options)}
          </TextField>
        );
      }
      return null;
    });
  };

  return (
    <>
      {updatedIntegrationData?.hasOwnProperty("isHealthy")
        ? renderHealthCheckSection()
        : null}
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: largeScreen ? "row" : "column",
          width: "100%",
        }}
      >
        {integrationState?.strategy === "custom" && (
          <>
            <Box
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                minWidth: largeScreen ? "18rem" : "0rem",
              }}
            >
              <Typography variant="h4" gutterBottom>
                Authentication
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <SettingsCard sx={{ width: "100%", minWidth: "288px" }}>
                <SettingsCardContent
                  sx={{
                    "&:last-child": {
                      paddingY: "16px",
                    },
                  }}
                >
                  {integrationState?.provider === "greenhouse" && (
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        paddingBottom: "24px",
                      }}
                    >
                      Configuration
                    </Typography>
                  )}
                  {renderAuthenticationFields()}
                  {integrationState?.provider === "greenhouse" &&
                    renderGreenhouseSubdomainText()}
                </SettingsCardContent>
              </SettingsCard>
              {integrationState?.provider === "greenhouse" && (
                <SettingsCard
                  sx={{
                    marginBottom: "48px",
                    marginTop: "16px",
                  }}
                >
                  {renderGreenhouseWebhookSection()}
                </SettingsCard>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
