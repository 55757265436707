import { textUsApiV3, textUsApiV4 } from "services";

function fetchContactTagCollection(tagCollectionId: string) {
  return textUsApiV3.get(tagCollectionId);
}

function fetchContactTagCollectionV4(tagCollectionId: string) {
  return textUsApiV4.get(tagCollectionId);
}
export { fetchContactTagCollection, fetchContactTagCollectionV4 };
