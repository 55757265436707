import { useState, useEffect } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import queryString from "query-string";

import {
  Box,
  Button,
  Card,
  Divider,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";

import BrandRegistration from "./BrandRegistration";
import AccountTree from "features/AccountManagement/AccountTree";
import Loader from "components/Loader";
import Logo from "components/Logo";
import PageHeader from "components/Page/PageHeader";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import withRecord from "higherOrderComponents/withRecord";

// import AccountSlugs from "./AccountSlugs";
// import AccountSlugModal from "./AccountSlugModal";

function BrandInformation({
  fetchAccountCollectionRequest,
  toggleSidebar,
  location,
  history,
}) {
  // const [openModal, setOpenModal] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [loading, setLoading] = useState(true);

  // const handleModalToggle = () => setOpenModal(!openModal);

  const accountHierarchyId = queryString.stringifyUrl({
    url: "/accounts",
    query: {
      ancestors: true,
      ...queryString.parse(location.search),
    },
  });

  useEffect(() => {
    const accountId = queryString.stringifyUrl({
      url: "/accounts",
      query: { legal: true },
    });
    fetchAccountCollectionRequest(accountId, null, {
      successCallback: (response) => {
        const accounts = response?.members;
        setAccountList(accounts);
        setLoading(false);
      },
    });
  }, [accountHierarchyId]);

  if (loading) {
    return (
      <Loader isLoading={loading} aria-label="Page is Loading">
        <Logo animate dotColor="contrast" />
      </Loader>
    );
  }

  return (
    <SettingsPageWrapper>
      <PageHeader title="Brand information" toggleSidebar={toggleSidebar} />
      <SettingsPageContent maxWidth="1024px" overflow="auto">
        <Box sx={{ marginBottom: "2rem" }}>
          <Box>
            <Typography
              sx={{ marginBottom: "12px" }}
              color="primary"
              variant="h4"
            >
              10 DLC registration
            </Typography>
            <p>
              10DLC (10-digit long code) is a formal program designed by US
              Carriers that validates your business for business-to-consumer
              text messaging, also known as A2P (Application-to-Person). All
              businesses that use text messaging services are required to
              register for 10DLC and comply with specific rules and regulations
              set by mobile carriers to ensure responsible and lawful messaging
              practices.
            </p>
          </Box>
        </Box>
        <Box sx={{ marginBottom: "2rem" }}>
          <Typography
            sx={{ marginBottom: "12px" }}
            element="h2"
            color="primary"
            variant="h4"
          >
            Register your brand
          </Typography>
          <Typography variant="body2" sx={{ margin: ".5rem 0" }}>
            Complete the forms below to begin your brand registration. Visit our{" "}
            <strong>
              <Link
                color="inherit"
                href="https://docs.textus.com"
                target="_blank"
              >
                Help Center Documentation
              </Link>
            </strong>{" "}
            to find FAQs.
          </Typography>
          <Divider />
          <Box m="2rem 0">
            {accountList.map(({ name, slug, id }) => {
              return (
                <BrandRegistration name={name} id={id} slug={slug} key={id} />
              );
            })}
          </Box>
        </Box>
        {/* <Box my={4}>
          <Card style={{ minHeight: "20rem" }} variant="outlined">
            <Box borderBottom={1} color="grey.300" display="flex" p="1rem">
              <H3 style={{ alignSelf: "center" }}>Account Slugs</H3>
              <AccountSlugModal
                handleAccountSlugModalToggle={handleModalToggle}
                openAccountSlugModal={openModal}
              />
            </Box>
            <AccountSlugs />
          </Card>
        </Box> */}
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

BrandInformation.propTypes = {
  fetchAccountCollectionRequest: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "features/AccountSettings/BrandInformation/accountCollection",
    noFetch: true,
    shape: {},
    showLoader: () => {
      return false;
    },
    type: "accountCollection",
  }),
)(BrandInformation);
