import { Account } from "@tesseract/core";
import { textUsApiV4 } from "services";

export const fetchKeyword = (
  currentAccount: Account.Raw,
  keywordId: string,
): Promise<Response> => {
  return textUsApiV4.get(
    `/${currentAccount.slug}/automation_keywords/${keywordId}`,
  );
};
