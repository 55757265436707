/* eslint-disable react/prop-types */

import {
  ClipboardEvent,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { Field, FieldInputProps, getIn } from "formik";
import {
  Box,
  Divider,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  IconButton,
  Menu,
} from "@mui/material";
import { SavedReply } from "@tesseract/core";
import { SegmentedMessage } from "sms-segments-calculator";
import { ItemToAdd, MessageInputFormRowProps } from "./types";
import AttachmentsOption from "containers/NewMessage/containers/AttachmentsOption";
import DraftMessage from "components/DraftMessage";
import EmojiPickerOption from "components/MessageInputOptions/EmojiPickerOption";
import MessageInputForMobile from "components/MessageInputForMobile";
import ShortenedLinkOption from "components/MessageInputOptions/ShortenedLinkOption/index";
import SignatureOption from "components/MessageInputOptions/SignatureOption";
import TemplateVariablePickerOption from "components/MessageInputOptions/TemplateVariablePickerOption";
import { MessageAddOns, SetFieldValue } from "containers/NewMessage/types";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { MoreVertIcon } from "icons";

const DividerLine = styled(Box)(({ theme }) => {
  return {
    borderBottom: `1px solid ${theme.palette.customColors.enabledBorder}`,
    height: "1px",
    width: "fill-available",
    margin: "0 -1rem",
  };
});

export default function MessageInputFormRow({
  attachmentField,
  attachmentFieldName,
  editorWrapperPadding,
  errors = {},
  fieldName,
  hideUserPersonalization,
  isCampaignProMember,
  isKeyword,
  isDisabled,
  isSignatureActive,
  label,
  linksFieldName,
  maxChars,
  SavedRepliesOption,
  placeholder = "",
  setFieldValue,
  signature,
  shortenedLink,
  toggleSignature,
  touched = {},
  values,
  setShortenedLink,
  withSignature,
  formPadding = "0",
  templates,
  settings,
  messageFieldOutlineActive = true,
  segmentCountActive = true,
}: MessageInputFormRowProps) {
  const [itemToAdd, setItemToAdd] = useState<ItemToAdd | null>(null);
  const [pastedImage, setPastedImage] = useState<File | undefined>(undefined);
  const [currentUrl, setCurrentUrl] = useState("");

  const [expandedActionsAnchorEl, setExpandedActionsAnchorEl] =
    useState<null | HTMLElement>(null);

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  const currentAccount = useCurrentAccount();

  const isMobile = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const { featureFlags } = useCurrentAccount();
  const templatesMaintenance = featureFlags?.templatesMaintenance;

  const isCampaigns = currentUrl.includes("campaigns");
  const disableTemplateAttachments =
    !currentAccount?.isCampaignProMember && isCampaigns;

  function getSelectionHandler({ optionName }: { optionName: MessageAddOns }) {
    switch (optionName) {
      case "Emojis":
        return (emoji: { native: string }) => {
          return setItemToAdd({
            item: emoji.native,
            type: "emoji",
          });
        };
      case "Personalize":
        return (templateVariable: string) => {
          return setItemToAdd({
            item: templateVariable,
            type: "templateVariable",
          });
        };
      case "Link":
        return (links: MessageInputFormRowProps["shortenedLink"]) => {
          return setItemToAdd({
            item: links.shortLink,
            type: "shortenedLink",
          });
        };
      default:
        return () => {};
    }
  }

  const handlePaste = (event: ClipboardEvent) => {
    if (event.clipboardData.files.length > 0) {
      const file = event.clipboardData.files[0];
      setPastedImage(file);
    }
  };

  const handleCampaignsProAttachmentsChange = (
    setAttachmentField: SetFieldValue,
  ) => {
    return (attachments?: File[] | SavedReply.Attachment[]) => {
      if (attachmentFieldName) {
        setAttachmentField(attachmentFieldName, attachments);
      }
      setItemToAdd(null);
    };
  };

  const insertSavedReply = (savedReply: SavedReply.Raw) => {
    handleCampaignsProAttachmentsChange(setFieldValue)(
      savedReply?.attachments?.members,
    );
    setItemToAdd({
      item: savedReply.content,
      type: "savedReply",
    });
  };

  const openExpandedActionsMenu = (
    event: React.MouseEvent<HTMLElement & SVGSVGElement>,
  ) => {
    setExpandedActionsAnchorEl(event.currentTarget);
  };

  const closeExpandedActionsMenu = () => {
    setExpandedActionsAnchorEl(null);
  };

  const handleAttachmentsChange = (setAttachmentFieldValue: SetFieldValue) => {
    return (attachments?: File[]) => {
      setAttachmentFieldValue("attachments", attachments);
      setItemToAdd(null);
    };
  };

  const handleSetShortenedLink = (setShortenedLinkField: SetFieldValue) => {
    return (links: MessageInputFormRowProps["shortenedLink"]) => {
      if (linksFieldName) {
        setShortenedLinkField(linksFieldName, links);
      }
      setShortenedLink(links);
      setItemToAdd(null);
    };
  };

  const formatShortLink = (message: string) => {
    const shortLink = shortenedLink?.shortLink;
    if (message.includes(shortLink)) {
      return message.replace(
        shortLink,
        `{{ "${shortenedLink?.fullLink ?? ""}" | shortlink }}`,
      );
    }
    return message;
  };

  const currentValue = getIn(values, fieldName) ?? "";
  const signatureContent = signature?.content ?? "";

  const { characterCount, segmentCount } = useMemo(() => {
    let message = currentValue;
    if (message.length === 0) {
      return {
        characterCount: 0,
        segmentCount: 0,
      };
    }

    if (isSignatureActive && signatureContent.length > 0) {
      message += `\n\n${signatureContent}`;
    }

    const smsMessage = new SegmentedMessage(message);

    return {
      characterCount: message.length,
      segmentCount: smsMessage.segmentsCount,
    };
  }, [currentValue, isSignatureActive, signatureContent]);

  const isSequence = Object.keys(values).includes("absoluteTime");

  const InputDraftMessage = useCallback(
    (props: any) => {
      if (templates && settings && !isMobile) {
        return <DraftMessage {...props} maxHeight="220px" overflow="auto" />;
      }

      if (templates && settings && isMobile) {
        return <DraftMessage {...props} maxHeight="150px" overflow="auto" />;
      }

      if (templates && !settings) {
        return <DraftMessage {...props} maxHeight="150px" overflow="auto" />;
      }

      if (editorWrapperPadding) {
        return (
          <DraftMessage
            {...props}
            editorWrapperPadding={editorWrapperPadding}
          />
        );
      }

      return <DraftMessage {...props} />;
    },
    [settings, templates, isMobile],
  );

  return (
    <Box flex="0 0 auto" padding={formPadding}>
      <Field type="text" name={fieldName}>
        {({ field }: { field: FieldInputProps<any> }) => {
          return (
            <>
              <TextField
                id="textus-NewMessageTextArea"
                data-testid="new-message-text-area"
                disabled={isDisabled}
                {...field}
                error={
                  getIn(touched, fieldName) && Boolean(getIn(errors, fieldName))
                }
                helperText={
                  getIn(touched, fieldName) ? getIn(errors, fieldName) : ""
                }
                fullWidth
                slotProps={{
                  inputLabel: {
                    margin: "dense",
                    shrink: Boolean(field.value),
                  },
                  htmlInput: {
                    name: field.name,
                    handleChange: (value: string) => {
                      setFieldValue(field.name, formatShortLink(value));
                      setItemToAdd(null);
                    },
                    isSignatureActive,
                    itemToAdd,
                    selectionHandler: getSelectionHandler({
                      optionName: "Link",
                    }),
                    setShortenedLink: handleSetShortenedLink(setFieldValue),
                    shortenedLink,
                    signature,
                    value: field.value,
                    withSignature,
                  },
                  input: {
                    inputComponent:
                      process.env.NODE_ENV === "test"
                        ? MessageInputForMobile
                        : InputDraftMessage,
                    disableUnderline: true,
                  },
                }}
                label={label}
                placeholder={placeholder}
                onPaste={handlePaste}
                type="text"
                {...(messageFieldOutlineActive
                  ? { variant: "outlined" }
                  : {
                      multiline: true,
                      sx: {
                        minHeight: "72px",
                      },
                      variant: "standard",
                    })}
              />
              {!messageFieldOutlineActive && <DividerLine />}
            </>
          );
        }}
      </Field>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={1}
        sx={(theme) =>
          messageFieldOutlineActive
            ? {}
            : {
                margin: "0px -16px -8px -16px",
                padding: "8px 16px 8px 8px",
                backgroundColor: theme.palette.primary.contrastText,
                borderRadius: "0 0 8px 8px",
              }
        }
      >
        {isMobile && isKeyword ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <EmojiPickerOption
              selectionHandler={
                getSelectionHandler({
                  optionName: "Emojis",
                }) as (emoji: { native: string }) => void
              }
              disabled={isDisabled}
            />
            <SavedRepliesOption
              selectionHandler={insertSavedReply}
              attachment={values?.attachment}
            />
            <Divider
              orientation="vertical"
              flexItem
              sx={(theme) => ({
                height: "24px",
                width: "1px",
                backgroundColor: theme.palette.divider,
                margin: "8px 4px",
              })}
            />
            <IconButton>
              <MoreVertIcon onClick={openExpandedActionsMenu} />
            </IconButton>
            <Menu
              open={Boolean(expandedActionsAnchorEl)}
              anchorEl={expandedActionsAnchorEl}
              onClose={closeExpandedActionsMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              slotProps={{
                paper: {
                  sx: {
                    padding: "0rem 0.5rem",
                  },
                },
              }}
              sx={{
                zIndex: 10,
              }}
            >
              <TemplateVariablePickerOption
                hideUserPersonalization={hideUserPersonalization}
                selectionHandler={
                  getSelectionHandler({
                    optionName: "Personalize",
                  }) as (templateVariable: string) => void
                }
                disabled={isDisabled}
              />
              <AttachmentsOption
                attachments={attachmentField ?? []}
                handleAttachmentsChange={handleCampaignsProAttachmentsChange(
                  setFieldValue,
                )}
                pastedImage={pastedImage}
                disabled={isDisabled}
              />
              <ShortenedLinkOption
                shortenedLink={shortenedLink}
                setShortenedLink={handleSetShortenedLink(setFieldValue)}
                selectionHandler={
                  getSelectionHandler({
                    optionName: "Link",
                  }) as (
                    links: MessageInputFormRowProps["shortenedLink"],
                  ) => void
                }
                disabled={isDisabled}
              />
            </Menu>
          </Box>
        ) : (
          <Box>
            {withSignature && (
              <SignatureOption
                isSignatureActive={isSignatureActive}
                toggleSignature={toggleSignature}
              />
            )}
            <EmojiPickerOption
              selectionHandler={
                getSelectionHandler({
                  optionName: "Emojis",
                }) as (emoji: { native: string }) => void
              }
              disabled={isDisabled}
            />
            {SavedRepliesOption && (
              <SavedRepliesOption
                selectionHandler={insertSavedReply}
                attachment={values?.attachment}
              />
            )}
            <TemplateVariablePickerOption
              hideUserPersonalization={hideUserPersonalization}
              selectionHandler={
                getSelectionHandler({
                  optionName: "Personalize",
                }) as (templateVariable: string) => void
              }
              disabled={isDisabled}
            />
            {(isCampaignProMember || isSequence) && (
              <AttachmentsOption
                attachments={attachmentField ?? []}
                handleAttachmentsChange={handleCampaignsProAttachmentsChange(
                  setFieldValue,
                )}
                pastedImage={pastedImage}
                disabled={isDisabled}
              />
            )}
            {templates &&
              templatesMaintenance &&
              !disableTemplateAttachments && (
                <AttachmentsOption
                  attachments={attachmentField ?? []}
                  handleAttachmentsChange={handleAttachmentsChange(
                    setFieldValue,
                  )}
                  pastedImage={pastedImage}
                  disabled={isDisabled}
                />
              )}
            {isKeyword && (
              <AttachmentsOption
                attachments={attachmentField ?? []}
                handleAttachmentsChange={handleCampaignsProAttachmentsChange(
                  setFieldValue,
                )}
                pastedImage={pastedImage}
                disabled={isDisabled}
              />
            )}
            <ShortenedLinkOption
              shortenedLink={shortenedLink}
              setShortenedLink={handleSetShortenedLink(setFieldValue)}
              selectionHandler={
                getSelectionHandler({
                  optionName: "Link",
                }) as (links: MessageInputFormRowProps["shortenedLink"]) => void
              }
              disabled={isDisabled}
            />
          </Box>
        )}

        <Box
          display="flex"
          data-testid="character-count"
          flexDirection="column"
          sx={(theme) => {
            const error =
              getIn(values, "messageBody") !== "" && characterCount > maxChars;
            return {
              color: error
                ? theme.palette.error.main
                : theme.palette.text.secondary,
            };
          }}
        >
          <Typography fontSize="0.75rem">{`${characterCount} of ${maxChars} characters`}</Typography>
          {segmentCountActive && (
            <Typography fontSize="0.75rem">{`(~${segmentCount} SMS messages)`}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
