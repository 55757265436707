/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
import { useRef, useState, useEffect, MouseEvent } from "react";
import { Chip, Menu, MenuItem } from "@mui/material";

interface OverflowChipsMenuProps {
  tags: string[];
  handleClick: any;
}

export function OverflowChipsMenu({
  tags,
  handleClick,
}: OverflowChipsMenuProps) {
  // ==== HOOKS ==== //
  const containerRef = useRef<HTMLDivElement>(null);
  const [overflowingChips, setOverflowingChips] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // ==== METHODS ==== //
  const calculateOverflow = () => {
    const container = containerRef.current;
    if (container) {
      const containerWidth = container.clientWidth - 80;
      const overflowChips = [];

      // Find overflowing chips
      let accumulatedWidth = 0;
      for (const [i, tag] of tags.entries()) {
        const chipWidth = container.children[i]?.clientWidth;
        accumulatedWidth += chipWidth;
        if (accumulatedWidth > containerWidth && i > 0) {
          overflowChips.push(tag);
        }
      }

      setOverflowingChips(overflowChips);
    }
  };

  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    calculateOverflow();
  }, [tags]);

  // ==== RENDER ===== //
  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
      }}
      data-testid="overflow-chips-menu"
    >
      {tags
        .slice(0, tags.length - overflowingChips.length)
        .map((tag, index) => {
          return (
            <Chip
              key={index}
              label={tag}
              variant="outlined"
              onClick={handleClick({ value: tag })}
            />
          );
        })}

      {overflowingChips.length > 0 && (
        <>
          <Chip
            data-testid="overflow-chips-menu-overflow-chip"
            onClick={handleMenuOpen}
            label={`+${overflowingChips.length}`}
            variant="outlined"
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {overflowingChips.map((tag, index) => {
              return (
                <MenuItem key={index} onClick={handleMenuClose}>
                  <Chip
                    key={index}
                    label={tag}
                    variant="outlined"
                    onClick={handleClick({ value: tag })}
                  />
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </div>
  );
}
