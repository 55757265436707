import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose } from "redux";
import get from "lodash/get";
import set from "lodash/set";
import { Formik, Form, Field, FieldArray } from "formik";
import Button from "@mui/material/Button";

import PageHeader from "components/Page/PageHeader";
import withProfileSubmit from "higherOrderComponents/withProfileSubmit";

const Description = styled.div`
  margin-bottom: 32px;

  p {
    margin: 0;
  }

  p + p {
    margin-top: 20px;
  }
`;

const FormRow = styled.div`
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  display: block;
  min-height: calc(
    100% -
      ${(props) => {
        return props.theme.layout.topHeaderHeight;
      }}
  );
  overflow-y: auto;
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding: ${(props) => {
    return props.isMediumLarge ? "54px" : "32px 20px";
  }};
`;

const PageWrapper = styled.div`
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  display: flex;
  flex-flow: column;
  font-size: 14px;
  height: 100%;
  margin: 0 auto;
`;

const SubmitRow = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  margin-top: 20px;
`;

class ProfileSetting extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    description: PropTypes.node.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isMediumLarge: PropTypes.bool.isRequired,
    settings: PropTypes.arrayOf(
      PropTypes.shape({
        default: PropTypes.any,
        name: PropTypes.string,
        render: PropTypes.func.isRequired,
      }),
    ).isRequired,
    title: PropTypes.string.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    validationSchema: PropTypes.any.isRequired,
  };

  handleSubmit = (values, actions) => {
    const { settings: unfilteredSettings } = this.props;
    const settings = unfilteredSettings.filter((setting) =>
      // Filter out name-less (input-less) form field elements ("settings" should maybe be renamed to "formFields" or something like that)
      {
        return setting.name;
      },
    );
    const updatedSettings = settings.reduce((prev, setting) => {
      const settingKeys = setting.name.split(".");
      const value = get(values, settingKeys);
      const updatedValue = value === -1 ? null : value;
      return set(prev, settingKeys, updatedValue);
    }, {});
    this.props.handleSubmit(updatedSettings, actions);
  };

  render() {
    const {
      currentUser,
      description,
      isMediumLarge,
      settings,
      title,
      toggleSidebar,
      validationSchema,
    } = this.props;
    const initialValues = settings
      .filter((setting) =>
        // Filter out name-less (input-less) form field elements ("settings" should maybe be renamed to "formFields" or something like that)
        {
          return setting.name;
        },
      )
      .reduce((prev, setting) => {
        return set(
          prev,
          setting.name.split("."),
          get(
            currentUser,
            ["settings", ...setting.name.split(".")],
            setting.default,
          ),
        );
      }, {});
    return (
      <PageWrapper>
        <PageHeader title={title} toggleSidebar={toggleSidebar} />
        <Wrapper>
          <Content isMediumLarge={isMediumLarge}>
            {description && <Description>{description}</Description>}
            <Formik
              enableReinitialize
              initialValues={initialValues}
              isInitialValid={validationSchema.isValidSync(initialValues)}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
            >
              {({ errors, touched, isSubmitting, isValid, values }) => {
                return (
                  <Form>
                    {settings.map((setting) => {
                      if (setting.shouldShow?.({ values }) === false) {
                        return null;
                      }
                      if (!setting.name && setting.render) {
                        return (
                          <div key={setting.reactKey}>
                            {setting.render({ values })}
                          </div>
                        );
                      }
                      return (
                        <FormRow key={setting.name}>
                          {Array.isArray(setting.default) ? (
                            <FieldArray name={setting.name}>
                              {setting.render({ errors, touched, values })}
                            </FieldArray>
                          ) : (
                            <Field name={setting.name}>
                              {setting.render({ errors, touched, values })}
                            </Field>
                          )}
                        </FormRow>
                      );
                    })}
                    <SubmitRow>
                      <Button
                        aria-label="Save"
                        data-testid="save-button"
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!isValid || isSubmitting}
                      >
                        Save
                      </Button>
                    </SubmitRow>
                  </Form>
                );
              }}
            </Formik>
          </Content>
        </Wrapper>
      </PageWrapper>
    );
  }
}

export default compose(withProfileSubmit)(ProfileSetting);
