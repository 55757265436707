import { SvgIconTypeMap, useTheme } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface IconWithThemeProps {
  IconComponent: OverridableComponent<SvgIconTypeMap<object, "svg">> & {
    muiName: string;
  };
}

// eslint-disable-next-line react/prop-types
export default function IconWithTheme({ IconComponent }: IconWithThemeProps) {
  const theme = useTheme();
  return <IconComponent sx={{ color: theme.palette.grayscale[700] }} />;
}
