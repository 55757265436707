import { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Typography, Box, Divider, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Stats from "../containers/ConversationStats";
import { ResponsiveRootContext } from "../../InboxRoot/components/ResponsiveRoot";
import WhoIsThis from "./WhoIsThis";
import PhoneActionsOption from "components/PhoneActionsOption";
import Modal from "components/Modal";
import ContactAssignment from "features/Inbox/containers/ContactAssignment";
import ContactSidebar from "features/ContactSidebar";
import getNestedId from "utils/getNestedId";
import { DialogHeader } from "components/DialogHeader";
import { MobileBottomNavigation } from "features/MobileBottomNavigation";
import { selectors as navigationSelectors } from "features/MainNavigation/state";

const Root = styled("div")(({ ...props }) => {
  return {
    height: "100%",
    minWidth: props.mobile ? "100%" : "15rem",
    maxWidth: props.mobile ? "100%" : "20rem",
    display: "flex",
    flexDirection: "column",
    flexWrap: "noWrap",
    overflow: "auto",
    position: "relative",
    zIndex: 1,
  };
});

const PhoneActionsSection = styled("div")({
  marginLeft: "5px",
  flex: "0 0 auto",
});

const PrimaryRow = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
});

const Content = styled("div")({
  overflow: "auto",
  height: "100%",
});

const SubStatus = styled("div")({
  fontSize: "12px",
  paddingTop: "16px",
  paddingBottom: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StatsWrapper = styled("div")({
  minHeight: "50px",
  position: "relative",
});

export default function ConversationSidebarMainView(props) {
  const {
    blockConversationRequest,
    conversation,
    currentAccount,
    fullScreen,
    setContactModal,
    subscribeConversationRequest,
    unblockConversationRequest,
    unsubscribeConversationRequest,
    handleConversationClick,
    handleEditClick,
  } = props;

  const [showAssignContacts, setShowAssignContacts] = useState(false);

  const setShowContactsDetailsSidebar = useContext(ResponsiveRootContext);

  const screens = {
    mobile: useMediaQuery((theme) => {
      return theme.breakpoints.down("sm");
    }),
    smAndUp: useMediaQuery((theme) => {
      return theme.breakpoints.up("sm");
    }),
  };

  function getSubStatus() {
    const { blocked, unsubscribed } = conversation;
    if (blocked && unsubscribed) {
      return (
        <Typography align="center" variant="caption" color="text.secondary">
          Number is <b>blocked</b> and cannot send or receive messages
        </Typography>
      );
    }
    if (blocked) {
      return (
        <Typography align="center" variant="caption" color="text.secondary">
          Number is <b>blocked</b> and cannot send messages
        </Typography>
      );
    }
    if (unsubscribed) {
      return (
        <Typography
          align="center"
          variant="caption"
          sx={(theme) => {
            return {
              color: theme.palette.text.secondary,
            };
          }}
        >
          Number is <b>opted out</b> and will not receive messages
        </Typography>
      );
    }
    return (
      <Typography align="center" variant="caption" color="text.secondary">
        Number is <b>opted in</b> and can receive messages
      </Typography>
    );
  }

  const handleConversationClickMethod = (
    conversationId,
    { conversationCollectionType, conversationProp } = {},
  ) => {
    const { assignedContact, formattedPhoneNumber } = conversation;

    (handleConversationClick ?? setContactModal)({
      active: true,
      activeConversation: conversationId,
      conversationPhoneNumber: formattedPhoneNumber,
      contact: assignedContact,
      expanded: conversationCollectionType,
      conversation: conversationProp,
      form: false,
    });
  };

  const currentAccountNavbarElement = useSelector((state) => {
    return navigationSelectors.selectCurrentAccountNavbarElement(state, props);
  });

  const handleEditClickMethod = () => {
    const { assignedContact, formattedPhoneNumber } = conversation;
    (handleEditClick ?? setContactModal)({
      active: true,
      activeConversation: undefined,
      conversationPhoneNumber: formattedPhoneNumber,
      contact: assignedContact,
      expanded: undefined,
      form: true,
    });
  };

  const hideAssignContactModal = () => {
    return setShowAssignContacts(false);
  };

  const showAssignContactModal = () => {
    return setShowAssignContacts(true);
  };

  function renderContent() {
    const { assignedContact, associatedContacts } = conversation;
    if (assignedContact)
      return (
        <ContactSidebar
          timelineJump
          activeConversationId={conversation.id}
          conversation={conversation}
          contact={assignedContact}
          currentAccount={currentAccount}
          handleConversationClick={handleConversationClickMethod}
          handleEditClick={handleEditClickMethod}
        />
      );
    return (
      <WhoIsThis
        associatedContacts={associatedContacts}
        showAssignContacts={showAssignContactModal}
      />
    );
  }

  const {
    assignedContact,
    associatedContacts: { members: associatedContacts = [] } = {},
    formattedPhoneNumber,
    id: conversationId,
  } = conversation;

  return (
    <Root mobile={screens.mobile}>
      <Box
        position="sticky"
        top="0"
        zIndex="10"
        sx={(theme) => {
          return {
            backgroundColor: theme.palette.background.paper,
          };
        }}
      >
        <DialogHeader
          title="Contact details"
          onClose={() => {
            return setShowContactsDetailsSidebar(false);
          }}
          fullScreen={screens.mobile}
          isPopover={false}
          hasTooltip
          dialogName="contact details"
        />
      </Box>
      <Box
        sx={(theme) => {
          return {
            background: theme.palette.background.default,
            padding: "16px",
          };
        }}
      >
        <PrimaryRow>
          <Typography
            variant="h4"
            sx={(theme) => {
              return { color: theme.palette.primary.main };
            }}
          >
            {formattedPhoneNumber}
          </Typography>
          <PhoneActionsSection>
            <PhoneActionsOption
              classes={{}}
              blockConversationRequest={blockConversationRequest}
              conversation={conversation}
              hasMultipleAssociated={associatedContacts.length > 1}
              showAssignContacts={() => {
                return showAssignContactModal();
              }}
              subscribeConversationRequest={subscribeConversationRequest}
              unblockConversationRequest={unblockConversationRequest}
              unsubscribeConversationRequest={unsubscribeConversationRequest}
            />
          </PhoneActionsSection>
        </PrimaryRow>
        <SubStatus>{getSubStatus()}</SubStatus>
        <StatsWrapper>
          <Stats conversationStatsId={getNestedId(conversation, "stats")} />
        </StatsWrapper>
      </Box>

      <Divider />
      <Box>
        <Content>{renderContent()}</Content>
        <Modal
          transitionIn={showAssignContacts}
          closeModal={hideAssignContactModal}
        >
          <ContactAssignment
            fullScreen={fullScreen}
            associatedContacts={associatedContacts}
            assignedContact={assignedContact}
            conversationId={conversationId}
            number={formattedPhoneNumber}
            close={() => {
              return hideAssignContactModal();
            }}
          />
        </Modal>
      </Box>
      <MobileBottomNavigation
        hideSiteNavigation={screens.smAndUp}
        siteNavigation={currentAccountNavbarElement.siteNavigation}
        mobileContactDetailsView
      />
    </Root>
  );
}

ConversationSidebarMainView.propTypes = {
  blockConversationRequest: PropTypes.func.isRequired,
  conversation: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool,
  setContactModal: PropTypes.func.isRequired,
  subscribeConversationRequest: PropTypes.func.isRequired,
  unblockConversationRequest: PropTypes.func.isRequired,
  unsubscribeConversationRequest: PropTypes.func.isRequired,
  handleConversationClick: PropTypes.func,
  handleEditClick: PropTypes.func,
};
