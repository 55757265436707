import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import get from "lodash/get";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Tooltip, styled } from "@mui/material";
import { InfoOutlinedIcon } from "icons";
import { useCurrentAccount } from "hooks";

function AutomaticMessageIndicator() {
  const currentAccount = useCurrentAccount();

  const autoReplyObject = get(currentAccount, [
    "settings",
    "autoReplyMessage",
    "value",
  ]);

  const autoReplyMessageEnabled = get(currentAccount, [
    "settings",
    "autoReplyMessageEnabled",
    "value",
  ]);

  const introMessageEnabled = get(currentAccount, [
    "settings",
    "introMessages",
    "value",
    "messageEnabled",
  ]);

  const introMessageCopy = get(currentAccount, [
    "settings",
    "introMessages",
    "value",
    "message",
  ]);

  const getAutoReplyDescription = () => {
    if (autoReplyObject && autoReplyObject.officeHours) {
      return "This message has been configured to send once every 24 hours outside your office hours.";
    }
    return "This message has been configured to send every 24 hours in response to all recipients.";
  };

  const getAutomaticMessages = () => {
    return (
      <Box
        p={2}
        fontSize={12}
        bgcolor={(theme) => {
          return theme.palette.background.paper;
        }}
      >
        <Box component="h3" mt={0}>
          <Typography variant="h6" color="text.primary">
            Automated messages enabled{" "}
          </Typography>
        </Box>
        {introMessageEnabled && introMessageCopy && (
          <Box>
            <Typography
              variant="subtitle2"
              color="text.primary"
              borderTop="0.75rem"
            >
              {" "}
              Introduction message{" "}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              The following introduction message will be sent the first time you
              message a contact.
            </Typography>
            <Box
              p={2}
              borderRadius="1rem"
              bgcolor={(theme) => {
                return theme.palette.messages.outbound;
              }}
              color="text.primary"
              padding="0.5rem 1rem"
            >
              <Typography gutterBottom>{introMessageCopy}</Typography>
              <Typography gutterBottom>
                Reply STOP to unsubscribe. Msg & Data Rates May Apply.
              </Typography>
            </Box>
          </Box>
        )}
        {autoReplyMessageEnabled && autoReplyObject && (
          <>
            <Box>
              <Typography
                variant="subtitle2"
                color="text.primary"
                paddingTop="0.75rem"
              >
                {" "}
                Auto reply{" "}
              </Typography>
            </Box>
            <Typography variant="caption" color="text.secondary" mb={1}>
              {getAutoReplyDescription()}
            </Typography>
            <Box
              p={2}
              borderRadius="16px"
              bgcolor={(theme) => {
                return theme.palette.messages.outbound;
              }}
              color="text.primary"
              padding="0.5rem 1rem"
            >
              <Typography gutterBottom>{autoReplyObject.message}</Typography>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const shouldRender = Boolean(
    autoReplyMessageEnabled || (introMessageEnabled && introMessageCopy),
  );

  const [open, setOpen] = useState(false);

  const handleTooltipToggle = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const AutomatedMessagesTooltip = styled(({ className, ...props }) => {
    return <Tooltip {...props} classes={{ popper: className }} />;
  })(({ theme }) => {
    return {
      ".MuiTooltip-tooltip": {
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        width: "100%",
        minWidth: "18rem",
        [theme.breakpoints.up("xs")]: {
          maxWidth: "18rem",
        },
        [theme.breakpoints.up("sm")]: {
          maxWidth: "31.25rem",
        },
        overflow: "hidden",
      },
    };
  });

  return (
    !!shouldRender && (
      <Box
        display="flex"
        flex="0 0 1"
        padding="0.25rem"
        justifyContent="flex-start"
        sx={(muiTheme) => {
          return {
            borderColor: muiTheme.palette.customColors.enabledBorder,
            borderRadius: "0 0 0.5rem 0.5rem",
          };
        }}
      >
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <AutomatedMessagesTooltip
            aria-label="More information"
            title={getAutomaticMessages()}
            onClick={handleTooltipToggle}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="top-start"
          >
            <Box
              sx={{
                color: "text.secondary",
                zIndex: 10,
                width: "100%",
                display: "flex",
                flex: "0 0 auto",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <InfoOutlinedIcon fontSize="inherit" />
              <Typography
                variant="caption"
                sx={{
                  display: "flex",
                  flex: "0 0 auto",
                  marginLeft: "2px",
                }}
              >
                Automated messages enabled
              </Typography>
            </Box>
          </AutomatedMessagesTooltip>
        </ClickAwayListener>
      </Box>
    )
  );
}

export default AutomaticMessageIndicator;
