import { textUsApiV3, textUsApiV4 } from "services";

function fetchContactCollectionV4(contactCollectionId: string) {
  return textUsApiV4.get(contactCollectionId);
}

function fetchContactCollection(contactCollectionId: string) {
  return textUsApiV3.get(contactCollectionId);
}

export { fetchContactCollection, fetchContactCollectionV4 };
