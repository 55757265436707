import { DialogProps } from "@mui/material";
import { ReactElement, Ref } from "react";

export type Props<T, R> = Omit<DialogProps, "open" | "onClose" | "children"> & {
  children?: ((state: State<T, R>) => ReactElement) | DialogProps["children"];
  ref: Ref<DialogRef<T, R>>;
  onClose?: (
    ...params: Parameters<NonNullable<DialogProps["onClose"]>>
  ) => boolean;
  defaultCloseValue?: R;
  maxWidth?: string;
};

export interface State<T, R> {
  close: (data: R) => () => void;
  parameters: T | undefined;
}

export interface DialogRef<T, R> {
  open: (parameters: T) => Promise<R>;
}
