import PropTypes from "prop-types";
import get from "lodash/get";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import ButtonLink from "@mui/material/Link";
import Tile from "./Tile";
import ICONS from "constants/icons";
import Icon from "components/Icon";
import H3 from "components/H3";

const Root = styled(Tile)`
  padding-bottom: 20px;

  ${H3} {
    margin-top: 10px;
  }
`;

const QuickLinkStyles = css`
  align-items: center;
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }} !important;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: flex-start;
  padding: 0 24px;
  text-decoration: none;
  width: 100%;

  svg {
    fill: currentColor;
    margin-right: 18px;
  }

  span {
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }

  &:hover {
    background: ${(props) => {
      return props.theme.colors.action.hover;
    }};
  }
`;

const QuickLink = styled(Link)`
  ${QuickLinkStyles};
`;

const QuickExternalLink = styled(ButtonLink)`
  ${QuickLinkStyles};
`;

const QuickAction = styled.button`
  ${QuickLinkStyles};
`;

function QuickLinks(props) {
  const { currentAccount, currentUser, setCompose } = props;
  const supportLink = get(
    currentAccount,
    ["settings", "supportLink", "value"],
    "https://help.textus.com/s",
  );

  const getItems = () => {
    return [
      {
        icon: ICONS.AIRPLANE,
        title: "Send a Message",
        action: () => {
          return setCompose({ active: true });
        },
        shouldRender: get(currentAccount, ["phoneNumbers"], []).length > 0,
        cypressTag: "QuickLinks-SendMessage",
      },
      {
        icon: ICONS.MEGAPHONE,
        title: "Start a Campaign",
        link: `/${currentAccount.slug}/campaigns/new`,
        shouldRender: get(currentAccount, ["phoneNumbers", "length"]) > 0,
        cypressTag: "QuickLinks-StartCampaign",
      },
      {
        icon: ICONS.CONTACT,
        title: "Upload Contacts",
        link: `/${currentAccount.slug}/contact_imports/new`,
        shouldRender: get(currentAccount, ["contactsEnabled"], false),
        cypressTag: "QuickLinks-UploadContacts",
      },
      {
        icon: ICONS.PENCIL,
        title: "Edit My Profile",
        link: currentAccount.featureFlags.settingsSidebarRedesign
          ? `/${currentAccount.slug}/settings/profile`
          : "/profile",
        shouldRender: true,
        cypressTag: "QuickLinks-EditProfile",
      },
      {
        icon: ICONS.ANALYTICS,
        title: "Analytics",
        link: "/analytics",
        shouldRender: true,
        cypressTag: "QuickLinks-Analytics",
      },
      {
        icon: ICONS.GEAR,
        title: "Settings",
        link: `/${currentAccount.slug}/settings`,
        shouldRender: true,
        cypressTag: "QuickLinks-Settings",
      },
    ];
  };

  const renderItems = () => {
    return getItems()
      .filter((i) => {
        return i.shouldRender;
      })
      .map((i) => {
        const onClick = i.link ? { to: i.link } : { onClick: i.action };
        const TagName = i.link ? QuickLink : QuickAction;
        return (
          <TagName {...onClick} key={i.title} data-product-tour={i.title}>
            <Icon icon={i.icon} iconSize="20" />
            <span>{i.title}</span>
          </TagName>
        );
      });
  };

  return (
    <Root dataTag="QuickLinks-Root" {...props}>
      <H3>Quick Links</H3>
      {renderItems()}
      {supportLink && (
        <QuickExternalLink href={supportLink} target="_new" underline="none">
          <Icon icon={ICONS.SUPPORT} iconSize="20" />
          <span>Contact Support</span>
        </QuickExternalLink>
      )}
    </Root>
  );
}

QuickLinks.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCompose: PropTypes.func.isRequired,
};

export default QuickLinks;
