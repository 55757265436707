import AddIcon from "@mui/icons-material/Add";
import { Button, Tooltip } from "@mui/material";
import { useFormikContext } from "formik";
import { SUBKEYWORD_DEFAULT_MAX_LENGTH } from "../constants";

function AddKeywordAnswerButton({ disabled = false }: { disabled?: boolean }) {
  const { values, setFieldValue } = useFormikContext();

  const { keywordAnswers } = values;

  const onClick = () => {
    if (keywordAnswers.length >= SUBKEYWORD_DEFAULT_MAX_LENGTH) {
      return;
    }
    const updatedKeywordAnswers = [
      ...keywordAnswers,
      {
        keyword: "",
        responseBody: "",
        enrollInSequence: false,
        sequenceId: "",
      },
    ];
    void setFieldValue("keywordAnswers", updatedKeywordAnswers);
  };

  const tooltipTitle = disabled
    ? "Maximum number of keyword answers reached"
    : "Click to add keyword answer";

  return (
    <Tooltip title={tooltipTitle}>
      <Button
        startIcon={<AddIcon />}
        color="primary"
        sx={{
          marginLeft: "6px",
          height: "36px",
          width: "216px",
          "&:hover": { backgroundColor: "transparent" },
        }}
        data-testid="add-keyword-answer-button"
        onClick={onClick}
        disabled={disabled}
      >
        Add Keyword Answer
      </Button>
    </Tooltip>
  );
}

export default AddKeywordAnswerButton;
