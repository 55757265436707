export const campaignFilterSlugs = Object.freeze({
  all: "all",
  allScheduled: "all_scheduled",
});

export const campaignLandingTabs = Object.freeze({
  overview: 0,
  oneTime: 1,
  recurring: 2,
});

export const campaignTypes = Object.freeze({
  oneTime: "single",
  recurring: "recurring",
  recurringInstance: "recurring_instance",
});

export const columnTooltips = Object.freeze({
  sendDate: "The date this instance of the campaign was sent",
  status: "The status of the campaign",
  totalRecipients: "Total number of contacts selected to receive the campaign",
  skipped:
    "Number of recipients the system did not send the message to due to cancellation or campaign cutoff time",
  sentCount: "Number of messages sent",
  deliveryRate: "Percentage of messages sent that were successfully delivered",
  replyRate:
    "Percentage of messages delivered that received a response from the recipient",
  failureRate: "Percentage of messages sent that were not delivered",
});

export const campaignVariantMap: { [key: number]: "A" | "B" | "C" } = {
  1: "A",
  2: "B",
  3: "C",
};
