import { Account } from "@tesseract/core";
import { textUsApiV4 } from "services";

export const archiveKeyword = (
  currentAccount: Account.Raw,
  id: string,
): Promise<Response> => {
  return textUsApiV4.destroy(
    `/${currentAccount.slug}/automation_keywords/${id}`,
  );
};
