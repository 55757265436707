/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Location } from "history";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { formatInTimeZone } from "date-fns-tz";
import { KeywordTableProps } from "../models/AutomationModels";
import stripKeywordId from "../utils/stripKeywordId";
import SimpleQrDialog from "../../Keywords/components/SimpleQrDialog";
import {
  CachedIcon,
  DeleteIcon,
  EditIcon,
  GroupsIcon,
  QRCodeIcon,
} from "icons";
import BlankState from "components/BlankState";
import Logo from "components/Logo";
import { useCurrentAccount, useTimeZones } from "hooks";
import { isDefined } from "utils/isDefined";

/**
 * The following are a set of Cypress tests that rely on the KeywordTable. Please check these tests when making changes.
 * (NOTE: Use CMD + Click instead of clicking the "follow link" option.)
 * {@link file://./../../../../../ui_packages/web-app/cypress/integration/keywords/keyword-automation-table.cy.ts}
 */

function KeywordTable({
  activeKeywords,
  discardedKeywords = false,
  keywords,
  page,
  searchTerm = "",
  handleFetchAutomationKeywords,
  reimplementKeyword,
  setPage,
  toggleDeleteModal,
  toggleEditModal,
}: KeywordTableProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationModel, setPaginationModel] = useState({
    page: Number(new URLSearchParams(window.location.search).get("page")) || 0,
    pageSize: 10,
  });

  const { name: accountName, phoneNumbers, featureFlags } = useCurrentAccount();
  const { accountTimeZone } = useTimeZones();

  useEffect(() => {
    setLoading(!keywords?.items);
  }, [keywords?.items]);

  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState("");

  const handleQrModalClose = () => {
    setSelectedKeyword("");
    setQrModalOpen(false);
  };

  useEffect(() => {
    const getPage = async () => {
      setLoading(true);
      await handleFetchAutomationKeywords({
        active: !discardedKeywords,
        items: paginationModel.pageSize,
        keywordCont: searchTerm,
      });
      setLoading(false);
    };

    getPage().catch((error) => {
      console.error(error);
    });
  }, [page, searchTerm]);

  const handlePageChange = (newPaginationModel: GridPaginationModel) => {
    setPage(newPaginationModel.page + 1);
    setPaginationModel(newPaginationModel);
  };

  const columns: GridColDef[] = [
    { field: "keyword", headerName: "Keyword", flex: 1 },
    {
      field: "responseBody",
      headerName: "Auto-response",
      flex: 1,
    },
    {
      field: "timesReceived",
      headerName: "Times received",
      flex: 1,
      type: "number",
    },
    {
      field: "createdBy",
      headerName: "Created by",
      flex: 1,
    },
    {
      field: discardedKeywords ? "deletedAt" : "createdAt",
      headerName: discardedKeywords ? "Deleted at" : "Created at",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return formatInTimeZone(params.value, accountTimeZone, "MM-dd-yyyy");
      },
    },
    {
      field: "Manage",
      headerName: "Manage",
      headerAlign: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const writeAccess = accountName === params.row.createdBy;
        const keywordGroupId = stripKeywordId(params.row.id);
        const groupRoute = `_groups/${keywordGroupId}`;
        const keywordAlreadyExists = Boolean(
          activeKeywords?.find((keyword) => {
            return keyword === params.row.keyword;
          }),
        );

        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Box>
              <Tooltip title="Generate QR Code">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setSelectedKeyword(params.row.keyword);
                    setQrModalOpen(true);
                  }}
                >
                  <QRCodeIcon data-testid="qr-icon" sx={{ fontSize: 22 }} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip
                title={
                  writeAccess
                    ? "Open Edit Keyword Modal"
                    : "You do not have edit access."
                }
              >
                <IconButton
                  color="primary"
                  disabled={!writeAccess}
                  onClick={() => {
                    if (writeAccess) toggleEditModal(params.row);
                  }}
                >
                  <EditIcon data-testid="edit-icon" sx={{ fontSize: 22 }} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title="View Keyword Subscribers">
                <IconButton
                  component={RouterLink}
                  to={(location: Location) => {
                    return `${location.pathname}${groupRoute}`;
                  }}
                >
                  <GroupsIcon
                    data-testid="groups-icon"
                    color="primary"
                    sx={{ fontSize: 22 }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              {discardedKeywords ? (
                <Tooltip
                  title={
                    writeAccess && !keywordAlreadyExists
                      ? "Reimplement Keyword"
                      : "You cannot reimplement"
                  }
                >
                  <span>
                    <IconButton
                      color="primary"
                      disabled={!writeAccess || keywordAlreadyExists}
                      onClick={() => {
                        if (reimplementKeyword) reimplementKeyword(params.row);
                      }}
                    >
                      <CachedIcon
                        data-testid="reimplement-keyword-icon"
                        sx={{ fontSize: 22 }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    writeAccess
                      ? "Open Delete Keyword Modal"
                      : "You do not have delete access"
                  }
                >
                  <IconButton
                    color="primary"
                    disabled={!writeAccess}
                    onClick={() => {
                      if (isDefined(toggleDeleteModal)) {
                        toggleDeleteModal(params.row);
                      }
                    }}
                  >
                    <DeleteIcon
                      data-testid="delete-keyword"
                      sx={{ fontSize: 22 }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        );
      },
    },
  ];

  const { items = [] } = keywords ?? {};

  const tableLabel = discardedKeywords
    ? "Deleted Keyword Automation Table"
    : "Keyword Automation Table";

  return (
    <>
      {!loading && items.length === 0 && (
        <Box py={4}>
          <BlankState
            image={<Logo color="disabled" />}
            title={`No ${
              discardedKeywords ? "inactive" : "active"
            } keywords found`}
            subTitle={
              discardedKeywords
                ? "Inactive keywords will appear here"
                : "Create a keyword and auto-reponse using the 'Create New' keyword button"
            }
          />
        </Box>
      )}
      <DataGridPro
        data-testid={tableLabel}
        aria-label={tableLabel}
        autoHeight
        columns={columns}
        disableRowSelectionOnClick
        loading={loading}
        onPaginationModelChange={handlePageChange}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        pageSizeOptions={[]}
        rows={items}
        rowCount={keywords?.page?.count ?? 0}
        // @ts-expect-error -data-testid is needed for testing
        slotProps={{ pagination: { "data-testid": "pagination" } }}
      />

      <SimpleQrDialog
        keyword={selectedKeyword}
        isOpen={qrModalOpen}
        phoneNumbers={phoneNumbers}
        closeHandler={handleQrModalClose}
      />
    </>
  );
}

export default KeywordTable;
