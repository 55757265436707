import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Contact } from "@tesseract/core";
import { normalize } from "normalizr";

import {
  fetchContactCollection,
  fetchContactCollectionV4,
} from "../api/fetchContactCollection";
import * as schema from "schema";
import { UPDATE_RECORDS } from "features/EntryPoint/containers/App/constants";
import denormalizeWithShape from "utils/denormalizeWithShape";
import { useCurrentAccount } from "hooks";

interface Props {
  contactCollectionId: string;
}

function useContactCollection({ contactCollectionId }: Props) {
  const [contactCollection, setContactCollection] = useState<
    Contact.Collection | Record<string, undefined>
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { featureFlags } = useCurrentAccount();

  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const records = useSelector((state: DefaultRootState & { get: any }) => {
    return {
      ...state.get("global").get("records").toJS(),
    };
  });

  // Batch actions and disconnected components can use this hook, which means they have discrete instances.
  // Each instance needs a way to access a shared state, so subscribe to the redux store.
  useEffect(() => {
    if (Object.keys(records || {}).length === 0) {
      return;
    }

    const shape = {
      members: [{ phones: { members: [] } }],
    };

    // munge the data into the shape the components expect
    const denormalized = denormalizeWithShape({
      id: contactCollectionId,
      records,
      shape,
    });

    if (
      denormalized?.members &&
      contactCollection.members &&
      denormalized.members.length !== contactCollection.members.length
    ) {
      // update the hook data
      setContactCollection(denormalized);
    }
  }, [records, contactCollectionId, contactCollection?.members]);

  const reshapeV4Data = useCallback(
    (data: any) => {
      const reshaped: Contact.Collection = {
        id: contactCollectionId as Contact.CollectionId,
        totalItems: data.page?.count, // || data.items > 0 ? data.items.length : 0;
        members: data.items.map((contact: any) => {
          const contactPhone: Record<string, any> = contact.contactPhones[0];

          return {
            id: `/contacts/${contact.id}`,
            "@type": "Contact",
            data: {
              id: `/contacts/${contact.id}`,
              tags: contact.tags,
              business: contact.business,
            },
            name: contact.name,
            firstName: contact.name.split(" ").shift(),
            lastName: contact.name.split(" ").pop(),
            createdAt: null, // TODO API needs to return this maybe?
            // notes: `/contacts/${contact.id}/notes`, // TODO is this critical?
            phones: {
              id: `/contacts/${contact.id}/phones`,
              members: [
                {
                  contact: `/contacts/${contact.id}`,
                  deliverabilityStatus: contactPhone?.deliverabilityStatus,
                  extension: null, // TODO API needs to return this
                  formattedPhoneNumber: contactPhone?.phoneNumber.replace(
                    /^(\+1)(\d{3})(\d{3})(\d{4})$/,
                    "($2) $3-$4",
                  ), // TODO API needs to return this,
                  id: `/contact_phones/${contactPhone?.id}`,
                  optedOut: contactPhone?.optedOut,
                  phoneNumber: contactPhone?.phoneNumber,
                  type: contactPhone.type,
                },
              ],
            },
            conversations: `/contacts/${contact.id}/conversations`,
            notes: `/contacts/${contact.id}/notes`,
          };
        }),
        view: {
          id: contactCollectionId,
          first: data.page.firstUrl,
          next: data.page.nextUrl,
          previous: data.page.previousUrl,
        },
      };
      return reshaped;
    },
    [contactCollectionId],
  );

  // fetch contact collection if needed
  const sendFetchRequest = useCallback(async () => {
    if (!contactCollectionId) return;
    setIsLoading(true);

    let data: Contact.Collection | Record<string, undefined>;

    if (featureFlags.newContactSearch) {
      const resv4 = await fetchContactCollectionV4(contactCollectionId);
      const raw = await resv4.json();
      data = reshapeV4Data(raw);
    } else {
      const res = await fetchContactCollection(contactCollectionId);
      data = await res.json();
    }

    // update data for use in component
    setContactCollection(data);

    // normalize data & dispatch to redux store
    const { entities } = normalize(data, schema.contactCollection);

    setIsLoading(false);

    dispatch({
      type: UPDATE_RECORDS,
      records: entities,
    });
  }, [
    contactCollectionId,
    dispatch,
    reshapeV4Data,
    featureFlags.newContactSearch,
  ]);

  useEffect(() => {
    void sendFetchRequest();
  }, [sendFetchRequest, contactCollectionId]);

  return {
    contactCollection,
    sendFetchRequest,
    isLoading,
    isError,
    error,
  };
}

export { useContactCollection };
