import { useEffect, useState } from "react";
import get from "lodash/get";
import { Box, Typography } from "@mui/material";

import ImportSection from "features/ContactImports/containers/NewContactImport/components/ImportSection";
import { Uploader } from "components/Uploader";

interface ImportUploadProps {
  handleFilesAccepted: (file: File) => void;
  withHeaders: boolean;
  withInstructionText: boolean;
}
export default function ImportUpload({
  handleFilesAccepted,
  withHeaders,
  withInstructionText,
}: ImportUploadProps) {
  const [error, setError] = useState(null);

  // clears error message after 5 seconds
  useEffect(() => {
    if (error) {
      const timeoutId = setTimeout(() => {
        setError(null);
      }, 5000);

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }

    return undefined;
  }, [error]);

  const handleFilesRejected = (fileWithErrors: Record<string, any>) => {
    const foundError = get(
      fileWithErrors,
      [0, "errors", 0, "message"],
      "Something went wrong. Please try again.",
    );

    setError(foundError);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        textAlign: "left",
        minWidth: "20rem",
      }}
    >
      <ImportSection>
        {(withHeaders || withInstructionText) && (
          <Box marginBottom="40px">
            {withHeaders && (
              <Typography variant="h6" color="primary.main" mb={1}>
                Select contacts
              </Typography>
            )}
            {withInstructionText && (
              <Typography variant="body2" className="textus-Import-helperText">
                Please select a spreadsheet (.csv, .xlsx, .xls) to import. At a
                minimum the file must contain a name and phone number.
              </Typography>
            )}
          </Box>
        )}

        <Uploader
          onFileAccepted={handleFilesAccepted}
          onFileRejected={handleFilesRejected}
          error={error || false}
        />
      </ImportSection>
    </Box>
  );
}
