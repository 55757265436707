import { useState } from "react";
import { Box, TextField, Typography, styled, Avatar } from "@mui/material";
import { Field, useFormikContext, getIn } from "formik";
import KeywordFormSequenceSelect from "./KeywordFormSequenceSelect";
import { RootKeywordFormProps } from "features/Keywords/types";
import AutomaticMessageIndicator from "components/AutomaticMessageIndicator";
import MessageInputFormRow from "components/MessageInputFormRow";
import { useCurrentAccount } from "hooks";
import { useAutomationNavLinks } from "features/Automation/hooks/useAutomationNavLinks";
import { ErrorOutlineIcon, KeyIcon } from "icons";
import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";
import SavedRepliesOption from "components/MessageInputOptions/SavedRepliesOption";

const MessageWrapper = styled(Box)(({ theme }) => {
  return {
    border: `1px solid ${theme.palette.customColors.enabledBorder}`,
    borderRadius: "8px",
  };
});

const renderIcon = () => {
  return (
    <Box
      sx={{
        paddingTop: "0px",
        paddingRight: "8px",
        display: "flex",
        flexDirection: "row",
        height: "36px",
        width: "36px",
        backgroundColor: (theme) => {
          return theme.palette.background.paper;
        },
      }}
    >
      <Avatar sx={{ bgcolor: "blueGrey.800", width: "32px", height: "32px" }}>
        <KeyIcon />
      </Avatar>
    </Box>
  );
};

function RootKeywordForm({ sequences, isMobile }: RootKeywordFormProps) {
  const account = useCurrentAccount();
  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: account.shortenedLinkPreviewUrl,
  });

  const { errors, touched, values, setFieldValue } = useFormikContext();
  const [_sequenceId, setSequenceId] = useState(values.sequenceId);

  const handleChange = (event: any) => {
    void setFieldValue("keyword", event.target.value.toUpperCase());
  };

  const { sequencesEnabled } = useAutomationNavLinks();

  const sizeExceededError = values.keyword.length > 20;
  const duplicateKeywordError = errors.validationError?.keyword;
  const triggerError = sizeExceededError || duplicateKeywordError;
  const triggerHelperText = sizeExceededError
    ? `${values.keyword.length} of 20 characters`
    : duplicateKeywordError
      ? "Keyword already in use"
      : "";
  const messageFieldError = errors.validationError?.responseBody;

  const { conversationMaxMessageLength } = account.settings;

  const maxCharacterLength =
    conversationMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH;

  return (
    <Box width="100%">
      <Box sx={{ display: "flex", width: "100%" }}>
        {isMobile ? renderIcon() : null}
        <Typography component="h4" variant="h4">
          Keyword trigger
        </Typography>
      </Box>
      <Box
        sx={(theme) => {
          return {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: "4px",
            marginTop: "1rem",
          };
        }}
      >
        <Box sx={{ margin: "1rem" }}>
          <Field name="keyword" type="text">
            {({ field }) => {
              return (
                <TextField
                  {...field}
                  fieldName={field.name}
                  disabled={values.id}
                  hiddenLabel
                  value={values.keyword}
                  onChange={handleChange}
                  data-testid="keyword-trigger"
                  error={triggerError}
                  helperText={triggerHelperText}
                  id="keyword-trigger"
                  placeholder="Keyword"
                  type="text"
                  variant="outlined"
                  fullWidth
                />
              );
            }}
          </Field>
          <Box
            sx={{
              margin: "1rem 0 1rem 0",
            }}
          >
            <MessageWrapper>
              <MessageInputFormRow
                attachmentFieldName="attachments"
                attachmentField={values?.attachments ?? []}
                data-testid="root-keyword-auto-response"
                currentAccount={account}
                errors=""
                values={values}
                fieldName="responseBody"
                isKeyword
                isDisabled={false}
                hideUserPersonalization={false}
                placeholder="Message"
                maxChars={maxCharacterLength}
                setFieldValue={setFieldValue}
                SavedRepliesOption={SavedRepliesOption}
                setShortenedLink={setShortenedLink}
                shortenedLink={shortenedLink}
                touched={touched}
                messageFieldOutlineActive={false}
                formPadding="12px 16px 8px 16px"
                segmentCountActive={false}
                editorWrapperPadding="0px"
              />
            </MessageWrapper>
            <Box sx={{ marginLeft: "0.25rem" }}>
              {messageFieldError ? (
                <Box>
                  <ErrorOutlineIcon fontSize="inherit" color="error" />

                  <Typography
                    color="error"
                    variant="caption"
                    sx={{ marginLeft: "2px" }}
                  >
                    {messageFieldError}
                  </Typography>
                </Box>
              ) : (
                <AutomaticMessageIndicator />
              )}
            </Box>
          </Box>
          {sequencesEnabled && (
            <KeywordFormSequenceSelect
              account={account}
              keyword={values.keyword}
              enrollInSequence={values.enrollInSequence}
              sequenceId={values.sequenceId}
              setSequenceId={setSequenceId}
              setFieldValue={setFieldValue}
              sequences={sequences}
              isMobile={isMobile}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default RootKeywordForm;
