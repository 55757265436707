/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useLayoutEffect } from "react";
import { openDB, IDBPDatabase, DBSchema } from "idb";
import { Attachment, Conversation } from "@tesseract/core";
import { useCurrentAccount } from "./useCurrentAccount";
import { useCurrentUser } from "./useCurrentUser";
import { useChromeExtension } from "./useChromeExtension";

type Key = `${string}-${string}`;

type Draft = {
  key: Key | null;
  message: string;
  attachments: Attachment.Model[];
  link: { fullLink: string; shortLink: string };
};

interface DraftsDB extends DBSchema {
  TEXTUS_DRAFTS: {
    key: string;
    value: Draft;
  };
}

// conversation will be undefined coming from QuickCompose
export const useDraft = (conversation?: Conversation.Raw) => {
  const [draftsDatabase, setDraftsDatabase] =
    useState<IDBPDatabase<DraftsDB> | null>(null);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [initializingIdb, setInitializingIdb] = useState(true);
  const [draft, setDraft] = useState<Draft | null>(null);
  const [initialDraftMessage, setInitialDraftMessage] = useState("");

  const { shortenedLinkPreviewUrl } = useCurrentAccount();
  const { id } = useCurrentUser();
  const { isExtension } = useChromeExtension();

  const strippedUserId = id.replace("/users/", "");
  const conversationId = window.location.pathname.split("/").at(-1);

  const draftKey: Key = `${conversationId}-${strippedUserId}`;
  const databaseName = "TEXTUS_DRAFTS";
  const doNotUseDraft = !conversation || isExtension;

  const blankDraft = {
    key: draftKey,
    message: "",
    attachments: [],
    link: { fullLink: "", shortLink: shortenedLinkPreviewUrl },
  };

  /* IDB GETTERS AND SETTERS */
  const getDraft = async (key: Key) => {
    return draftsDatabase?.get(databaseName, key);
  };

  const saveDraft = async (draftValues: Draft) => {
    return draftsDatabase?.put(databaseName, draftValues);
  };

  const deleteDraft = async (key: Key) => {
    return draftsDatabase?.delete(databaseName, key);
  };

  const clearDraft = async (key: Key) => {
    setDraft(null);
    return deleteDraft(key);
  };

  /* DRAFT STATE SETTERS */
  const setDraftMessage = (message: string) => {
    setDraft((prevDraft) => {
      if (!prevDraft) {
        return { ...blankDraft, message };
      }

      return {
        ...prevDraft,
        message,
      };
    });
  };

  const setDraftAttachments = (attachments: Attachment.Model[]) => {
    setDraft((prevDraft) => {
      if (!prevDraft) {
        return { ...blankDraft, attachments };
      }

      return {
        ...prevDraft,
        attachments,
      };
    });
  };

  const setDraftLink = (link: { fullLink: string; shortLink: string }) => {
    setDraft((prevDraft) => {
      if (!prevDraft) {
        return { ...blankDraft, link };
      }

      return {
        ...prevDraft,
        link,
      };
    });
  };

  /* INITIALIZES THE IDB ON INITIAL RENDER */
  useEffect(() => {
    if (doNotUseDraft) {
      setInitializingIdb(false);
      return undefined;
    }

    const initDB = async () => {
      try {
        const indexedDB = await openDB<DraftsDB>(databaseName, 1, {
          upgrade(db) {
            db.createObjectStore(databaseName, {
              keyPath: "key",
            });
          },
        });
        setDraftsDatabase(indexedDB);
      } catch (error) {
        console.error(error);
      } finally {
        setInitializingIdb(false);
      }
    };

    if (!draftsDatabase) {
      initDB().catch((error) => {
        return console.error(`Error initializing idb: ${error}`);
      });
    }

    return () => {};
  }, []);

  /* LOAD DRAFT FROM IDB WHEN CONVERSATION CHANGES OR IDB INITIALIZES */
  useLayoutEffect(() => {
    // useLayoutEffect blocks painting until it's done,
    // preventing a stale data issue with the draft state
    if (doNotUseDraft) {
      return undefined;
    }

    const loadDraft = async () => {
      try {
        return getDraft(draftKey).then((draftFromIdb) => {
          if (draftFromIdb) {
            setDraft(draftFromIdb);
            setInitialDraftMessage(draftFromIdb.message);
          } else {
            setDraft(null);
            setInitialDraftMessage("");
          }
          return draftFromIdb;
        });
      } catch (error) {
        return console.error(error);
      } finally {
        setLoadingDraft(false);
      }
    };

    loadDraft().catch((error) => {
      return console.error(`Error loading draft: ${error}`);
    });

    return () => {};
  }, [window.location.pathname, initializingIdb]);

  /* SAVE DRAFT TO INDEXEDDB WHEN CONVERSATION CHANGES */
  useEffect(() => {
    if (doNotUseDraft) {
      return undefined;
    }

    if (draft) {
      // When clicking from one conversation to the next, this draft state represents the conversation that's being left
      saveDraft(draft).catch((error) => {
        return console.error(`Error saving draft: ${error}`);
      });
    }

    return () => {};
  }, [window.location.pathname]);

  /* SAVE DRAFT TO INDEXEDDB WHEN TAB REFRESHES OR CLOSES */
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (draft) {
        saveDraft(draft).catch((error) => {
          return console.error(`Error saving draft: ${error}`);
        });
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [draft]);

  return {
    clearDraft,
    deleteDraft,
    draftLoading: initializingIdb || loadingDraft,
    draftKey,
    getDraft,
    initialDraftMessage,
    setDraftMessage,
    setDraftAttachments,
    setDraftLink,
    draft,
  };
};
