import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Alert, Box, Button } from "@mui/material";
import withSizes from "react-sizes";
import breakpoints from "utils/styles/breakpoints";

import getNestedId from "utils/getNestedId";
import getPaginatedId from "utils/getPaginatedId";
import ContactCollection from "features/Contacts/containers/ContactCollection";
import NewContactFilter from "features/Contacts/containers/NewContactFilter/NewContactFilter";
import PageHeader from "components/Page/PageHeader";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  font-size: ${(props) => {
    return props.theme.fonts.messageFontSize;
  }};
  height: 100%;
  margin: 0 auto;
  position: relative;
  width: 100%;
`;

const PageContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  overflow-x: auto;
  position: relative;
`;

const SavedSearchButtonContainer = styled.div`
  flex: 0 0 auto;
  padding-right: 10px;
`;

const SavedSearchButton = styled(Button)``;

class Root extends Component {
  static propTypes = {
    contactFilter: PropTypes.object.isRequired,
    showSearchBox: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    toggleSearch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showNewSavedSearchModal: false,
      searchErrorState: false,
    };
  }

  hideNewSavedSearchModal = () => {
    this.setState({ showNewSavedSearchModal: false });
  };

  showNewSavedSearchModal = () => {
    this.setState({ showNewSavedSearchModal: true });
  };

  handleErrorState = (error) => {
    console.log(
      "ContactFilter error (ES down? 404 on a request endpoint?)",
      error,
    );
    // Disabled until item #2 here is fixed: https://app.shortcut.com/textus/story/48667/searching-tags-is-inconsistent-and-doesn-t-usually-work-getting-a-sorry-search-is-unavailable#activity-48858
    // this.setState({ searchErrorState: true });
  };

  render() {
    const { contactFilter, showSearchBox, toggleSidebar, toggleSearch } =
      this.props;
    const { showNewSavedSearchModal, searchErrorState } = this.state;
    const { id, title, contacts } = contactFilter;
    const showSavedSearch = id?.includes("q=");
    let pageHeaderTitle = showSavedSearch ? "Search Results" : title;
    if (typeof contacts !== "string" && !!contacts) {
      pageHeaderTitle += ` (${contacts?.totalItems.toLocaleString()})`;
    }
    const baseContactCollectionId = getNestedId(contactFilter, "contacts");
    const contactCollectionId = getPaginatedId(baseContactCollectionId);
    return (
      <Wrapper>
        <NewContactFilter
          closeModal={this.hideNewSavedSearchModal}
          contactFilterId={id || ""}
          visible={showNewSavedSearchModal}
        />
        <PageHeader
          className="contacts-page-header"
          searchPlaceholder="Search contacts"
          displaySearch={
            showSavedSearch === false && id?.includes("/search:") === false
          }
          title={pageHeaderTitle}
          toggleSidebar={toggleSidebar}
          toggleSearch={toggleSearch}
          showSearchBox={showSearchBox}
        >
          <SavedSearchButtonContainer>
            {showSavedSearch && (
              <SavedSearchButton
                aria-label="Save Search Button"
                data-testid="save-search-button"
                variant="outlined"
                color="primary"
                onClick={this.showNewSavedSearchModal}
              >
                Save Search
              </SavedSearchButton>
            )}
          </SavedSearchButtonContainer>
        </PageHeader>
        <Box display="flex" flexDirection="column" gap={2}>
          {contactFilter.slug === "recently-deleted" ? (
            <Alert severity="info" sx={{ borderRadius: 0 }}>
              Recently removed contacts will be permanently deleted after 30
              days.
            </Alert>
          ) : null}
        </Box>
        <PageContent>
          <ContactCollection
            contactFilter={contactFilter}
            contactCollectionId={contactCollectionId}
            handleErrorState={this.handleErrorState}
            searchErrorState={searchErrorState}
            {...this.props}
          />
        </PageContent>
      </Wrapper>
    );
  }
}

// TODO: REPLACE WITH useScreenSizes hook
export default withSizes(({ width }) => {
  return {
    showSearchBox: width >= breakpoints.sm,
  };
})(Root);
