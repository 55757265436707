import React, { useState } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Typography,
  Box,
  Tooltip,
  Theme,
} from "@mui/material";
import { ChevronRightFilled, ChevronLeftFilled, InfoOutlineIcon } from "icons";

function CardCarousel({ cards }: { cards: any[] }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      return (prevIndex + 1) % cards.length;
    });
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      return (prevIndex - 1 + cards.length) % cards.length;
    });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="Back">
        <span>
          <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
            <ChevronLeftFilled />
          </IconButton>
        </span>
      </Tooltip>

      <Card key="1" sx={{ margin: "4px" }}>
        <CardContent
          sx={{
            padding: "8px 16px",
            "&:last-child": {
              paddingBottom: "8px",
            },
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              variant="overline"
              color="textSecondary"
              paddingRight="4px"
              paddingTop="3px"
            >
              {cards[currentIndex].title}
            </Typography>
            <Tooltip
              title={
                <Typography variant="caption">
                  {cards[currentIndex].tooltip}
                </Typography>
              }
              slotProps={{
                tooltip: {
                  sx: {
                    maxWidth: "320px",
                  },
                },
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -8],
                      },
                    },
                  ],
                },
              }}
            >
              <span>
                <InfoOutlineIcon
                  sx={(theme: Theme) => {
                    return {
                      color: theme.palette.text.secondary,
                      fontSize: "20px",
                      stroke: theme.palette.text.secondary,
                      strokeWidth: 10,
                    };
                  }}
                />
              </span>
            </Tooltip>
          </Box>
          <Typography
            display="flex"
            justifyContent="center"
            variant="dataMedium"
            align="center"
            color="primary"
          >
            {cards[currentIndex].stat}
          </Typography>
        </CardContent>
      </Card>

      <Tooltip title="Next">
        <span>
          <IconButton
            onClick={handleNext}
            disabled={currentIndex === cards.length - 1}
          >
            <ChevronRightFilled />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
}

export default CardCarousel;
