import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";

import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { isBefore, startOfToday } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { getCutoffError } from "../utils/getCutoffError";
import MessageQuality from "./MessageQuality";
import RecurringCampaignTimeForm from "./RecurringCampaignTimeForm";
import {
  RecurringCampaignValidationSchema,
  SingleCampaignValidationSchema,
} from "formHelpers/validationSchemas";
import AutomaticMessageIndicator from "components/AutomaticMessageIndicator";
import Loader from "components/Loader";
import Portal from "components/Portal";
import MessageInputFormRow from "components/MessageInputFormRow";
import SavedRepliesOption from "components/MessageInputOptions/SavedRepliesOption";

import { useCurrentAccount, useTimeZones } from "hooks";
import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";

function CampaignForm({
  attachments,
  campaignEndDate,
  campaignType,
  currentAccount,
  currentUser,
  dayToSend,
  editCampaign,
  groupName,
  handleSubmit,
  isSignatureActive,
  shortenedLink,
  messageBody,
  runsIndefinitely,
  sendFrequency,
  sendTime,
  setIsSignatureActive,
  setShortenedLink,
  timeZone,
  title,
}) {
  const { campaignMaxMessageLength } = currentAccount.settings;
  const maxCharacterLength =
    campaignMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH;
  const { isCampaignProMember } = useCurrentAccount();

  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const { accountTimeZone } = useTimeZones();

  const recurringCampaign = campaignType === "recurring";

  const campaignValidationSchema = recurringCampaign
    ? RecurringCampaignValidationSchema(maxCharacterLength)
    : SingleCampaignValidationSchema(maxCharacterLength);
  const initialValues = recurringCampaign
    ? {
        campaignEndDate,
        dayToSend,
        messageBody,
        runsIndefinitely,
        sendFrequency,
        sendTime,
        title,
      }
    : { title, messageBody, attachments };
  const root = document.querySelector("#textus-NewCampaign-NextButton");

  const mobileRoot = document.querySelector(
    "#mobile-textus-NewCampaign-NextButton",
  );

  const editRoot = document.querySelector(
    "#edit-textus-NewCampaign-NextButton",
  );

  const characterCountExceeded = (values) => {
    const signature = currentUser?.signature?.content ?? "";
    const messageText = values?.messageBody ?? "";
    const totalLength = isSignatureActive
      ? signature.length + messageText.length
      : messageText.length;
    return totalLength > maxCharacterLength;
  };

  const repeatsUntilPastDate = (scheduledEndDate) => {
    return isBefore(
      scheduledEndDate,
      utcToZonedTime(startOfToday(), accountTimeZone),
    );
  };

  const disableScheduling = (values) => {
    return (
      repeatsUntilPastDate(values.campaignEndDate) &&
      values.runsIndefinitely !== true
    );
  };

  return (
    <Box
      display="flex"
      flex="1 1 auto"
      flexDirection="column"
      minHeight="0"
      px="1.5rem"
      pt={mobileScreen ? "2rem" : 0}
      width="100%"
    >
      <Typography align="center" variant="h4" fontWeight={700} mb="0.5rem">
        Create message
      </Typography>
      <Typography
        align="center"
        component="p"
        color="textSecondary"
        variant="subtitle1"
      >
        {editCampaign
          ? "Edit the title and the message for your campaign."
          : "Please enter an easy to identify title and the message for your campaign."}
      </Typography>
      <Box m="1rem auto" maxWidth="600px" p="0">
        <Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={campaignValidationSchema}
          >
            {({
              errors,
              handleBlur,
              isSubmitting,
              isValid,
              setFieldValue,
              touched,
              values,
            }) => {
              const isNextDisabled =
                !isValid ||
                isSubmitting ||
                characterCountExceeded(values) ||
                disableScheduling(values) ||
                getCutoffError(currentAccount, values.sendTime);

              return (
                <Form id="textus-CampaignForm">
                  <Field name="title" type="text">
                    {({ field }) => {
                      return (
                        <TextField
                          {...field}
                          autoFocus
                          data-testid="textus-Campaigns-title"
                          error={touched.title && !!errors.title}
                          fullWidth
                          helperText={touched.title && errors.title}
                          inputProps={{ "data-lpignore": true }}
                          label="Title"
                          id="textus-Campaigns-title"
                          placeholder="Best Campaign Ever"
                          type="text"
                          variant="outlined"
                          style={{
                            marginBottom:
                              touched.title && errors.title ? "0" : "1.4rem",
                          }}
                        />
                      );
                    }}
                  </Field>
                  <MessageInputFormRow
                    attachmentField={values?.attachments}
                    attachmentFieldName="attachments"
                    currentAccount={currentAccount}
                    data-testid="campaign-message-body"
                    errors={errors}
                    fieldName="messageBody"
                    isCampaignProMember={isCampaignProMember}
                    isDisabled={false}
                    isSignatureActive={isSignatureActive}
                    label="Message"
                    shortenedLink={shortenedLink}
                    maxChars={maxCharacterLength}
                    SavedRepliesOption={SavedRepliesOption}
                    setFieldValue={setFieldValue}
                    setShortenedLink={setShortenedLink}
                    signature={currentUser.signature}
                    templates={values?.messageTemplates}
                    toggleSignature={() => {
                      return setIsSignatureActive(!isSignatureActive);
                    }}
                    touched={touched}
                    values={values}
                    withSignature
                  />
                  <AutomaticMessageIndicator />
                  <MessageQuality message={values.messageBody} />
                  {recurringCampaign && (
                    <RecurringCampaignTimeForm
                      currentAccount={currentAccount}
                      errors={errors}
                      groupName={groupName}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      timeZone={timeZone}
                      touched={touched}
                      values={values}
                    />
                  )}
                  {root && (
                    <Portal root={root}>
                      <Button
                        aria-label="Next Button"
                        color="primary"
                        data-testid="campaign-title-and-message-confirmed"
                        disabled={isNextDisabled}
                        form="textus-CampaignForm"
                        type="submit"
                        variant="contained"
                      >
                        <Loader isLoading={isSubmitting}>Next</Loader>
                      </Button>
                    </Portal>
                  )}
                  {mobileRoot && (
                    <Portal root={mobileRoot}>
                      <Button
                        aria-label="Next Button"
                        data-testid="campaign-title-and-message-confirmed"
                        color="primary"
                        disabled={isNextDisabled}
                        form="textus-CampaignForm"
                        type="submit"
                        variant="text"
                        size="small"
                      >
                        <Loader isLoading={isSubmitting}>Next</Loader>
                      </Button>
                    </Portal>
                  )}
                  {editRoot && (
                    <Portal root={editRoot}>
                      <Button
                        aria-label="Next Button"
                        color="primary"
                        data-testid="campaign-title-and-message-confirmed"
                        disabled={
                          !isValid ||
                          isSubmitting ||
                          characterCountExceeded(values)
                        }
                        form="textus-CampaignForm"
                        type="submit"
                        variant="text"
                        size="small"
                      >
                        <Loader isLoading={isSubmitting}>Next</Loader>
                      </Button>
                    </Portal>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

CampaignForm.propTypes = {
  attachments: PropTypes.array,
  campaignEndDate: PropTypes.string,
  campaignType: PropTypes.string,
  currentAccount: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  editCampaign: PropTypes.bool,
  dayToSend: PropTypes.string,
  groupName: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  shortenedLink: PropTypes.object,
  isSignatureActive: PropTypes.bool.isRequired,
  messageBody: PropTypes.string,
  nextButton: PropTypes.any,
  runsIndefinitely: PropTypes.bool,
  sendFrequency: PropTypes.string,
  sendTime: PropTypes.string,
  setIsSignatureActive: PropTypes.func.isRequired,
  setShortenedLink: PropTypes.func,
  timeZone: PropTypes.string,
  title: PropTypes.string,
  mobileView: PropTypes.bool,
};

export default CampaignForm;
