import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { Box, Typography, useMediaQuery, Theme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useKeyword } from "../hooks/useKeyword";
import { createKeyword, updateKeyword } from "../api";
import ConfirmCancelModal from "./ConfirmCancelModal";
import KeywordFormDirectoryTree from "./KeywordFormDirectoryTree";
import { DialogFooter } from "components/DialogFooter";
import PageHeader from "components/Page/PageHeader";
import { KeywordFormProps } from "features/Keywords/types";
import { fetchSequences } from "features/Sequences/api";
import { getKeywordValidationSchema } from "formHelpers/validationSchemas";

export default function KeywordForm({
  keywordId,
  currentAccount,
  toggleSidebar,
}: KeywordFormProps) {
  const [sequences, setSequences] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [maxCharacterCount, setMaxCharacterCount] = useState(0);
  const { load, keyword, state, error } = useKeyword();

  useEffect(() => {
    const { conversationMaxMessageLength } = currentAccount.settings;

    setMaxCharacterCount(
      conversationMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH,
    );
  }, [currentAccount.settings]);

  const isMobile = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const getInitialValues = () => {
    return {
      id: keyword?.id || "",
      keyword: keyword?.keyword || "",
      responseBody: keyword?.responseBody || "",
      attachments: keyword?.attachments || [],
      enrollInSequence: !!keyword?.sequences?.length,
      sequenceId: keyword?.sequences?.[0]?.id || "",
      keywordAnswers: (keyword?.subKeywords || []).map((keywordAnswer) => {
        return {
          id: keywordAnswer.id,
          keyword: keywordAnswer.keyword,
          responseBody: keywordAnswer.responseBody,
          enrollInSequence: !!keywordAnswer.sequences.length,
          sequenceId: keywordAnswer.sequences?.[0]?.id,
          attachments: keywordAnswer.attachments || [],
        };
      }),
    };
  };

  const initialValues = getInitialValues();

  const fetchSequenceList = async () => {
    const response = await fetchSequences(currentAccount, {
      activeOnly: true,
    });

    const body = await response.json();

    setSequences(body);
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    void fetchSequenceList();
  }, []);

  useEffect(() => {
    if (!keywordId) {
      return;
    }

    load(keywordId);
  }, []);

  useEffect(() => {
    if (error || state === "error") {
      enqueueSnackbar(
        "There was a problem fetching the keyword. Please try again.",
        { variant: "error" },
      );
    }
  }, []);

  const toggleCancelModal = () => {
    setShowCancelModal(!showCancelModal);
  };

  const history = useHistory();

  const handleValidationErrors = (errorResponse, values, formik) => {
    const messageFieldError = !values.keywordAnswers.every((keywordAnswer) =>
      values.responseBody.includes(keywordAnswer.keyword),
    );

    const keywordFieldsError = errorResponse.messages.some((errorMessage) => {
      const regex = /Keyword .+ already in use/;
      return regex.test(errorMessage);
    });

    const responseBodyError = {
      responseBody: messageFieldError
        ? "Missing keyword answer(s) in the message"
        : null,
    };

    const keywordError = {
      keyword: keywordFieldsError ? "Keyword already in use" : null,
    };
    const validationErrors = {
      validationError: {
        ...responseBodyError,
        ...keywordError,
      },
    };
    formik.setSubmitting(false);
    formik.setErrors(validationErrors);
  };

  const onSubmit = async (values, formik) => {
    formik.setStatus({ errors: {} });
    const body = {
      ...values,
      childKeywordsAttributes: values.keywordAnswers,
    };
    delete body.keywordAnswers;
    try {
      let respKeywordId;
      let response;
      response = keywordId
        ? await updateKeyword(currentAccount, keywordId, body)
        : await createKeyword(currentAccount, body);
      const respBody = await response.json();
      respKeywordId = respBody.id;

      if (respBody.error) {
        handleValidationErrors(respBody, values, formik);
      } else {
        history.push(
          `/${currentAccount.slug}/automation/keywords/${respKeywordId}`,
        );
      }
    } catch (error_) {
      console.log("Error submitting keyword", error_);
      const validationErrors = {
        name: "Something went wrong!",
      };
      formik.setSubmitting(false);
      enqueueSnackbar(validationErrors.name, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <PageHeader
        title={keywordId ? "Edit keyword" : "Create keyword"}
        toggleSidebar={toggleSidebar}
      />
      <ConfirmCancelModal
        pageTitle={keywordId ? "edit" : "create"}
        open={showCancelModal}
        onClose={toggleCancelModal}
      />
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={getKeywordValidationSchema(maxCharacterCount)}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid }) => {
            return (
              <Form
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    padding: isMobile ? "32px 16px" : "40px 96px 0px 96px",
                    height: "fit-content",
                    maxHeight: "calc(100vh - 150px)",
                    overflow: "scroll",
                    "&::-webkit-scrollbar": { display: "none" },
                  }}
                >
                  <Typography
                    component="h3"
                    variant="h3"
                    marginBottom={isMobile ? "19px" : "40px"}
                  >
                    {keywordId ? "Edit automation" : "New automation"}
                  </Typography>

                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <KeywordFormDirectoryTree
                      currentAccount={currentAccount}
                      sequences={sequences}
                      isMobile={isMobile}
                      savedKeyword={keyword}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "fill-available",
                    position: "fixed",
                    bottom: "0",
                    backgroundColor: (theme) => {
                      return theme.palette.background.paper;
                    },
                  }}
                >
                  <DialogFooter
                    onCancel={toggleCancelModal}
                    confirmText={keywordId ? "Save" : "Create"}
                    isConfirmDisabled={isSubmitting || !isValid}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
}
