/* global pendo */
import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import whatInput from "what-input";

import { lightTheme, darkTheme } from "@tesseract/theme";
import TypingIndicators from "../TypingIndicators";
import Badges from "../Badges";
import { initializeTrackers } from "./utils/initializeTrackers";
import { actionGenerators, saga, reducer, selectors } from "./state";
import {
  selectAppColors,
  selectCurrentAccount,
  selectCurrentUser,
  selectOidc,
} from "features/EntryPoint/containers/App/selectors";
import {
  selectors as animationSelectors,
  actionGenerators as animationActionGenerators,
} from "features/EntryPoint/containers/Animation/state";

import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import LoginFailedPage from "components/LoginFailedPage";
import NotFoundPage from "features/NotFoundPage";
import PageLoader from "components/PageLoader";

import {
  updateAppColors,
  updateUserCanHover,
} from "features/EntryPoint/containers/App/actions";
import { setCurrentAccount } from "features/MainNavigation/state";
import PusherComponent from "features/PusherComponent";

class Bootstrap extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    appColors: PropTypes.object.isRequired,
    bootstrapContainer: PropTypes.object.isRequired,
    currentAccount: PropTypes.object,
    currentAccountSiteNavigation: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
    updateAppColors: PropTypes.func.isRequired,
    updateUserCanHover: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      fetched: false,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown, { capture: true });

    if (this.shouldFetch(this.props)) this.fetchBootstrap(this.props);

    const onFirstHover = () => {
      const userCanHover = whatInput.ask() !== "touch";
      this.props.updateUserCanHover(userCanHover);
      window.removeEventListener("mouseover", onFirstHover, false);
    };

    window.addEventListener("mouseover", onFirstHover, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.shouldFetch(nextProps)) this.fetchBootstrap(nextProps);
  }

  componentDidUpdate(prevProps) {
    const accountChanged =
      this.props.currentAccount.id !== prevProps.currentAccount.id;
    const membershipsUpdated =
      get(prevProps, ["currentUser", "memberships", "members"], []).length !==
      get(this.props, ["currentUser", "memberships", "members"], []).length;
    const accountSettingsLoaded =
      Boolean(get(this.props, ["currentAccount", "settings"])) &&
      !get(prevProps, ["currentAccount", "settings"]);

    if (accountChanged || membershipsUpdated || accountSettingsLoaded) {
      this.setColorTheme();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 85 && (event.metaKey || event.ctrlKey)) {
      const newAppColors =
        this.props.appColors.type === "dark" ? this.getLightTheme() : darkTheme;
      this.props.updateAppColors(newAppColors);
    }
  };

  getLightTheme = () => {
    const { currentAccount } = this.props;
    if (
      [
        "primaryColor",
        "secondaryColor",
        "tertiaryColor",
        "outboundMessageColor",
      ].every((themeSetting) => {
        return Boolean(
          get(currentAccount, ["settings", themeSetting, "value"]),
        );
      })
    ) {
      const {
        settings: {
          primaryColor: { value: primaryColor },
          secondaryColor: { value: secondaryColor },
          tertiaryColor: { value: tertiaryColor },
          outboundMessageColor: { value: outboundMessageColor },
        },
      } = currentAccount;
      return {
        primaryColor,
        secondaryColor,
        tertiaryColor,
        outboundMessageColor,
        type: "light",
      };
    }
    return lightTheme;
  };

  setColorTheme = () => {
    const { appColors } = this.props;
    if (get(appColors, ["type"]) === "dark") return;
    this.props.updateAppColors(this.getLightTheme());
  };

  fetchBootstrap(props) {
    const { bootstrapId, fetchBootstrapRequest } = props;

    fetchBootstrapRequest(bootstrapId);
    this.setState({ fetched: true });
  }

  shouldFetch(nextProps) {
    return (
      (!isEmpty(this.props.currentAccountSiteNavigation) &&
        isEmpty(nextProps.currentAccountSiteNavigation)) ||
      (!this.state.fetched && !nextProps.oidc.isLoadingUser)
    );
  }

  render() {
    const {
      bootstrapContainer: {
        denormalizedRecord,
        substate: { isFetching, errorFetching },
      },
      currentAccount,
      currentUser,
    } = this.props;

    if (
      isFetching ||
      get(errorFetching, ["response", "title"], undefined) === "Unauthorized"
    ) {
      return <PageLoader logoWidth={70} inverse />;
    }
    if (
      get(errorFetching, ["response", "title"]) === "Forbidden" &&
      get(errorFetching, ["response", "url"], "").includes("/boot")
    )
      return <LoginFailedPage />;
    if (errorFetching) {
      return <NotFoundPage />;
    }
    const { badges } = denormalizedRecord;

    initializeTrackers(currentUser, currentAccount);

    return (
      <>
        <PusherComponent />
        <TypingIndicators />
        <Badges badgeCollectionId={badges} />
        {this.props.children(this.props)}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    appColors: selectAppColors(state, props),
    bootstrapContainer: selectors.selectBootstrap(state, props),
    currentAccount: selectCurrentAccount(state, props),
    currentAccountSiteNavigation: selectors.selectCurrentAccountSiteNavigation(
      state,
      props,
    ),
    currentUser: selectCurrentUser(state, props),
    oidc: selectOidc(state, props),
    isHeaderTransitioning: animationSelectors.selectIsHeaderTransitioning(
      state,
      props,
    ),
  };
};

const withConnect = connect(mapStateToProps, {
  ...actionGenerators,
  updateAppColors,
  updateUserCanHover,
  setCurrentAccount,
  setIsHeaderTransitioning: animationActionGenerators.setIsHeaderTransitioning,
});

const withReducer = injectReducer({
  key: "bootstrapContainer",
  reducer,
});
const withSaga = injectSaga({ key: "bootstrapContainer", saga });

export default compose(withReducer, withSaga, withConnect)(Bootstrap);
