import { textUsApiV3, textUsApiV4 } from "services";

function fetchContactFilterCollection(contactFilterCollectionId: string) {
  return textUsApiV3.get(contactFilterCollectionId);
}

function fetchContactFilterCollectionV4(contactFilterCollectionId: string) {
  return textUsApiV4.get(contactFilterCollectionId);
}

export { fetchContactFilterCollection, fetchContactFilterCollectionV4 };
