import { PropTypes } from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AccountSetting from "./AccountSetting";
import { ConversationExportValidationSchema } from "formHelpers/validationSchemas";

function ConversationExport(props) {
  const getDescription = () => {
    return (
      <p>
        Enabling this setting will allow admins on this account and any child
        account to export any conversation to CSV format.
      </p>
    );
  };

  const getRender = (label) => {
    return () => {
      return function ({ field, form }) {
        return (
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                onChange={(event) => {
                  const { checked } = event.target;
                  form.setFieldValue(field.name, checked);
                }}
                color="secondary"
              />
            }
            label={label}
          />
        );
      };
    };
  };

  return (
    <AccountSetting
      {...props}
      description={getDescription()}
      settings={[
        {
          default: false,
          name: "conversationExportsEnabled",
          render: getRender("Enable conversation exports"),
        },
      ]}
      title="Conversation export"
      validationSchema={ConversationExportValidationSchema}
    />
  );
}

ConversationExport.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

export default ConversationExport;
