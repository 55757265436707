/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import union from "lodash/union";
import queryString from "query-string";
import { Contact } from "@tesseract/core";
import { useDispatch } from "react-redux";

import { Fab, useMediaQuery } from "@mui/material";

import ContactBatchActions from "./ContactBatchActions";
import { contactBatchDeleteRequest } from "./api/contactBatchDeleteRequest";
import { contactBatchPutRequest } from "./api/contactBatchPutRequest";
import { ContactCollectionDataGrid } from "./ContactCollectionDataGrid";
import { useContactCollection } from "features/Contacts/hooks/useContactCollection";
import { actionGenerators } from "features/Compose/containers/ComposeRoot/state";
import { useCurrentAccount, useCurrentUser } from "hooks";
import BasicButton from "components/BasicButton";
import BlankState from "components/BlankState";
import breakpoints from "utils/styles/breakpoints";
import ErrorComponent from "components/ErrorComponent";
import NoContactsFoundSvg from "utils/images/no-contacts-found.svg";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";
import {
  subscribeToContactCreated,
  subscribeToContactUpdated,
} from "features/Contact/events";
import { AddIcon } from "icons";

export const ContactButton = styled(BasicButton)`
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${breakpoints.medium - 1}px) {
    &:hover {
      text-decoration: none;
    }
  }
`;

export const MissingName = styled.span`
  color: ${(props) => {
    return props.theme.colors.text.disabled;
  }};

  &:hover {
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }
`;

export interface RootProps {
  contactCollectionId: string;
  contactFilter: Record<string, any>;
  history: Record<string, any>;
  location: Record<string, any>;
  searchErrorState?: boolean;
  setContactModal: (param: { active: true; form: true }) => void;
}

function Root({
  contactCollectionId,
  contactFilter,
  history,
  location,
  searchErrorState,
  setContactModal,
  ...props
}: RootProps) {
  const [allSelected, setAllSelected] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  const { contactCollection, sendFetchRequest, isLoading } =
    useContactCollection({
      contactCollectionId,
    });
  const currentAccount = useCurrentAccount();
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const prevContactCollection = useRef(contactCollection);
  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const load = useCallback(() => {
    void sendFetchRequest();
  }, [contactCollectionId]);

  /**
   * Subscribe to contact updated events
   *
   * Reload the contact collection when a contact is updated
   */
  useEffect(() => {
    return subscribeToContactUpdated(load);
  }, [load]);

  /**
   * Subscribe to contact created events
   *
   * Reload the contact collection when a contact is created
   */
  useEffect(() => {
    return subscribeToContactCreated(load);
  }, [load]);

  /**
   * Listen to search query changes
   */
  useEffect(() => {
    const { selected = false } = queryString.parse(location?.search);
    setAllSelected(!!selected);
  }, [location?.search]);

  useEffect(() => {
    if (allSelected && prevContactCollection?.id !== contactCollection?.id) {
      const visibleIds = get(contactCollection, ["members"], []).map(
        (recipient: { id: string }) => {
          return recipient.id;
        },
      );

      const updatedRecords = [
        ...new Set([...selectedRecords, ...(contactCollection?.members || [])]),
      ];

      setSelectedRecords(updatedRecords);
      setSelectedContacts(union(selectedContacts, visibleIds));
    }
  }, [allSelected, contactCollection]);

  const updateSearch = ({
    search,
    type,
    value,
  }: {
    search: string;
    type: string;
    value: string;
    // the scope is fine in here.
    // eslint-disable-next-line unicorn/consistent-function-scoping
  }) => {
    const parsedSearch = queryString.parse(search);
    const decodedQ = (parsedSearch.q as string) || "";

    // new contact search does not decode tag:'...' or business:'...'
    if (currentAccount.featureFlags.newContactSearch) {
      return `q=${fixedEncodeURIComponent(value)}`;
    }

    const addMatch = value.includes(" ")
      ? `${type}:"${value}"`
      : `${type}:${value}`;
    if (!decodedQ) {
      return `q=${fixedEncodeURIComponent(addMatch)}`;
    }
    return decodedQ.includes(addMatch)
      ? `q=${fixedEncodeURIComponent(decodedQ)}`
      : `q=${fixedEncodeURIComponent(`${decodedQ} ${addMatch}`)}`;
  };

  const handleClick = ({
    type = "tags",
    value,
  }: {
    type: string;
    value: string;
  }) => {
    return () => {
      const { search } = location;
      const nextSearch = `?${updateSearch({ search, type, value })}`;
      history.push({
        pathname: `/${currentAccount.slug}/contacts/search`,
        search: nextSearch,
      });
    };
  };

  const clearBatchActionState = () => {
    setAllSelected(false);
    setSelectedContacts([]);
    setSelectedRecords([]);
  };

  const handleSetCompose = () => {
    const recipientIds = selectedRecords
      .map((contact: Contact.Raw) => {
        return get(contact, ["phones", "members", 0, "id"]);
      })
      .filter(Boolean);
    dispatch(actionGenerators.setCompose({ active: true, recipientIds }));
  };

  const handleSetSelected = (selected: string[]) => {
    setAllSelected(false);
    setSelectedContacts(selected);
  };

  const showContactBatchActions =
    !isEmpty(contactCollection) && selectedContacts.length > 0;

  if (searchErrorState) {
    return (
      <ErrorComponent
        title="Sorry! Search is unavailable."
        subtitle="Try again later."
      />
    );
  }

  if (
    !isLoading &&
    (isEmpty(contactCollection) || contactCollection.totalItems! === 0)
  ) {
    return (
      <BlankState
        dataTestId="contacts-collection-no-contacts"
        image={<img src={NoContactsFoundSvg} alt="No contacts found" />}
        subTitle="No contacts found."
      />
    );
  }

  return (
    <>
      {showContactBatchActions && (
        <ContactBatchActions
          allSelected={allSelected}
          clearBatchActionState={clearBatchActionState}
          contactCollection={contactCollection}
          contactFilter={contactFilter}
          currentAccount={currentAccount}
          currentUser={currentUser}
          handleSetCompose={handleSetCompose}
          history={history}
          isMobile={mobileScreen}
          location={location}
          selectedContacts={selectedContacts}
          selectedRecords={selectedRecords}
          setAllSelected={setAllSelected}
          setSelectedContacts={handleSetSelected}
          setSelectedRecords={setSelectedRecords}
          contactBatchPutRequest={contactBatchPutRequest}
          contactBatchDeleteRequest={contactBatchDeleteRequest}
          {...props}
        />
      )}
      <>
        <ContactCollectionDataGrid
          contactCollection={contactCollection}
          handleClick={handleClick}
          isLoading={isLoading}
          selectedContacts={selectedContacts}
          selectedRecords={selectedRecords}
          setContactModal={setContactModal}
          setSelectedContacts={handleSetSelected}
          setSelectedRecords={setSelectedRecords}
        />

        {mobileScreen && (
          <Fab
            aria-label="Add Contact"
            color="primary"
            onClick={() => {
              return setContactModal({ active: true, form: true });
            }}
            sx={{
              position: "absolute",
              bottom: "4rem",
              right: "2rem",
            }}
          >
            <AddIcon />
          </Fab>
        )}
      </>
    </>
  );
}

export default Root;
