import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  styled,
} from "@mui/material";
import { AddIcon, EditIcon, ArrowBackIcon } from "icons";

import ModalHeader from "components/ModalHeader";
import SavedReplyList from "features/SavedReplies/components/SavedReplyList";
import SavedReply from "features/SavedReplies/containers/SavedReply";
import PageHeader from "components/Page/PageHeader";
import { useCurrentAccount } from "hooks";

const ManagerWrapper = styled("div")(({ fullScreen }) => {
  return {
    display: "flex",
    flexFlow: "column nowrap",
    height: fullScreen ? "100%" : "500px",
    width: fullScreen ? "100%" : "750px",
    position: "relative",
  };
});

const ManagerMain = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "row",
  flexFlow: "nowrap",
  height: "100%",
  minHeight: 0,
});

const ManagerSidebar = styled("div")(({ theme }) => {
  return {
    borderRight: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flex: "1 0 350px",
    flexDirection: "column",
    flexWrap: "nowrap",
    maxWidth: "350px",
    position: "relative",
    paddingBottom: "1.5rem",
  };
});

export default function Root({
  activeSavedReply,
  closeHandler,
  currentUser,
  handleCloseModal,
  handleEditButtonClick,
  handleSavedReplySelected = () => {},
  handleSelect,
  hasManageOptions,
  setActiveSavedReply,
  settings = false,
  settingsAccount,
  settingsCreateMode,
  showManager = false,
  toggleSidebar,
}) {
  const mobile = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const initialSavedReplyCollectionId = `${currentUser.savedReplies}?filter=mine`;

  const [savedReplyCollectionId, setSavedReplyCollectionId] = useState(
    initialSavedReplyCollectionId,
  );
  const [savedReplies, setSavedReplies] = useState();
  const [hideList, setHideList] = useState(false);
  const [showMobileManager, setShowMobileManager] = useState(false);
  const [showMobileCreator, setShowMobileCreator] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [showMobileSettings, setShowMobileSettings] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  const currentAccount = useCurrentAccount();
  const isCampaigns = currentUrl.includes("campaigns");
  const { featureFlags } = currentAccount;
  const templatesMaintenance = featureFlags?.templatesMaintenance;

  const disableTemplateAttachments =
    !currentAccount?.isCampaignProMember && isCampaigns;

  const noTemplatesWithoutAttachments =
    savedReplies?.members?.find((member) => {
      return member?.attachments?.members?.length === 0;
    }) === undefined;

  useEffect(() => {
    if (!mobile) {
      setShowMobileManager(false);
    }
  }, [mobile]);

  useEffect(() => {
    if (settings && mobile && templatesMaintenance) {
      setShowMobileManager(true);
      setShowMobileSettings(true);
    }
  }, [mobile, settings, templatesMaintenance]);

  const handleMobileEditSelection = (activeSavedReplyParam) => {
    setActiveSavedReply(activeSavedReplyParam);
    setEditMode(true);
  };

  const renderList = ({ asSidebar = false } = {}) => {
    const savedReplySelectionHandler = asSidebar
      ? setActiveSavedReply
      : handleSavedReplySelected;
    return (
      <SavedReplyList
        activeSavedReply={activeSavedReply}
        closeHandler={closeHandler}
        currentAccount={currentAccount}
        currentUser={currentUser}
        disableTemplateAttachments={disableTemplateAttachments}
        hasManageOptions={hasManageOptions}
        isSearching={isSearching}
        savedReplies={savedReplies}
        savedReplySelectionHandler={savedReplySelectionHandler}
        savedReplyCollectionId={savedReplyCollectionId}
        setActiveSavedReply={setActiveSavedReply}
        setIsSearching={setIsSearching}
        setSavedReplyCollectionId={setSavedReplyCollectionId}
        setSavedReplies={setSavedReplies}
        settings={settings}
        templatesMaintenance={templatesMaintenance}
      />
    );
  };

  const renderManager = () => {
    return (
      <ManagerWrapper fullScreen={settings}>
        {!settings && (
          <ModalHeader closeModal={handleCloseModal}>
            <Box
              height="auto"
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent={
                hideList && templatesMaintenance
                  ? "flex-start"
                  : "space-between"
              }
              alignItems="center"
            >
              {hideList && templatesMaintenance ? (
                <IconButton
                  sx={{ paddingLeft: 0 }}
                  onClick={() => {
                    setCreateMode(false);
                    setHideList(false);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              ) : null}
              <Typography
                variant="h5"
                display="flex"
                sx={{
                  color: (theme) => {
                    return theme.palette.text.primary;
                  },
                }}
              >
                {hideList && templatesMaintenance
                  ? "Create template"
                  : "Templates"}
              </Typography>
              {!createMode && (
                <Tooltip title="Create Template">
                  <IconButton
                    onClick={() => {
                      setHideList(true);
                      setCreateMode(Boolean(templatesMaintenance));
                      return setActiveSavedReply(undefined);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </ModalHeader>
        )}
        <ManagerMain>
          {hideList && templatesMaintenance ? null : (
            <ManagerSidebar>{renderList({ asSidebar: true })}</ManagerSidebar>
          )}
          {(savedReplies?.members?.length === 0 ||
            (noTemplatesWithoutAttachments && disableTemplateAttachments)) &&
          !createMode &&
          !settingsCreateMode &&
          templatesMaintenance ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="auto"
              width="100%"
            >
              <Typography
                variant="body2"
                display="flex"
                alignItmes="center"
                sx={{
                  color: (theme) => {
                    return theme.palette.text.secondary;
                  },
                }}
              >
                {" "}
                Select a template to preview it here.{" "}
              </Typography>
            </Box>
          ) : (
            <SavedReply
              disableTemplateAttachments={disableTemplateAttachments}
              handleCloseModal={handleCloseModal}
              handleSavedReplySelected={handleSavedReplySelected}
              handleSelect={handleSelect}
              hideList={hideList}
              savedReply={activeSavedReply}
              savedReplies={savedReplies}
              savedReplyCollectionId={savedReplyCollectionId}
              setActiveSavedReply={setActiveSavedReply}
              settings={settings}
              settingsAccount={settingsAccount}
              setSavedReplies={setSavedReplies}
              templatesMaintenance={templatesMaintenance}
            />
          )}
        </ManagerMain>
      </ManagerWrapper>
    );
  };

  const renderMobileManager = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        height="100%"
        paddingBottom="3rem"
      >
        <Box
          alignItems="center"
          component="header"
          display="flex"
          height="55px"
          width="100%"
          justifyContent="space-between"
          padding="0.5rem"
          sx={{
            borderBottom: (theme) => {
              return `1px solid ${theme.palette.divider}`;
            },
            boxShadow: (theme) => {
              return theme.shadows[4];
            },
          }}
        >
          <Box display="flex" flex="0 0 auto" marginRight="0.5rem">
            <Tooltip title="Close templates">
              <IconButton
                onClick={() => {
                  return editMode
                    ? setEditMode(false)
                    : setShowMobileManager(false);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box width="100%">
            <Typography
              variant="h6"
              sx={{
                color: (theme) => {
                  return theme.palette.text.primary;
                },
              }}
            >
              {editMode ? "Edit template" : "Templates"}
            </Typography>
          </Box>
          {templatesMaintenance && !editMode && (
            <Box display="flex" flex="0 0 auto" justifyContent="flex-end">
              <Tooltip title="Create template">
                <IconButton
                  onClick={() => {
                    setActiveSavedReply(undefined);
                    return setShowMobileCreator(true);
                  }}
                  size="large"
                >
                  <AddIcon data-testid="add" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        {editMode ? (
          <SavedReply
            disableTemplateAttachments={disableTemplateAttachments}
            handleCloseModal={handleCloseModal}
            handleSavedReplySelected={handleSavedReplySelected}
            handleSelect={handleSelect}
            hideList={hideList}
            mobileManager
            savedReply={activeSavedReply}
            savedReplies={savedReplies}
            savedReplyCollectionId={savedReplyCollectionId}
            setActiveSavedReply={setActiveSavedReply}
            settings={settings}
            settingsAccount={settingsAccount}
            setSavedReplies={setSavedReplies}
            templatesMaintenance={templatesMaintenance}
          />
        ) : (
          <SavedReplyList
            activeSavedReply={activeSavedReply}
            closeHandler={closeHandler}
            currentAccount={currentAccount}
            disableTemplateAttachments={disableTemplateAttachments}
            hasManageOptions={hasManageOptions}
            mobileManager
            isSearching={isSearching}
            savedReplies={savedReplies}
            savedReplySelectionHandler={handleMobileEditSelection}
            savedReplyCollectionId={savedReplyCollectionId}
            setActiveSavedReply={setActiveSavedReply}
            setIsSearching={setIsSearching}
            setSavedReplyCollectionId={setSavedReplyCollectionId}
            setSavedReplies={setSavedReplies}
            settings={settings}
            templatesMaintenance={templatesMaintenance}
          />
        )}
      </Box>
    );
  };

  const renderMobileSettingsManager = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        height="100%"
      >
        {mobile && !editMode && !showMobileCreator ? (
          <PageHeader title="Templates" toggleSidebar={toggleSidebar}>
            {" "}
            {templatesMaintenance && !editMode && (
              <Box display="flex" flex="0 0 auto" justifyContent="flex-end">
                <Tooltip title="Create template">
                  <IconButton
                    onClick={() => {
                      setActiveSavedReply(undefined);
                      return setShowMobileCreator(true);
                    }}
                    size="large"
                  >
                    <AddIcon data-testid="add" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}{" "}
          </PageHeader>
        ) : (
          <Box
            alignItems="center"
            component="header"
            display="flex"
            flex="0 0 auto"
            height="55px"
            width="100%"
            justifyContent="flex-start"
            padding="0.5rem"
            sx={{
              borderBottom: (theme) => {
                return `1px solid ${theme.palette.divider}`;
              },
              boxShadow: (theme) => {
                return theme.shadows[4];
              },
            }}
          >
            <Box display="flex" flex="0 0 auto" marginRight="0.5rem">
              <Tooltip title="Close templates">
                <IconButton
                  onClick={() => {
                    return editMode && setEditMode(false);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => {
                  return theme.palette.text.primary;
                },
              }}
            >
              {editMode ? "Edit template" : "Templates"}
            </Typography>
          </Box>
        )}
        {editMode ? (
          <SavedReply
            disableTemplateAttachments={disableTemplateAttachments}
            handleCloseModal={handleCloseModal}
            handleSavedReplySelected={handleSavedReplySelected}
            handleSelect={handleSelect}
            hideList={hideList}
            mobileManager
            savedReply={activeSavedReply}
            savedReplies={savedReplies}
            savedReplyCollectionId={savedReplyCollectionId}
            setActiveSavedReply={setActiveSavedReply}
            settings={settings}
            settingsAccount={settingsAccount}
            setSavedReplies={setSavedReplies}
            templatesMaintenance={templatesMaintenance}
            setEditMode={setEditMode}
          />
        ) : (
          <SavedReplyList
            activeSavedReply={activeSavedReply}
            closeHandler={closeHandler}
            currentAccount={currentAccount}
            disableTemplateAttachments={disableTemplateAttachments}
            hasManageOptions={hasManageOptions}
            mobileSettingsManager
            savedReplies={savedReplies}
            savedReplySelectionHandler={handleMobileEditSelection}
            savedReplyCollectionId={savedReplyCollectionId}
            setActiveSavedReply={setActiveSavedReply}
            setSavedReplyCollectionId={setSavedReplyCollectionId}
            setSavedReplies={setSavedReplies}
            settings={settings}
            templatesMaintenance={templatesMaintenance}
          />
        )}
      </Box>
    );
  };

  const renderMobileCreator = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        height="100%"
      >
        <Box
          alignItems="center"
          component="header"
          display="flex"
          flex="0 0 auto"
          height="55px"
          width="100%"
          justifyContent="flex-start"
          padding="0.5rem"
          sx={{
            borderBottom: (theme) => {
              return `1px solid ${theme.palette.divider}`;
            },
            boxShadow: (theme) => {
              return theme.shadows[4];
            },
          }}
        >
          <Box display="flex" flex="0 0 auto" marginRight="0.5rem">
            <Tooltip title="Close templates">
              <IconButton
                onClick={() => {
                  return setShowMobileCreator(false);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography
            variant="h6"
            sx={{
              color: (theme) => {
                return theme.palette.text.primary;
              },
            }}
          >
            Create template
          </Typography>
        </Box>
        <SavedReply
          disableTemplateAttachments={disableTemplateAttachments}
          handleCloseModal={handleCloseModal}
          handleSavedReplySelected={handleSavedReplySelected}
          hideList={hideList}
          savedReply={activeSavedReply}
          savedReplies={savedReplies}
          savedReplyCollectionId={savedReplyCollectionId}
          setActiveSavedReply={setActiveSavedReply}
          settings={settings}
          settingsAccount={settingsAccount}
          setSavedReplies={setSavedReplies}
          templatesMaintenance={templatesMaintenance}
          mobileManager
          setShowMobileManager={setShowMobileManager}
          closeHandler={closeHandler}
        />
      </Box>
    );
  };

  const renderPopover = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        height="100%"
        paddingBottom={mobile ? "3rem" : "0rem"}
      >
        <Box
          alignItems="center"
          component="header"
          display="flex"
          flex="0 0 auto"
          height="55px"
          width="100%"
          justifyContent="space-between"
          padding={mobile ? "0.5rem" : "1rem"}
          sx={{
            borderBottom: (theme) => {
              return `1px solid ${theme.palette.divider}`;
            },
            boxShadow: (theme) => {
              return mobile ? theme.shadows[4] : "none";
            },
          }}
        >
          {mobile ? (
            <Box display="flex" flex="0 0 auto" marginRight="0.5rem">
              <Tooltip title="Close templates">
                <IconButton onClick={closeHandler}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}
          <Typography
            variant="h6"
            flex="1 1 auto"
            sx={{
              color: (theme) => {
                return theme.palette.text.primary;
              },
            }}
          >
            Templates
          </Typography>
          {hasManageOptions && (
            <Box display="flex" flex="0 0 auto" justifyContent="flex-end">
              <Tooltip title="Create/edit templates">
                <IconButton onClick={handleEditButtonClick} size="large">
                  <EditIcon data-testid="edit" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          {mobile && templatesMaintenance ? (
            <Box display="flex" flex="0 0 auto" justifyContent="flex-end">
              <Tooltip title="Create/edit templates">
                <IconButton
                  onClick={() => {
                    return setShowMobileManager(true);
                  }}
                  size="large"
                >
                  <EditIcon data-testid="edit" />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}
        </Box>
        {renderList()}
      </Box>
    );
  };

  const mobileManager = showMobileSettings
    ? renderMobileSettingsManager()
    : renderMobileManager();

  const mobileTemplateView = showMobileCreator
    ? renderMobileCreator()
    : mobileManager;

  const templateView = showManager ? renderManager() : renderPopover();

  return showMobileManager ? mobileTemplateView : templateView;
}

PropTypes.Root = {
  activeSavedReply: PropTypes.object, // passed
  currentAccount: PropTypes.object.isRequired, // withConnect
  currentUser: PropTypes.object.isRequired, // withConnect
  handleCloseModal: PropTypes.func, // passed
  handleEditButtonClick: PropTypes.func, // passed
  handleSavedReplySelected: PropTypes.func, // passed
  hasManageOptions: PropTypes.bool, // passed
  setActiveSavedReply: PropTypes.func, // passed
  settings: PropTypes.bool, // passed
  settingsAccount: PropTypes.object, // passed
  showManager: PropTypes.bool, // passed
};
