import {
  NavLink,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import get from "lodash/get";
import { Box, Divider, styled, Theme, useMediaQuery } from "@mui/material";
import ReactTouchEvents from "react-touch-events"; // As of August 2024, there is no @types/react-touch-events package
import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { CampaignFilterCollection } from "./containers/CampaignFilterCollection";
import { CampaignsProps } from "./types";
import { campaignFilterSlugs } from "./constants";
import { fetchCampaignDeliveryStats } from "./api";
import { DeliveryStats } from "./containers/DeliveryStats";
import { CampaignLimitStats } from "./containers/DeliveryStats/types";
import Campaign from "features/Campaigns/containers/Campaign";
import CampaignLanding from "features/Campaigns/containers/CampaignLanding";
import CampaignFilter from "features/Campaigns/containers/CampaignFilter";
import EditCampaign from "features/Campaigns/containers/SendCampaign/EditCampaign";
import NewCampaign from "features/Campaigns/containers/SendCampaign/NewCampaign";

import CampaignIcon from "icons/CampaignIcon";
import PageContent from "components/Page/PageContent";
import PageSection from "components/Page/PageSection";
import PageSidebar from "components/Page/PageSidebar";
import PageSidebarHeader from "components/Page/PageSidebarHeader";
import PageSidebarHeaderButton from "components/Page/PageSidebarHeaderButton";
import PageWrapper from "components/Page/PageWrapper";
import withSidebar from "higherOrderComponents/withSidebar";
import { handleMainSwipe, handleSidebarSwipe } from "utils/handleSwiping";
import getMessagingRestrictedCopy from "utils/getMessagingRestrictedCopy";
import { MobileNavDrawer } from "components/MobileNavDrawer";

const StartCampaignNavLink = styled(NavLink)(({ theme }) => {
  return {
    textDecoration: "none",
    width: "100%",
    "&.active": {
      button: {
        background: theme.palette.action.hover,
        color: theme.palette.primary.main,
      },
    },
  };
});

export function Campaigns({
  currentAccount,
  currentUser,
  isSinglePanel,
  match,
  showSidebar,
  toggleSidebar,
  uiLink,
}: CampaignsProps) {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const getDeliveryStats = async () => {
    const response = await fetchCampaignDeliveryStats(currentAccount.slug);
    return response.json();
  };

  const { data: deliveryStats } = useQuery<CampaignLimitStats>({
    queryKey: ["campaignsDeliveryStats"],
    queryFn: getDeliveryStats,
    throwOnError: (error) => {
      enqueueSnackbar("Something went wrong, please try again.", {
        variant: "error",
      });
      console.error(error);
      return false;
    },
  });

  const selectedContacts = get(location, ["state", "selectedContacts"]);
  const contactImportId = get(location, ["state", "contactImportId"]);
  const contactFilterId = get(location, ["state", "contactFilterId"]);
  const contactCollectionId = get(location, ["state", "contactCollectionId"]);
  const initialActiveStep = get(location, ["state", "initialActiveStep"]);

  const { featureFlags, messagingRestricted } = currentAccount;
  const { all } = campaignFilterSlugs;
  const history = useHistory();
  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const targeted10dlcNotice = getMessagingRestrictedCopy(
    currentAccount,
    currentUser,
    "New Campaign",
  );

  return (
    <PageWrapper>
      {mobileScreen ? (
        <MobileNavDrawer
          actionButton={
            <PageSidebarHeaderButton
              disabled={messagingRestricted}
              icon={<CampaignIcon fontSize="small" />}
              text="New Campaign"
              tooltipText={targeted10dlcNotice}
              primary
              clickHandler={() => {
                toggleSidebar(); // closes drawer when button is clicked
                history.push(`${match.url}/new`.replace("//", "/"));
              }}
            />
          }
          open={mobileScreen && showSidebar}
          toggleDrawer={toggleSidebar}
        >
          <Box display="flex" flexDirection="column">
            <Divider />
            <DeliveryStats
              currentAccount={currentAccount}
              deliveryStats={deliveryStats}
            />
            <CampaignFilterCollection
              campaignFilterCollectionId={currentAccount.campaignFilters}
              match={match}
              toggleSidebar={toggleSidebar}
            />
          </Box>
        </MobileNavDrawer>
      ) : (
        <ReactTouchEvents
          style={{ zIndex: 101 }}
          onSwipe={(direction: string) => {
            return handleSidebarSwipe(direction, toggleSidebar);
          }}
        >
          <PageSidebar>
            <PageSidebarHeader>
              <StartCampaignNavLink
                to={`${match.url}/new`.replace("//", "/")}
                data-product-tour="NewCampaignButton"
              >
                <PageSidebarHeaderButton
                  dataTestId="new-campaign-button"
                  disabled={messagingRestricted}
                  icon={<CampaignIcon fontSize="small" />}
                  text="New Campaign"
                  tooltipText={targeted10dlcNotice}
                  primary
                />
              </StartCampaignNavLink>
            </PageSidebarHeader>
            <DeliveryStats
              currentAccount={currentAccount}
              deliveryStats={deliveryStats}
            />
            <CampaignFilterCollection
              campaignFilterCollectionId={currentAccount.campaignFilters}
              match={match}
              toggleSidebar={toggleSidebar}
            />
          </PageSidebar>
        </ReactTouchEvents>
      )}

      <ReactTouchEvents
        style={{ zIndex: 101 }}
        onSwipe={(direction: string) => {
          return handleMainSwipe(direction, toggleSidebar);
        }}
      >
        <PageContent
          isSinglePanel={false}
          showSidebar={!mobileScreen && showSidebar}
        >
          <PageSection>
            <Switch location={location}>
              <Route
                exact
                path={`${match.path}`}
                render={() => {
                  return (
                    <Redirect to={`${match.url}/${all}`.replace("//", "/")} />
                  );
                }}
              />
              <Route
                exact
                path={`${match.path}/new`}
                render={(props) => {
                  return (
                    <NewCampaign
                      {...props}
                      contactFilterId={contactFilterId}
                      contactImportId={contactImportId}
                      contactCollectionId={contactCollectionId}
                      currentAccount={currentAccount}
                      currentUser={currentUser}
                      deliveryStats={deliveryStats}
                      initialActiveStep={initialActiveStep}
                      selectedContacts={selectedContacts}
                      toggleSidebar={toggleSidebar}
                      uiLink={uiLink}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${match.path}/:campaignSlug/edit`}
                render={(props) => {
                  return (
                    <EditCampaign
                      {...props}
                      campaignId={`/campaigns/${props.match.params.campaignSlug}`}
                      currentAccount={currentAccount}
                      currentUser={currentUser}
                      toggleSidebar={toggleSidebar}
                      uiLink={uiLink}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${match.path}/:campaignFilterSlug/:campaignSlug`}
                render={(props) => {
                  return (
                    <Redirect
                      to={`${props.match.url}/total`.replace("//", "/")}
                    />
                  );
                }}
              />
              <Route
                path={`${match.path}/:campaignFilterSlug/:campaignSlug`}
                render={(props) => {
                  return (
                    <Campaign
                      {...props}
                      campaignId={`/campaigns/${props.match.params.campaignSlug}`}
                      toggleSidebar={toggleSidebar}
                      currentAccount={currentAccount}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`${match.path}/:campaignFilterSlug`}
                render={(props) => {
                  const { campaignFilterSlug } = props.match.params;
                  if (campaignFilterSlug === all) {
                    return (
                      <CampaignLanding
                        {...props}
                        campaignFilterId={`/${
                          currentAccount.slug
                        }/campaign_filters/${all}`}
                        currentAccount={currentAccount}
                        toggleSidebar={toggleSidebar}
                      />
                    );
                  }
                  return (
                    <CampaignFilter
                      {...props}
                      campaignFilterId={`/${currentAccount.slug}/campaign_filters/${campaignFilterSlug}`}
                      currentAccount={currentAccount}
                      toggleSidebar={toggleSidebar}
                    />
                  );
                }}
              />
            </Switch>
          </PageSection>
        </PageContent>
      </ReactTouchEvents>
    </PageWrapper>
  );
}

export default withSidebar(Campaigns);
