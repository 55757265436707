import { Formik, Field, Form, FormikHelpers, FieldProps } from "formik";
import { Box, Grid2 as Grid, TextField, Typography } from "@mui/material";
import { DialogFooter } from "components/DialogFooter";

import { ContactFilterValidationSchema } from "formHelpers/validationSchemas";
import { useContactFilterCollection } from "features/Contacts/hooks/useContactFilterCollection";

interface FormValues {
  title: string;
  savedPath: string;
}

interface Props {
  closeModal: () => void;
  requestUrl: string;
  savedPath: string;
}

export default function ContactFilterFormRoot({
  closeModal,
  requestUrl,
  savedPath,
}: Props) {
  const initialValues = { title: "", savedPath };
  const { createContactFilterHandler } = useContactFilterCollection();

  const handleSubmit = async (
    values: FormValues,
  ) => {
    await createContactFilterHandler(requestUrl, values);
    closeModal();
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={ContactFilterValidationSchema.isValidSync(initialValues)}
      validationSchema={ContactFilterValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting, isValid }) => {
        return (
          <Form>
            <Grid
              sx={{
                paddingX: 2,
                paddingTop: 2,
              }}
            >
              <Typography variant="body1" color="text.primary" mb={2}>
                Save your search terms in order to quickly access them later.
              </Typography>

              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  marginBottom: "10px",
                }}
              >
                <Field type="text" name="title">
                  {({ field }: FieldProps<FormValues, string>) => {
                    return (
                      <TextField
                        aria-label="Title Text Field"
                        data-testid="title-text-field"
                        {...field}
                        autoFocus
                        error={touched.title && Boolean(errors.title)}
                        fullWidth
                        helperText={touched.title ? errors.title || " " : " "}
                        inputProps={{ "data-lpignore": true }}
                        label="Title"
                        placeholder="Best Search Ever"
                        type="text"
                        variant="outlined"
                      />
                    );
                  }}
                </Field>
              </Box>
            </Grid>

            <DialogFooter
              confirmText="Save"
              isConfirmDisabled={!isValid || isSubmitting}
              onCancel={closeModal}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
