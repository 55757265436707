import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Pagination } from "@tesseract/core/src/models/Pagination";
import {
  Account,
  AccountCollection,
  AccountPlan,
  LicenseGridRow,
} from "../models/AccountPlanModels";
import getAccountLicenses from "../utils/getAccountLicenses";
import { fetchAccounts, Query } from "features/Accounts/api";
import { useCurrentAccount } from "hooks";
import { useAccounts } from "features/Accounts/state";

function generateNextUrl(page: Pagination) {
  // get the query string
  const queryString = page.nextUrl.split("?")[1];

  // nextUrl is not reliably null when pagination is complete,
  // so we need to compare the current page against the total pages.
  if (page.page >= page.pages) {
    return null;
  }

  const params = new URLSearchParams(queryString);

  const result = {
    items: Number(params.get("items")),
    // type is excluded from search queries so spread it in conditionally
    ...(params.has("type") && {
      type: params.get("type"),
    }),
    page: Number(params.get("page")),
    // optionally spread in search query
    ...(params.has("q[slugOrNameOrProviderPhonesPhoneNumberCont]") && {
      "q[slugOrNameOrProviderPhonesPhoneNumberCont]": params.get(
        "q[slugOrNameOrProviderPhonesPhoneNumberCont]",
      ),
    }),
  } as Query;

  return result;
}

const useLicenseGrid = (accountPlans: AccountPlan[]) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<LicenseGridRow[]>([]);
  const [filteredRows, setFilteredRows] = useState<LicenseGridRow[]>([]);
  const [mountFetchExecuted, setMountFetchExecuted] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const [nextPage, setNextPage] = useState<Query | null>(null);
  const [isChecked, setIsChecked] = useState({
    campaigns: false,
    sequences: false,
    keywords: false,
    none: false,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const currentAccount = useCurrentAccount() ?? {};

  const { accountCollection, members, totalItems, view, isLoading } =
    useAccounts(currentAccount, {
      type: "messaging",
      items: 100,
    });

  const isFilterApplied = Object.values(isChecked).some((check) => {
    return check === true;
  });

  // we only want this to execute in two cases.
  // 1. on mount
  // 2. when a search is cleared and we need to go back to all results.
  useEffect(() => {
    // view is async and will not be available on mount so we need to include an additional state value to track this.
    if (mountFetchExecuted) {
      return;
    }

    // if nextPage has not been set on init, then try to set it with the view
    if (!nextPage && view?.nextUrl) {
      setNextPage(generateNextUrl(view as unknown as Pagination));
      setMountFetchExecuted(true);
    }
  }, [view, mountFetchExecuted, nextPage]);

  const setSearchTermHandler = (value: string) => {
    setSearchTerm(value);
    // if the search term is cleared, reset to initial state
    if (!value) {
      setRows([]);
      setMountFetchExecuted(false);
    }
  };

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const formatRows = useCallback(
    (responseBody: AccountCollection | null) => {
      if (responseBody === null) {
        return null;
      }
      return responseBody.items?.map((account: Account) => {
        return {
          id: `/accounts/${account.slug}`,
          accountName: account.name,
          licenses: getAccountLicenses(account.slug, accountPlans),
        };
      });
    },
    [accountPlans],
  );

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked({ ...isChecked, [event.target.id]: event.target.checked });
  };

  const getSearchedRows = useCallback(
    async (query: string) => {
      setLoading(true);
      try {
        const response = await fetchAccounts(currentAccount.slug, {
          "q[slugOrNameOrProviderPhonesPhoneNumberCont]": query,
        });
        const body: AccountCollection = await response.json();
        const newRows = formatRows(body);
        setRows(newRows ?? []);
        setNextPage(generateNextUrl(body.page as unknown as Pagination));
      } catch (error) {
        throw new Error(`Error fetching searched contacts: ${error as string}`);
      } finally {
        setLoading(false);
      }
    },
    [currentAccount.slug, formatRows],
  );

  const fetchNextPage = async () => {
    if (!nextPage) {
      return null;
    }
    const response = await fetchAccounts(currentAccount.slug, nextPage);
    const body: AccountCollection = await response.json();
    const newRows = formatRows(body) || [];

    setRows([...rows, ...newRows]);

    return setNextPage(generateNextUrl(body.page as unknown as Pagination));
  };

  return {
    accountCollection,
    filteredRows,
    isChecked,
    isFilterApplied,
    loading,
    members,
    rows,
    searchTerm,
    totalItems,
    view,
    fetchNextPage,
    formatRows,
    getSearchedRows,
    handleCheck,
    setIsChecked,
    setFilteredRows,
    setNextPage,
    setRows,
    setSearchTermHandler,
  };
};

export { useLicenseGrid };
