import { Dialog } from "@mui/material";
import ContactFilterForm from "./components/ContactFilterForm";
import { DialogHeader } from "components/DialogHeader";
import { useCurrentAccount } from "hooks";

interface Props {
  visible: boolean;
  closeModal: () => void;
  contactFilterId: string;
}
function NewContactFilter({ visible, closeModal, contactFilterId }: Props) {
  const { contactFilters } = useCurrentAccount();
  return (
    <Dialog open={visible} fullScreen={false} fullWidth={false} maxWidth="sm">
      <DialogHeader onClose={closeModal} title="New saved search" />
      <ContactFilterForm
        closeModal={closeModal}
        savedPath={contactFilterId}
        requestUrl={contactFilters as string}
      />
    </Dialog>
  );
}

export default NewContactFilter;
