import { Breadcrumbs, Typography, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import { BreadcrumbLinksProps } from "./types";

const StyledReturnLink = styled(NavLink)(({ theme }) => {
  return {
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    textDecoration: "none",
    ":hover": { textDecoration: "underline" },
  };
});

function BreadcrumbLinks({ returnPath, sequenceName }: BreadcrumbLinksProps) {
  return (
    <Breadcrumbs aria-label="Sequences breadcrumbs">
      <StyledReturnLink to={returnPath}>Sequences</StyledReturnLink>
      <Typography fontSize="1rem" lineHeight="1.5rem" color="text.hyperlink">
        {sequenceName}
      </Typography>
    </Breadcrumbs>
  );
}

export { BreadcrumbLinks };
