import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Box } from "@mui/material";
import { ColumnHeader, KeywordGroupsProps } from "../models/AutomationModels";
import { keywordGroupsColumnHeader } from "../constants/KeywordConstants";
import { StyledHeaderTableCell } from "../components/styles";
import Loader from "components/Loader";

import BlankState from "components/BlankState";
import Logo from "components/Logo";
import PageHeader from "components/Page/PageHeader";
import { Keyword } from "models/Keyword";
import { StickyFooter } from "components/StickyFooter";

function KeywordGroups({
  loading,
  keywords,
  links,
  page,
  rowsPerPage,
  getRow,
  handlePageChange,
  toggleSidebar,
}: KeywordGroupsProps) {
  if (!keywords?.items || loading) {
    return <Loader isLoading />;
  }

  const tableColumns = keywordGroupsColumnHeader;

  return (
    <>
      <PageHeader
        data-testid="keyword-subscribers-title"
        aria-label={`Keywords Subscribers (${keywords.page.count})`}
        title={`Keywords Subscribers (${keywords.page.count})`}
        toggleSidebar={toggleSidebar}
      />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
      >
        {keywords?.items?.length ? (
          <TableContainer
            sx={{
              overflowY: "auto",
              height: { xs: "calc(100vh - 13.75rem)", sm: "100%" },
            }}
          >
            <Table
              aria-label="Keywords Subscribers"
              data-testid="keyword-subscribers"
            >
              <TableHead>
                <TableRow style={{ height: 28 }}>
                  {tableColumns.map((cell: ColumnHeader) => {
                    return (
                      <StyledHeaderTableCell
                        key={cell.id}
                        align={cell.numeric ? "right" : "left"}
                      >
                        {cell.label}
                      </StyledHeaderTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody data-testid="keyword-groups">
                {keywords.items.map((row: Keyword) => {
                  return getRow(row, links);
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <BlankState
            image={<Logo color="disabled" />}
            title="No keywords subscribers found"
            subTitle="Create a keyword and auto-reponse on the 'Keywords' page"
          />
        )}
        <StickyFooter>
          <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
            <TablePagination
              count={keywords.page.count}
              onPageChange={handlePageChange}
              page={page - 1}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              sx={{ borderBottom: "none" }}
            />
          </Box>
        </StickyFooter>
      </Box>
    </>
  );
}

export default KeywordGroups;
