import { Box } from "@mui/system";
import { Typography, Grid2 as Grid } from "@mui/material";
import AutoReplyMessage from "./AutoReplyMessage";
import OfficeHours from "./OfficeHours";
import PageHeader from "components/Page/PageHeader";

import { User } from "models/User";
import { Account } from "models/Account";

export default function OfficeHoursAndAutoReply({
  currentAccount,
  currentUser,
  toggleSidebar,
}: {
  currentAccount: Account;
  currentUser: User;
  toggleSidebar: () => boolean;
}) {
  return (
    <Box sx={{ height: "100%", overflowY: "scroll" }}>
      <PageHeader title="Office hours & reply" toggleSidebar={toggleSidebar} />
      <Grid
        sx={(theme) => {
          return {
            margin: "auto",
            maxWidth: "100%",
            padding: 2,
            paddingTop: 0,
            overflowY: "scroll",
            [theme.breakpoints.up("lg")]: {
              padding: 4,
              paddingTop: 0,
              maxWidth: "1040px",
              display: "grid",
              gridTemplateColumns: "250px auto",
              gap: theme.spacing(4),
            },
            [theme.breakpoints.up("xl")]: {
              maxWidth: "1420px",
            },
          };
        }}
      >
        <Typography
          variant="h4"
          sx={(theme) => {
            return { paddingTop: theme.spacing(4) };
          }}
          gutterBottom
        >
          Office hours & automatic replies
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pb: "2rem",
          }}
        >
          <AutoReplyMessage
            account={currentAccount}
            currentUser={currentUser}
          />
          <OfficeHours account={currentAccount} currentUser={currentUser} />
        </Box>
      </Grid>
    </Box>
  );
}
