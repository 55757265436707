import {
  Box,
  Checkbox,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  styled,
} from "@mui/material";
import GroupAdd from "@mui/icons-material/GroupAdd";
import SendIcon from "@mui/icons-material/Send";

import { kebabCase } from "lodash";
import { SubscribersGridToolbarProps } from "../types";
import { gridHeaderHeight } from "features/Sequences/components/ContactsDataGrid/constants";

const StyledCheckbox = styled(Checkbox)(({ theme }) => {
  return {
    height: "40px",
    width: "40px",
    "&.Mui-checked": {
      color: theme.palette.lightBlue[800],
    },
  };
});

function SubscribersGridToolbar({
  allSelected,
  pagination,
  rowCount,
  selectedRows,

  handleSelectAll,
  handleUnselectAll,
  openQuickCompose,
  toggleAddGroupModal,
  toggleAllSelected,
}: SubscribersGridToolbarProps) {
  const desktopScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up("lg");
  });

  const iconMap = [
    {
      icon: <SendIcon />,
      tooltip: "Send message",
      disabled: selectedRows.length >= 10,
      onClick: openQuickCompose({}, () => {
        return null;
      }),
    },
    {
      icon: <GroupAdd />,
      tooltip: "Add contacts to group",
      disabled: false,
      onClick: toggleAddGroupModal,
    },
  ];

  const renderIcons = () => {
    return iconMap.map(({ disabled, icon, tooltip, onClick }) => {
      return (
        <Tooltip key={tooltip} title={tooltip}>
          <span>
            <IconButton
              data-testid={`${kebabCase(tooltip).toLowerCase()}`}
              onClick={onClick}
              disabled={disabled}
              sx={{ padding: "1rem" }}
            >
              {icon}
            </IconButton>
          </span>
        </Tooltip>
      );
    });
  };

  const handleChange = () => {
    if (
      selectedRows.length > 0 &&
      !allSelected &&
      rowCount !== selectedRows.length
    ) {
      return handleSelectAll();
    }
    return handleUnselectAll();
  };

  const copy = {
    selectAllButton: allSelected
      ? "Clear selection"
      : `Select all ${
          rowCount > pagination.count ? rowCount : pagination.count
        }`,
    selectedCount: `${allSelected ? "All" : ""} ${
      allSelected ? pagination.count : selectedRows.length
    } selected`,
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      height={gridHeaderHeight}
      width="100%"
      paddingRight={desktopScreen ? "0" : "5rem"}
      justifyContent="space-between"
      sx={(theme) => {
        return {
          backgroundColor: theme.palette.customColors.outboundMessageColor,
        };
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <StyledCheckbox
          checked={allSelected}
          data-testid="unselect-all-contacts"
          indeterminate={!allSelected}
          size="medium"
          onChange={handleChange}
        />
        <Typography sx={{ fontSize: "14px", fontWeight: 700, marginLeft: 1 }}>
          {copy.selectedCount}
        </Typography>
        <Typography
          onClick={toggleAllSelected}
          component="button"
          sx={(theme) => {
            return {
              fontSize: "14px",
              fontWeight: 700,
              color: theme.palette.text.hyperlink,
              textDecoration: "underline",
              marginLeft: 0.5,
            };
          }}
        >
          {copy.selectAllButton}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        marginRight="1rem"
      >
        {renderIcons()}
      </Box>
    </Box>
  );
}
export { SubscribersGridToolbar };
