import { IconButton, Menu, MenuItem } from "@mui/material";
import { MouseEvent, useState } from "react";
import { capitalize, upperCase } from "lodash";
import { enqueueSnackbar } from "notistack";
import { useHistory } from "react-router";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { archiveKeyword, updateKeyword } from "../api";
import SimpleQrDialog from "./SimpleQrDialog";
import KeywordConfirmationModal from "./KeywordConfirmationModal";
import { MoreVertIcon } from "icons";
import { useCurrentAccount } from "hooks";
import { Keyword } from "models/Keyword";

interface KeywordTableActionMenuProps {
  actionType: "archive" | "reactivate";
  keyword: Keyword;
  refresh: () => Promise<void>;
  params: GridRenderCellParams;
}

const renderSnackbar = (success: boolean, verb: string) => {
  return success // response.ok
    ? enqueueSnackbar(`Keyword automation successfully ${verb}.`, {
        variant: "info",
      })
    : enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
};

function KeywordTableActionMenu({
  actionType,
  keyword,
  refresh,
  params,
}: KeywordTableActionMenuProps) {
  /* HOOKS */
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentAccount = useCurrentAccount();

  /* VARIABLES */
  const activeKeywords = actionType === "archive";
  const title = `${capitalize(actionType)} keyword`;
  const actionPastParticiple = activeKeywords ? "archived" : "reactivated";

  const localStorageKey = `HIDE_${upperCase(actionType)}_KEYWORD_MODAL`;
  const skipDialog = localStorage.getItem(localStorageKey) === "true";

  /* FUNCTIONS */
  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleConfirm = async () => {
    if (activeKeywords) {
      return archiveKeyword(currentAccount, keyword.id).then((response) => {
        renderSnackbar(response.ok, actionPastParticiple);
        return refresh();
      });
    }
    return updateKeyword(currentAccount, keyword.id, {
      active: true,
    }).then((response) => {
      renderSnackbar(response.ok, actionPastParticiple);
      return refresh();
    });
  };

  const handleOpenModal = () => {
    if (skipDialog) {
      handleConfirm().catch((error) => {
        // Error snackbar is rendered inside handleConfirm() function
        // This is an added layer for devs to see the error in console
        console.error(
          `Error occurred trying to ${actionType} keyword: ${error}`,
        );
      });
    } else {
      setIsModalOpen(true);
    }

    setMenuAnchorEl(null);
  };

  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState("");

  const handleQrOpenModal = (clickedKeyword: string) => {
    setSelectedKeyword(clickedKeyword);
    setQrModalOpen(true);
  };

  const handleQrModalClose = () => {
    setSelectedKeyword("");
    setQrModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const history = useHistory();

  const handleManageClick = (tabIndex: number) => {
    history.push({
      pathname: `${window.location.pathname}/${params?.id}`,
      search: `?tab=${tabIndex}`,
    });
  };

  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {/* TODO: Add onCLick handlers to MenuItems */}
        <MenuItem
          onClick={() => {
            return handleManageClick(0);
          }}
        >
          Manage keyword
        </MenuItem>
        <MenuItem
          onClick={() => {
            return handleManageClick(1);
          }}
        >
          View subscribers
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleQrOpenModal(params.row.keyword);
          }}
        >
          Generate QR code
        </MenuItem>
        <MenuItem onClick={handleOpenModal}>{title}</MenuItem>
      </Menu>
      <KeywordConfirmationModal
        actionType={actionType}
        isOpen={isModalOpen}
        cancellationHandler={handleCloseModal}
        confirmationHandler={handleConfirm}
        localStorageKey={localStorageKey}
      />

      <SimpleQrDialog
        keyword={selectedKeyword}
        isOpen={qrModalOpen}
        phoneNumbers={currentAccount.phoneNumbers}
        closeHandler={handleQrModalClose}
      />
    </>
  );
}

export default KeywordTableActionMenu;
