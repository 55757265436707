import { isBefore, parseISO } from "date-fns";
import { fromJS } from "immutable";
import get from "lodash/get";
import queryString from "query-string";

const removeCursorParam = (id) => {
  const { url, query } = queryString.parseUrl(id);
  if (query.cursor) {
    const queryParams = queryString.stringify({ ...query, cursor: undefined });
    return queryParams ? `${url}?${queryParams}` : url;
  }
  return id;
};

const flattenNormalizedRecords = (records) => {
  return Object.values(Object.assign({}, ...Object.values(records)));
};

const hasInfiniteScroll = (record) => {
  const typesWithInfiniteScroll = [
    "ConversationCollection",
    "Timeline",
    "SavedReplyCollection",
  ];
  return typesWithInfiniteScroll.includes(record["@type"]);
};

const hasCursor = (record) => {
  return record.id?.includes("cursor=");
};

export default (state, action) => {
  return state.mergeIn(["records"], {
    ...flattenNormalizedRecords(action.records).reduce((prev, record) => {
      const recordHasInfiniteScroll = hasInfiniteScroll(record);
      const recordHasCursor = hasCursor(record);
      const recordId =
        recordHasInfiniteScroll && recordHasCursor
          ? removeCursorParam(record.id)
          : record.id;
      const newRecord = { ...record, id: recordId };
      const existingRecord = state.getIn(["records", recordId]) || fromJS({});
      const updatedRecord = (() => {
        if (recordHasInfiniteScroll && recordHasCursor) {
          return existingRecord.mergeWith((oldVal, newVal, key) => {
            return key === "members"
              ? oldVal.concat(newVal).toOrderedSet().toList()
              : newVal;
          }, newRecord);
        }

        if (
          recordHasInfiniteScroll &&
          get(action, ["options", "mergeFirstPage"]) &&
          record.members.length !== record.totalItems
        ) {
          return existingRecord.mergeWith((oldVal, newVal, key) => {
            if (key === "members") {
              return newVal.concat(oldVal).toOrderedSet().toList();
            }
            if (key === "view") {
              return oldVal;
            }
            return newVal;
          }, newRecord);
        }
        if (
          existingRecord.get("@type") === "Message" &&
          isBefore(
            new Date(record.latestEventAt),
            new Date(existingRecord.get("latestEventAt")),
          )
        ) {
          return existingRecord;
        }

        return existingRecord.merge(newRecord);
      })();
      return { ...prev, [recordId]: updatedRecord };
    }, {}),
  });
};
