import { useState } from "react";
import {
  Box,
  Button,
  Slider,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";

import type { FormikHelpers } from "formik";
import type { Account } from "@tesseract/core";

import { useAccountSettingsSubmit } from "../hooks";
import BranchAccountWarning from "./BranchAccountWarning";
import LockForm from "./LockForm";
import {
  MAX_MESSAGE_LENGTHS_OPTIONS,
  DEFAULT_MAX_MESSAGE_LENGTH,
} from "constants/defaults";
import PageHeader from "components/Page/PageHeader";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";

type FormValues = {
  settings: {
    [key: string]: {
      value?: string | number | boolean;
      locked?: boolean;
    };
  };
};
interface CharacterLimitProps {
  account: Account.Raw;
  handleSubmit: (
    values: FormValues,
    actions: Partial<FormikHelpers<object>>,
    successMessage?: string,
  ) => void;
  location: Location;
  toggleSidebar: () => boolean;
}

function CharacterLimit({
  account,
  handleSubmit,
  location,
  toggleSidebar,
}: CharacterLimitProps) {
  const { handleSubmit: handleSettingsSubmit } = useAccountSettingsSubmit();
  const { campaignMaxMessageLength, conversationMaxMessageLength } =
    account.settings;
  const [customCampaignLength, setCustomCampaignLength] = useState(
    campaignMaxMessageLength.value !== conversationMaxMessageLength.value,
  );

  const [conversationLength, setConversationLength] = useState(
    conversationMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH,
  );
  const [campaignLength, setCampaignLength] = useState(
    campaignMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH,
  );

  const fromCampaign = !!location.pathname.includes("/campaign");
  const isParent = account.familyCount > 1;
  const messageLengthOptions = MAX_MESSAGE_LENGTHS_OPTIONS;
  const settingName = fromCampaign
    ? "campaignMaxMessageLength"
    : "conversationMaxMessageLength";

  const { locked } = account.settings[settingName];

  const handleBaseChange = (event: Event, value: number | number[]) => {
    if (!customCampaignLength) {
      setCampaignLength(Number(value));
    }

    return setConversationLength(Number(value));
  };

  const handleCampaignChange = (event: Event, value: number | number[]) => {
    setCampaignLength(Number(value));
  };

  const valueLabelFormat = (value: number) => {
    return (
      messageLengthOptions.find((option) => {
        return option.value === value;
      })?.valueLabel || `${value} Characters`
    );
  };

  const handleLock = (values: FormValues, actions: FormikHelpers<object>) => {
    handleSettingsSubmit(
      {
        settings: {
          [settingName]: {
            locked: values.settings[settingName].locked,
          },
        },
      },
      actions,
    );
  };

  return (
    <SettingsPageWrapper
      aria-label={`${
        fromCampaign ? "Campaign" : "Message"
      } Character Limit Setting`}
    >
      <PageHeader title="Character limit" toggleSidebar={toggleSidebar}>
        <LockForm
          account={account}
          handleSubmit={handleLock}
          settings={[settingName]}
        />
      </PageHeader>
      <SettingsPageContent
        aria-label={`${
          fromCampaign ? "Campaign" : "Message"
        } Character Limit Settings Content`}
        overflow="auto"
      >
        {isParent && (
          <BranchAccountWarning subAccountCount={account.familyCount - 1} />
        )}
        <Box
          sx={{
            marginY: "16px",
            paddingY: "16px",
            padding: "16px 32px",
          }}
        >
          <Typography variant="h5" color="primary">
            Character limits
          </Typography>
          <Typography
            variant="body2"
            sx={(theme) => {
              return {
                margin: "32px 0",
                paddingBottom: "32px",
                borderBottom: `1px solid ${theme.palette.divider}`,
              };
            }}
          >
            Set a character limit for all outbound messages sent from this
            account and all child accounts.
          </Typography>
          <Slider
            disabled={locked}
            color="secondary"
            aria-label="Max Character Length Slider"
            aria-valuetext={`${
              fromCampaign ? campaignLength : conversationLength
            } characters`}
            value={
              fromCampaign ? Number(campaignLength) : Number(conversationLength)
            }
            marks={messageLengthOptions}
            min={messageLengthOptions[0].value}
            max={messageLengthOptions.at(-1)?.value}
            onChange={handleBaseChange}
            step={null}
            valueLabelDisplay="auto"
            valueLabelFormat={valueLabelFormat}
          />
        </Box>
        <Box
          sx={(theme) => {
            return {
              bgcolor: theme.palette.customColors.backgrounds.secondary,
              marginY: "16px",
              paddingY: "16px",
              borderTop: `1px solid ${theme.palette.divider}`,
              borderBottom: `1px solid ${theme.palette.divider}`,
              padding: "16px 32px",
            };
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
              required
              control={
                <Switch
                  checked={customCampaignLength}
                  onChange={() => {
                    return setCustomCampaignLength(!customCampaignLength);
                  }}
                  name="customCampaignLength"
                  color="secondary"
                />
              }
              label="Customize limit for bulk messaging"
            />
          </Box>
          <Typography variant="body2" sx={{ margin: "32px 0" }}>
            Bulk messaging includes messages from campaigns or sequences.
          </Typography>
          {customCampaignLength && (
            <Slider
              disabled={!customCampaignLength}
              color="secondary"
              aria-label="Max Campaign Character Length Slider"
              aria-valuetext={`${campaignLength} characters`}
              value={Number(campaignLength)}
              marks={messageLengthOptions}
              min={messageLengthOptions[0].value}
              max={messageLengthOptions.at(-1)?.value}
              onChange={handleCampaignChange}
              step={null}
              valueLabelDisplay="auto"
              valueLabelFormat={valueLabelFormat}
            />
          )}
        </Box>
        <Box>
          <Button
            aria-label="Save"
            disabled={locked}
            color="primary"
            onClick={() => {
              return handleSubmit(
                {
                  settings: {
                    conversationMaxMessageLength: { value: conversationLength },
                    campaignMaxMessageLength: {
                      value: customCampaignLength
                        ? campaignLength
                        : conversationLength,
                    },
                  },
                },
                { setErrors: () => {}, setSubmitting: () => {} },
              );
            }}
            type="submit"
            variant="contained"
            sx={{ mt: "20%", ml: "90%" }}
          >
            Save
          </Button>
        </Box>
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

export default CharacterLimit;
