import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AccountSetting from "./AccountSetting";
import { LinkStrippingValidationSchema } from "formHelpers/validationSchemas";

function LinkStripping(props) {
  const getRender = (label) => {
    return () => {
      return function ({ field, form }) {
        return (
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                onChange={(event) => {
                  const { checked } = event.target;
                  form.setFieldValue(field.name, checked);
                }}
                color="secondary"
              />
            }
            label={label}
          />
        );
      };
    };
  };

  return (
    <AccountSetting
      {...props}
      description="Links can be removed from messages
        to help prevent inbound phishing attacks or
        limit your team’s ability to send links to contacts.
        When enabled, detected links will be replaced with
        [LINK REDACTED] before being sent or received."
      settings={[
        {
          default: false,
          name: "stripInboundLinks",
          render: getRender("Remove inbound links"),
        },
        {
          default: false,
          name: "stripOutboundLinks",
          render: getRender("Remove outbound links"),
        },
      ]}
      title="Link removal"
      validationSchema={LinkStrippingValidationSchema}
    />
  );
}

LinkStripping.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

export default LinkStripping;
