/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Box, Divider, Skeleton } from "@mui/material";
import { useSnackbar } from "notistack";
import { Contact } from "@tesseract/core";
import { GroupPageContentProps } from "./types";
import {
  getColumns,
  getCollapsedColumns,
} from "features/Groups/constants/contactColumns";
import BatchActionToolbar from "components/BatchActionToolbar";
import BlankState from "components/BlankState";
import Logo from "components/Logo";
import Table from "components/Table";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  deleteContactCollection,
  fetchContactCollection,
} from "features/Contacts/api";
import { renderSkeletonRows } from "utils/renderSkeletonRows";

function GroupPageContent({
  contactCollectionId,
  setIsSendDisabled,
  selected,
  selectedRecords,
  setSelected,
  setSelectedRecords,
  showSelectAll,
  allSelected,
  setAllSelected,
}: GroupPageContentProps) {
  const [deleteConfirmationVisibility, setDeleteConfirmationVisibility] =
    useState(false);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const toggleDeleteConfirmationVisibility = () => {
    setDeleteConfirmationVisibility(!deleteConfirmationVisibility);
  };

  const pluralizedContact = selected.length === 1 ? "contact" : "contacts";

  const getGroupContacts = async () => {
    const response = await fetchContactCollection(contactCollectionId);
    return response.json();
  };

  const { data: contactCollection, isLoading } = useQuery({
    queryKey: [contactCollectionId],
    queryFn: getGroupContacts,
    throwOnError: (error) => {
      enqueueSnackbar(
        "Something went wrong retrieving contacts. Please try again.",
        {
          variant: "error",
        },
      );
      console.error(error);
      return false;
    },
  });

  const deleteGroupContacts = () => {
    return deleteContactCollection(contactCollection?.id, selected);
  };

  const deleteContacts = useMutation({
    mutationFn: deleteGroupContacts,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [contactCollectionId],
        })
        .catch((error) => {
          console.error(error);
        });

      enqueueSnackbar(
        `Successfully removed ${selected.length} ${pluralizedContact} from the group.`,
        {
          variant: "info",
        },
      );

      setSelected([]);
      setSelectedRecords([]);
    },
    onError: (error) => {
      enqueueSnackbar(
        "Something went wrong removing contacts. Please try again.",
        {
          variant: "error",
        },
      );
      console.error(error);
    },
  });

  const handleDelete = () => {
    deleteContacts.mutate();
    toggleDeleteConfirmationVisibility();
  };

  const batchActions = [
    {
      title: "Remove Contacts",
      icon: (
        <DeleteIcon
          aria-label="Remove Group Contacts"
          data-testid="remove-group-contacts"
        />
      ),
      clickHandler: toggleDeleteConfirmationVisibility,
      showExpanded: true,
    },
  ];

  const { totalItems = 0 } = contactCollection ?? {};

  const handleSelected = (values: Contact.ID[]) => {
    if (showSelectAll && setAllSelected)
      setAllSelected(values.length === totalItems);
    setSelected(values);
  };

  useEffect(() => {
    selected.length === 0 || selected.length === totalItems
      ? setIsSendDisabled(false)
      : setIsSendDisabled(true);
  }, [selected, contactCollection]);

  // START OFFSET CALCULATION
  /* This is used to calculate the height of the table wrapper, so that the pagination is not cut off when the table scrolls */
  const boxRef = useRef<HTMLDivElement>(null);
  const [boxOffset, setBoxOffset] = useState(0);

  useEffect(() => {
    if (boxRef.current) {
      const bottomPagination = document.querySelector<HTMLElement>(
        ".MuiBottomNavigation-root",
      );
      const { top } = boxRef.current.getBoundingClientRect();
      let offset = top + 1;

      if (bottomPagination) {
        offset = top + bottomPagination.offsetHeight;
      }
      setBoxOffset(offset);
    }
  }, [boxRef, boxOffset]);
  // END OFFSET CALCULATION

  return (
    <Box
      aria-label="Group Page Content"
      ref={boxRef}
      sx={{
        display: "flex",
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: "column",
        overflow: "auto",
        position: "relative",
        maxHeight: `calc(100vh - ${boxOffset}px)`,
      }}
    >
      <ConfirmationModal
        cancellationHandler={toggleDeleteConfirmationVisibility}
        confirmationHandler={handleDelete}
        isOpen={deleteConfirmationVisibility}
        message={`You are about to remove ${selected.length} ${pluralizedContact} from this group.`}
        title={`Remove ${selected.length} ${pluralizedContact} from group?`}
      />
      {selected.length > 0 && (
        <BatchActionToolbar
          // @ts-expect-error - Type error on deprecated component, fixing would require a refactor
          actions={batchActions}
          position={{ top: "0px", left: "64px" }}
          selectedCount={selected.length}
          setSelectedRecords={setSelectedRecords}
          selectAllCount={totalItems}
          showSelectAll={showSelectAll}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
        />
      )}
      {isLoading || !contactCollection ? (
        <Box display="flex" flexDirection="column" width="100%">
          <Box padding="0.5rem">
            <Skeleton height="3.5rem" />
          </Box>
          <Divider />
          <Box padding="0.5rem">{renderSkeletonRows(30, "3rem")}</Box>
        </Box>
      ) : (
        <Table
          blankState={
            <BlankState
              image={<Logo color="disabled" />}
              title="This group is empty"
              subTitle="Click on the button below to add some contacts"
            />
          }
          collection={contactCollection}
          collapsedColumns={getCollapsedColumns()}
          columns={getColumns()}
          notExpandable
          selected={selected}
          selectedRecords={selectedRecords}
          setSelected={handleSelected}
          setSelectedRecords={setSelectedRecords}
          withBatchActions
        />
      )}
    </Box>
  );
}

export default GroupPageContent;
