import { ChangeEvent, useCallback, useEffect, useState } from "react";
import format from "date-fns/format";
import { useHistory } from "react-router";
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  Theme,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  DataGridPro,
  GridCellCheckboxRenderer,
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  selectedIdsLookupSelector,
} from "@mui/x-data-grid-pro";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSubscribers } from "../hooks/useSubscribers";
import { SubscribersGridToolbar } from "./SubscribersGridToolbar";
import SubscribersDatePicker from "./SubscribersDatePicker";
import { SearchBox } from "components/SearchBox";
import { useTabs } from "hooks/useTabs";
import { SubKeyword, SubscribersProps } from "features/Keywords/types";
import { InfoOutlineIcon } from "icons";
import { SequencesContact } from "features/Sequences/screens/IndividualSequenceOverview/types";
import { OverflowChipsMenu } from "features/Contacts/containers/ContactCollection/components/OverflowChipsMenu";
import AddContactsToGroupModal from "features/Contacts/containers/AddContactsToGroupModal";
import QuickComposer from "features/Compose/containers/QuickComposer";
import { Dialog } from "components/Dialog";
import BlankState from "components/BlankState/Version1";
import NoNumbersFound from "utils/images/no-numbers-found.svg";

export default function Subscribers({
  keyword,
  currentAccount,
  sequenceId,
}: SubscribersProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [hideFilters, setHideFilters] = useState<boolean>(false);

  const {
    selectedTab: selectedSubscriberTab,
    handleTabChange: handleSubscriberTabChange,
  } = useTabs();

  /* VARIABLES */
  const { subKeywords, timesReceived } = keyword;
  const keywordTitle = keyword?.keyword;
  const gridMinColumnWidth = 160;

  /* HOOKS */
  const history = useHistory();

  const isMobile = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const {
    rowState,
    pagination,
    isLoadingAllSubscribers,
    allSelected,
    apiRef,
    dialogRef,
    formattedGroupContacts,
    formattedSubscribers,
    fullScreen,
    isAddGroupModalOpen,
    isEmpty,
    isSelectionMade,
    rowCount,
    selectedRows,

    gridSelectAll,
    handleCheck,
    handleSelectAll,
    handleUnselectAll,
    openQuickCompose,
    setAllSelected,
    toggleAddGroupModal,
    toggleAllSelected,
    getSubscribers,
    fetchNextPage,
  } = useSubscribers(currentAccount, keyword.id);
  const { rows } = rowState;

  useEffect(
    () => {
      if (isLoadingAllSubscribers) return;
      void getSubscribers(undefined, { searchTerm, dateRange });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, dateRange],
  );

  // If all available rows are fetched and selected, setAllSelected to true
  useEffect(() => {
    if (
      selectedRows.length > 0 &&
      rowCount === apiRef.current.state?.rowSelection.length &&
      selectedRows.length === pagination?.count
    ) {
      setAllSelected(true);
    }
  }, [selectedRows, pagination, apiRef, setAllSelected, rowCount]);

  // If allSelected is true and more rows are fetched, set the new rows to checked
  useEffect(() => {
    if (allSelected) {
      gridSelectAll();
    }
  }, [allSelected, gridSelectAll, apiRef]);

  /* FUNCTIONS */
  const getPercentResponded = (subKeyword: SubKeyword) => {
    return keyword.timesReceived === 0 || subKeyword.answeredCount === 0
      ? 0
      : Math.round((subKeyword.answeredCount / keyword.timesReceived) * 100);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    history.push(`/${currentAccount.slug}/automation/sequences/${sequenceId}`);
    handleClose();
  };

  const handleSearch = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setSearchTerm(value);
    },
    [setSearchTerm],
  );

  /* DISPLAY DATA */
  const keywordCardItems =
    selectedSubscriberTab > 0
      ? [
          {
            title: "Total Answers",
            stat:
              subKeywords &&
              subKeywords[selectedSubscriberTab - 1]?.answeredCount,
            tooltip: `The total number of subscribers who responded with ${subKeywords ? subKeywords[selectedSubscriberTab - 1]?.keyword : "a keyword answer"}`,
          },
          {
            title: "Opted out",
            stat: keyword?.optedOutCount,
            tooltip:
              "The total number of subscribers who chose to opt-out after receiving an auto-response.",
          },
        ]
      : [
          {
            title: "Subscribers",
            stat: keyword?.timesReceived,
            tooltip: `The number of subscribers who responded with ${keyword?.keyword}.`,
          },
          {
            title: "Opted out",
            stat: keyword?.optedOutCount,
            tooltip:
              "The total number of subscribers who chose to opt-out after receiving an auto-response.",
          },
        ];

  const columns: GridColDef[] = isMobile
    ? [
        {
          field: "name",
          headerName: "Name",
          flex: 1,
          minWidth: gridMinColumnWidth,
          renderCell: (params) => (
            <div>
              <div>{params.value}</div>
              {isMobile && (
                <div
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "12px" }}>
                    {params.row.contactPhones[0]?.lastReceivedAt
                      ? format(
                          new Date(params.row.contactPhones[0].lastReceivedAt),
                          "E, MMM d, h:mm aaa",
                        )
                      : null}
                  </Typography>
                </div>
              )}
            </div>
          ),
        },
      ]
    : [
        {
          field: "__check__",
          type: "string",
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          filterable: false,
          headerAlign: "left",
          resizable: false,
          sortable: false,
          valueGetter: (params) => {
            const selectionLookup = selectedIdsLookupSelector(apiRef);
            return selectionLookup[params.id] !== undefined;
          },
          renderHeader: () => {
            return (
              <Checkbox
                checked={allSelected}
                onChange={handleSelectAll}
                data-testid="select-all-keyword-subscribers"
              />
            );
          },
          renderCell: (params) => {
            return (
              <GridCellCheckboxRenderer
                {...params}
                sx={(theme) => {
                  return {
                    height: "40px",
                    width: "40px",
                    "&.Mui-checked": {
                      color: theme.palette.lightBlue[800],
                    },
                  };
                }}
              />
            );
          },
        },
        {
          field: "name",
          headerName: "Name",
          flex: 1,
          minWidth: gridMinColumnWidth,
        },
        {
          field: "contactPhones",
          headerName: "Number",
          flex: 1,
          minWidth: gridMinColumnWidth,
          valueFormatter: (params) => {
            const contactPhones = params.value || [];
            return (contactPhones[0]?.phoneNumber || "").replace(
              /^(\+1)(\d{3})(\d{3})(\d{4})$/,
              "($2) $3-$4",
            );
          },
        },
        {
          field: "lastReceivedAt",
          headerName: "Last received",
          flex: 1,
          minWidth: gridMinColumnWidth,
          valueGetter: (params) => {
            const contactPhones = params.row.contactPhones || [];
            return format(
              new Date(contactPhones[0]?.lastReceivedAt),
              "E, MMM d, h:mm aaa",
            );
          },
        },
        {
          field: "contactResponse",
          headerName: "Contact response",
          flex: 1,
          minWidth: gridMinColumnWidth,
          valueGetter: (params) => {
            const contactPhones = params.row.contactPhones || [];
            return contactPhones[0]?.keywordResponse;
          },
        },
        {
          field: "tags",
          headerName: "Tags",
          flex: 1,
          minWidth: 220,
          resizable: true,
          sortable: false,
          renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
          ) => {
            return (
              <OverflowChipsMenu tags={params.value} handleClick={() => {}} />
            ); // TODO: Implement handleClick for search
          },
        },
        {
          field: "business",
          headerName: "Business",
          flex: 1,
          minWidth: gridMinColumnWidth,
        },
      ];

  const getSearchParams = () => {
    const paginatedUrl = pagination?.firstUrl;
    const params = paginatedUrl?.split("?")[1].split?.("&page")[0];

    /* eslint unicorn/no-array-reduce: "off" */
    const parsedParams = params?.split("&").reduce(
      (acc, param) => {
        const [key, value] = param.split("=");

        const parsedKey = decodeURIComponent(key).replace(/^q\[(.*)\]$/, "$1");
        const parsedValue = decodeURIComponent(value || "");

        if (parsedKey && parsedValue) {
          acc[parsedKey] = parsedValue;
        }
        return acc;
      },
      {} as Record<string, string | boolean>,
    );

    return parsedParams;
  };

  return (
    <Box height="100%">
      <Tabs
        value={selectedSubscriberTab}
        onChange={handleSubscriberTabChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        sx={(theme: Theme) => {
          return {
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingLeft: "16px",
            paddingTop: "16px",
            display: "flex",
            alignItems: "center",
            "& .MuiTabs-scrollButtons.Mui-disabled": {
              display: "none",
            },
            "& .MuiTabs-scrollButtons:first-of-type": {
              paddingLeft: "0px",
            },
            "& .MuiTabs-scrollButtons:last-of-type": {
              paddingRight: "0px",
            },
          };
        }}
      >
        <Tab
          key={keyword?.keyword}
          label={
            <>
              <Typography variant="label">{keywordTitle}</Typography>
              <Typography variant="tableHeader"> {timesReceived} </Typography>
            </>
          }
          sx={{ padding: "0 16px" }}
        />
        {subKeywords?.map((subKeyword: SubKeyword) => {
          return (
            <Tab
              key={keyword?.id}
              label={
                <>
                  <Typography variant="label">{subKeyword?.keyword}</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-evenly"
                    width="60px"
                    height="24px"
                  >
                    <Typography variant="tableHeader">
                      {subKeyword.answeredCount}
                    </Typography>
                    <Typography variant="tableHeader">
                      {`(${getPercentResponded(subKeyword)}%)`}
                    </Typography>
                  </Box>
                </>
              }
              sx={{ padding: "0 16px" }}
            />
          );
        })}
      </Tabs>
      {selectedSubscriberTab === 0 && !isMobile ? (
        <Box display="flex" padding="16px 16px 12px 16px">
          {" "}
          {keywordCardItems.map((keywordCardItem) => {
            return (
              <Card key="1" sx={{ margin: "4px" }}>
                <CardContent
                  sx={{
                    padding: "8px 16px",
                    "&:last-child": {
                      paddingBottom: "8px",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      paddingRight="4px"
                      paddingTop="3px"
                    >
                      {keywordCardItem.title}
                    </Typography>
                    <Tooltip
                      title={
                        <Typography
                          sx={{
                            variant: "label",
                            fontSize: "10px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {keywordCardItem.tooltip}
                        </Typography>
                      }
                      slotProps={{
                        tooltip: {
                          sx: {
                            maxWidth: "none",
                          },
                        },
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -8],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <span>
                        <InfoOutlineIcon
                          sx={(theme: Theme) => {
                            return {
                              color: theme.palette.text.secondary,
                              fontSize: "20px",
                              stroke: theme.palette.text.secondary,
                              strokeWidth: 10,
                            };
                          }}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                  <Typography
                    display="flex"
                    justifyContent="center"
                    variant="dataMedium"
                    align="center"
                    color="primary"
                    noWrap
                  >
                    {keywordCardItem.stat}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}{" "}
        </Box>
      ) : null}
      {selectedSubscriberTab === 0 || isMobile ? null : (
        <Box display="flex" padding="16px 16px 12px 16px">
          {keywordCardItems.map((keywordCardItem) => {
            return (
              <Card key="1" sx={{ margin: "4px" }}>
                <CardContent
                  sx={{
                    padding: "8px 16px",
                    "&:last-child": {
                      paddingBottom: "8px",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      paddingRight="4px"
                      paddingTop="3px"
                    >
                      {keywordCardItem.title}
                    </Typography>
                    <Tooltip
                      title={
                        <Typography
                          sx={{
                            variant: "label",
                            fontSize: "10px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {keywordCardItem.tooltip}
                        </Typography>
                      }
                      slotProps={{
                        tooltip: {
                          sx: {
                            maxWidth: "none",
                          },
                        },
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -8],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <span>
                        <InfoOutlineIcon
                          sx={(theme: Theme) => {
                            return {
                              color: theme.palette.text.secondary,
                              fontSize: "20px",
                              stroke: theme.palette.text.secondary,
                              strokeWidth: 10,
                            };
                          }}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                  <Typography
                    display="flex"
                    justifyContent="center"
                    variant="dataMedium"
                    align="center"
                    color="primary"
                    noWrap
                  >
                    {keywordCardItem.stat}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </Box>
      )}
      <Box
        sx={{
          margin: "1rem",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <SearchBox
          value={searchTerm}
          onClear={() => {
            setSearchTerm("");
          }}
          onChange={handleSearch}
          placeholder="Search subscribers"
          slotProps={{ input: { sx: { height: "40px" } } }}
          sx={{
            display: hideFilters ? "none" : "inline-flex",
            width: isMobile ? "100%" : "calc(100% - 138px)",
            marginRight: "8px",
            paddingBottom: isMobile ? "16px" : "0",
          }}
        />
        <SubscribersDatePicker
          setDateRange={setDateRange}
          isMobile={isMobile}
          isHidden={hideFilters}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: hideFilters ? "0" : "8px",
          }}
        >
          <Button
            sx={{ display: isMobile ? "block" : "none" }}
            onClick={() => setHideFilters(!hideFilters)}
          >
            <Typography variant="button" height="24px">
              {hideFilters ? "Show Filters" : "Hide filters"}
            </Typography>
          </Button>
          {keyword.sequences.length > 0 ? (
            <Chip
              label="Sequence linked"
              variant="outlined"
              onClick={(event) => {
                return setAnchorEl(event.currentTarget);
              }}
              sx={{ marginLeft: "16px" }}
            />
          ) : null}
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {keyword.sequences.map((sequence) => {
            return (
              <MenuItem
                key={sequence.id}
                onClick={() => {
                  handleClick();
                }}
              >
                {sequence.name}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
      <Box
        sx={(theme) => {
          return {
            marginBottom: "1rem",
            marginRight: "-15px",
            borderTop: `1px solid ${theme.palette.divider}`,
            height: isMobile ? "calc(100vh - 450px)" : "calc(100vh - 425px)",
          };
        }}
      >
        <DataGridPro
          columns={columns}
          rows={rows}
          apiRef={apiRef}
          checkboxSelection
          columnBuffer={2}
          slots={{
            toolbar: isSelectionMade ? SubscribersGridToolbar : null,
          }}
          slotProps={{
            baseCheckbox: {
              disabled: isEmpty,
            },
            toolbar: {
              allSelected,
              pagination,
              rowCount,
              selectedRows,
              handleSelectAll,
              handleUnselectAll,
              openQuickCompose,
              toggleAddGroupModal,
              toggleAllSelected,
            },
          }}
          disableRowSelectionOnClick
          getRowId={(row: SequencesContact) => {
            return row.id;
          }}
          columnHeaderHeight={isSelectionMade ? 0 : 60}
          hideFooter
          loading={isLoadingAllSubscribers}
          localeText={{
            noRowsLabel: "",
          }}
          onRowSelectionModelChange={(contactId) => {
            return handleCheck(contactId);
          }}
          onRowsScrollEnd={fetchNextPage}
          sx={{
            height:
              !isLoadingAllSubscribers && isEmpty ? "60px" : "fill-available",
            overflowY: "scroll",
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              display: "none",
            },
          }}
        />
        {!isLoadingAllSubscribers && isEmpty && (
          <BlankState
            dataTestId="keyword-no-subscribers"
            image={<img src={NoNumbersFound} alt="No subscribers found" />}
            subTitle="No subscribers found."
          />
        )}
        <AddContactsToGroupModal
          closeModal={toggleAddGroupModal}
          contacts={
            allSelected
              ? {
                  contactFilterSlug: `sequence:${sequenceId}`,
                  q: getSearchParams(),
                }
              : formattedGroupContacts
          }
          contactTotal={
            allSelected && pagination
              ? pagination.count
              : formattedGroupContacts.length
          }
          visible={isAddGroupModalOpen}
        />
        {/* Quick Compose Modal */}
        <Dialog<string, boolean> ref={dialogRef} maxWidth="sm">
          {({ close }) => {
            return (
              <QuickComposer
                closeModal={() => {
                  return close(true)();
                }}
                fullScreen={fullScreen}
                isActive
                recipientIds={formattedSubscribers}
              />
            );
          }}
        </Dialog>
      </Box>
    </Box>
  );
}
