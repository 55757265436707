import { styled, Box, useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useAutomationNavLinks } from "../hooks/useAutomationNavLinks";
import { AutomationSidebarProps } from "./types";

import PageSidebar from "components/Page/PageSidebar";
import PageSidebarNavLink from "components/Page/PageSidebarNavLink";
import { SecondaryLinks } from "features/Automation/components/SecondaryLinks";
import { AUTOMATION_PATHS } from "features/Automation/constants/AutomationConstants";
import { MobileNavDrawer } from "components/MobileNavDrawer";

function AutomationSidebar({
  keywordAnswers,
  location,
  selectedSequenceNavLink,
  setSelectedSequenceNavLink,
  selectedKeywordNavLink,
  setSelectedKeywordNavLink,
  keywordsMainUrl,
  keywordGroupsUrl,
  sequencesUrl,
  showSidebar,
  toggleSidebar,
}: AutomationSidebarProps) {
  const { keywordsMain, sequences } = AUTOMATION_PATHS;
  const { keywordsEnabled, sequencesEnabled } = useAutomationNavLinks();
  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const sequencesSelected = location.pathname.includes(sequences);
  const keywordsSelected = location.pathname.includes(keywordsMain);

  const AutomationSidebarNavLink = styled(NavLink)(({ theme }) => {
    return {
      alignItems: "center",
      color: theme.palette.text.primary,
      fontSize: "13px",
      display: "flex",
      fontWeight: keywordAnswers ? 400 : 600,
      justifyContent: "space-between",
      textDecoration: "none",
      padding: "0.75rem 1.25rem",

      ":hover": {
        background: theme.palette.action.hover,
      },
    };
  });

  return mobileScreen && keywordAnswers ? (
    <MobileNavDrawer
      open={mobileScreen && showSidebar}
      toggleDrawer={toggleSidebar}
    >
      {keywordsEnabled ? (
        <AutomationSidebarNavLink to={keywordsMainUrl}>
          Keywords
        </AutomationSidebarNavLink>
      ) : null}
      {keywordsEnabled && keywordsSelected ? (
        <SecondaryLinks
          redirectUrl={keywordsMainUrl}
          selectedNavLink={selectedKeywordNavLink}
          setSelectedNavLink={setSelectedKeywordNavLink}
        />
      ) : null}
      {keywordsEnabled && sequencesEnabled ? (
        <Box
          sx={(theme) => {
            return {
              borderBottom: `1px solid ${theme.palette.divider}`,
              height: "1px",
            };
          }}
        />
      ) : null}
      {sequencesEnabled ? (
        <AutomationSidebarNavLink to={sequencesUrl}>
          Sequences
        </AutomationSidebarNavLink>
      ) : null}

      {sequencesSelected && sequencesEnabled ? (
        <SecondaryLinks
          redirectUrl={sequencesUrl}
          selectedNavLink={selectedSequenceNavLink}
          setSelectedNavLink={setSelectedSequenceNavLink}
        />
      ) : null}
    </MobileNavDrawer>
  ) : (
    <PageSidebar>
      {keywordsEnabled && !keywordAnswers && (
        <>
          <PageSidebarNavLink to={keywordsMainUrl}>Keywords</PageSidebarNavLink>
          <PageSidebarNavLink
            to={keywordGroupsUrl}
            data-testid="keyword-subscribers-nav-link"
          >
            Keywords Subscribers
          </PageSidebarNavLink>
        </>
      )}
      {keywordsEnabled && keywordAnswers && (
        <AutomationSidebarNavLink to={keywordsMainUrl}>
          Keywords
        </AutomationSidebarNavLink>
      )}
      {keywordsEnabled && keywordsSelected && keywordAnswers && (
        <SecondaryLinks
          redirectUrl={keywordsMainUrl}
          selectedNavLink={selectedKeywordNavLink}
          setSelectedNavLink={setSelectedKeywordNavLink}
        />
      )}
      {keywordsEnabled && sequencesEnabled && keywordAnswers && (
        <Box
          sx={(theme) => {
            return {
              borderBottom: `1px solid ${theme.palette.divider}`,
              height: "1px",
            };
          }}
        />
      )}
      {sequencesEnabled && (
        <AutomationSidebarNavLink to={sequencesUrl}>
          Sequences
        </AutomationSidebarNavLink>
      )}

      {sequencesSelected && sequencesEnabled && (
        <SecondaryLinks
          redirectUrl={sequencesUrl}
          selectedNavLink={selectedSequenceNavLink}
          setSelectedNavLink={setSelectedSequenceNavLink}
        />
      )}
    </PageSidebar>
  );
}

export default AutomationSidebar;
