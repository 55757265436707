import { textUsApiV4 } from "services";

function batchDeleteContactsV4(
  contactCollectionId: string,
  // note this parameter should be "hashedContactId"[] NOT "/contacts/hashedContactId"[]
  ids?: string[],
) {
  return textUsApiV4.destroy(contactCollectionId, { ids });
}

export { batchDeleteContactsV4 };
