import { textUsApiV3, textUsApiV4 } from "services";

function deleteContactFilter(contactFilterId: string) {
  return textUsApiV3.destroy(contactFilterId);
}

function deleteContactFilterV4(contactFilterId: string) {
  return textUsApiV4.destroy(contactFilterId);
}

export { deleteContactFilter, deleteContactFilterV4 };
