import { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Box, LinearProgress, Typography } from "@mui/material";

import ImportSection from "features/ContactImports/containers/NewContactImport/components/ImportSection";
import Logo from "components/Logo";
import ImportingContacts from "utils/images/importing-contacts.svg";

const Loader = styled(ImportSection)`
  justify-content: center;
`;

class ImportLoader extends Component {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    useHourglass: PropTypes.bool.isRequired,
    state: PropTypes.string.isRequired,
  };

  render() {
    const { currentAccount, useHourglass, state } = this.props;
    const { featureFlags } = currentAccount;

    return (
      <Loader>
        {featureFlags.dedupeContacts && useHourglass ? (
          <Box
            alignItems="center"
            className="importing-contacts-loader"
            display="flex"
            flexDirection="column"
            flexWrap="nowrap"
            justifyContent="center"
            minHeight="60dvh"
          >
            <img
              alt="Importing contacts..."
              src={ImportingContacts}
              style={{
                marginBottom: "24px",
              }}
            />

            <LinearProgress
              sx={{
                width: "200px",
                marginBottom: "24px",
              }}
            />

            <Typography marginBottom="24px" variant="body2">
              Hang tight! We&apos;re pulling in your contacts.
            </Typography>
          </Box>
        ) : (
          <Logo animate width={50} dotColor="primary" color="transparent" />
        )}
      </Loader>
    );
  }
}

export default ImportLoader;
