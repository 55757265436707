import { useLocation } from "react-router-dom";
import queryString from "query-string";
import get from "lodash/get";
import uniq from "lodash/uniq";

import { User } from "@tesseract/core";
import OfficeHoursAndAutoReply from "../components/OfficeHoursAndAutoReply";
import CharacterLimit from "../components/CharacterLimitV2";
import SavedReplies from "../components/SavedReplies";
import { AutomatedSender } from "../components/AutomatedSender";
import { IntroAndAppended } from "../components/IntroAndAppended";

import ConversationExport from "../components/ConversationExport";
import ConversationImport from "../components/ConversationImport";
import InboxSettings from "../components/InboxSettings";

import CampaignSettings from "../components/CampaignSettings";
import FilterContactSettings from "../components/FilterContactSettings";

import LinkStripping from "../components/LinkStripping";
import MmsSettings from "../components/MmsSettings";

import AccountConfiguration from "../components/AccountConfiguration";
import CustomUserSchema from "../components/CustomUserSchema";

import RegisteredLinks from "../components/RegisteredLinks";
import WhiteLabel from "../components/WhiteLabel";

import Integrations from "../containers/Integrations";
import { IntegrationsLandingPage } from "../components/Integrations/components/IntegrationsLandingPage/IntegrationsLandingPage";

import BrandInformation from "../components/BrandInformation";

import ManageOptOuts from "../components/ManageOptOuts";
import { ImportRules } from "../components/ImportRules";
import ScheduledAnalytics from "features/Profile/containers/ScheduledAnalytics";
import ApiTokens from "features/Profile/containers/ApiTokens";

import { Accounts } from "pages/_account/settings/team-management/accounts";
import { Create } from "pages/_account/settings/team-management/accounts/create";
import UserManagement from "pages/UserManagement/Loadable";
import BasicProfile from "features/Profile/containers/BasicProfile";
import Signature from "features/Profile/containers/Signature";
import UserPersonalization from "features/Profile/containers/UserPersonalization";
import ChangePassword from "features/Profile/components/ChangePassword";
import Notifications from "features/Profile/components/Notifications";

import {
  SettingsSectionWithSubSections,
  SettingsSection,
} from "models/SettingsSection";

import getPaginatedId from "utils/getPaginatedId";
import { Account } from "models/Account";

export function getSettingsOptionsV2({
  account,
  currentUser,
  isAdmin,
  role,
}: {
  account: Account;
  currentUser: User.Raw;
  isAdmin: boolean;
  role: string;
}): SettingsSectionWithSubSections[] {
  const featureFlags = account?.featureFlags || {};

  const userSchemaIdsArray = uniq(
    get(currentUser, ["memberships", "members"], [])
      .filter((item) => {
        return item.account.userSchema;
      })
      .map((item) => {
        return item.account.userSchema;
      }),
  );

  const brandRules =
    account?.type === "organization" ||
    account?.type === "partner" ||
    !account?.phoneNumbers?.length;

  const orgOrLegalRules =
    account?.type === "organization" || !!account?.settings?.legalEntity?.value;

  const orgOrBranchRules =
    account?.type === "organization" || !account?.phoneNumbers?.length;

  const orgOrRootOnlyRules =
    account?.type === "organization" || account?.type === "root";

  const canManageAccounts =
    ["organization", "root"].includes(account.type) &&
    ["admin", "support"].includes(role);

  const sections = [
    {
      sectionTitle: "Messaging settings",
      subsections: [
        {
          title: "Messages",
          path: "message",
          settings: [
            {
              title: "Templates",
              to: `/templates`,
              OptionComponent: SavedReplies,
            },
            isAdmin && {
              title: "Intro & appended",
              to: `/intro-appended-message`,
              OptionComponent: IntroAndAppended,
            },
            {
              title: "Office hours & reply",
              to: `/office-hours-and-auto-reply`,
              OptionComponent: OfficeHoursAndAutoReply,
              //  consider doing an alternate return value for office hours page based on feature flag
              // otherwise I'll need to create a new component for the feature flag and copy the contents of the current component
              // experiment with a new component and see how far you can get in 30 minutes
              extraProps: { currentUser, currentAccount: account }, // consider moving into the component
            },
            featureFlags.automatedSender && {
              title: "Automated sender",
              to: `/automated-sender`,
              OptionComponent: AutomatedSender,
            },
          ],
        }, // end Messages
        {
          title: "Inbox",
          path: "inbox",
          settings: [
            {
              title: "Clean-up rules",
              to: `/organization`,
              OptionComponent: InboxSettings,
            },
            {
              title: "Conversation import",
              to: `/conversation-import`,
              OptionComponent: ConversationImport,
            },
            {
              title: "Conversation export",
              to: `/conversation-export`,
              OptionComponent: ConversationExport,
            },
          ],
        }, // end Inbox Settings
        {
          title: "Campaigns",
          path: "campaigns",
          settings: [
            orgOrLegalRules && {
              title: "Cutoff time",
              to: `/campaign-delivery-cutoff-time`,
              OptionComponent: CampaignSettings,
            },
            account.type === "organization" && {
              title: "Contact filtering",
              to: `/contact-filtering`,
              OptionComponent: FilterContactSettings,
            },
          ],
        }, // end Campaign Settings
        {
          title: "Contacts",
          path: "contacts",
          settings: [
            account.featureFlags.contactDeduplication2 && {
              title: "Import rules",
              to: `/import-rules`,
              OptionComponent: ImportRules,
            },
            isAdmin && {
              title: "Manage opt-outs",
              to: `/manage-opt-outs`,
              OptionComponent: ManageOptOuts,
            },
          ],
        }, // end Contacts
        {
          title: "Privacy",
          path: "privacy",
          settings: [
            {
              title: "Character limits",
              to: `/character-limit`,
              OptionComponent: CharacterLimit,
            },
            {
              title: "Link removal",
              to: `/link-removal`,
              OptionComponent: LinkStripping,
            },
            {
              title: "MMS settings",
              to: `/mms-settings`,
              OptionComponent: MmsSettings,
            },
          ],
        }, // end Privacy
      ],
    }, // end Messaging Settings
    isAdmin && {
      sectionTitle: "Organization settings",
      subsections: [
        orgOrBranchRules && {
          title: "Team management",
          path: "team-management",
          settings: [
            canManageAccounts && {
              title: "Accounts",
              to: `/accounts`,
              OptionComponent: Accounts,
            },
            canManageAccounts && {
              title: "Create Account",
              to: `/accounts/create`,
              route: true,
              OptionComponent: Create,
              extraProps: {
                account,
                toggleSidebar: () => {},
              },
            },
            {
              title: "Users",
              to: `/users`,
              // if you see an arrow function on these OptionComponents,
              // it's because WithRecord is here to ruin my day
              // couldn't utilize extraProps without passing location all over the place
              OptionComponent: () => {
                const location = useLocation();
                return (
                  <UserManagement
                    accountId={get(
                      queryString.parse(location.search, {
                        arrayFormat: "bracket",
                      }),
                      ["accounts", 0],
                    )}
                    userCollectionId={getPaginatedId("/users", {
                      location,
                    })}
                  />
                );
              },
            },
            account.type !== "partner" &&
              account.childrenCount > 0 && {
                title: "Licenses",
                to: `/account-configuration`,
                OptionComponent: AccountConfiguration,
              },
            {
              title: "Personalization fields",
              to: `/Personalization-fields`,
              OptionComponent: CustomUserSchema,
            },
          ],
        }, // end Team Management
        brandRules && {
          title: "Brand",
          path: "brand",
          settings: [
            orgOrLegalRules && {
              title: "Shortened Links",
              to: `/registered-links`,
              OptionComponent: RegisteredLinks,
            },
            account.type === "partner" && {
              title: "White label",
              to: "/white-label",
              OptionComponent: WhiteLabel,
              linkProps: {
                sx: { display: { xs: "none", sm: "none", md: "block" } },
              },
            },
          ],
        }, // end Brand
        orgOrRootOnlyRules && {
          title: "Data & compliance",
          path: "data-compliance",
          settings: [
            {
              title: "Registration (10DLC)",
              to: `/brand-registration`,
              OptionComponent: BrandInformation,
            },
            {
              title: "Conversation export",
              to: `/conversation-export`,
              OptionComponent: ConversationExport,
            },
            {
              title: "Manage opt-outs",
              to: `/manage-opt-outs`,
              OptionComponent: ManageOptOuts,
            },
          ],
        }, // end Data & Compliance
        {
          title: "Integrations",
          path: "integrations",
          settings: [
            isAdmin &&
              ((account.type !== "partner" && account.childrenCount > 0) ||
                account.type === "organization") && {
                title: "index",
                to: `/`,
                // Luke TODO: Remove feature flag once IF 1.3 is released
                OptionComponent: account.featureFlags?.integrationsFrameworkFe
                  ? IntegrationsLandingPage
                  : Integrations,
              },
          ],
        }, // end Brand 2
      ],
    }, // end Organization Settings
    {
      sectionTitle: "My settings",
      mySettingsModule: true,
      subsections: [
        {
          title: "My profile",
          path: "profile",
          settings: [
            {
              title: "Basic profile",
              to: `/me`,
              OptionComponent: BasicProfile,
            },
            {
              title: "Signature",
              to: `/signature`,
              OptionComponent: Signature,
            },
            {
              title: "Personalizations",
              to: `/user-personalization`,
              OptionComponent: UserPersonalization,
              extraProps: { userSchemaIdsArray },
            },
            {
              title: "Change password",
              to: `/change-password`,
              OptionComponent: ChangePassword,
            },
          ],
        }, // end My Profile
        {
          title: "My notifications",
          path: "notifications/",
          settings: [
            {
              title: "My notifications",
              to: `/`,
              OptionComponent: Notifications,
            },
          ],
        }, // end My Notifications
        {
          title: "Advanced user settings",
          path: "advanced",
          settings: [
            {
              title: "Scheduled analytics",
              to: `/scheduled-analytics`,
              OptionComponent: ScheduledAnalytics,
              extraProps: {
                isMediumLarge: true,
                currentAccount: account,
                currentUser,
                toggleSidebar: () => {},
              },
            },
            {
              title: "API tokens",
              to: "/api-tokens",
              OptionComponent: ApiTokens,
              extraProps: { userApiTokenCollectionId: "/user_api_tokens" },
            },
          ],
        }, // end Advanced User Settings
      ],
    }, // end My Settings
  ];

  return (
    sections
      .filter(Boolean)
      .map((section) => {
        return {
          ...section,
          // @ts-expect-error it says `false` isn't acceptable for
          // this type, even though they're filtered out
          subsections: section.subsections
            .map((sub: SettingsSection) => {
              return {
                ...sub,
                // Filter out any null settings
                settings: sub.settings?.filter(Boolean) ?? [],
              };
            })
            // Filter out any subsections with no settings
            .filter((sub: SettingsSection) => {
              return sub.settings.length > 0;
            }),
        };
      })
      // Filter out sections with no subsections
      .filter((section) => {
        return section.subsections.length > 0;
      })
      .filter(Boolean) as SettingsSectionWithSubSections[]
  );
}
