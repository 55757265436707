import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { normalize } from "normalizr";
import { Contact } from "@tesseract/core";
import {
  fetchContactTagCollection,
  fetchContactTagCollectionV4,
} from "../api/fetchContactTagCollection";
import * as schema from "schema";
import { UPDATE_RECORDS } from "features/EntryPoint/containers/App/constants";
import { useCurrentAccount } from "hooks";

interface Props {
  contactTagCollectionId: string;
}

function useContactTagCollection({ contactTagCollectionId }: Props) {
  const [contactTagCollection, setContactTagCollection] = useState<
    Contact.TagCollection | Record<string, undefined>
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const { featureFlags } = useCurrentAccount();

  const reshapeV4Response = useCallback(
    (data: any): Contact.TagCollection => {
      return {
        id: contactTagCollectionId as Contact.TagCollectionId,
        totalItems: data.items.length,
        members: data.items.map((item: any) => ({
          "@context": "/contexts/ContactTag.jsonld",
          "@type": "ContactTag",
          id: item.name,
          tag: item.name,
        })),
      };
    },
    [contactTagCollectionId],
  );

  const sendFetchRequest = useCallback(async () => {
    if (!contactTagCollectionId) return;

    setIsLoading(true);

    let data: Contact.TagCollection | Record<string, undefined>;

    if (featureFlags.newContactSearch) {
      const res2 = await fetchContactTagCollectionV4(contactTagCollectionId);
      const rawData = await res2.json();
      data = reshapeV4Response(rawData);
    } else {
      const res = await fetchContactTagCollection(contactTagCollectionId);
      data = await res.json();
    }

    // update data for use in component
    setContactTagCollection(data);

    // normalize data & dispatch to redux store
    const { entities } = normalize(data, schema.contactTags);
    setIsLoading(false);

    dispatch({
      type: UPDATE_RECORDS,
      records: entities,
    });
  }, [
    contactTagCollectionId,
    dispatch,
    reshapeV4Response,
    featureFlags.newContactSearch,
  ]);

  useEffect(() => {
    void sendFetchRequest();
  }, [sendFetchRequest, contactTagCollectionId]);

  return {
    contactTagCollection,
    sendFetchRequest,
    isLoading,
    isError,
    error,
  };
}

export { useContactTagCollection };
