/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import { Component, createRef, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import startCase from "lodash/startCase";
import uniq from "lodash/uniq";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  NativeSelect,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { ChevronLeft, ChevronRight, InfoOutlined } from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";

import { capitalize } from "lodash";
import PageFooter from "components/Page/PageFooter";
import { useResize } from "features/Sequences/hooks/useResize";

const CONTACT_ATTRIBUTE_OPTIONS = [
  { value: "", label: "[Do not import]", matcher: /^(?![\s\S])/i },
  { value: "firstName", label: "First Name", matcher: /first.*name/i },
  { value: "lastName", label: "Last Name", matcher: /last.*name/i },
  { value: "name", label: "Full Name", matcher: /(full.*name|^name$)/i },
  { value: "phoneNumber", label: "Number", matcher: /phone/i },
  { value: "business", label: "Business", matcher: /business|company/i },
  { value: "tags", label: "Tags", matcher: /tag/i },
];

const Footer = styled("footer")({
  margin: 0,
  textAlign: "right",
  width: "100%",
});

const SummaryRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: "15px 0",
});

const Errors = styled("ul")(({ theme }) => ({
  color: theme.palette.error.main,
}));

const Error = styled("li")``;

function ColumnWrapper({ columnIndex, children }) {
  return (
    <Box
      className={`column-${columnIndex}`}
      sx={{
        flex: "1 1 auto",
        minWidth: { xs: "100%", sm: "144px" },
      }}
    >
      {children}
    </Box>
  );
}

function SelectHeader({ assignedHeaders, handleHeaderChange, columnIndex }) {
  return (
    <Box
      sx={{
        margin: { xs: "0 auto", sm: "0" },
        padding: "0 8px",
        width: { xs: "184px", sm: "100%" },
      }}
    >
      <FormControl
        variant="standard"
        sx={{
          width: "100%",
        }}
      >
        <FormLabel htmlFor={`select--${columnIndex}`} sx={visuallyHidden}>
          Column Select {columnIndex}
        </FormLabel>
        <NativeSelect
          id={`select--${columnIndex}`}
          data-testid={`select--${columnIndex}`}
          aria-label={`select--${columnIndex}`}
          input={<OutlinedInput />}
          name="column"
          onChange={(event) => {
            return handleHeaderChange(event, {
              dataIndex: columnIndex,
            });
          }}
          value={assignedHeaders[columnIndex]}
        >
          {CONTACT_ATTRIBUTE_OPTIONS.map(({ value, label }) => {
            return (
              <option value={value} key={`select--${columnIndex}--${value}`}>
                {label}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>
    </Box>
  );
}

function RowWrapper({ children }) {
  return (
    <Box
      sx={{
        borderBottom: "1px solid #e0e0e0",
        padding: "9px 16px",
        minHeight: "2.7rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </Box>
  );
}

function ColumnHeader({ fromExtension, title }) {
  if (fromExtension) {
    return null;
  }
  return (
    <RowWrapper>
      <Typography variant="tableHeader" color="text.primary">
        {capitalize(title)}
      </Typography>
    </RowWrapper>
  );
}

function ColumnData({ previewRows, columnIndex }) {
  return previewRows.map((preview, rowIndex) => {
    return (
      <RowWrapper
        className={`column-${columnIndex}-row-${rowIndex}`}
        key={`column-${columnIndex}-row-${rowIndex}`}
      >
        {preview[columnIndex]}
      </RowWrapper>
    );
  });
}

function MobileSwipers({ tableRef }) {
  const [position, setPosition] = useState(0);
  const rightEdge = tableRef.current.scrollWidth - tableRef.current.clientWidth;

  const { windowWidth } = useResize();

  const onSwipeClick = (direction) => {
    const swipeDistance = windowWidth - 32; // Padding
    const newPosition = tableRef.current.scrollLeft + direction * swipeDistance;
    tableRef.current.scrollTo({
      left: newPosition,
      behavior: "smooth",
    });

    setPosition(newPosition);
  };

  return (
    <Box
      sx={{
        display: { xs: "flex", sm: "none" },
        height: "40px",
        justifyContent: "space-between",
        position: "absolute",
        top: "70px",
        left: 0,
        right: 0,
      }}
    >
      <IconButton
        onClick={() => {
          return onSwipeClick(-1);
        }}
        aria-label="Swipe left"
        disabled={position === 0}
      >
        <ChevronLeft />
      </IconButton>

      <IconButton
        onClick={() => {
          return onSwipeClick(1);
        }}
        aria-label="Swipe right"
        disabled={position === rightEdge}
      >
        <ChevronRight />
      </IconButton>
    </Box>
  );
}

export default class ImportPreviewRedesign extends Component {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    fromExtension: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func,
    isUploading: PropTypes.bool.isRequired,
    header: PropTypes.array,
    previewRows: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    withHeaders: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
      linkToTOS:
        props.currentAccount.type === "partner"
          ? "some-link"
          : "some-other-link",
      isTOSAccepted: false,
      assignedHeaders: props.header.map((header) => {
        const assignedAttr = CONTACT_ATTRIBUTE_OPTIONS.find(({ matcher }) => {
          return matcher.test(header);
        });
        return assignedAttr ? assignedAttr.value : "";
      }),
      tableRefSet: false,
    };
    this.tableRef = createRef();
  }

  componentDidMount() {
    this.checkRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tableRefSet !== this.state.tableRefSet) {
      this.checkRef();
    }
  }

  getErrors = () => {
    const { assignedHeaders } = this.state;
    const nameAssigned = assignedHeaders.some((h) => {
      return ["firstName", "lastName", "name"].includes(h);
    });
    const phoneAssigned = assignedHeaders.includes("phoneNumber");
    const duplicates = uniq(
      assignedHeaders
        .filter((header) => {
          return header !== "";
        })
        .filter((header) => {
          return (
            assignedHeaders.indexOf(header) !==
            assignedHeaders.lastIndexOf(header)
          );
        }),
    );
    let errors = [];
    if (!nameAssigned) {
      errors = [...errors, "Please select a name column"];
    }
    if (!phoneAssigned) {
      errors = [...errors, "Please select a phone number column"];
    }
    if (duplicates.length > 0) {
      errors = [
        ...errors,
        ...duplicates.map((duplicate) => {
          return `Please select only one ${startCase(duplicate)}`;
        }),
      ];
    }
    if (
      (assignedHeaders.includes("name") &&
        (assignedHeaders.includes("firstName") ||
          assignedHeaders.includes("lastName"))) ||
      (!assignedHeaders.includes("firstName") &&
        assignedHeaders.includes("lastName")) ||
      (assignedHeaders.includes("firstName") &&
        !assignedHeaders.includes("lastName"))
    ) {
      errors = [
        ...errors,
        "Please select both a first and last name or a full name",
      ];
    }
    return errors;
  };

  handleHeaderChange = (event, { dataIndex }) => {
    const { assignedHeaders } = this.state;
    this.setState({
      assignedHeaders: assignedHeaders.map((header, index) => {
        return index === dataIndex ? event.target.value : header;
      }),
    });
  };

  handleTOSChange = (event) => {
    this.setState({ isTOSAccepted: event.target.checked });
  };

  handleSubmit = () => {
    if (
      this.props.currentAccount.featureFlags?.dedupeContacts &&
      !this.state.isTOSAccepted
    ) {
      this.setState({ formSubmitted: true });
      return;
    }
    this.props.handleSubmit(this.state.assignedHeaders);
  };

  checkRef() {
    // Check if the ref is not null and update state
    if (this.tableRef.current) {
      this.setState({ tableRefSet: true });
    } else {
      this.setState({ tableRefSet: false });
    }
  }

  render() {
    const {
      fromExtension,
      header,
      isUploading,
      previewRows,
      total,
      withHeaders,
      currentAccount,
    } = this.props;
    const {
      assignedHeaders,
      isTOSAccepted,
      formSubmitted,
      linkToTOS,
      tableRefSet,
    } = this.state;

    const { featureFlags } = currentAccount;
    const directions = fromExtension
      ? "Please verify the contact names and numbers below"
      : "Please map each column of your spreadsheet to one of the contact attributes.";
    const errors = this.getErrors();
    const isTOAError = formSubmitted && !isTOSAccepted;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: "1rem", md: "2rem" },
          padding: { xs: "1rem", sm: "2rem" },
        }}
      >
        {featureFlags.dedupeContacts && (
          <Alert
            icon={<InfoOutlined />}
            severity="info"
            sx={{
              width: "1000px",
              maxWidth: "100%",
              padding: "1rem",
              borderRadius: "4px",
            }}
          >
            <AlertTitle>Prevent duplicate contacts</AlertTitle>
            If a contact with a matching primary phone number already exists,
            imported data will <strong>replace</strong> existing information.
            Empty imported fields will preserve existing information.
          </Alert>
        )}
        <Box
          sx={{
            width: "800px",
            maxWidth: "100%",
            position: "relative",
          }}
        >
          <Box>
            {tableRefSet && <MobileSwipers tableRef={this.tableRef} />}

            {withHeaders && (
              <Typography variant="h6">Review contact attributes</Typography>
            )}

            <Typography
              variant="body2"
              sx={{
                marginBottom: "16px !important",
              }}
            >
              {directions}
            </Typography>

            <Box
              className="table-wrapper"
              sx={{
                display: "flex",
                flexFlow: "row nowrap",
                overflowX: { xs: "hidden", sm: "auto" },
              }}
              ref={this.tableRef}
            >
              {header.map((title, columnIndex) => {
                return (
                  <ColumnWrapper
                    key={`column-${columnIndex}`}
                    columnIndex={columnIndex}
                  >
                    <SelectHeader
                      assignedHeaders={assignedHeaders}
                      handleHeaderChange={this.handleHeaderChange}
                      columnIndex={columnIndex}
                    />

                    <ColumnHeader fromExtension={fromExtension} title={title} />

                    <ColumnData
                      previewRows={previewRows}
                      columnIndex={columnIndex}
                    />
                  </ColumnWrapper>
                );
              })}
            </Box>

            <Footer>
              <SummaryRow>
                <Typography variant="caption" color="text.secondary">
                  Previewing {previewRows.length} of {total} rows
                </Typography>
                {errors.length > 0 && (
                  <Errors data-testid="error-displays">
                    {errors.map((error) => {
                      return <Error key={error}>{error}</Error>;
                    })}
                  </Errors>
                )}
              </SummaryRow>
              {featureFlags.dedupeContacts ? (
                <SummaryRow>
                  <FormControlLabel
                    data-testid="contact-import-tos-wrapper"
                    control={
                      <Checkbox
                        value={isTOSAccepted}
                        onClick={this.handleTOSChange}
                        sx={(theme) =>
                          isTOAError ? { color: theme.palette.error.main } : {}
                        }
                        inputProps={{
                          "aria-label": "TOS Checkbox",
                        }}
                        data-testid="contact-import-tos-checkbox"
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={(theme) => ({
                          ...(isTOAError
                            ? { color: theme.palette.error.main }
                            : {}),
                          textAlign: "left",
                        })}
                      >
                        By importing these contacts, I agree to the{" "}
                        <a target="_blank" rel="noreferrer" href={linkToTOS}>
                          Terms and Conditions
                        </a>{" "}
                        and confirm that I have received consent to message the
                        contacts that I am importing.
                      </Typography>
                    }
                  />
                </SummaryRow>
              ) : null}
            </Footer>
          </Box>
          <PageFooter>
            <Button
              className="cancel-button"
              color="primary"
              onClick={this.props.handleCancel}
            >
              Cancel
            </Button>

            {!isUploading && (
              <Button
                data-testid="import-button"
                className="submit-button"
                variant="outlined"
                color="primary"
                onClick={this.handleSubmit}
                disabled={errors.length > 0}
              >
                Import
              </Button>
            )}
          </PageFooter>
        </Box>
      </Box>
    );
  }
}
