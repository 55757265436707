/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { upperCase } from "lodash";
import { enqueueSnackbar } from "notistack";
import { useLocation, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Tabs,
  Tab,
  Theme,
  Tooltip,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import { archiveKeyword, updateKeyword, fetchKeyword } from "../api";
import SubKeywordCard from "./SubKeywordCard";
import CardCarousel from "./CardCarousel";
import Subscribers from "./Subscribers";
import KeywordConfirmationModal from "./KeywordConfirmationModal";

import PageHeader from "components/Page/PageHeader";
import {
  KeywordProps,
  KeywordObject,
  SubKeyword,
} from "features/Keywords/types";
import { DirectoryTree } from "components/DirectoryTree";
import { useCurrentAccount } from "hooks";
import {
  ArchiveFilledIcon,
  AttachFileIcon,
  EditIcon,
  InfoOutlineIcon,
  UnarchiveFilledIcon,
} from "icons";
import { EFFECTIVE_ROLES } from "features/Accounts/constants";
import { ACCOUNT_SETTINGS_KEYS } from "features/AccountSettings/constants/settingsKeys";

const renderSnackbar = (success: boolean, verb: string) => {
  return success // response.ok
    ? enqueueSnackbar(`Keyword automation successfully ${verb}.`, {
        variant: "info",
      })
    : enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
};

function Keyword({ currentAccount, keywordId, toggleSidebar }: KeywordProps) {
  const location = useLocation();

  const [keyword, setKeyword] = useState<KeywordObject | null>(null);
  const [selectedTab, setSelectedTab] = useState(
    location.search.includes("tab=1") ? 1 : 0,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const actionType = keyword?.deletedAt ? "reactivate" : "archive";

  const handleFetchAutomationKeywordById = async () => {
    try {
      const response = await fetchKeyword(currentAccount, keywordId);
      const json = await response.json();

      setKeyword(json);
    } catch (error) {
      console.error(error);
    }
  };

  const localStorageKey = `HIDE_${upperCase(actionType)}_KEYWORD_MODAL`;
  const skipDialog = localStorage.getItem(localStorageKey) === "true";

  /* EFFECTS */
  useEffect(() => {
    void handleFetchAutomationKeywordById();
  }, [keywordId, currentAccount]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = Number.parseInt(queryParams.get("tab") || "0", 10);
    setSelectedTab(tab);
  }, [location.search]);

  /* HOOKS */
  const { effectiveRole, settings, name: accountName } = useCurrentAccount();
  const history = useHistory();

  const isMobile = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  /* FUNCTIONS */
  const getTotalAnswered = (subKeywords: SubKeyword[]) => {
    return subKeywords?.reduce((acc, subKeyword) => {
      return acc + subKeyword.answeredCount;
    }, 0);
  };

  const hasLink = (messageBody: string) => {
    const regex =
      /(\bhttps?|ftp):\/\/[^\s/$.?#].[^\s]*\b|^(www\.)[^\s/$.?#].[^\s]*$/;
    return regex.test(messageBody);
  };

  const handleTabChange = () => {
    const newTab = selectedTab === 0 ? 1 : 0;
    history.push({
      pathname: location.pathname,
      search: `?tab=${newTab}`,
    });
    return setSelectedTab(newTab);
  };

  const handleEditClick = () => {
    history.push(
      `/${currentAccount.slug}/automation/keywords/${keywordId}/edit`,
    );
  };

  const handleConfirm = async () => {
    if (actionType === "archive") {
      return archiveKeyword(currentAccount, keywordId).then((response) => {
        renderSnackbar(response.ok, `${actionType}d`);
        setIsModalOpen(false);
        void handleFetchAutomationKeywordById();
        return null;
      });
    }
    return updateKeyword(currentAccount, keywordId, {
      active: true,
    }).then((response) => {
      renderSnackbar(response.ok, `${actionType}d`);
      setIsModalOpen(false);
      void handleFetchAutomationKeywordById();
      return null;
    });
  };

  const handleOpenModal = () => {
    if (skipDialog) {
      handleConfirm().catch((error) => {
        // Error snackbar is rendered inside handleConfirm() function
        // This is an added layer for devs to see the error in console
        console.error(
          `Error occurred trying to ${actionType} keyword: ${error}`,
        );
      });
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  /* VARIABLES */
  const settingKey = ACCOUNT_SETTINGS_KEYS.keywordsLock;
  const { locked = false } = settings[settingKey];
  const userLocked = locked && effectiveRole === EFFECTIVE_ROLES.user;
  const subKeywords = keyword?.subKeywords || [];
  const messageHasLink = hasLink(keyword?.responseBody || "");
  const writeAccess = accountName === keyword?.createdBy;
  const tooltipTitle = userLocked
    ? "Disabled by admin"
    : writeAccess
      ? ""
      : "Keyword belongs to a different account.";

  const totalAnsweredCount = keyword?.subKeywords
    ? getTotalAnswered(keyword.subKeywords)
    : 0;

  const percentClicked =
    keyword && keyword.timesReceived !== 0
      ? (keyword.clickedLinkCount / keyword.timesReceived) * 100
      : 0;

  /* COMPONENTS AND DISPLAY DATA */
  const keywordCardItems =
    subKeywords.length > 0
      ? [
          {
            title: "Subscribers",
            stat: keyword?.timesReceived,
            tooltip: `The number of subscribers who responded with ${keyword?.keyword}.`,
          },
          {
            title: "Total Answers",
            stat: totalAnsweredCount,
            tooltip:
              "The total number of subscribers who responded with a keyword answer.",
          },
          {
            title: "Opted out",
            stat: keyword?.optedOutCount,
            tooltip:
              "The total number of subscribers who chose to opt-out after receiving an auto-response.",
          },
        ]
      : [
          {
            title: "Subscribers",
            stat: keyword?.timesReceived,
            tooltip: `The number of subscribers who responded with ${keyword?.keyword}.`,
          },
          {
            title: "Opted out",
            stat: keyword?.optedOutCount,
            tooltip:
              "The total number of subscribers who chose to opt-out after receiving an auto-response.",
          },
        ];

  const statCardArray = keywordCardItems.map((keywordCardItem) => {
    return (
      <Card key="1" sx={{ margin: "4px" }} data-testid={keywordCardItem.title}>
        <CardContent
          sx={{
            padding: "8px 16px",
            "&:last-child": {
              paddingBottom: "8px",
            },
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              variant="overline"
              color="textSecondary"
              paddingRight="4px"
              paddingTop="3px"
            >
              {keywordCardItem.title}
            </Typography>
            <Tooltip
              title={
                <Typography
                  variant="caption"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {keywordCardItem.tooltip}
                </Typography>
              }
              slotProps={{
                tooltip: {
                  sx: {
                    maxWidth: "none",
                  },
                },
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -8],
                      },
                    },
                  ],
                },
              }}
            >
              <span>
                <InfoOutlineIcon
                  sx={(theme: Theme) => {
                    return {
                      color: theme.palette.text.secondary,
                      fontSize: "20px",
                      stroke: theme.palette.text.secondary,
                      strokeWidth: 10,
                    };
                  }}
                />
              </span>
            </Tooltip>
          </Box>
          <Typography
            display="flex"
            justifyContent="center"
            variant="dataMedium"
            align="center"
            color="primary"
            noWrap
          >
            {keywordCardItem.stat}
          </Typography>
        </CardContent>
      </Card>
    );
  });

  const childComponentArray = subKeywords?.map((subKeyword) => {
    return (
      <SubKeywordCard
        data-testid="sub-keyword-card"
        key={subKeyword.keyword}
        hasLink={hasLink}
        keyword={keyword}
        subKeyword={subKeyword}
        isMobile={isMobile}
      />
    );
  });

  const icon = <KeyIcon sx={{ fontSize: 20 }} />;

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      data-testid="keyword"
    >
      <PageHeader
        title={
          <Breadcrumbs>
            <Link
              to={`/${currentAccount.slug}/automation/keywords`}
              style={{ textDecoration: "none", color: "inherit" }}
              data-testid="breadcrumb-link"
            >
              <Typography>Keywords</Typography>
            </Link>
            <Typography color="text.hyperlink" data-testid="keyword-breadcrumb">
              {keyword?.keyword}
            </Typography>
          </Breadcrumbs>
        }
        toggleSidebar={toggleSidebar}
      />
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={(theme: Theme) => {
          return {
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingLeft: "16px",
            display: "flex",
            alignItems: "center",
          };
        }}
      >
        <Tab
          label="Overview"
          sx={{ padding: isMobile ? "12px 12px" : "12px 16px" }}
          data-testid="overview-tab"
        />
        <Tab
          label="Subscribers"
          sx={{ padding: isMobile ? "12px 12px" : "12px 16px" }}
          data-testid="subscribers-tab"
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ width: "100%", paddingRight: "16px" }}
        >
          {keyword?.deletedAt ? (
            <Tooltip
              title={writeAccess && isMobile ? "Reactivate" : tooltipTitle}
            >
              <span>
                {isMobile ? (
                  <IconButton size="small" onClick={handleOpenModal}>
                    <UnarchiveFilledIcon />
                  </IconButton>
                ) : (
                  <Button
                    variant="outlined"
                    disabled={userLocked || !writeAccess}
                    onClick={handleOpenModal}
                    sx={{
                      height: "36px",
                      padding: "6px 16px",
                      marginRight: "8px",
                    }}
                    data-testid="reactivate-button"
                  >
                    Reactivate
                  </Button>
                )}
              </span>
            </Tooltip>
          ) : (
            <>
              <Tooltip title={writeAccess && isMobile ? "Edit" : tooltipTitle}>
                <span>
                  {isMobile ? (
                    <IconButton
                      size="small"
                      data-testid="edit-button"
                      onClick={handleEditClick}
                      disabled={userLocked || !writeAccess}
                    >
                      <EditIcon />
                    </IconButton>
                  ) : (
                    <Button
                      variant="outlined"
                      disabled={userLocked || !writeAccess}
                      onClick={handleEditClick}
                      sx={{
                        height: "36px",
                        padding: "6px 16px",
                        marginRight: "8px",
                      }}
                      data-testid="edit-button"
                    >
                      Edit
                    </Button>
                  )}
                </span>
              </Tooltip>
              <Tooltip
                title={writeAccess && isMobile ? "Archive" : tooltipTitle}
              >
                <span>
                  {isMobile ? (
                    <IconButton
                      size="small"
                      color="error"
                      data-testid="archive-button"
                      onClick={handleOpenModal}
                      disabled={userLocked || !writeAccess}
                    >
                      {" "}
                      <ArchiveFilledIcon />{" "}
                    </IconButton>
                  ) : (
                    <Button
                      variant="outlined"
                      disabled={userLocked || !writeAccess}
                      onClick={handleOpenModal}
                      sx={(theme) => {
                        return {
                          height: "36px",
                          padding: "6px 16px",
                          color: theme.palette.error.main,
                          borderColor: theme.palette.error.main,
                        };
                      }}
                      data-testid="archive-button"
                    >
                      Archive
                    </Button>
                  )}
                </span>
              </Tooltip>
            </>
          )}
        </Box>
      </Tabs>
      {selectedTab === 0 ? (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            paddingTop: "16px",
            overflowY: "auto",
            overflowX: "hidden",
            height: "100%",
          }}
        >
          <Box
            display="flex"
            justifyContent={isMobile ? "center" : "flex-start"}
            alignItems="center"
            padding="16px"
            paddingTop="0px"
            paddingBottom={isMobile ? "16px" : "50px"}
            flexDirection="row"
            width="100%"
          >
            {isMobile ? (
              <CardCarousel cards={keywordCardItems} />
            ) : (
              statCardArray
            )}
          </Box>
          <Box
            padding="0px 16px"
            width="100%"
            maxWidth="760px"
            minWidth="320px"
          >
            <DirectoryTree
              icon={icon}
              iconPadding="16px"
              rootComponent={
                <Box
                  sx={{
                    alignItems: "flex-start",
                    border: "1px solid #0000001F",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    maxWidth: "760px",
                    minWidth: "200px",
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: "1px solid #0000001F",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        display: "flex",
                        height: "56px",
                        padding: "16px",
                        width: "100%",
                      }}
                    >
                      {isMobile
                        ? keyword?.keyword
                        : `Keyword: ${keyword?.keyword}`}
                    </Typography>
                    <Typography
                      sx={(theme) => {
                        return {
                          alignItems: "flex-end",
                          color: theme.palette.text.secondary,
                          display: "flex",
                          fontSize: "13px",
                          padding: "16px",
                          whiteSpace: "nowrap",
                        };
                      }}
                    >
                      {keyword?.timesReceived} subscribers
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      padding: "4px 16px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "620px",
                        overflow: "hidden",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          height: "52px",
                          maxWidth: "620px",
                          overflow: "hidden",
                          width: "100%",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            display: "-webkit-box",
                            fontSize: "16px",
                            lineHeight: "1.5",
                            width: "100%",
                            color: "text.secondary",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            overflowWrap: "break-word",
                          }}
                        >
                          {keyword?.responseBody}
                        </Typography>
                      </Box>
                      {keyword?.attachments?.length ? (
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            height: "20px",
                            maxWidth: "620px",
                            overflow: "hidden",
                            width: "100%",
                            marginBottom: "4px",
                          }}
                        >
                          <AttachFileIcon
                            fontSize="small"
                            sx={{ color: "text.secondary" }}
                          />
                          <Typography
                            variant="caption"
                            sx={{ fontSize: "12px", color: "text.secondary" }}
                          >
                            {keyword?.attachments[0].originalFilename}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flex: "1 1 auto",
                        justifyContent: "flex-end",
                        paddingLeft: "24px",
                      }}
                    >
                      {messageHasLink ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "16px",
                          }}
                        >
                          <Box textAlign="center">
                            <Typography
                              sx={(theme) => {
                                return {
                                  color: theme.palette.primary.main,
                                  fontSize: "16px",
                                  fontWeight: 700,
                                };
                              }}
                            >
                              {percentClicked}%
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={(theme) => {
                                return {
                                  alignSelf: "stretch",
                                  color: theme.palette.text.secondary,
                                  whiteSpace: "nowrap",
                                  fontWeight: 500,
                                };
                              }}
                            >
                              Clicked links
                            </Typography>
                          </Box>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              }
              childComponents={childComponentArray}
            />
          </Box>
        </Box>
      ) : keyword ? (
        <Subscribers
          keyword={keyword}
          currentAccount={currentAccount}
          sequenceId={keyword?.sequences?.[0]?.id}
        />
      ) : null}
      <KeywordConfirmationModal
        actionType={actionType}
        isOpen={isModalOpen}
        cancellationHandler={handleCloseModal}
        confirmationHandler={handleConfirm}
        localStorageKey={localStorageKey}
      />
    </Box>
  );
}

export default Keyword;
