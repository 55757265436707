import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import AccountSetting from "./AccountSetting";
import { MmsSettingsValidationSchema } from "formHelpers/validationSchemas";

function MmsSettings(props) {
  const getDescription = () => {
    return (
      <>
        <Typography variant="body2">
          Attachments are enabled by default for all accounts. If necessary for
          compliance or legal requirements, you have the option to deactivate
          this feature to prevent attachments from being sent in either or both
          directions.
        </Typography>

        <Typography variant="body2">
          Disabling inbound attachments can be advantageous in preventing
          contacts from sending sensitive materials such as credit card or
          personally identifiable information through image attachments.
          Similarly, disabling outbound attachments can aid in preventing
          employees from sending inappropriate images or sensitive materials to
          contacts.
        </Typography>
      </>
    );
  };

  const getRender = (label) => {
    return () => {
      return function ({ field, form }) {
        return (
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={!field.value}
                onChange={(event) => {
                  const { checked } = event.target;
                  form.setFieldValue(field.name, !checked);
                }}
                color="secondary"
              />
            }
            label={label}
          />
        );
      };
    };
  };

  return (
    <AccountSetting
      {...props}
      description={getDescription()}
      settings={[
        {
          default: false,
          name: "inboundMmsDisabled",
          render: getRender("Allow inbound attachments"),
        },
        {
          default: false,
          name: "outboundMmsDisabled",
          render: getRender("Allow outbound attachments"),
        },
      ]}
      title="MMS settings"
      validationSchema={MmsSettingsValidationSchema}
    />
  );
}

MmsSettings.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

export default MmsSettings;
