/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState, Dispatch, SetStateAction } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Popover,
} from "@mui/material";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { subscriberDateRanges } from "./SubscribersDropdownDates";

export default function SubscribersDatePicker({
  setDateRange,
  isMobile,
  isHidden,
}: {
  setDateRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  isMobile: boolean;
  isHidden: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [selectedMenuDate, setSelectedMenuDate] = useState<
    [Date | null, Date | null] | string
  >("All time");

  const [customDisplayDate, setCustomDisplayDate] =
    useState<string>("Custom range");

  const [selectedRange, setSelectedRange] = useState<
    [Date | null, Date | null]
  >([null, null]);

  /* EFFECTS */
  useEffect(() => {
    if (selectedRange[0] === null && selectedRange[1] === null) {
      setCustomDisplayDate("Custom range");
      setSelectedMenuDate("All time");
    }
  }, [selectedRange]);

  /* FUNCTIONS */
  function formatDateRange(dates: [Date | null, Date | null]): string {
    const formatDate = (date: Date) => {
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    };

    const startDate = dates[0] ? formatDate(dates[0]) : "Start";
    const endDate = dates[1] ? formatDate(dates[1]) : "End";

    return `${startDate} - ${endDate}`;
  }

  /* VARIABLES */
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  /* HANDLERS */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    startDate: Date | null,
    endDate: Date | null,
  ) => {
    setCustomDisplayDate("Custom range");
    setSelectedRange([startDate, endDate]);
    setDateRange([startDate, endDate]);
  };

  const handlePopoverClick = () => {
    const textFieldAnchor = document.querySelector("#dateRangeTextField");
    if (textFieldAnchor) {
      setAnchorEl(textFieldAnchor);
    }
    if (customDisplayDate === "Custom range") {
      setSelectedRange([null, null]);
      setDateRange([null, null]);
    }
  };

  const handleAccept = () => {
    setSelectedMenuDate(formatDateRange(selectedRange));
    setCustomDisplayDate(formatDateRange(selectedRange));
    handleClose();
  };

  const handleDateRangeChange = (newValue: [Date | null, Date | null]) => {
    setSelectedRange(newValue);
    setDateRange(newValue);
    setSelectedMenuDate(formatDateRange(newValue));
    setCustomDisplayDate(formatDateRange(newValue));
  };

  const handlePopoverClose = () => {
    setSelectedRange([null, null]);
    setDateRange([null, null]);
    setAnchorEl(null);
  };

  const handleClear = () => {
    setSelectedRange([null, null]);
    setDateRange([null, null]);
    handlePopoverClose();
  };

  /* RENDER FUNCTIONS */
  const renderDateRangeOptions = (): JSX.Element[] => {
    const rangeDropDown: JSX.Element[] = subscriberDateRanges.map((range) => {
      return (
        <MenuItem
          value={range.value}
          key={range.key}
          sx={{
            padding: "6px 16px",
            height: "40px",
            width: isMobile ? "auto" : "235px",
          }}
          onClick={(event) => {
            handleMenuItemClick(range.startDate, range.endDate);
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          {range.value}
        </MenuItem>
      );
    });
    return rangeDropDown;
  };

  function CustomButtons() {
    return (
      <Box display="flex" justifyContent="flex-end" padding="8px 0px">
        <Button onClick={handleClear}>Cancel</Button>
        <Button onClick={handleAccept}>OK</Button>
      </Box>
    );
  }

  return (
    <Box
      data-testid="subscribers-date-picker"
      sx={{ display: isHidden ? "none" : "flex" }}
    >
      <Popover
        id={id}
        open={open}
        onClose={handlePopoverClose}
        disableRestoreFocus
        anchorEl={anchorEl}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDateRangePicker
            onChange={handleDateRangeChange}
            onAccept={handleAccept}
            disableFuture
            slots={{
              actionBar: CustomButtons,
            }}
            defaultValue={selectedRange}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          />
        </LocalizationProvider>
      </Popover>
      <Box
        sx={{
          padding: "0px",
          width: isMobile ? "100%" : "235px",
          marginLeft: isMobile ? "0px" : "16px",
          marginRight: "0px",
        }}
      >
        <TextField
          defaultValue="All time"
          select
          label="Date range"
          onChange={(event) => {
            setSelectedMenuDate(event.target.value);
          }}
          id="dateRangeTextField"
          value={selectedMenuDate}
          slotProps={{
            input: { sx: { height: "40px" } },
          }}
          sx={{
            padding: "0px",
            width: "100%",
          }}
        >
          <MenuItem
            value={customDisplayDate}
            key={customDisplayDate}
            onClick={() => handlePopoverClick()}
            sx={{
              padding: "6px 16px",
              height: "40px",
              width: "auto",
            }}
          >
            {customDisplayDate === "Custom range" ? (
              <Box display="flex" width="100%" justifyContent="space-between">
                {customDisplayDate}
                <ChevronRightIcon
                  sx={(theme) => {
                    return { color: theme.palette.text.secondary };
                  }}
                />
              </Box>
            ) : (
              <Typography
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                variant="h6"
              >
                {customDisplayDate}
              </Typography>
            )}
          </MenuItem>
          {renderDateRangeOptions()}
        </TextField>
      </Box>
    </Box>
  );
}
