/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { GridStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-pro";
import { Contact } from "@tesseract/core";
import { SubscribersPage } from "../types";
import { fetchKeywordSubscribers } from "../api";
import { Account as AccountType } from "models/Account";
import { useDialog } from "components/Dialog";

const useSubscribers = (currentAccount: AccountType, keywordId: string) => {
  const [rows, setRows] = useState<any[]>([]);
  const [pagination, setPagination] = useState<SubscribersPage>({
    firstUrl: "",
    prevUrl: "",
    pageUrl: "",
    nextUrl: "",
    lastUrl: "",
    count: 0,
    page: 0,
    pages: 0,
    items: 0,
    from: 0,
    to: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Contact.Raw[]>([]);
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);

  const selectedRowCount = selectedRows.length;
  const isSelectionMade = selectedRowCount > 0;
  const rowCount = rows.length;
  const isEmpty = rowCount === 0;

  const fullScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up("md");
  });
  const apiRef = useGridApiRef();
  const { ref: dialogRef, open: openQuickCompose } = useDialog<
    string,
    boolean
  >();

  const gridSelectAll = useCallback(() => {
    const rowContactIds = rows.map((row) => {
      return row.id;
    });
    apiRef.current.setState((prevState: GridStatePro) => {
      return { ...prevState, rowSelection: rowContactIds };
    });
  }, [apiRef, rows]);

  const handleSelectAll = () => {
    setSelectedRows(rows);
    gridSelectAll();
  };

  const handleUnselectAll = useCallback(() => {
    setSelectedRows([]);
    setAllSelected(false);
    apiRef.current.setState((prevState: GridStatePro) => {
      return { ...prevState, rowSelection: [] };
    });
  }, [apiRef]);

  const toggleAllSelected = () => {
    handleSelectAll();
    setAllSelected(!allSelected);
    return allSelected ? handleUnselectAll() : handleSelectAll();
  };

  const toggleAddGroupModal = () => {
    setIsAddGroupModalOpen(!isAddGroupModalOpen);
  };

  const formattedSubscribers = selectedRows.map((row) => {
    const contactPhones = row.contactPhones || [];
    const formattedPhoneNumber = (contactPhones[0]?.phoneNumber || "").replace(
      /^(\+1)(\d{3})(\d{3})(\d{4})$/,
      "($2) $3-$4",
    );
    return {
      phoneNumber: contactPhones[0]?.phoneNumber,
      formattedPhoneNumber,
      id: `/contact_phones/${contactPhones[0]?.id}`,
      contact: { name: row.name },
    };
  });

  const formattedGroupContacts = selectedRows.map((row) => {
    return `/contacts/${row.id}`;
  });

  const handleCheck = (contactId: GridRowSelectionModel) => {
    const selected = rows.filter((row) => {
      return contactId.includes(row.id);
    });
    setSelectedRows(selected);
  };

  const getUpdatedSubscribers = (currentRows: any[], newRows: any[]) => {
    const currentRowIds = currentRows.map((row) => {
      return row.id;
    });

    return newRows.filter((row) => {
      return !currentRowIds.includes(row.id);
    });
  };

  const getSubscribers = useCallback(
    async (
      next?: string,
      query?: { searchTerm: string; dateRange: [Date | null, Date | null] },
    ) => {
      setIsLoading(true);
      try {
        const response = await fetchKeywordSubscribers({
          currentAccount,
          keywordId,
          next,
          query,
        });
        const subscribers = await response.json();
        const updatedRows = getUpdatedSubscribers(rows, subscribers.items);
        setRows(query && !next ? subscribers.items : [...rows, ...updatedRows]);
        setPagination(subscribers.page);
        setIsLoading(false);
      } catch (err) {
        throw new Error(`Error fetching keyword subscribers: ${err}`);
      }
    },
    [rows, setRows],
  );

  const fetchNextPage = async () => {
    if (!pagination) return null;
    return pagination.count !== pagination.to
      ? getSubscribers(pagination.nextUrl)
      : null;
  };

  return {
    isLoadingAllSubscribers: isLoading,
    rowState: { rows, setRows },
    pagination,
    allSelected,
    apiRef,
    dialogRef,
    isEmpty,
    isSelectionMade,
    formattedGroupContacts,
    formattedSubscribers, // formattedRecipients
    fullScreen,
    isAddGroupModalOpen,
    rowCount,
    selectedRows,
    fetchNextPage,
    getSubscribers,
    setAllSelected,
    handleSelectAll,
    handleUnselectAll,
    openQuickCompose,
    handleCheck,
    toggleAddGroupModal,
    toggleAllSelected,
    gridSelectAll,
  };
};

export { useSubscribers };
