import { Collapse, List } from "@mui/material";
import { useState } from "react";
import NavListItemButton from "./NavListItemButton";
import { CollapsibleNavListItemProps } from "./types";
import { ExpandLessIcon, ExpandMoreIcon } from "icons";

export default function CollapsibleNavListItem({
  isOpen = true,
  primary,
  to,
  children,
}: CollapsibleNavListItemProps) {
  const [open, setOpen] = useState(isOpen);

  const toggleIcon = open ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  return (
    <>
      <NavListItemButton
        handleClick={() => {
          setOpen(!open);
        }}
        icon={toggleIcon}
        primary={primary}
        to={to}
      />

      <li style={{ paddingLeft: "0px" }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>{children}</List>
        </Collapse>
      </li>
    </>
  );
}
