/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { useEffect, useState } from "react";
import { Box, Divider, IconButton, Theme, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router";
import { MobileKeywordSearchProps } from "../types";
import useKeywordTable from "../hooks/useKeywordTable";
import KeywordTable from "./KeywordTable";
import PageHeader from "components/Page/PageHeader";
import { SearchBox } from "components/SearchBox";
import { ArrowBackIcon } from "icons";
import { KeywordResponse } from "models/Keyword";
import { SECONDARY_NAV_LINKS } from "features/Automation/constants/AutomationConstants";

function MobileKeywordSearch({
  selectedKeywordNavLink,
  selectedKeywordTab,
  toggleSidebar,
}: MobileKeywordSearchProps) {
  /* STATE */
  // Separate from main keywords state, for mobile search results only
  const [keywords, setKeywords] = useState<KeywordResponse | null>(null);

  /* HOOKS */
  const history = useHistory();

  const isMobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const {
    debouncedSearchTerm,
    fetchFirstPage,
    fetchNextPage,
    handleClear,
    handleSearch,
    isLoading,
    searchTerm,
    userLocked,
  } = useKeywordTable(keywords, setKeywords);

  /* VARIABLES */
  const mainKeywordsPageUrl = window.location.pathname.replace("/search", "");
  const active = selectedKeywordTab === 0;
  const placeholder = `Search ${active ? "active" : "archived"} keywords`;

  /* EFFECTS */
  useEffect(() => {
    if (!isMobileScreen) {
      history.push(mainKeywordsPageUrl);
    }

    return () => {
      // Clears keywords state on unmount only for this mobile component
      setKeywords(null);
    };
  }, [isMobileScreen, mainKeywordsPageUrl, history]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchFirstPage({
        active,
        mine: selectedKeywordNavLink === SECONDARY_NAV_LINKS.mine,
        keywordCont: debouncedSearchTerm,
      }).catch((error) => {
        console.error(error);
      });
    } else {
      setKeywords(null);
    }
  }, [debouncedSearchTerm, selectedKeywordNavLink, selectedKeywordTab]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ margin: "8px 16px 8px 0px" }}
      >
        <IconButton
          onClick={() => {
            return history.push(mainKeywordsPageUrl);
          }}
          sx={{ padding: "0px 16px" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <SearchBox
          value={searchTerm}
          onClear={handleClear}
          onChange={handleSearch}
          placeholder={placeholder}
          slotProps={{ input: { sx: { height: "48px" } } }}
        />
      </Box>
      <Divider />
      <PageHeader
        title={`Search results (${debouncedSearchTerm ? (keywords?.items.length ?? 0) : 0})`}
        toggleSidebar={toggleSidebar}
      />
      <KeywordTable
        debouncedSearchTerm={debouncedSearchTerm}
        fetchNextPage={fetchNextPage}
        isLoading={isLoading}
        keywords={keywords}
        mine={selectedKeywordNavLink === SECONDARY_NAV_LINKS.mine}
        refresh={() => {
          return fetchFirstPage();
        }}
        showingActiveKeywords={selectedKeywordTab === 0}
        userLocked={userLocked}
        sx={{ height: "calc(100vh - 216px)" }} // Allows room for mobile navbar
      />
    </>
  );
}

export default MobileKeywordSearch;
