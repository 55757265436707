/* eslint-disable unicorn/no-thenable */
import * as Yup from "yup";
import isEqual from "lodash/isEqual";
import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";
import { messageSettings } from "features/AccountSettings/components/IntroAndAppended/constants";

// eslint-disable-next-line func-names
Yup.addMethod(Yup.object, "uniqueProperty", function (propertyName, message) {
  // eslint-disable-next-line func-names
  return this.test("unique", message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (
      this.parent
        .filter((v) => {
          return v !== value;
        })
        .some((v) => {
          return isEqual(v[propertyName], value[propertyName]);
        })
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      });
    }

    return true;
  });
});

export const ContactValidationSchema = Yup.object().shape({
  name: Yup.string(),
  data: Yup.object().shape({
    business: Yup.string().nullable(true),
    tags: Yup.array().of(Yup.string()).nullable(true),
  }),
  phones: Yup.array()
    .of(
      Yup.object().shape({
        number: Yup.string().required("Phone number is required"),
        type: Yup.string(),
      }),
    )
    .required("Must have phone numbers")
    .min(1, "Minimum of 1 phone number"),
});

export const NewGroupValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
});

export const AddContactsToGroupValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
});

export const ContactFilterValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
});

const getSingleCampaignValidationSchema = (maxCharacters) => {
  return Yup.object().shape({
    title: Yup.string().required("Title is required"),
    messageBody: Yup.string()
      .required("Message is required")
      .max(maxCharacters, ({ max, value }) => {
        return `${value.length} of ${max} characters`;
      }),
  });
};

export const SingleCampaignValidationSchema = (
  maxCharacters = DEFAULT_MAX_MESSAGE_LENGTH,
) => {
  return getSingleCampaignValidationSchema(maxCharacters);
};

const getCampaignsProValidationSchema = (maxCharacters) => {
  return Yup.object().shape({
    title: Yup.string().required("Title is required"),
    messageTemplates: Yup.array().of(
      Yup.object().shape({
        body: Yup.string()
          .required("Message is required")
          .max(maxCharacters, ({ max, value }) => {
            return `${value.length} of ${max} characters`;
          }),
        signatureActive: Yup.boolean(),
      }),
    ),
  });
};

export const CampaignsProValidationSchema = (
  maxCharacters = DEFAULT_MAX_MESSAGE_LENGTH,
) => {
  return getCampaignsProValidationSchema(maxCharacters);
};

const getRecurringCampaignValidationSchema = (maxCharacters) => {
  return Yup.object().shape({
    title: Yup.string().required("Title is required"),
    messageBody: Yup.string()
      .required("Message is required")
      .max(maxCharacters, ({ max, value }) => {
        return `${value.length} of ${max} characters`;
      }),
    sendFrequency: Yup.string().required("Send frequency is required"),
    dayToSend: Yup.string().required("Send date is required"),
    sendTime: Yup.date()
      .required("Send time is required")
      .typeError("Please select a valid time"),
    campaignEndDate: Yup.date("Please select a valid date")
      .when("runsIndefinitely", (runsIndefinitely, schema) => {
        return runsIndefinitely
          ? schema
          : schema.required("Please select a campaign end date");
      })
      .typeError("Please select a valid date"),
  });
};

export const RecurringCampaignValidationSchema = (
  maxCharacters = DEFAULT_MAX_MESSAGE_LENGTH,
) => {
  return getRecurringCampaignValidationSchema(maxCharacters);
};

export const ContactNoteValidationSchema = Yup.object().shape({
  content: Yup.string().required("Content is required"),
});

const getSavedReplyValidationSchema = (maxCharacters) => {
  return Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .max(100, ({ max, value }) => {
        return `${value.length} of ${max} characters`;
      }),
    content: Yup.string()
      .required("Content is required")
      .max(maxCharacters, ({ max, value }) => {
        return `${value.length} of ${max} characters`;
      }),
  });
};

export const SavedReplyValidationSchema = (
  maxCharacters = DEFAULT_MAX_MESSAGE_LENGTH,
) => {
  return getSavedReplyValidationSchema(maxCharacters);
};

const getMessageValidationSchema = (maxCharacters) => {
  return Yup.object().shape({
    attachments: Yup.array().of(
      Yup.object().shape({
        key: Yup.string(),
        size: Yup.string(),
        width: Yup.string(),
        height: Yup.string(),
        contentType: Yup.string(),
      }),
    ),
    body: Yup.string()
      .when("attachments", (attachments, schema) => {
        return attachments?.length === 0
          ? schema.required("You cannot send an empty message")
          : schema;
      })
      .max(maxCharacters, ({ max, value }) => {
        return `${value.length} of ${max} characters`;
      }),
    to: Yup.array()
      .of(Yup.string())
      .required("Your message must have at least one recipient")
      .min(1, "Your message must have at least one recipient"),
  });
};

export const MessageValidationSchema = (
  maxCharacters = DEFAULT_MAX_MESSAGE_LENGTH,
) => {
  return getMessageValidationSchema(maxCharacters);
};

export const BasicSettingsValidationSchema = Yup.object().shape({
  name: Yup.string().required("Account name is required"),
  settings: Yup.object().shape({
    forwardingNumber: Yup.object().shape({ value: Yup.string() }),
    countryCode: Yup.object().shape({
      value: Yup.string().required("Country is required"),
    }),
    timeZone: Yup.object().shape({
      value: Yup.string().required("Time zone is required"),
    }),
  }),
});

export const InboxSettingsValidationSchema = Yup.object().shape({
  settings: Yup.object().shape({
    conversationAutoCloseAfter: Yup.object().shape({
      value: Yup.mixed(),
    }),
    unassignClosedConversationsAfter: Yup.object().shape({
      value: Yup.mixed(),
    }),
    unassignOpenConversationsAfter: Yup.object().shape({
      value: Yup.mixed(),
    }),
  }),
});

export const IntroAndAppendedValidationSchema = Yup.object().shape({
  introMessages: Yup.object().shape({
    value: Yup.object().shape({
      message: Yup.string()
        .max(
          messageSettings.introMessages.maxChars,
          `Message must be ${messageSettings.introMessages.maxChars} characters or less`,
        )
        .when("messageEnabled", {
          is: true,
          then: Yup.string().required(
            "Cannot be empty if 'Use introduction' is active",
          ),
          otherwise: Yup.string(),
        }),
      messageEnabled: Yup.boolean(),
      position: Yup.string(),
      frequency: Yup.number()
        .nullable()
        .min(1, "Min of 1 day")
        .max(365, "Max of 365 days")
        .notRequired(),
    }),
  }),
  appendedMessageDefaults: Yup.object().shape({
    value: Yup.object()
      .shape({
        message: Yup.string().max(
          messageSettings.appendedMessageDefaults.maxChars,
          `Message must be ${messageSettings.appendedMessageDefaults.maxChars} characters or less`,
        ),
        frequency: Yup.number()
          .min(1, "Min of 1 day")
          .max(365, "Max of 365 days")
          .required(),
      })
      .required(),
  }),
  conversationAppendedMessages: Yup.object().shape({
    value: Yup.object()
      .shape({
        messageEnabled: Yup.boolean().required(),
        appendOnEvery: Yup.boolean().required(),
        customMessageEnabled: Yup.boolean().required(),
        customMessage: Yup.string()
          .max(
            messageSettings.appendedMessageDefaults.maxChars,
            `Message must be ${messageSettings.appendedMessageDefaults.maxChars} characters or less`,
          )
          .when("customMessageEnabled", {
            is: true,
            then: Yup.string().required(
              "Cannot be empty if 'Use in conversations' is active",
            ),
            otherwise: Yup.string(),
          }),
      })
      .required(),
  }),
  campaignAppendedMessages: Yup.object().shape({
    value: Yup.object()
      .shape({
        messageEnabled: Yup.boolean().required(),
        appendOnEvery: Yup.boolean().required(),
        customMessageEnabled: Yup.boolean().required(),
        customMessage: Yup.string()
          .max(
            messageSettings.appendedMessageDefaults.maxChars,
            `Message must be ${messageSettings.appendedMessageDefaults.maxChars} characters or less`,
          )
          .when("customMessageEnabled", {
            is: true,
            then: Yup.string().required(
              "Cannot be empty if 'Use in campaigns' is active",
            ),
            otherwise: Yup.string(),
          }),
      })
      .required(),
  }),
});

const getAutoReplyMessageValidationSchema = (
  maxCharacters = DEFAULT_MAX_MESSAGE_LENGTH,
) => {
  return Yup.object().shape({
    settings: Yup.object().shape({
      [`autoReplyMessageEnabled`]: Yup.object().shape({
        value: Yup.boolean(),
      }),
      [`autoReplyMessage`]: Yup.object().when(
        `autoReplyMessageEnabled.value`,
        (messageEnabled, schema) => {
          return messageEnabled
            ? schema.shape({
                value: Yup.object().shape({
                  message: Yup.string()
                    .required("Message is required")
                    .max(maxCharacters, ({ max, value }) => {
                      return `${value.length} of ${max} characters`;
                    }),
                  officeHours: Yup.boolean(),
                }),
              })
            : schema.shape({
                value: Yup.string().max(maxCharacters, ({ max, value }) => {
                  return `${value.length} of ${max} characters`;
                }),
                officeHours: Yup.boolean(),
              });
        },
      ),
    }),
  });
};

export const AutoReplyMessageValidationSchema = (
  maxCharacters = DEFAULT_MAX_MESSAGE_LENGTH,
) => {
  return getAutoReplyMessageValidationSchema(maxCharacters);
};

export const OfficeHoursValidationSchema = Yup.object().shape({
  settings: Yup.object().shape({
    officeHours: Yup.object().shape({
      value: Yup.array().of(
        Yup.object().shape({
          startDay: Yup.number().required("Start day is required"),
          endDay: Yup.number().required("End day is required"),
          startTime: Yup.number().required("Start time is required"),
          endTime: Yup.number().required("End time is required"),
        }),
      ),
    }),
  }),
});

export const MmsSettingsValidationSchema = Yup.object().shape({
  settings: Yup.object().shape({
    inboundMmsDisabled: Yup.object().shape({ value: Yup.boolean() }),
    outboundMmsDisabled: Yup.object().shape({ value: Yup.boolean() }),
  }),
});

export const LinkStrippingValidationSchema = Yup.object().shape({
  settings: Yup.object().shape({
    stripInboundLinks: Yup.object().shape({ value: Yup.boolean() }),
    stripOutboundLinks: Yup.object().shape({ value: Yup.boolean() }),
  }),
});

export const ConversationExportValidationSchema = Yup.object().shape({
  settings: Yup.object().shape({
    conversationExportsEnabled: Yup.object().shape({ value: Yup.boolean() }),
  }),
});

export const ErecruitValidationSchema = Yup.object().shape({
  config: Yup.object().shape({
    clientId: Yup.string().required("Client ID is required"),
    clientSecret: Yup.string().required("Client secret is required"),
    entityId: Yup.string(),
    baseUrl: Yup.string()
      .url("Must be a valid URL")
      .required("Base URL is required"),
  }),
});

export const GreenhouseV3 = Yup.object().shape({
  config: Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    apiKey: Yup.string().required("API key is required"),
  }),
  settings: Yup.object().shape({
    batching: Yup.object().shape({
      enabled: Yup.boolean(),
      noteDuration: Yup.string(),
    }),
  }),
});

export const MsDynamicsValidationSchema = Yup.object().shape({
  config: Yup.object().shape({
    organizationUrl: Yup.string().required(),
    clientId: Yup.string().required(),
    clientSecret: Yup.string().required(),
    tenantId: Yup.string().required(),
  }),
  settings: Yup.object().shape({
    batching: Yup.object().shape({
      enabled: Yup.boolean(),
      noteDuration: Yup.string(),
      updateFrequencyMinutes: Yup.number(),
    }),
  }),
});

export const SalesforceValidationSchema = Yup.object().shape({
  settings: Yup.object().shape({
    batching: Yup.object().shape({
      enabled: Yup.boolean(),
      noteDuration: Yup.string(),
      updateFrequencyMinutes: Yup.number(),
    }),
  }),
});

export const HubspotValidationSchema = Yup.object().shape({
  settings: Yup.object().shape({
    noteType: Yup.string().required(),
  }),
});

export const BullhornValidationSchema = Yup.object().shape({
  config: Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
    clientId: Yup.string().required("Client ID is required"),
    clientSecret: Yup.string().required("Client secret is required"),
    apiHost: Yup.string().required("DataCenter is required"),
    authHost: Yup.string(),
  }),
  settings: Yup.object().shape({
    batching: Yup.object().shape({
      enabled: Yup.boolean(),
      noteDuration: Yup.string(),
      updateFrequencyMinutes: Yup.number(),
    }),
    enabledPhones: Yup.array(),
    customObject: Yup.object().shape({
      active: Yup.boolean(),
      entityName: Yup.string()
        .nullable()
        .when("active", {
          is: true,
          then: Yup.string().required("Entity Name is required"),
        }),
    }),
  }),
});

export const WebhookValidationSchema = Yup.object().shape({
  config: Yup.object().shape({
    baseUrl: Yup.string()
      .url("Must be a valid URL")
      .required("Webhook URL is required"),
  }),
});

export const HerefishValidationSchema = Yup.object().shape({
  config: Yup.object().shape({
    baseUrl: Yup.string()
      .url("Must be a valid URL")
      .required("Webhook URL is required"),
  }),
});

export const WorkdayValidationSchema = Yup.object().shape({
  config: Yup.object().shape({
    apiUrl: Yup.string()
      .url("Must be a valid URL")
      .required("API URL is required"),
    password: Yup.string().required("password is required"),
    username: Yup.string().required("Username is required"),
    tenantSlug: Yup.string().required("Tenant Slug is required"),
  }),
});

export const GreenhouseV2ValidationSchema = Yup.object().shape({
  config: Yup.object().shape({
    apiKey: Yup.string().required("API key is required"),
    authorizingUserEmail: Yup.string().email().required("Email is required"),
  }),
});

export const Auth0PasswordlessValidationSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
});

export const PasswordLoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const PasswordlessLoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
});

export const RequestResetPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
});

export const ResetPasswordValidationSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .min(12, "Password is too short - should be 12 chars minimum."),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirm is required"),
});

export const BasicProfileValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required("Email is required"),
  avatar: Yup.string(),
  defaultMessagingAccount: Yup.string(),
});

export const AnalyticsReportsSchema = Yup.object().shape({
  account: Yup.string().required("Account is required"),
  timePeriod: Yup.string().required("Time Period is required"),
  sendFrequency: Yup.string().required("Send Frequency is required"),
  dayToSend: Yup.string().required("Send Date is required"),
});

export const getKeywordAutomationSchema = (maxCharacters) => {
  return Yup.object().shape({
    keyword: Yup.string()
      .max(20, ({ max, value }) => {
        return `${value.length} of ${max} characters`;
      })
      .required("Keyword is required"),
    attachments: Yup.array().of(
      Yup.object().shape({
        file: Yup.string(),
        key: Yup.string(),
        name: Yup.string(),
        originalFilename: Yup.string(),
        signedUrl: Yup.string(),
        size: Yup.string(),
        width: Yup.string(),
        height: Yup.string(),
        contentType: Yup.string(),
      }),
    ),
    autoResponse: Yup.string()
      .required("Auto-Response is required")
      .max(maxCharacters, ({ max, value }) => {
        return `${value.length} of ${max} characters`;
      }),
    signatureActive: Yup.boolean(),
  });
};

export const KeywordAutomationSchema = (
  maxCharacters = DEFAULT_MAX_MESSAGE_LENGTH,
) => {
  return getKeywordAutomationSchema(maxCharacters);
};

export const NewSequenceValidationSchema = Yup.object().shape({
  sequenceName: Yup.string().required("Sequence name is a required field"),
  scheduleType: Yup.string().required("Sequence type is a required field"),
  finishedOnReply: Yup.string().required("Field is required"),
});

export const SequenceStepValidationSchema = Yup.object().shape(
  {
    body: Yup.string().required("Step message required"),
    attachments: Yup.array().of(
      Yup.object().shape({
        key: Yup.string(),
        size: Yup.string(),
        width: Yup.string(),
        height: Yup.string(),
        contentType: Yup.string(),
        originalFilename: Yup.string(),
      }),
    ),
    delay: Yup.object()
      .nullable(true)
      .when("delay", {
        is: (delay) => {
          return delay ? delay.days + delay.hours + delay.minutes === 0 : false;
        },
        then: Yup.object().shape({
          days: Yup.number(),
          hours: Yup.number(),
          minutes: Yup.number().min(5, "Delay required"),
        }),
        otherwise: Yup.object(),
      }),
  },
  // Cyclic dependencies: https://github.com/jquense/yup/issues/1364
  [
    ["delay", "delay"],
    ["time", "time"],
    ["time", "delay"],
    ["date", "date"],
    ["date", "delay"],
  ],
);

export const WhiteLabelValidationSchema = Yup.object().shape({
  settings: Yup.object().shape({
    primaryColor: Yup.object().shape({
      value: Yup.string(),
    }),
    secondaryColor: Yup.object().shape({
      value: Yup.string(),
    }),
    tertiaryColor: Yup.object().shape({
      value: Yup.string(),
    }),
    outboundMessageColor: Yup.object().shape({
      value: Yup.string(),
    }),
    logo: Yup.object().shape({
      value: Yup.string(),
    }),
  }),
});

export const NotificationsValidationSchema = Yup.object().shape({
  notifications: Yup.object().shape({
    // desktop: Yup.boolean(),
    emailSettings: Yup.object().shape({
      enabled: Yup.boolean().required(),
      filters: Yup.object().shape({
        you: Yup.boolean(),
        otherUser: Yup.boolean(),
        unassigned: Yup.boolean(),
      }),
      messageDelayMinutes: Yup.number(), // ideally use union.
    }),
    // audio: Yup.boolean(),
    // analytics: Yup.string(), // IDEALLY use union, but maybe just should switch to zod? https://github.com/jquense/yup/issues/593
  }),
});

export const ApiTokensValidationSchema = Yup.object().shape({
  description: Yup.string().required(),
});

export const SignatureValidationSchema = Yup.object().shape({
  content: Yup.string(),
});

export const CampaignSettingsValidationSchema = Yup.object().shape({
  settings: Yup.object().shape({
    campaignDeliveryCutoffTimeEnabled: Yup.object().shape({
      value: Yup.boolean(),
    }),
    campaignDeliveryCutoffTime: Yup.object().when(
      "campaignDeliveryCutoffTimeEnabled.value",
      (cutoffEnabled, schema) => {
        return cutoffEnabled
          ? schema.shape({
              value: Yup.number().required("Cutoff time is required"),
            })
          : schema.shape({ value: Yup.mixed().default("") });
      },
    ),
  }),
});

export const RegisteredLinksValidationSchema = Yup.object().shape({
  settings: Yup.object().shape({
    registeredLinks: Yup.object().shape({
      value: Yup.string()
        .min(2, "Shortened Links have a 2-character minimum.")
        .max(12, "Shortened Links have a 12-character maximum.")
        .matches(
          /^[a-z][a-z0-9-]*[a-z0-9]$/gi,
          "Shortened Links cannot contain special characters or begin/end with hyphens",
        ),
    }),
  }),
});

export const CreatePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Password is required")
    .min(12, "Password is too short - should be 12 chars minimum."),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Password confirm is required"),
});

export const ChangePasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required(
    "You must enter your current password to set a new one",
  ),
  newPassword: Yup.string()
    .required("Password is required")
    .min(12, "Password is too short - should be 12 chars minimum."),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Password confirm is required"),
});

export const UserValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  name: Yup.string().required("Name is required"),
  memberships: Yup.array()
    .of(
      Yup.object()
        .shape({
          account: Yup.string().required("Please select an account"),
          role: Yup.string().required("Role is required"),
        })
        .uniqueProperty("account", "Only one membership allowed per account"),
    )
    .required("Must have memberships")
    .min(1, "User's must have at least one membership"),
});

export const UserSchemaVariableValidationSchema = Yup.object().shape({
  type: Yup.object().shape({
    name: Yup.string().required("Please select a type"),
    value: Yup.string(),
  }),
  title: Yup.string()
    .required("Name is required")
    .test("length", "Must contain at least one valid character", (val) => {
      return val && val.trim().length > 0;
    })
    .max(70, ({ max, value }) => {
      return `${value.length} of ${max} characters`;
    }),
  description: Yup.string().max(1000, ({ max, value }) => {
    return `${value.length} of ${max} characters`;
  }),
});

export const getKeywordValidationSchema = (maxResponseBodyLength) => {
  return Yup.object().shape({
    id: Yup.string(),
    keyword: Yup.string().required("Keyword is required for keyword trigger"),
    responseBody: Yup.string()
      .required("Response body is required for keyword trigger")
      .max(
        maxResponseBodyLength,
        `Maximum length is ${maxResponseBodyLength} characters`,
      ),
    enrollInSequence: Yup.boolean(),
    sequenceId: Yup.string().when("enrollInSequence", {
      is: true,
      then: Yup.string().required(
        "sequence is required when enroll in sequence is true",
      ),
      otherwise: Yup.string(),
    }),
    keywordAnswers: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        keyword: Yup.string().required(
          "Keyword is required for keyword answer",
        ),
        responseBody: Yup.string()
          .required("Response body is required for keyword answer")
          .max(
            maxResponseBodyLength,
            `Maximum length is ${maxResponseBodyLength} characters`,
          ),
        enrollInSequence: Yup.boolean(),
        sequenceId: Yup.string().when("enrollInSequence", {
          is: true,
          then: Yup.string().required(
            "sequence is required when enroll in sequence is true",
          ),
          otherwise: Yup.string(),
        }),
      }),
    ),
  });
};
