import { ReactNode } from "react";

import Typography from "@mui/material/Typography";

import { AccountPlan, AccountPlanCards } from "../models/AccountPlanModels";
import { LinkText, StyledCard } from "../styles/styles";

function HeroText({ children }: { children: ReactNode }) {
  return (
    <Typography
      variant="dataSmall"
      sx={(theme) => {
        return {
          padding: ".5rem",
        };
      }}
    >
      {children}
    </Typography>
  );
}

const getAccountPlanValues = (accountPlan: AccountPlan): ReactNode => {
  const { assignments, shared, type, quantity } = accountPlan;
  const remainingLicenses = quantity - assignments.length || 0;
  const accountPlanValues: AccountPlanCards = {
    campaigns: {
      text: <HeroText>{remainingLicenses} available</HeroText>,
      title: "Campaigns Pro",
    },
    support: {
      text: (
        <Typography
          color="textPrimary"
          sx={{ fontSize: ".75rem", lineHeight: ".85rem" }}
        >
          Contact you account manager or email{" "}
          <LinkText href="mailto:support@textus.com">
            support@textus.com
          </LinkText>{" "}
          to enable
        </Typography>
      ),
      title: "Premium Support",
    },
    analytics: {
      text: <HeroText>{shared ? "Enabled" : "Not enabled"}</HeroText>,
      title: "Analytics Pro",
    },
    keywords: {
      text: <HeroText>{remainingLicenses} available</HeroText>,
      title: "Keywords",
    },
    sequences: {
      text: <HeroText>{remainingLicenses} available</HeroText>,
      title: "Sequences",
    },
  };

  const { title, text, total = quantity } = accountPlanValues[type] ?? {};

  return (
    <StyledCard
      key={accountPlan.id}
      aria-label={`${title?.toLowerCase()}-card`}
      sx={(theme) => {
        return {
          textAlign: "center",
          padding: ".5rem",
          height: "auto",
          flexGrow: 1,
        };
      }}
    >
      <Typography variant="overline">{title}</Typography>

      {text}

      <Typography
        variant="caption"
        color="textSecondary"
        padding="0.1rem"
      >{`${assignments.length} of ${total} used`}</Typography>
    </StyledCard>
  );
};

export default getAccountPlanValues;
