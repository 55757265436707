import PropTypes from "prop-types";
import get from "lodash/get";
import styled from "styled-components";
import { getIn } from "formik";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import AccountSetting from "./AccountSetting";

import { CampaignSettingsValidationSchema } from "formHelpers/validationSchemas";
import SettingsDescription from "components/SettingsPageComponents/SettingsDescription";

const hourOptions = [
  { label: "", value: "" },
  { label: "12:00 PM", value: 720 },
  { label: "1:00 PM", value: 780 },
  { label: "2:00 PM", value: 840 },
  { label: "3:00 PM", value: 900 },
  { label: "4:00 PM", value: 960 },
  { label: "5:00 PM", value: 1020 },
  { label: "6:00 PM", value: 1080 },
  { label: "7:00 PM", value: 1140 },
  { label: "8:00 PM", value: 1200 },
  { label: "9:00 PM", value: 1260 },
  { label: "10:00 PM", value: 1320 },
  { label: "11:00 PM", value: 1380 },
];

const Description = styled(SettingsDescription)`
  a {
    font-weight: bold;
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }
`;

function CampaignSettings(props) {
  const { account } = props;

  const getDescription = () => {
    return (
      <Box mt={2}>
        <Box my={2}>
          <Typography variant="h5">Cutoff Time</Typography>
        </Box>
        <Description>
          {!get(account, ["settings", "timeZone", "value"]) && (
            <p>
              {"You must configure your "}
              <Link to={`/${account.slug}/settings`}>
                account&apos;s time zone
              </Link>
              {" to use this feature."}
            </p>
          )}
          <p>
            {`
              When enabled, the delivery of campaign messages will cease at the 
              specified time, and any delivery attempts that fall outside of 
              this cutoff time will result in a “Skipped” status. These skipped 
              deliveries will have to be re-sent manually.
            `}
          </p>
        </Description>
      </Box>
    );
  };

  const getSwitchRender = () => {
    return () => {
      return function ({ field, form }) {
        return (
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                onChange={(event) => {
                  const { checked } = event.target;
                  form.setFieldValue(field.name, checked);
                }}
                color="secondary"
              />
            }
            label="Enable Campaign Delivery Cutoff Time"
          />
        );
      };
    };
  };

  const getSelectRender = () => {
    return ({ values }) => {
      return function ({ field }) {
        return (
          <TextField
            disabled={
              !getIn(values, "settings.campaignDeliveryCutoffTimeEnabled.value")
            }
            {...field}
            select
            variant="outlined"
            SelectProps={{
              native: true,
            }}
            label="Cutoff Time"
            id="extus-CampaignSettings-cutoffTime"
            fullWidth
          >
            {hourOptions.map((option) => {
              return (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </TextField>
        );
      };
    };
  };

  return (
    <AccountSetting
      {...props}
      description={getDescription()}
      isDisabled={!get(account, ["settings", "timeZone", "value"])}
      settings={[
        {
          default: false,
          name: "campaignDeliveryCutoffTimeEnabled",
          render: getSwitchRender(),
        },
        {
          default: "",
          name: "campaignDeliveryCutoffTime",
          render: getSelectRender(),
        },
      ]}
      title="Cutoff Time"
      validationSchema={CampaignSettingsValidationSchema}
    />
  );
}

CampaignSettings.propTypes = {
  account: PropTypes.object.isRequired,
  field: PropTypes.object,
  form: PropTypes.object,
};

export default CampaignSettings;
