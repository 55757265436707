import { ReactNode, useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Tooltip10dlcNotice from "components/Tooltip10dlcNotice";
import { ArrowDropDownIcon } from "icons";

interface Props {
  defaultClickHandler: () => void;
  // className: string;
  // dataTag: string;
  // dataTestId: string;
  // ariaLabel: string;
  disabled: boolean;
  icon: ReactNode;
  primary?: boolean;
  menuItems: any;
  text: string;
  tooltipText?: string;
}

export default function PageSidebarHeaderButtonGroup({
  defaultClickHandler,
  //   dataTag,
  //   ariaLabel,
  //   dataTestId,
  menuItems,
  disabled = false,
  icon,
  primary,
  text,
  tooltipText = "",
}: Props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const variant = primary ? "contained" : "text";
  const visibility = disabled ? "visible" : "hidden";
  const theme = useTheme();

  const handleOpenMenu = () => {
    setOpen(true);
  };

  const handleClose = (event: Event) => {
    setOpen(false);
  };

  return (
    <Tooltip10dlcNotice text={tooltipText} visibility={visibility}>
      {/* Extra div required to get Tooltip to display on a disabled Button */}
      <div style={{ width: "100%" }}>
        <ButtonGroup
          color="primary"
          fullWidth
          variant="contained"
          aria-label="Split button"
          ref={anchorRef}
          //   TODO: There are functionality changes that likely necessitate updates to the tour videos
          //   {...(dataTag && { "data-product-tour": dataTag })}
          //   {...(ariaLabel && { "aria-label": ariaLabel })}
          //   {...(dataTestId && { "data-testid": dataTestId })}
        >
          <Button
            color="primary"
            fullWidth
            onClick={defaultClickHandler}
            variant={variant}
          >
            {icon}
            <span style={{ marginLeft: "10px" }}>{text}</span>
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            size="small"
            aria-haspopup="true"
            onClick={handleOpenMenu}
            sx={{ width: "35px" }}
            aria-label="more options"
          >
            <ArrowDropDownIcon data-testid="send-options-button" />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 100,
            // it kills me to use !important, but `<Tooltip10dlcNotice>` has
            // a very strange interaction with the positioning of the `<ButtonGroup/> + <Popper />`,
            // and this is the only way i was able to correct the issue while still including the tooltip
            position: "absolute !important",
            top: "51px !important",
            left: "16px !important",
            // 100% is ignoring the parent's parent padding, so subtract 16px * 2
            width: "calc(100% - 32px)",
            boxShadow: theme.shadows[4],
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem sx={{ zIndex: 1 }}>
                {menuItems.map(({ menuIcon, menuText, handleClick }: any) => (
                  <MenuItem key={menuText} onClick={handleClick}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      {menuIcon}
                      {menuText}
                    </Box>
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
    </Tooltip10dlcNotice>
  );
}
