import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import get from "lodash/get";
import Box from "@mui/material/Box";

import AutomatedMessage from "./AutomatedMessage";

export const Description = styled.section`
  margin-bottom: 40px;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};

  a {
    font-weight: bold;
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }

  p:first-child {
    margin-top: 0;
  }
`;

interface AutoReplyMessageProps {
  account: any;
  currentUser: any;
}

export default function AutoReplyMessage(props: AutoReplyMessageProps) {
  const { account } = props;
  const [choice, setChoice] = useState(
    get(
      account,
      ["settings", `autoReplyMessage`, "value", "officeHours"],
      false,
    ),
  );

  const descriptionText = () => {
    return (
      <Description>
        {account.featureFlags?.settingsSidebarRedesign ? (
          <p>
            Set your office hours to reflect the times you’ll be available. An
            auto-reply message can be automatically sent in response to inbound
            text messages on the defined cadence below.
          </p>
        ) : (
          <>
            <p>
              {`An Auto Reply responds automatically to inbound text messages.
            There are two options below:`}
            </p>
            <p>
              Always On - This message is sent every time a new inbound message
              is received to this account.
            </p>
            <p>
              Outside Office Hours - This message is only sent when an inbound
              message is received outside of your configured{" "}
              <Link to={`/${account.slug}/settings/office-hours`}>
                office hours
              </Link>
              .
            </p>
            {!get(account, ["settings", "timeZone", "value"]) && (
              <Box color="error">
                {"You must configure your "}
                {}
                <Link to={`/${account.slug}/settings`}>
                  account's time zone
                </Link>
                {" to use this feature."}
              </Box>
            )}
          </>
        )}
      </Description>
    );
  };

  const updateChoice = (newChoice: string) => {
    setChoice(newChoice === "true");
  };

  const getWarning = () => {
    if (
      get(account, ["settings", "officeHours", "value"], []).length === 0 &&
      choice
    ) {
      return (
        <p>
          The auto reply will not send until you set up{" "}
          <Link to={`/${account.slug}/settings/office-hours`}>
            office hours
          </Link>{" "}
          for this account.
        </p>
      );
    }
    return null;
  };

  return (
    <AutomatedMessage
      {...props}
      descriptionText={descriptionText}
      type="autoReply"
      choices={{
        choiceType: "officeHours",
        choice,
        options: [
          { title: "Always On", value: false },
          { title: "Outside Office Hours", value: true },
        ],
        updateChoice,
        warning: getWarning(),
      }}
    />
  );
}
