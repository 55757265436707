import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import get from "lodash/get";
import uniq from "lodash/uniq";
import { Redirect } from "react-router";

import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import MuiChip from "@mui/material/Chip";
import { Contact } from "@tesseract/core";

import Autocomplete, {
  AutocompleteInputChangeReason,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  FilterOptionsState,
  Tooltip,
  styled as muiStyled,
} from "@mui/material";
import TagCreationInfo from "./components/TagCreationInfo";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";
import List from "components/List";
import PageLoader from "components/PageLoader";
import { useContactTagCollection } from "features/Contacts/hooks/useContactTagCollection";
import { FormValues } from "features/ContactForm";
import { useContact } from "features/Contact/hooks";
import { useCurrentAccount } from "hooks";

const Tags = styled(List)``;

const Tag = styled.li`
  display: inline-block;
`;

const Chip = muiStyled(MuiChip)(({ theme }) => {
  return {
    "&.MuiChip-colorPrimary": {
      background: theme.palette.primary.main,
      color: theme.palette.background.paper,
    },
  };
});

const filter = createFilterOptions();

export interface ContactTagsProps {
  closeModal: () => void;
  contact: Contact.Identity;
  contactTagsId: string;
}

type Option = {
  label: string;
} & (
  | { value: string; inputValue: never }
  | { inputValue: string; value: never }
);

function ContactTags({
  closeModal,
  contact: contactId,
  contactTagsId,
}: ContactTagsProps) {
  const [nextLocation, setNextLocation] = useState<string | null>(null);
  const [shouldRequest, setShouldRequest] = useState(true);
  const [options, setOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState("");
  const { contactTagCollection } = useContactTagCollection({
    contactTagCollectionId: contactTagsId,
  });
  const currentAccount = useCurrentAccount();
  const { load, contact, updateContactHandler } = useContact();

  /**
   * Fetch the contact from the server if the id is defined
   */
  useEffect(() => {
    return load(contactId);
  }, [contactId, load]);

  const handleDelete = (deletedTag: string) => {
    async function deleteTagCallback() {
      const tags = get(contact, ["data", "tags"], []);
      const newTags = uniq(tags).filter((tag) => {
        return tag !== deletedTag;
      });

      await updateContactHandler(contactId, {
        data: { ...contact?.data, tags: newTags },
      } as FormValues);
    }

    return () => void deleteTagCallback();
  };

  const handleClick = (clickedTag: string) => {
    return () => {
      if (currentAccount.featureFlags?.newContactSearch) {
        const tagLink = clickedTag.includes(" ")
          ? `q=${fixedEncodeURIComponent(`"${clickedTag}"`)}`
          : `q=${fixedEncodeURIComponent(`${clickedTag}`)}`;
        setNextLocation(tagLink);
        if (closeModal) closeModal();
        return;
      }

      const tagLink = clickedTag.includes(" ")
        ? `q=tags:${fixedEncodeURIComponent(`"${clickedTag}"`)}`
        : `q=tags:${fixedEncodeURIComponent(`${clickedTag}`)}`;
      setNextLocation(tagLink);
      if (closeModal) closeModal();
    };
  };

  const handleSelectOption = async (option: { value: string }) => {
    const { value = "" } = option;
    const sanitizedValue = value.replaceAll(/["',]/g, "").trim();

    const tags = get(contact, ["data", "tags"], []);
    const newTags = uniq([...tags, sanitizedValue]);

    await updateContactHandler(contactId, {
      data: { ...contact?.data, tags: newTags },
    } as FormValues);

    // clear the input when done.
    setInputValue("");
  };

  const requestTags = () => {
    if (shouldRequest) {
      const newOptions: Option[] =
        contactTagCollection?.members?.map((tag) => {
          return {
            value: tag.id,
            label: tag.tag,
          } as Option;
        }) || [];
      setOptions(newOptions);
      setShouldRequest(false);
    }
  };

  const contactTags = useMemo(() => {
    return contact?.data?.tags || [];
  }, [contact]);

  const selectOptions = options.filter(({ value }) => {
    return !contactTags.includes(value);
  });

  if (nextLocation)
    return (
      <Redirect
        push
        to={{
          pathname: `/${currentAccount.slug}/contacts/search`,
          search: nextLocation,
        }}
      />
    );

  const getOptionLabel = (option: unknown) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    const typedOption = option as Option;
    // Add "xxx" option created dynamically
    if (typedOption.inputValue) {
      return typedOption.inputValue;
    }
    // Regular option
    return typedOption.label;
  };

  const onChange = (
    _event: React.SyntheticEvent,
    newValue: Option | string,
  ) => {
    if (typeof newValue === "string") {
      void handleSelectOption({ value: newValue });
    } else if (newValue?.inputValue) {
      void handleSelectOption({ value: newValue.inputValue });
    } else {
      void handleSelectOption(newValue);
    }
  };

  const filterOptions = (
    opts: unknown[],
    params: FilterOptionsState<unknown>,
  ) => {
    const filtered = filter(opts, params);

    if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        label: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  const onInputChange = (
    _e: React.SyntheticEvent,
    input: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (reason === "reset") {
      setInputValue("");
    } else {
      setInputValue(input);
    }
  };

  return (
    <Box p={2.5} width="100%">
      <Box position="relative" width="100%">
        <Tooltip title={<TagCreationInfo />}>
          <Box
            display="flex"
            alignItems="center"
            position="absolute"
            color="text.secondary"
            px={1}
            height="100%"
            style={{ zIndex: 1 }}
          >
            <InfoIcon
              color="inherit"
              style={{
                cursor: "help",
              }}
              fontSize="small"
            />
          </Box>
        </Tooltip>
        <Autocomplete
          onOpen={requestTags}
          // @ts-expect-error - there is no currently known use case where the newValue argument will be {}
          onChange={onChange}
          disableClearable
          inputValue={inputValue}
          onInputChange={onInputChange}
          options={selectOptions}
          filterOptions={filterOptions}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="autocomplete-tags"
          getOptionLabel={getOptionLabel}
          renderOption={(props, option: unknown) => {
            // could not find a way to type this properly, fixme if you can
            // eslint-disable-next-line react/prop-types
            const { key, ...rest } = props;
            return (
              <li {...rest} key={key}>
                {(option as Option).label}
              </li>
            );
          }}
          blurOnSelect
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                placeholder="Add or create tags"
                data-testid="add-tag-input"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  style: { paddingLeft: 24 },
                }}
              />
            );
          }}
        />
      </Box>
      <Box mt={2.5} position="relative">
        <Tags>
          {uniq(contactTags).map((tag) => {
            return (
              <Tag data-testid="tag chip" key={tag}>
                <Chip
                  data-testid="chip"
                  size="small"
                  label={tag}
                  onClick={handleClick(tag)}
                  onDelete={handleDelete(tag)}
                />
              </Tag>
            );
          })}
        </Tags>
        {false && <PageLoader />}
      </Box>
    </Box>
  );
}

export default ContactTags;
