import { capitalize } from "lodash";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

interface KeywordConfirmationModalProps {
  actionType: "archive" | "reactivate";
  isOpen: boolean;
  confirmationHandler: () => Promise<void | null>;
  cancellationHandler: () => void;
  localStorageKey: string;
}

function KeywordConfirmationModal({
  actionType,
  isOpen,
  confirmationHandler,
  cancellationHandler,
  localStorageKey,
}: KeywordConfirmationModalProps) {
  const activeKeywords = actionType === "archive";
  const title = `${capitalize(actionType)} keyword`;
  const actionPresentParticiple = activeKeywords ? "archiving" : "reactivating";

  const modalBody = activeKeywords
    ? "Archiving a keyword will deactivate the automation. Incoming messages containing the keyword will no longer receive the auto-response. Keywords can be reactivated after archiving."
    : "Reactivating a keyword will allow inbound messages containing the keyword to receive the auto-response. This keyword will move from the archive to active page.";

  return (
    <ConfirmationModal
      title={`${title}?`}
      message={modalBody}
      buttonText={capitalize(actionType)}
      cancellationHandler={cancellationHandler}
      confirmationHandler={confirmationHandler}
      confirmStyles={
        activeKeywords ? { color: "error", variant: "text" } : undefined
      }
      doNotAskAgainText={`${actionPresentParticiple} a keyword`}
      isOpen={isOpen}
      localStorageKey={localStorageKey}
    />
  );
}

export default KeywordConfirmationModal;
