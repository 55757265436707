import { all, put, select, takeLatest } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";
import { createSelector } from "reselect";
import get from "lodash/get";
import { push } from "connected-react-router";

import * as schema from "schema";
import {
  selectCurrentAccount,
  selectRecords,
} from "features/EntryPoint/containers/App/selectors";
import denormalizeWithShape from "utils/denormalizeWithShape";
import generateContainerState from "utils/generateContainerState";

const {
  actionTypes,
  actionGenerators,
  sagas,
  reducers,
  selectors: campaignSelectors,
} = generateContainerState([
  {
    container: "NewCampaign",
    crudActions: ["create", "schedule"],
    recordType: "campaign",
    schema: schema.campaign,
  },
]);

function* redirectOnSuccess({ response }) {
  const currentAccount = yield select(selectCurrentAccount);
  const [, , campaignSlug] = response.id.split("/");
  yield put(push(`/${currentAccount.slug}/campaigns/all/${campaignSlug}`));
}

function* saga() {
  yield all([
    takeLatest(
      actionTypes.CREATE_CAMPAIGN_REQUEST,
      sagas.createCampaignRequest,
    ),
    takeLatest(actionTypes.CREATE_CAMPAIGN_SUCCESS, redirectOnSuccess),
    takeLatest(
      actionTypes.SCHEDULE_CAMPAIGN_REQUEST,
      sagas.scheduleCampaignRequest,
    ),
    takeLatest(actionTypes.SCHEDULE_CAMPAIGN_SUCCESS, redirectOnSuccess),
  ]);
}

const reducer = combineReducers(reducers);

// SELECTORS
const selectRecipientIds = (state, props) => {
  return get(props, "selectedContacts");
};
const selectRecipients = createSelector(
  selectRecords,
  selectRecipientIds,
  (records, ids = []) => {
    return ids
      .map((id) => {
        const record = records.get(id);
        if (!record) return undefined;
        if (record.get("@type") === "CampaignRecipient") {
          return denormalizeWithShape({
            id,
            records: records.toJS(),
            shape: { contactPhone: { contact: {} } },
          });
        }
        return denormalizeWithShape({
          id,
          records: records.toJS(),
          shape: { phones: { members: [] } },
        });
      })
      .filter((record) => {
        return !!record;
      });
  },
);
const selectContactImportId = (state, props) => {
  return get(props, "contactImportId");
};
const selectContactImport = createSelector(
  selectRecords,
  selectContactImportId,
  (records, id) => {
    return records.get(id)
      ? denormalizeWithShape({
          id,
          records: records.toJS(),
          shape: { contactFilter: { contacts: {} } },
        })
      : undefined;
  },
);
const selectContactFilterId = (state, props) => {
  return get(props, "contactFilterId");
};
const selectContactFilter = createSelector(
  selectRecords,
  selectContactFilterId,
  (records, id) => {
    return records.get(id)
      ? denormalizeWithShape({
          id,
          records: records.toJS(),
          shape: { contacts: {} },
        })
      : undefined;
  },
);
const selectContactCollectionId = (state, props) => {
  return get(props, "contactCollectionId");
};
const selectContactCollection = createSelector(
  selectRecords,
  selectContactCollectionId,
  (records, id) => {
    return records.get(id)
      ? denormalizeWithShape({
          id,
          records: records.toJS(),
          shape: {},
        })
      : undefined;
  },
);
const selectDeliveryStats = createSelector(
  selectRecords,
  selectCurrentAccount,
  (records, currentAccount) => {
    const deliveryStats = records.get(
      `/${currentAccount.slug}/deliveries/stats`,
    );
    return deliveryStats ? deliveryStats.toJS() : {};
  },
);
const selectors = {
  ...campaignSelectors,
  selectContactFilter,
  selectContactImport,
  selectContactCollection,
  selectRecipients,
  selectDeliveryStats,
};

export { actionGenerators, actionTypes, saga, reducer, selectors };
