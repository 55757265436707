import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import startCase from "lodash/startCase";
import { Form, Formik, Field, getIn } from "formik";
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useAccountSettingsSubmit } from "../hooks";
import { MESSAGE_TYPES } from "../constants/automatedMessages";
import LockForm from "./LockForm";
import LockOverlay from "./LockOverlay";
import SubmitRow from "./SubmitRow";
import getUserRole from "utils/getUserRole";
import { AutoReplyMessageValidationSchema } from "formHelpers/validationSchemas";
import MessageInputFormRow from "components/MessageInputFormRow";
import PageHeader from "components/Page/PageHeader";

import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";
import { USER_ROLES } from "constants/userRoles";

// Prevent Chrome from transitioning to a yellow background on autocomplete
export const FormWrapper = styled.div`
  font-size: 0.8rem;
  width: 100%;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
  }

  form {
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: center;
    max-height: 500px;
  }
`;

export const FormRow = styled.div`
  flex: 0 0 auto;
  margin-bottom: 20px;
`;

const { autoReply } = MESSAGE_TYPES;
const { user } = USER_ROLES;

function AutomatedMessage(props) {
  const getValue = (type, account) => {
    const settingValue = get(
      account,
      ["settings", `${type}Message`, "value"],
      type === autoReply ? { message: "", officeHours: false } : "",
    );

    if (type === autoReply && settingValue !== null && !settingValue?.message) {
      settingValue.message = "";
    }
    return settingValue;
  };

  const {
    account,
    choices,
    currentUser,
    descriptionText,
    type,
    toggleSidebar,
  } = props;

  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: account.shortenedLinkPreviewUrl,
  });

  const { conversationMaxMessageLength } = account.settings;

  const maxCharacterLength =
    conversationMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH;
  const { handleSubmit } = useAccountSettingsSubmit(type, shortenedLink);

  const automatedMessageSettings = [`${type}Message`, `${type}MessageEnabled`];

  const locked = automatedMessageSettings.some((settingName) => {
    const setting = account.settings[settingName];
    return setting?.locked;
  });

  const lockedAtAccount =
    automatedMessageSettings.map((setting) => {
      return account.settings[setting]?.lockedAtAccount;
    })[0] || {};

  const disabled =
    automatedMessageSettings.some((setting) => {
      return account.settings[setting]?.disabled;
    }) || locked;

  const message = {
    disabled: get(account, ["settings", `${type}Message`, "disabled"], false),
    locked: get(account, ["settings", `${type}Message`, "locked"], false),
    lockedAtAccount: get(
      account,
      ["settings", `${type}Message`, "lockedAtAccount"],
      {
        id: null,
        name: null,
      },
    ),
    value: getValue(type, account),
  };

  const messageEnabled = {
    disabled: get(
      account,
      ["settings", `${type}MessageEnabled`, "disabled"],
      false,
    ),
    locked: get(
      account,
      ["settings", `${type}MessageEnabled`, "locked"],
      false,
    ),
    lockedAtAccount: get(
      account,
      ["settings", `${type}MessageEnabled`, "lockedAtAccount"],
      {
        id: null,
        name: null,
      },
    ),
    value: get(account, ["settings", `${type}MessageEnabled`, "value"], false),
  };

  const initialValues = {
    settings: {
      [`${type}Message`]: {
        value: message.value,
      },
      [`${type}MessageEnabled`]: {
        value: messageEnabled.value,
      },
    },
  };

  const validationSchema = () => {
    switch (type) {
      default:
        return AutoReplyMessageValidationSchema(maxCharacterLength);
    }
  };
  const isParent = account.familyCount > 1;
  const subAccountCount = account.familyCount - 1;

  const userRole = getUserRole(currentUser, account);

  const lockedBannerText =
    userRole === user ? (
      "This setting can only be modified by your administrator."
    ) : (
      <span>
        Locked by an admin for accounts below{" "}
        <strong>{lockedAtAccount.name}.</strong>
      </span>
    );

  return (
    <SettingsPageWrapper aria-label={`${startCase(type)} Message Form`}>
      {!account.featureFlags?.settingsSidebarRedesign && (
        <PageHeader
          title={`${startCase(type)} Message`}
          toggleSidebar={toggleSidebar}
        >
          <LockForm
            account={account}
            handleSubmit={handleSubmit}
            settings={[`${type}MessageEnabled`, `${type}Message`]}
          />
        </PageHeader>
      )}
      <SettingsPageContent
        aria-label={`${type} settings content`}
        overflow="auto"
      >
        <Box display="flex" flexDirection="column" gap={2} marginBottom="1rem">
          {isParent ? (
            <Alert severity="warning">
              Modifying this may overwrite changes to{" "}
              <strong>{subAccountCount} subaccounts.</strong>
            </Alert>
          ) : null}
          {locked ? (
            <Alert severity="info" icon={<LockIcon color="primary" />}>
              {lockedBannerText}
            </Alert>
          ) : null}
        </Box>
        {descriptionText && descriptionText()}
        <FormWrapper>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            isInitialValid={validationSchema().isValidSync(initialValues)}
            onSubmit={handleSubmit}
            validationSchema={validationSchema()}
          >
            {({
              errors = {},
              touched = {},
              isSubmitting,
              isValid,
              values,
              setFieldValue,
            } = {}) => {
              return (
                <Box
                  padding={disabled ? "30px 20px 20px 20px" : 0}
                  position="relative"
                >
                  <Form>
                    {message.disabled || messageEnabled.disabled ? (
                      <LockOverlay />
                    ) : null}
                    <FormRow>
                      <Field name={`settings.${type}MessageEnabled.value`}>
                        {({ field, form }) => {
                          return (
                            <FormControlLabel
                              control={
                                <Switch
                                  {...field}
                                  size="small"
                                  color="secondary"
                                  checked={field.value}
                                  onChange={(event) => {
                                    const { checked } = event.target;
                                    form.setFieldValue(field.name, checked);
                                  }}
                                  disabled={disabled}
                                />
                              }
                              label={`Enable ${startCase(type)} Message`}
                            />
                          );
                        }}
                      </Field>
                    </FormRow>
                    {choices && choices.options.length > 0 && (
                      <FormRow>
                        <Field
                          name={`settings.${type}Message.value.${choices.choiceType}`}
                        >
                          {({ field, form }) => {
                            return (
                              <TextField
                                select
                                variant="outlined"
                                SelectProps={{
                                  native: true,
                                }}
                                fullWidth
                                name={`${type}-settings-options`}
                                label="When should this message be sent?"
                                disabled={
                                  !getIn(
                                    values,
                                    `settings.${type}MessageEnabled.value`,
                                  )
                                }
                                onChange={(event) => {
                                  form.setFieldValue(
                                    field.name,
                                    event.target.value,
                                  );
                                  choices.updateChoice(event.target.value);
                                }}
                                value={choices.choice}
                              >
                                {choices.options.map((option) => {
                                  return (
                                    <option
                                      key={option.title}
                                      value={option.value}
                                    >
                                      {option.title}
                                    </option>
                                  );
                                })}
                              </TextField>
                            );
                          }}
                        </Field>
                        {choices.warning && (
                          <Box
                            aria-label="Choice Warning"
                            fontSize="10"
                            mb={-3}
                          >
                            {choices.warning}
                          </Box>
                        )}
                      </FormRow>
                    )}
                    <MessageInputFormRow
                      currentAccount={account}
                      errors={errors}
                      fieldName={`settings.${type}Message.value.message`}
                      isDisabled={
                        !getIn(
                          values,
                          `settings.${type}MessageEnabled.value`,
                        ) || disabled
                      }
                      hideUserPersonalization={type === autoReply}
                      label="Message"
                      maxChars={maxCharacterLength}
                      setFieldValue={setFieldValue}
                      setShortenedLink={setShortenedLink}
                      shortenedLink={shortenedLink}
                      touched={touched}
                      values={values}
                    />
                    <SubmitRow>
                      <Button
                        color="primary"
                        disabled={!isValid || isSubmitting || disabled}
                        type="submit"
                        variant="contained"
                      >
                        Save
                      </Button>
                    </SubmitRow>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </FormWrapper>
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

AutomatedMessage.propTypes = {
  account: PropTypes.object.isRequired,
  choices: PropTypes.object,
  currentUser: PropTypes.object,
  descriptionText: PropTypes.func,
  toggleSidebar: PropTypes.func.isRequired,
  type: PropTypes.string,
};

AutomatedMessage.defaultProps = {
  type: autoReply,
};

export default AutomatedMessage;
