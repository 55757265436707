import { Typography } from "@mui/material";
import { User } from "@tesseract/core";
import Avatar from "components/Avatar";

export default function MySettingsModule({
  me,
  children,
}: {
  me: User.Raw;
  children: React.ReactNode;
}) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          padding: "20px",
        }}
      >
        <Avatar subject={me} size={38} />
        <div>
          <Typography variant="overline">My Settings</Typography>
          <Typography fontSize="small" color="text.secondary">
            {me.name}
          </Typography>
        </div>
      </div>
      {children}
    </div>
  );
}
