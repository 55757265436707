import { all, put, select, takeLatest } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";
import { createSelector } from "reselect";
import { push } from "connected-react-router";

import * as schema from "schema";
import {
  selectCurrentAccount,
  selectRecords,
} from "features/EntryPoint/containers/App/selectors";
import generateContainerState from "utils/generateContainerState";

const {
  actionTypes,
  actionGenerators,
  sagas,
  reducers,
  selectors: campaignSelectors,
} = generateContainerState([
  {
    container: "EditCampaign",
    crudActions: ["update"],
    recordType: "campaign",
    schema: schema.campaign,
  },
]);

function* redirectOnSuccess({ response }) {
  const currentAccount = yield select(selectCurrentAccount);
  const [, , campaignSlug] = response.id.split("/");
  yield put(push(`/${currentAccount.slug}/campaigns/all/${campaignSlug}`));
}

function* saga() {
  yield all([
    takeLatest(
      actionTypes.UPDATE_CAMPAIGN_REQUEST,
      sagas.updateCampaignRequest,
    ),
    takeLatest(actionTypes.UPDATE_CAMPAIGN_SUCCESS, redirectOnSuccess),
  ]);
}

const reducer = combineReducers(reducers);

// SELECTORS
const selectDeliveryStats = createSelector(
  selectRecords,
  selectCurrentAccount,
  (records, currentAccount) => {
    const deliveryStats = records.get(
      `/${currentAccount.slug}/deliveries/stats`,
    );
    return deliveryStats ? deliveryStats.toJS() : {};
  },
);
const selectors = {
  ...campaignSelectors,
  selectDeliveryStats,
};

export { actionGenerators, actionTypes, saga, reducer, selectors };
