import { Keyword } from "@tesseract/core";
import { useCallback, useState } from "react";
import { fetchKeyword } from "../api/fetchKeyword";
import { useCurrentAccount } from "hooks";

const useKeyword = () => {
  const currentAccount = useCurrentAccount();
  const [keyword, setKeyword] = useState<Keyword.Raw>();
  const [state, setState] = useState<"waiting" | "loading" | "error" | "done">(
    "waiting",
  );
  const [error, setError] = useState<Error>();

  const load = useCallback(
    (id: string) => {
      setState("loading");
      setError(undefined);

      const controller = new AbortController();

      fetchKeyword(currentAccount, id)
        .then((response) => {
          return response.json();
        })
        .then((data: Keyword.Raw) => {
          setKeyword(data);
          setState("done");

          return data;
        })

        .catch((error_) => {
          setState("error");
          setError(error_);
        });

      return () => {
        setState("waiting");
        controller.abort();
      };
    },
    [currentAccount],
  );

  return {
    load,
    keyword,
    state,
    error,
  };
};

export { useKeyword };
