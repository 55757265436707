import { matchPath, Redirect } from "react-router-dom";
import Root from "features/Contacts/containers/ContactFilterCollection/components/Root";
import { useContactFilterCollection } from "features/Contacts/hooks/useContactFilterCollection";

function ContactFilterCollection() {
  const match: { params: {accountSlug: string}} | null = matchPath(document.location.pathname, {
    path: "/:accountSlug/contacts",
    exact: true,
  });
  const { contactFilterCollection } = useContactFilterCollection();

  if (match && contactFilterCollection?.members && contactFilterCollection.members.length > 0) {
    return (
      <Redirect
        to={`/${match.params.accountSlug}/contacts/${contactFilterCollection?.members?.[0].slug}`}
      />
    );
  }

  return <Root />;
}

export default ContactFilterCollection;
