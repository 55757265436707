import { useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";

interface NavLinkWithThemeProps {
  to: string;
  style: object;
  children: React.ReactNode;
}

export default function NavLinkWithTheme({
  to,
  style = {},
  children,
}: NavLinkWithThemeProps) {
  const theme = useTheme();
  const styles = {
    color: theme.palette.text.primary,
    textDecoration: "none",
    ...style,
  };
  return (
    <NavLink to={to} style={styles}>
      {children}
    </NavLink>
  );
}
