/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { compose } from "redux";
import { useMediaQuery } from "@mui/material";
import KeywordAutomation from "./screens/KeywordAutomation";
import KeywordGroup from "./screens/KeywordGroup";
import KeywordGroups from "./screens/KeywordGroups";
import {
  AutomationProps,
  AutomationNavValues,
} from "./models/AutomationModels";
import { useAutomationNavLinks } from "./hooks/useAutomationNavLinks";
import useKeywordGroups from "./hooks/useKeywordGroups";
import AutomationSidebar from "./components/AutomationSidebar";
import { useKeywordLinking } from "features/Sequences/hooks/useKeywordLinking";
import PageWrapper from "components/Page/PageWrapper";
import KeywordForm from "features/Keywords/components/KeywordForm";
import Keyword from "features/Keywords/components/Keyword";
import Keywords from "features/Keywords/components/Keywords";

import {
  AUTOMATION_PATHS,
  SECONDARY_NAV_LINKS,
} from "features/Automation/constants/AutomationConstants";
import { IndividualSequenceOverview } from "features/Sequences/screens/IndividualSequenceOverview";
import { Sequences } from "features/Sequences/screens/SequencesOverview";
import withSidebar from "higherOrderComponents/withSidebar";
import type { KeywordResponse } from "models/Keyword";
import MobileKeywordSearch from "features/Keywords/components/MobileKeywordSearch";
import { useTabs } from "hooks";
import PageContent from "components/Page/PageContent";

function Automation({
  currentAccount,
  location,
  showSidebar,
  toggleSidebar,
}: AutomationProps): JSX.Element {
  const { automation, keywordsMain, keywordGroups, sequences } =
    AUTOMATION_PATHS;
  const { mine } = SECONDARY_NAV_LINKS;

  const [selectedSequenceNavLink, setSelectedSequenceNavLink] =
    useState<AutomationNavValues>(mine);
  const [selectedKeywordNavLink, setSelectedKeywordNavLink] =
    useState<AutomationNavValues>(mine);

  const [keywords, setKeywords] = useState<KeywordResponse | null>(null);
  const { slug } = currentAccount ?? {};
  const { initialPage, keywordsEnabled } = useAutomationNavLinks();
  const {
    selectedTab: selectedKeywordTab,
    handleTabChange: handleKeywordTabChange,
  } = useTabs();

  const {
    confirmUnlinkRef,
    links,
    linkKeyword,
    setLinks,
    toggleUnlinkDialog,
    unlinkKeyword,
  } = useKeywordLinking();

  const { rowsPerPage, loading, page, getRow, handlePageChange } =
    useKeywordGroups(keywords, setKeywords);

  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  useEffect(() => {
    const initialLinks = keywords?.items
      ?.filter((keyword) => {
        // filters for only Keywords with a linked Sequence
        return keyword.sequences?.length > 0;
      })
      .map((link) => {
        return {
          sequence: {
            id: link.sequences[0].id,
            name: link.sequences[0].name,
          },
          keyword: {
            id: link.id,
            keyword: link.keyword,
          },
        };
      });
    setLinks(initialLinks ?? []);
  }, [keywords]);

  const url = `/${slug}/${automation}`;
  const keywordsMainUrl = `${url}/${keywordsMain}`;
  const keywordGroupsUrl = `${url}/${keywordGroups}`;
  const sequencesUrl = `${url}/${sequences}`;

  const { keywordAnswers } = currentAccount.featureFlags;

  return (
    <PageWrapper>
      {/* ==== SIDEBAR NAV LINKS ==== */}
      <AutomationSidebar
        keywordsMainUrl={keywordsMainUrl}
        keywordGroupsUrl={keywordGroupsUrl}
        sequencesUrl={sequencesUrl}
        location={location}
        keywordAnswers={keywordAnswers}
        selectedSequenceNavLink={selectedSequenceNavLink}
        setSelectedSequenceNavLink={setSelectedSequenceNavLink}
        selectedKeywordNavLink={selectedKeywordNavLink}
        setSelectedKeywordNavLink={setSelectedKeywordNavLink}
        showSidebar={showSidebar}
        toggleSidebar={toggleSidebar}
      />

      {/* ==== ROUTES ==== */}
      {/* Wait for initial page to be set. */}
      {initialPage ? (
        <PageContent
          isSinglePanel={mobileScreen}
          showSidebar={
            keywordAnswers ? !mobileScreen && showSidebar : showSidebar
          }
        >
          <Switch location={location.path}>
            {/* /automation */}
            <Route
              exact
              path={url}
              render={() => {
                return <Redirect to={`${url}/${initialPage}`} />;
              }}
            />

            {/* /automation/keywords */}
            <Route
              exact
              path={keywordsMainUrl}
              render={() => {
                // User tried to navigate directly to this page without proper license.
                if (!keywordsEnabled) {
                  return <Redirect to={`${url}/${initialPage}`} />;
                }

                return keywordAnswers ? (
                  <Keywords
                    keywords={keywords}
                    selectedKeywordNavLink={selectedKeywordNavLink}
                    selectedKeywordTab={selectedKeywordTab}
                    handleTabChange={handleKeywordTabChange}
                    setKeywords={setKeywords}
                    toggleSidebar={toggleSidebar}
                  />
                ) : (
                  <KeywordAutomation
                    currentAccount={currentAccount}
                    keywords={keywords}
                    setKeywords={setKeywords}
                    toggleSidebar={toggleSidebar}
                  />
                );
              }}
            />

            {/* /automation/keywords/search */}
            <Route
              exact
              path={`${keywordsMainUrl}/search`}
              render={() => {
                if (!keywordsEnabled || !keywordAnswers) {
                  return <Redirect to={`${url}/${initialPage}`} />;
                }
                return (
                  <MobileKeywordSearch
                    selectedKeywordNavLink={selectedKeywordNavLink}
                    selectedKeywordTab={selectedKeywordTab}
                    toggleSidebar={toggleSidebar}
                  />
                );
              }}
            />

            {/* /automation/keywords/new */}
            <Route
              exact
              path={`${keywordsMainUrl}/new`}
              render={() => {
                if (!keywordsEnabled || !keywordAnswers) {
                  return <Redirect to={`${url}/${initialPage}`} />;
                }

                return (
                  <KeywordForm
                    currentAccount={currentAccount}
                    toggleSidebar={toggleSidebar}
                  />
                );
              }}
            />

            {/* /automation/keywords/:id/edit */}
            <Route
              exact
              path={`${keywordsMainUrl}/:keywordId/edit`}
              render={({ match }) => {
                if (!keywordsEnabled || !keywordAnswers) {
                  return <Redirect to={`${url}/${initialPage}`} />;
                }

                const { keywordId } = match?.params ?? {};
                return (
                  <KeywordForm
                    keywordId={keywordId}
                    currentAccount={currentAccount}
                    toggleSidebar={toggleSidebar}
                  />
                );
              }}
            />

            {/* /automation/keywords/:id */}
            <Route
              exact
              path={`${keywordsMainUrl}/:keywordId`}
              render={({ match }) => {
                if (!keywordsEnabled || !keywordAnswers) {
                  return <Redirect to={`${url}/${initialPage}`} />;
                }

                const { keywordId } = match?.params ?? {};
                return (
                  <Keyword
                    currentAccount={currentAccount}
                    keywordId={keywordId}
                    toggleSidebar={toggleSidebar}
                  />
                );
              }}
            />

            {/* /automation/keywords_groups */}
            <Route
              exact
              path={keywordGroupsUrl}
              render={() => {
                // User tried to navigate directly to this page without proper license.
                if (!keywordsEnabled) {
                  return <Redirect to={`${url}/${initialPage}`} />;
                }

                return (
                  <KeywordGroups
                    keywords={keywords}
                    loading={loading}
                    links={links}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    getRow={getRow}
                    handlePageChange={handlePageChange}
                    setLinks={setLinks}
                    toggleSidebar={toggleSidebar}
                  />
                );
              }}
            />

            {/* /automation/keywords_groups/:id */}
            <Route
              exact
              path={`${keywordGroupsUrl}/:keywordGroupId`}
              render={({ match }) => {
                const { keywordGroupId } = match?.params ?? {};
                const contactCollectionId = `/${currentAccount.slug}/contact_filters/keyword:${keywordGroupId}/contacts`;
                return (
                  <KeywordGroup
                    confirmUnlinkRef={confirmUnlinkRef}
                    contactCollectionId={contactCollectionId}
                    keywordGroupId={keywordGroupId}
                    links={links}
                    linkKeyword={linkKeyword}
                    toggleSidebar={toggleSidebar}
                    toggleUnlinkDialog={toggleUnlinkDialog}
                  />
                );
              }}
            />

            {/* /automation/sequences */}
            <Route
              exact
              path={sequencesUrl}
              render={() => {
                return (
                  <Sequences
                    currentAccount={currentAccount}
                    returnPath={sequencesUrl}
                    selectedNavLink={selectedSequenceNavLink}
                    setSelectedNavLink={setSelectedSequenceNavLink}
                    toggleSidebar={toggleSidebar}
                  />
                );
              }}
            />

            {/* /automation/sequences/:id */}
            <Route
              exact
              path={`${sequencesUrl}/:sequenceId`}
              render={({ match }) => {
                const { sequenceId } = match?.params ?? {};
                return (
                  <IndividualSequenceOverview
                    currentAccount={currentAccount}
                    returnPath={sequencesUrl}
                    sequenceId={sequenceId}
                    showSidebar={showSidebar}
                    toggleSidebar={toggleSidebar}
                    unlinkKeyword={unlinkKeyword}
                  />
                );
              }}
            />
          </Switch>
        </PageContent>
      ) : null}
    </PageWrapper>
  );
}

export default compose(withSidebar)(Automation);
