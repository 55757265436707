import { Contact } from "@tesseract/core";
import { textUsApiV4 } from "services";

function batchUpdateContactsV4(
  contactCollectionId: string,
  // note this parameter should be "hashedContactId"[] NOT "/contacts/hashedContactId"[]
  ids?: string[],
) {
  return textUsApiV4.put(contactCollectionId, { ids });
}

export { batchUpdateContactsV4 };
