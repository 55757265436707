/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Fab,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import useKeywordTable from "../hooks/useKeywordTable";
import { fetchKeywordsCounts } from "../api";
import KeywordTable from "./KeywordTable";
import { useCurrentAccount, useCurrentUser } from "hooks";
import PageHeader from "components/Page/PageHeader";
import { KeywordsProps } from "features/Keywords/types";
import { SearchBox } from "components/SearchBox";
import { AddIcon } from "icons";
import { SECONDARY_NAV_LINKS } from "features/Automation/constants/AutomationConstants";
import { ACCOUNT_SETTINGS_KEYS } from "features/AccountSettings/constants/settingsKeys";

function Keywords({
  keywords,
  selectedKeywordNavLink,
  selectedKeywordTab,
  setKeywords,
  handleTabChange,
  toggleSidebar,
}: KeywordsProps) {
  /* STATE */
  const [keywordCounts, setKeywordCounts] = useState<{
    currentFilter: { active: number; archived: number };
    accountTotals: { active: number; archived: number };
  } | null>(null);

  /* HOOKS */
  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });
  const history = useHistory();

  const currentAccount = useCurrentAccount();
  const { id: userId } = useCurrentUser();

  const {
    debouncedSearchTerm,
    fetchFirstPage,
    fetchNextPage,
    handleClear,
    handleSearch,
    isLoading,
    searchTerm,
    userLocked,
  } = useKeywordTable(keywords, setKeywords);

  /* VARIABLES */
  const settingKey = ACCOUNT_SETTINGS_KEYS.keywordsLock;
  const mine = selectedKeywordNavLink === SECONDARY_NAV_LINKS.mine;
  const strippedUserId = userId.replace("/users/", "");
  const ACCOUNT_KEYWORD_LIMIT = 100;
  const rateLimited =
    (keywordCounts?.accountTotals.active ?? 0) >= ACCOUNT_KEYWORD_LIMIT;

  /* FUNCTIONS */
  const handleCreateClick = () => {
    // Path: /automation/keywords/new
    history.push(`${window.location.pathname}/new`);
  };

  const getCounts = async () => {
    const response = await fetchKeywordsCounts(
      currentAccount,
      mine,
      strippedUserId,
    );

    const counts = await response.json();

    return setKeywordCounts(counts);
  };

  const getTooltipText = () => {
    if (rateLimited && !userLocked) {
      return `Account keyword limit: ${ACCOUNT_KEYWORD_LIMIT}`;
    }

    if (userLocked) {
      return "Disabled by admin";
    }

    return "";
  };

  /* EFFECTS */
  useEffect(() => {
    fetchFirstPage({
      active: selectedKeywordTab === 0,
      items: 30,
      keywordCont: debouncedSearchTerm,
      mine,
    }).catch((error) => {
      console.error(error);
    });
  }, [debouncedSearchTerm, selectedKeywordTab, selectedKeywordNavLink]);

  useEffect(() => {
    getCounts().catch((error) => {
      console.error(error);
    });
  }, [keywords, selectedKeywordNavLink]);

  return (
    <>
      <PageHeader
        aria-label="Keywords"
        title="Keywords"
        toggleSidebar={toggleSidebar}
        displaySearch={mobileScreen}
        settingKey={settingKey}
        toggleSearch={() => {
          // Path: /automation/keywords/search
          history.push(`${window.location.pathname}/search`);
        }}
      />
      {mobileScreen ? (
        <Fab
          aria-label="Add Keyword"
          data-testid="create-keyword-fab"
          color="primary"
          size="medium"
          disabled={userLocked || rateLimited}
          onClick={handleCreateClick}
          sx={{ position: "absolute", right: "32px", bottom: "16px" }}
        >
          <AddIcon />
        </Fab>
      ) : (
        <>
          <Box
            display="flex"
            gap="8px"
            justifyContent="space-between"
            sx={{ margin: "8px 16px" }}
          >
            <SearchBox
              value={searchTerm}
              onClear={handleClear}
              onChange={handleSearch}
              placeholder="Search keywords"
              slotProps={{ input: { sx: { height: "40px" } } }}
            />
            <Tooltip title={getTooltipText()}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "124px",
                  paddingLeft: "16px",
                }}
              >
                <Button
                  data-testid="create-keyword-button"
                  size="large"
                  variant="contained"
                  startIcon={<AddIcon fontSize="small" />}
                  onClick={handleCreateClick}
                  disabled={userLocked || rateLimited}
                >
                  Keyword
                </Button>
              </Box>
            </Tooltip>
          </Box>
          <Divider />
        </>
      )}
      <Tabs value={selectedKeywordTab} onChange={handleTabChange}>
        <Tab label={`Active (${keywordCounts?.currentFilter.active ?? 0})`} />
        <Tab
          label={`Archive (${keywordCounts?.currentFilter.archived ?? 0})`}
        />
      </Tabs>
      <Divider />
      <KeywordTable
        showingActiveKeywords={selectedKeywordTab === 0}
        mine={selectedKeywordNavLink === SECONDARY_NAV_LINKS.mine}
        debouncedSearchTerm={debouncedSearchTerm}
        fetchNextPage={fetchNextPage}
        isLoading={isLoading}
        keywords={keywords}
        refresh={() => {
          return fetchFirstPage({
            active: selectedKeywordTab === 0,
            items: 30,
            keywordCont: debouncedSearchTerm,
            mine: selectedKeywordNavLink === SECONDARY_NAV_LINKS.mine,
          });
        }}
        userLocked={userLocked}
        sx={mobileScreen ? { height: "calc(100vh - 216px)" } : {}} // Keeps bottom row visible above mobile navbar
      />
    </>
  );
}

export default Keywords;
