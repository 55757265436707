import { useState } from "react";
import uniq from "lodash/uniq";
import {
  Autocomplete,
  createFilterOptions,
  Box,
  TextField,
  AutocompleteChangeReason,
  FilterOptionsState,
} from "@mui/material";

import { useContactTagCollection } from "features/Contacts/hooks/useContactTagCollection";

export interface ContactFormTagsProps {
  changeHandler: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  contactTagsId: string;
  value: string[];
}

type Option = {
  label: string;
} & (
  | { value: string; inputValue: never }
  | { inputValue: string; value: never }
);

function ContactFormTags({ value, changeHandler, contactTagsId }: ContactFormTagsProps) {
  const [shouldRequest, setShouldRequest] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const filter = createFilterOptions();

  const { contactTagCollection } = useContactTagCollection({
    contactTagCollectionId: contactTagsId,
  });

  const handleChange = (
    _event: React.SyntheticEvent,
    selectedOptions: unknown[],
    action: AutocompleteChangeReason,
  ) => {
    switch (action) {
      case "clear":
      case "createOption":
      case "removeOption":
      case "selectOption":
        return changeHandler(
          "data.tags",
          uniq(
            (selectedOptions as Option[]).map((selectedOption) => {
              if (selectedOption.value) {
                return selectedOption.value.replaceAll(/["',]/g, "").trim();
              }

              return selectedOption.inputValue.replaceAll(/["',]/g, "").trim();
            }),
          ),
        );
      default:
        return null;
    }
  };

  const requestTags = () => {
    if (shouldRequest) {
      const newOptions: Option[] =
        contactTagCollection?.members?.map((tag) => {
          return {
            value: tag.id,
            label: tag.tag,
          } as Option;
        }) || [];
      setOptions(newOptions);
      setShouldRequest(false);
    }
  };

  const selectedValues: Option[] = value.map((val) => {
    return { value: val, label: val } as Option;
  });

  const filterOptions = (
    opts: unknown[],
    params: FilterOptionsState<unknown>,
  ) => {
    const filtered = filter(opts, params);

    if (params.inputValue !== "") {
      return [
        ...filtered,
        {
          inputValue: params.inputValue,
          label: `Add "${params.inputValue}"`,
        },
      ];
    }

    return filtered;
  };

  return (
    <Box position="relative" width="100%" mb="0">
      <Autocomplete
        ChipProps={{
          size: "small",
        }}
        aria-label="Add Tag"
        data-testid="add-tag"
        multiple
        id="autocomplete-tags"
        options={options}
        onOpen={requestTags}
        filterOptions={filterOptions}
        getOptionLabel={(option: unknown) => {
          const typedOption = option as Option;
          return typedOption.label;
        }}
        isOptionEqualToValue={(option: unknown) => {
          const typedOption = option as Option;
          return !!selectedValues?.find((item) => {
            return typedOption.value === item.value;
          });
        }}
        value={selectedValues}
        onChange={handleChange}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              onFocus={() => {
                setIsFocused(true);
              }}
              onBlur={() => {
                setIsFocused(false);
              }}
              label={
                isFocused || value.length > 0 ? "Tags" : "Add or create tags"
              }
              aria-label="Add or create tags"
              data-testid="add-or-create-tags"
            />
          );
        }}
      />
    </Box>
  );
}

export default ContactFormTags;
