import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { normalize } from "normalizr";
import { ContactPhone } from "@tesseract/core";
import { fetchContactPhoneCollectionV4 } from "../api";
import * as schema from "schema";
import { UPDATE_RECORDS } from "features/EntryPoint/containers/App/constants";

function useContactPhoneCollection() {
  const [contactPhoneCollection, setContactPhoneCollection] = useState<
    ContactPhone.Collection | Record<string, undefined>
  >({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const reshapeV4Response = useCallback(
    (data: any, contactPhoneCollectionId: string): ContactPhone.Collection => {
      return {
        // TODO 1. add the view prop (not sure whether this will work out of the box or require updates elsewhere)
        // TODO 2. figure out whether the rest of the app will work with the different members[context/type]
        "@type": "hydra:Collection",
        id: contactPhoneCollectionId as ContactPhone.CollectionId,
        totalItems: data.items.length,
        members: data.items.map((item: any) => ({
          // v3 API props:
          //  "@context": "/contexts/RecipientContactPhone.jsonld",
          //  "@type": "RecipientContactPhone",
          // v4 API props:
          //  "@context": "/contexts/ContactPhone.jsonld"
          //  "@type": "ContactPhone"
          ...item,
          phones: `/contacts/${item.id}/phones`,
          notes: `/contacts/${item.id}/notes`,
          conversations: `/contacts/${item.id}/conversations`,
        })),
        // TODO - figure out if the v4 API returns any pagination info...
        view: {
          "@type": "hydra:PartialCollectionView",
          "@context": "/contexts/hydra:PartialCollectionView.jsonld",
          id: data.id,
          first: "/twilio-dev/contact_phones",
          next: null,
          previous: null,
        },
      };
    },
    [],
  );

  const sendFetchRequest = useCallback(
    async (contactPhoneCollectionId: string) => {
      if (!contactPhoneCollectionId) return;

      setLoading(true);

      const res2 = await fetchContactPhoneCollectionV4(
        contactPhoneCollectionId,
      );
      const rawData = await res2.json();
      const data: ContactPhone.Collection = reshapeV4Response(
        rawData,
        contactPhoneCollectionId,
      );

      // update data for use in component
      setContactPhoneCollection(data);

      // normalize data & dispatch to redux store
      // TODO: determine what the proper schema is for contactPhones
      const { entities } = normalize(data, schema.contactPhoneCollection);

      dispatch({
        type: UPDATE_RECORDS,
        records: entities,
      });

      setLoading(false);
    },
    [dispatch, reshapeV4Response],
  );

  return {
    contactPhoneCollection,
    sendFetchRequest,
    loading,
    setLoading,
  };
}

export { useContactPhoneCollection };
