import { useDispatch } from "react-redux";
import { Contact } from "@tesseract/core";
import { useCallback, useState } from "react";
import { normalize } from "normalizr";
import { fetchContact } from "../api/fetchContact";
import { updateContact } from "features/Contact/api";
import { FormValues } from "features/ContactForm";
import actionTypes from "features/PusherComponent/actionTypes";

import * as schema from "schema";
import { UPDATE_RECORDS } from "features/EntryPoint/containers/App/constants";

export const useContact = () => {
  const [contact, setContact] = useState<Contact.Raw>();
  const dispatch = useDispatch();
  const [state, setState] = useState<"waiting" | "loading" | "error" | "done">(
    "waiting",
  );
  const [error, setError] = useState<Error>();

  const load = useCallback((id: Contact.Identity) => {
    setState("loading");
    setError(undefined);

    const controller = new AbortController();

    fetchContact(id, {
      signal: controller.signal,
    })
      .then((response) => {
        return response.json();
      })
      .then((data: Contact.Raw) => {
        setContact(data);
        setState("done");

        return data;
      })

      .catch((error_) => {
        setState("error");
        setError(error_);
      });

    return () => {
      setState("waiting");
      controller.abort();
    };
  }, []);

  /**
   * Sends a PATCH request to the server to update the Contact record.
   * Upon a successful response, it updates the Contact record in the Redux state.
   *
   * @async
   * @function myThing
   * @param {string} id - The ID of the contact to be updated.
   * @param {Object} payload - The contact data to be patched. The structure of the payload
   *                            depends on the fields that need to be updated for the contact.
   * @returns {Promise<void>} A promise that resolves once the update operation is complete
   *                          and the Redux state is updated.
   */
  const updateContactHandler = async (
    id: Contact.Identity,
    payload: FormValues,
  ) => {
    try {
      const res = await updateContact(id, payload);
      const data = await res.json();

      // normalize data & dispatch to redux store
      const { entities } = normalize(data, schema.contact);

      // update the redux store
      dispatch({
        type: UPDATE_RECORDS,
        records: entities,
      });
    } catch (error_: unknown) {
      setState("error");
      setError(error_ as Error);
    }
  };

  return {
    load,
    contact,
    updateContactHandler,
    state,
    error,
  };
};
