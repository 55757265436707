import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { Box, Button, Typography, Grid2 as Grid } from "@mui/material";

import { FullFileLabel } from "../../helper-components/file-name/FullFileLabel";
import BlankState from "components/BlankState";
import MultipleSuccessGraphic from "utils/images/confirmation-checkmark.svg";
import PageHeader from "components/Page/PageHeader";
import PageFooter from "components/Page/PageFooter";
import PageLoader from "components/PageLoader";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 0 auto;
`;

const Success = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  gap: 16px;
  justify-content: center;
  margin: 0 auto;
  max-width: 400px;
  min-height: 0;
  padding: 20px;
  text-align: center;
  a {
    margin: 10px 0;
  }
`;

const ImportCategory = styled(Typography)`
  text-align: left;
`;

const ImportValue = styled(Typography)`
  text-align: right;
`;

export default class Root extends Component {
  static propTypes = {
    contactImport: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showLongRunningMessage: false,
    };
  }

  componentDidMount() {
    this.longRunningTimeout = setTimeout(() => {
      this.setState({ showLongRunningMessage: true });
    }, 3000);
  }

  componentWillUnmount() {
    clearTimeout(this.longRunningTimeout);
  }

  render() {
    const { contactImport, currentAccount, toggleSidebar } = this.props;
    const { showLongRunningMessage } = this.state;
    const { contactFilter, state, recordCount, failureCount } = contactImport;
    const { featureFlags } = currentAccount;
    if (state !== "processed" && showLongRunningMessage) {
      return (
        <BlankState
          image={
            <Box
              aria-label="hammer and wrench"
              component="span"
              fontSize="45px"
              role="img"
            >
              🛠
            </Box>
          }
          title="We're creating your contacts..."
          subComponent={
            <Box component="p" maxWidth="450px">
              This may take a while. Feel free to use the rest of the app and we
              will send you a notification when the import is complete.
            </Box>
          }
        />
      );
    }
    if (state !== "processed") {
      return <PageLoader />;
    }
    const contactFilterId =
      typeof contactFilter === "string" ? contactFilter : contactFilter.id;
    const [, accountSlug, , contactFilterSlug] = contactFilterId.split("/");
    const successCount = recordCount - failureCount;
    const dailyCampaignRecipients = get(currentAccount, [
      "settings",
      "dailyCampaignRecipients",
      "value",
    ]);
    const messagingEnabled =
      get(currentAccount, ["phoneNumbers"], []).length > 0;
    return (
      <Wrapper>
        <PageHeader
          title={<FullFileLabel contactImport={contactImport} />}
          toggleSidebar={toggleSidebar}
        />

        <Success>
          <img alt="Contact Import Success" src={MultipleSuccessGraphic} />
          {featureFlags.dedupeContacts ? (
            <>
              <Typography variant="h6">Import successful!</Typography>
              <Grid container spacing={0.5}>
                <Grid size={9}>
                  <ImportCategory variant="body1">
                    Total contacts
                  </ImportCategory>
                </Grid>
                <Grid size={3}>
                  <ImportValue variant="body1">
                    <strong>{successCount}</strong>
                  </ImportValue>
                </Grid>
                <Grid size={9}>
                  <ImportCategory variant="body1">New</ImportCategory>
                </Grid>
                <Grid size={3}>
                  <ImportValue variant="body1">
                    <strong>{Math.floor(successCount * 0.75)}</strong>
                  </ImportValue>
                </Grid>
                <Grid size={9}>
                  <ImportCategory variant="body1">Updated</ImportCategory>
                </Grid>
                <Grid size={3}>
                  <ImportValue variant="body1">
                    <strong>{Math.ceil(successCount * 0.25)}</strong>
                  </ImportValue>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Typography variant="h6">Successfully imported!</Typography>
              <Typography variant="body2">
                {`${successCount} contacts were successfully updated or created`}
              </Typography>
            </>
          )}
        </Success>

        <PageFooter>
          {successCount <= dailyCampaignRecipients && messagingEnabled && (
            <Button
              aria-label="Send Campaign Button"
              data-testid="send-campaign-button"
              color="primary"
              className="send-campaign"
              style={{ marginRight: "10px" }}
              component={Link}
              to={{
                pathname: `${global.document.location.pathname
                  .split("/")
                  .slice(0, 2)
                  .join("/")}/campaigns/new`,
                state: { contactImportId: contactImport.id },
              }}
            >
              Send Campaign
            </Button>
          )}
          <Button
            aria-label="View Contacts Button"
            data-testid="view-contacts-button"
            variant="contained"
            color="primary"
            component={Link}
            to={`/${accountSlug}/contacts/${contactFilterSlug}`}
          >
            View Contacts
          </Button>
        </PageFooter>
      </Wrapper>
    );
  }
}
