import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import styled from "styled-components";
import { SettingsSectionWithSubSections } from "models/SettingsSection";

const ListWrapper = styled("div")(({ theme }) => {
  return {
    borderBottom: "1px solid #e0e0e0",
    "&:last-child": {
      borderBottom: "none",
    },
  };
});

function SectionTitle({
  linkProps,
  title,
  path,
  activeParentRoute,
  subRoute,
}: {
  linkProps: any;
  path: string;
  title: string;
  activeParentRoute?: string;
  subRoute?: string;
}) {
  const match = useRouteMatch();

  return (
    <Link
      sx={(theme) => {
        return {
          alignItems: "center",
          color: theme.palette.text.primary,
          display: "flex",
          fontSize: theme.typography.body2.fontSize,
          justifyContent: "space-between",
          padding: "12px 16px",
          width: "100%",
          "&:hover": {
            background: theme.palette.action.hover,
          },
          ...(activeParentRoute === path &&
            !subRoute && {
              background: theme.palette.selected.secondary,
            }),
        };
      }}
      component={RouterLink}
      key={title}
      to={`${match.path}/${path}`.replace("//", "/")}
      underline="none"
      {...linkProps}
    >
      {title}
    </Link>
  );
}

export function SettingsSectionList({
  sections,
  activeParentRoute,
  subRoute,
  match,
  hideTitle,
}: {
  sections: SettingsSectionWithSubSections[];
  activeParentRoute: string;
  subRoute: string;
  match: any;
  hideTitle?: boolean;
}) {
  return (
    <>
      {sections.map(({ sectionTitle, subsections }) => {
        return (
          <ListWrapper key={sectionTitle}>
            {!hideTitle && (
              <Typography
                variant="overline"
                sx={(theme) => {
                  return {
                    display: "block",
                    padding: "8px 16px",
                  };
                }}
              >
                {sectionTitle}
              </Typography>
            )}
            {subsections?.map(({ linkProps, path, settings, title }) => {
              return (
                <div key={path}>
                  {SectionTitle({
                    linkProps,
                    path,
                    title,
                    activeParentRoute,
                    subRoute,
                  })}
                  {activeParentRoute === path && (
                    <ul
                      style={{
                        listStyleType: "none",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {settings.map((setting) => {
                        if (setting.to === "/") {
                          return null;
                        }
                        return (
                          !setting.route && (
                            <li key={setting.title}>
                              <Link
                                underline="none"
                                relative
                                component={RouterLink}
                                to={
                                  setting.redirect
                                    ? `${match.url}/${settings.length > 0 ? setting.to : null}`.replace(
                                        "//",
                                        "/",
                                      )
                                    : `${match.url}/${path}${settings.length > 0 ? setting.to : null}`.replace(
                                        "//",
                                        "/",
                                      )
                                }
                                {...linkProps}
                                sx={(theme) => {
                                  return {
                                    alignItems: "center",
                                    color: theme.palette.text.primary,
                                    display: "flex",
                                    fontSize: theme.typography.body2.fontSize,
                                    justifyContent: "space-between",
                                    padding: "12px 50px",
                                    width: "100%",
                                    "&:hover": {
                                      background: theme.palette.action.hover,
                                    },
                                    ...(subRoute ===
                                      setting.to.replace("/", "") && {
                                      background:
                                        theme.palette.selected.secondary,
                                    }),
                                  };
                                }}
                              >
                                {setting.title}
                              </Link>
                            </li>
                          )
                        );
                      })}
                    </ul>
                  )}
                </div>
              );
            })}
          </ListWrapper>
        );
      })}
    </>
  );
}
