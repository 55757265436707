import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setCurrentAccount } from "features/MainNavigation/state";

// Actions
const SET_TIMESTAMP_JUMP = "SET_TIMESTAMP_JUMP";
const RESET_TIMESTAMP_JUMP = "RESET_TIMESTAMP_JUMP";
const RESET_CONVERSATION = "RESET_CONVERSATION";
const RESET_SIDEBAR = "RESET_SIDEBAR";

const setTimestampJumpAction = (payload: {
  timestamp: string;
  memberId: string;
}) => ({
  type: SET_TIMESTAMP_JUMP,
  payload,
});

const resetConversationAction = () => ({
  type: RESET_CONVERSATION,
});

const resetTimestampJumpAction = () => ({
  type: RESET_TIMESTAMP_JUMP,
});

const resetSidebarAction = () => ({
  type: RESET_SIDEBAR,
});

// Reducer
const defaultTimestampState = {
  timestamp: "",
  memberId: "",
  conversationId: "",
  accountSlug: "",
  memberSlug: "",
  resetConversation: false,
  resetSidebar: false,
};

const timestampJumpReducer = (state = defaultTimestampState, action) => {
  switch (action.type) {
    case SET_TIMESTAMP_JUMP:
      return action.payload;
    case RESET_TIMESTAMP_JUMP:
      return defaultTimestampState;
    case RESET_SIDEBAR:
      return { ...state, resetSidebar: true };
    case RESET_CONVERSATION:
      return { ...state, resetConversation: true };
    default:
      return state;
  }
};

// Selectors
const selectTimestampJump = (state) => state.get("timestampJump");

// Hook
export const useTimestampJump = () => {
  const dispatch = useDispatch();
  const timestampJump = useSelector(selectTimestampJump);
  const history = useHistory();

  const setTimestampJump = (payload) => {
    const path = `/${payload.memberSlug}/inbox/open/${payload.conversationId?.split("/").pop()}`;
    dispatch(setTimestampJumpAction(payload));
    if (!payload.memberSlug) {
      return;
    }

    if (payload.accountSlug === payload.memberSlug) {
      history.push(path);
    } else {
      dispatch(
        setCurrentAccount(`/accounts/${payload.memberSlug}`, {
          pathname: path,
        }),
      );
    }
  };

  const resetSidebar = () => {
    dispatch(resetSidebarAction());
  };

  const resetConversation = () => {
    dispatch(resetConversationAction());
  };

  const resetTimestampJump = () => {
    dispatch(resetTimestampJumpAction());
  };

  return {
    timestampJump,
    setTimestampJump,
    resetTimestampJump,
    resetConversation,
    resetSidebar,
  };
};

// Export the reducer to be added to the store
export { timestampJumpReducer };
