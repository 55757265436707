import {
  lastDayOfMonth,
  lastDayOfQuarter,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  sub,
  subQuarters,
} from "date-fns";

export const subscriberDateRanges = [
  {
    name: "All time",
    value: "All time",
    key: "all-time",
    startDate: null,
    endDate: null,
  },
  {
    name: "Today",
    value: "Today",
    key: "today",
    startDate: new Date(),
    endDate: new Date(),
  },
  {
    name: "Yesterday",
    value: "Yesterday",
    key: "yesterday",
    startDate: sub(new Date(), { days: 1 }),
    endDate: sub(new Date(), { days: 1 }),
  },
  {
    name: "Last 7 days",
    value: "Last 7 days",
    key: "last-7-days",
    startDate: sub(new Date(), { weeks: 1 }),
    endDate: new Date(),
  },
  {
    name: "Last 30 days",
    value: "Last 30 days",
    key: "last-30-days",
    startDate: sub(new Date(), { days: 30 }),
    endDate: new Date(),
  },
  {
    name: "Last 90 days",
    value: "Last 90 days",
    key: "last-90-days",
    startDate: sub(new Date(), { days: 90 }),
    endDate: new Date(),
  },
  {
    name: "Last month",
    value: "Last month",
    key: "last-month",
    startDate: sub(startOfMonth(new Date()), { months: 1 }),
    endDate: lastDayOfMonth(sub(new Date(), { months: 1 })),
  },
  {
    name: "Last quarter",
    value: "Last quarter",
    key: "last-quarter",
    startDate: startOfQuarter(subQuarters(new Date(), 1)),
    endDate: lastDayOfQuarter(subQuarters(new Date(), 1)),
  },
  {
    name: "This year",
    value: "This year",
    key: "this-year",
    startDate: startOfYear(new Date()),
    endDate: new Date(),
  },
];
