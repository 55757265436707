import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { Box, styled, Theme, Typography, useMediaQuery } from "@mui/material";
import { formatDistanceToNow, format } from "date-fns";
import { Unarchive } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { Keyword } from "@tesseract/core";
import { bulkArchiveKeywords, bulkReactivateKeywords } from "../api";
import KeywordTableActionMenu from "./KeywordTableActionMenu";
import { KeywordTableProps } from "features/Keywords/types";
import { ArchiveFilledIcon } from "icons";
import { NoResultsScreen } from "components/Search/NoResultsScreen";
import { GridActionToolbar } from "components/GridActionToolbar";
import { useGridActionToolbar } from "components/GridActionToolbar/hooks/useGridActionToolbar";
import { useCurrentAccount } from "hooks";
import { KeywordResponse } from "models/Keyword";

export const StyledCellLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "100%",
  textDecoration: "none",
  color: "inherit",
  ":hover": {
    textDecoration: "underline",
  },
});

function KeywordTable({
  debouncedSearchTerm,
  fetchNextPage,
  isLoading,
  keywords,
  mine,
  refresh,
  showingActiveKeywords,
  sx,
  userLocked,
}: KeywordTableProps) {
  /* HOOKS */
  const isMobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const currentAccount = useCurrentAccount();
  const { enqueueSnackbar } = useSnackbar();

  const {
    gridApiRef,
    allSelected,
    checkRows,
    clearRows,
    handleSelectRow,
    selectedCount,
    selectedRows,
    setAllSelected,
  } = useGridActionToolbar();

  /* VARIABLES */
  const actionType = showingActiveKeywords ? "archive" : "reactivate";
  const { items = [] } = keywords ?? {};
  const tableLabel = "keyword-table";
  const ids = allSelected ? [] : selectedRows;

  /* FUNCTIONS */
  const handleResponse = (response: Response) => {
    if (!response.ok) {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
    }
    return response.json();
  };

  const handleSuccess = (data: KeywordResponse) => {
    const keywordCount = data.items?.length;
    if (keywordCount > 0) {
      const verb = actionType === "archive" ? "archived" : "reactivated";
      const noun = keywordCount === 1 ? "keyword" : "keywords";
      enqueueSnackbar(`${keywordCount} ${noun} successfully ${verb}.`, {
        variant: "info",
      });
    }
    return refresh();
  };

  const handleError = (error: Error) => {
    console.error(error);
  };

  const handleBulkClick = async () => {
    if (actionType === "archive") {
      return bulkArchiveKeywords(currentAccount, {
        ids,
        mine,
      })
        .then(handleResponse)
        .then(handleSuccess)
        .catch(handleError);
    }

    return bulkReactivateKeywords(currentAccount, {
      ids,
      mine,
    })
      .then(handleResponse)
      .then(handleSuccess)
      .catch(handleError);
  };

  /* COLUMNS */
  const menuColumn: GridColDef = {
    field: "moreVertIcon",
    headerName: "",
    disableColumnMenu: true,
    disableReorder: true,
    filterable: false,
    hideSortIcons: true,
    align: "center",
    width: 10,
    renderCell: (params: GridRenderCellParams) => {
      return userLocked ? null : (
        <KeywordTableActionMenu
          actionType={actionType}
          keyword={params.row}
          refresh={refresh}
          params={params}
        />
      );
    },
  };

  const dateColumn: GridColDef = showingActiveKeywords
    ? {
        field: "updatedAt",
        headerName: "Last edited",
        flex: 1,
        valueFormatter: (params: { value: string }) => {
          return formatDistanceToNow(new Date(params.value), {
            addSuffix: true,
          });
        },
      }
    : {
        field: "updatedAt",
        headerName: "Archived on",
        flex: 1,
        valueFormatter: (params: { value: string }) => {
          return format(new Date(params.value), "MM-dd-yyyy");
        },
      };

  const columns: GridColDef[] = isMobileScreen
    ? [
        {
          field: "keyword",
          headerName: "Name",
          flex: 1,
          type: "string",
          renderCell: ({
            id,
            row: { responseBody },
            value,
          }: GridRenderCellParams) => {
            return (
              <Box sx={userLocked ? { paddingLeft: "6px" } : {}}>
                <StyledCellLink
                  data-testid="keyword-table-cell-link"
                  to={`${window.location.pathname}/${id}?tab=0`}
                >
                  {value}
                </StyledCellLink>
                <Typography variant="caption" color="textSecondary">
                  {responseBody}
                </Typography>
              </Box>
            );
          },
        },
        menuColumn,
      ]
    : [
        {
          field: "keyword",
          headerName: "Name",
          flex: 1,
          renderCell: ({ id, value }: GridRenderCellParams) => {
            return (
              <StyledCellLink
                data-testid="keyword-table-cell-link"
                to={`${window.location.pathname}/${id}?tab=0`}
              >
                {value}
              </StyledCellLink>
            );
          },
        },
        {
          field: "responseBody",
          headerName: "Auto-response",
          flex: 1,
        },
        {
          field: "timesReceived",
          headerName: "Keyword views",
          flex: 1,
          type: "number",
        },
        {
          field: "subKeywords",
          headerName: "Answers",
          flex: 1,
          valueFormatter: (params) => {
            const subKeywords = params.value.map(
              (subKeyword: { id: string; keyword: string }) => {
                return subKeyword.keyword;
              },
            );
            return subKeywords.length > 0 ? subKeywords.join(", ") : "—";
          },
          sortComparator: (row1, row2) => {
            const keywords1 = row1
              .map((subKeyword: { keyword: string }) => subKeyword.keyword)
              .join(", ");
            const keywords2 = row2
              .map((subKeyword: { keyword: string }) => subKeyword.keyword)
              .join(", ");
            return keywords1.localeCompare(keywords2);
          },
        },
        dateColumn,
        menuColumn,
      ];

  return (
    <DataGridPro
      apiRef={gridApiRef}
      data-testid={tableLabel}
      aria-label={tableLabel}
      checkboxSelection={!userLocked}
      columns={columns}
      disableRowSelectionOnClick
      hideFooter
      loading={isLoading}
      onRowSelectionModelChange={handleSelectRow}
      onRowsScrollEnd={() => {
        fetchNextPage()
          .then((newKeywords: Keyword.Response) => {
            // If new rows are loaded when allSelected is true, check the new rows
            if (newKeywords && allSelected) {
              const newKeywordIds = newKeywords.items.map((keyword) => {
                return keyword.id;
              });
              return checkRows(newKeywordIds);
            }
            return undefined;
          })
          .catch((error) => {
            return console.error(error);
          });
      }}
      rows={items}
      rowCount={keywords?.page?.count ?? 0}
      rowHeight={64}
      slots={{
        noRowsOverlay: NoResultsScreen,
        toolbar: selectedCount > 0 ? GridActionToolbar : null,
      }}
      slotProps={{
        baseCheckbox: {
          color: "secondary",
        },
        noRowsOverlay: {
          // @ts-expect-error - noRowsOverlay is a custom slot
          source: "keywords",
          descriptor: showingActiveKeywords ? "active" : "archived",
          active: Boolean(debouncedSearchTerm),
          noItemsCreated: keywords?.items.length === 0,
          noItemsVerb: showingActiveKeywords ? "created" : "archived",
        },
        toolbar: {
          actions: [
            {
              key: showingActiveKeywords ? "archive" : "reactivate",
              title: `${showingActiveKeywords ? "Archive" : "Reactivate"} keyword(s)`,
              clickHandler: handleBulkClick,
              icon: showingActiveKeywords ? (
                <ArchiveFilledIcon />
              ) : (
                <Unarchive />
              ),
              showExpanded: true,
            },
          ],
          allSelected,
          clearRows,
          selectedCount,
          selectAllCount: keywords?.page?.count ?? 0,
          setAllSelected,
          showHeaderCheckbox: true,
        },
      }}
      sx={{
        ...sx,
        "& .MuiDataGrid-columnHeader": userLocked
          ? { paddingLeft: "16px" }
          : {},
      }}
    />
  );
}

export default KeywordTable;
