import { createRef, Component } from "react";
import PropTypes from "prop-types";
import {
  debounce,
  difference,
  get,
  intersection,
  isEmpty,
  orderBy,
  union,
} from "lodash";
import { rgba } from "polished";
import queryString from "query-string";
import {
  Box,
  Checkbox,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  keyframes,
} from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Logo from "components/Logo";
import BlankState from "components/BlankState";
import PageLoader from "components/PageLoader";
import { ROWS_PER_PAGE } from "constants/defaults";

const bounce = keyframes`
  0%,
  10%,
  20%,
  30% {
    transform: translateX(0%);
  }

  5%,
  15%,
  25% {
    transform: translateX(-80%);
  }

  100% {
    transform: translateX(0%);
  }
`;

const HorizontalScrollIndicator = styled("div")(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    width: "50px",
    pointerEvents: "none",
    background: `linear-gradient(
    to right,
    ${rgba(theme.palette.background.paper, 0)},
    ${theme.palette.background.paper}
  )`,
    zIndex: 10,
    "& svg": {
      animation: `${bounce} 10s infinite`,
    },
  };
});

const StyledTableHead = styled(TableHead)(({ theme }) => {
  return {
    backgroundColor: theme.palette.background.paper,
    borderBottom: "none",
    height: "55px",
    position: "sticky",
    top: 0,
    zIndex: 2,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
    "&::before": {
      borderBottom: `1px solid ${theme.palette.divider}`,
      bottom: "-1px",
      content: "''",
      height: "1px",
      left: 0,
      position: "absolute",
      width: "100%",
      zIndex: 4,
    },
  };
});

const StyledHeadCheckbox = styled(Checkbox)({
  left: 0,
  padding: "0 0 0 0.5rem",
  zIndex: 3,
});

const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    borderBottom: "none",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
  };
});

const StyledTableCellCheckbox = styled(Checkbox)(({ theme }) => {
  return {
    backgroundColor: theme.palette.background.paper,
    borderBottom: "none",
    padding: "0 12px",
    position: "sticky",
    left: 0,
    width: "64px",
    zIndex: 1,
  };
});

class Root extends Component {
  debouncedUpdateDivider = debounce(
    (event) => {
      const { showColumnDivider } = this.state;
      const { scrollLeft } = event.target;
      if (showColumnDivider && scrollLeft === 0) {
        this.setState({ showColumnDivider: false });
      }
      if (!showColumnDivider && scrollLeft > 0) {
        this.setState({ showColumnDivider: true });
      }
    },
    100,
    { leading: true },
  );

  static propTypes = {
    blankState: PropTypes.any,
    collapsedColumns: PropTypes.array,
    collection: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    currentPage: PropTypes.number,
    fromContactSearch: PropTypes.bool,
    handleChangePage: PropTypes.func,
    history: PropTypes.object.isRequired,
    horizontalScrollIndicator: PropTypes.bool,
    orderBy: PropTypes.string,
    paginationStyles: PropTypes.object,
    page: PropTypes.number,
    selected: PropTypes.array,
    selectedRecords: PropTypes.array,
    setSelected: PropTypes.func,
    setSelectedRecords: PropTypes.func,
    showExpandButton: PropTypes.bool,
    notExpandable: PropTypes.bool,
    withBatchActions: PropTypes.bool,
    withoutPagination: PropTypes.bool,
    resetToTop: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.Wrapper = createRef();
    this.Table = createRef();

    this.state = {
      expanded: !props.showExpandButton,
      showColumnDivider: false,
      showHorizontalScrollIndicator: false,
    };
  }

  componentDidMount() {
    if (this.Wrapper && this.props.horizontalScrollIndicator) {
      const { scrollWidth, offsetWidth } = this.Wrapper.current;

      if (offsetWidth < scrollWidth) {
        this.setState({ showHorizontalScrollIndicator: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { showExpandButton } = this.props;
    if (prevProps.showExpandButton !== showExpandButton) {
      this.setState({ expanded: !showExpandButton });
    }
  }

  getAddedCellStyle = (column) => {
    const { showExpandButton, withBatchActions } = this.props;
    if (!showExpandButton || !column.primary) return {};
    return withBatchActions
      ? { left: "64px", minWidth: "120px", width: "120px" }
      : { left: "0px", minWidth: "200px", width: "200px" };
  };

  convertIdToUrl = ({ id, page }) => {
    const { history } = this.props;
    const { search } = history.location;
    const searchParams = queryString.parse(search);
    const {
      query: { cursor },
    } = queryString.parseUrl(id);
    const queryParams = {
      ...searchParams,
      cursor,
      page,
    };
    return {
      ...history.location,
      search: `?${queryString.stringify(queryParams)}`,
    };
  };

  handleChangePage = (event, page) => {
    const { collection, handleChangePage, history, resetToTop } = this.props;

    if (handleChangePage) {
      return handleChangePage(event, page);
    }

    let { page: currentPage = "0" } = queryString.parse(
      history.location.search,
    );

    if (resetToTop) {
      // Scroll to top of table after page change.
      this.Table.current.scrollIntoView();
    }

    currentPage = Number.parseInt(currentPage, 10);
    if (currentPage > page) {
      return history.goBack();
    }

    const nextId = get(collection, ["view", "next"]);
    if (nextId) {
      return history.push(this.convertIdToUrl({ id: nextId, page }));
    }

    return null;
  };

  handleToggle = (record) => {
    return () => {
      const { selected, selectedRecords, setSelected, setSelectedRecords } =
        this.props;

      const nextSelected = selected.includes(record.id)
        ? selected.filter((id) => {
            return id !== record.id;
          })
        : [...selected, record.id];

      if (selectedRecords && setSelectedRecords) {
        const isSelected =
          selectedRecords?.some((selectedRecord) => {
            return selectedRecord.id === record.id;
          }) ?? false;

        const nextSelectedWithData = isSelected
          ? selectedRecords.filter((selectedRecord) => {
              return selectedRecord.id !== record.id;
            })
          : [...selectedRecords, record];

        setSelectedRecords(nextSelectedWithData);
      }

      setSelected(nextSelected);
    };
  };

  handleScroll = (event) => {
    event.persist();
    this.debouncedUpdateDivider(event);

    if (this.Wrapper && this.Wrapper.current) {
      const { scrollLeft } = this.Wrapper.current;

      if (scrollLeft > 100 && this.state.showHorizontalScrollIndicator) {
        this.setState({ showHorizontalScrollIndicator: false });
      }

      if (scrollLeft < 100 && !this.state.showHorizontalScrollIndicator) {
        this.setState({ showHorizontalScrollIndicator: true });
      }
    }
  };

  getToggleAllVisibleSelection = () => {
    const {
      selected: currentlySelectedIds,
      collection: { members = [] },
    } = this.props;

    const visibleIds = members.map((record) => {
      return record.id;
    });

    // if no selections, select all current visible ids
    const noCurrentlySelectedIds = currentlySelectedIds.length === 0;
    if (noCurrentlySelectedIds) return visibleIds;

    const visibleIdsCurrentlySelected = intersection(
      currentlySelectedIds,
      visibleIds,
    );

    const areAllVisibleIdsCurrentlySelected =
      visibleIdsCurrentlySelected.length === visibleIds.length;

    if (areAllVisibleIdsCurrentlySelected) {
      return difference(currentlySelectedIds, visibleIds);
    }

    return union(currentlySelectedIds, visibleIds);
  };

  getToggleAllVisibleSelectedRecords = () => {
    const {
      selectedRecords,
      collection: { members = [] },
    } = this.props;

    const noneSelected = selectedRecords.length === 0;
    if (noneSelected) {
      return members;
    }

    const selectedVisibleRecords = selectedRecords.filter((record) => {
      return members.find((member) => {
        return member.id === record.id;
      });
    });

    const allRecordsSelected = selectedVisibleRecords.length === members.length;
    if (allRecordsSelected) {
      return selectedRecords.filter((record) => {
        return !members.some((member) => {
          return member.id === record.id;
        });
      });
    }

    return [...new Set([...selectedRecords, ...members])];
  };

  handleMemberSort = (members) => {
    if (this.props.fromContactSearch) {
      return members;
    }
    return this.props.orderBy
      ? orderBy(members, this.props.orderBy, "asc")
      : members.sort((a, b) => {
          return a.firstName?.localeCompare(b.firstName);
        });
  };

  handleToggleAllVisible = () => {
    const { setSelected, setSelectedRecords } = this.props;
    const updatedSelection = this.getToggleAllVisibleSelection();
    setSelected(updatedSelection);

    if (setSelectedRecords) {
      const updatedRecordSelection = this.getToggleAllVisibleSelectedRecords();
      setSelectedRecords(updatedRecordSelection);
    }
  };

  handleToggleExpand = () => {
    this.setState((prevState) => {
      return { expanded: !prevState.expanded };
    });
  };

  getPage = () => {
    const { currentPage } = this.props;
    if (currentPage) return currentPage;
    const { page = "0" } = queryString.parse(document.location.search);
    return Number.parseInt(page, 10);
  };

  render() {
    const {
      blankState,
      collapsedColumns,
      collection,
      columns,
      selected,
      showExpandButton,
      notExpandable,
      paginationStyles = {},
      page,
      withBatchActions,
      withoutPagination,
    } = this.props;
    const { expanded, showColumnDivider } = this.state;
    const { members: collectionMembers = [] } = collection;
    const collectionMemberIds = collectionMembers.map((record) => {
      return record.id;
    });
    const selectedIntersection = intersection(selected, collectionMemberIds);
    const visibleColumns =
      !expanded && collapsedColumns ? collapsedColumns : columns;
    const rowsPerPage = ROWS_PER_PAGE;
    const currentPage = page || this.getPage();
    const count = get(this.props, ["collection", "totalItems"], 0);
    const orderedMembers = this.handleMemberSort(collectionMembers);
    return (
      <>
        <Box
          onScroll={this.handleScroll}
          ref={this.Wrapper}
          sx={(theme) => {
            return {
              borderBottom: `1px solid ${theme.palette.divider}`,
              flex: "1 1 auto",
              overflow: "auto",
              width: "100%",
            };
          }}
        >
          {this.state.showHorizontalScrollIndicator &&
            this.props.horizontalScrollIndicator && (
              <HorizontalScrollIndicator>
                <ChevronRightIcon style={{ fontSize: 26 }} />
              </HorizontalScrollIndicator>
            )}
          <Table
            ref={this.Table}
            style={{
              minWidth: expanded
                ? `${
                    visibleColumns.length * 120 + (withBatchActions ? 64 : 0)
                  }px`
                : "100%",
            }}
          >
            <StyledTableHead>
              <TableRow>
                {withBatchActions && (
                  <TableCell
                    sx={(theme) => {
                      return {
                        backgroundColor:
                          selected.length > 0
                            ? theme.palette.outboundBackground
                            : "",
                      };
                    }}
                  >
                    <StyledHeadCheckbox
                      color="secondary"
                      indeterminate={
                        selected.length > 0 &&
                        selectedIntersection.length < collectionMemberIds.length
                      }
                      checked={
                        selected.length > 0 &&
                        selectedIntersection.length ===
                          collectionMemberIds.length
                      }
                      onChange={this.handleToggleAllVisible}
                      inputProps={{ "aria-label": "Select all visible" }}
                    />
                  </TableCell>
                )}
                {visibleColumns.map((column) => {
                  return (
                    <StyledTableCell
                      key={column.title}
                      align={column.align}
                      style={this.getAddedCellStyle(column)}
                      sx={
                        column.primary
                          ? ({ breakpoints }) => {
                              return {
                                zIndex: 3,
                                left: 0,
                                [breakpoints.down("md")]: {
                                  paddingLeft: "12px",
                                  paddingRight: "12px",
                                },
                                "::after": showColumnDivider
                                  ? {
                                      boxShadow: "5px 0 10px -10px",
                                      content: "''",
                                      height: "calc(100% + 20px)",
                                      position: "absolute",
                                      top: "-10px",
                                      right: 0,
                                      width: "24px",
                                      zIndex: 4,
                                    }
                                  : {},
                              };
                            }
                          : {}
                      }
                    >
                      {column.titleContent || column.title}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {orderedMembers.map((record) => {
                return (
                  <TableRow
                    key={record.id}
                    aria-label="Table Row"
                    data-testid="table-row"
                  >
                    {withBatchActions && (
                      <StyledTableCell padding="checkbox">
                        <StyledTableCellCheckbox
                          color="secondary"
                          checked={selected.includes(record.id)}
                          onClick={this.handleToggle(record)}
                          inputProps={{
                            "aria-label": "Select item",
                            "data-testid": "select-item",
                          }}
                        />
                      </StyledTableCell>
                    )}
                    {visibleColumns.map((column) => {
                      return (
                        <StyledTableCell
                          aria-label="Table Cell Selection"
                          data-testid="table-cell-selection"
                          key={`${record.id}-${column.title}`}
                          align={column.align}
                          style={this.getAddedCellStyle(column)}
                        >
                          {column.getTableCellContent(record)}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        {isEmpty(collection) && collectionMembers.length === 0 && (
          <PageLoader />
        )}
        {!isEmpty(collection) &&
          collectionMembers.length === 0 &&
          (blankState || (
            <BlankState
              image={<Logo color="disabled" />}
              title="No records to display"
            />
          ))}
        {!withoutPagination && Boolean(count) && (
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            slotProps={{
              previousButton: {
                "aria-label": "Previous Page",
              },
              nextButton: {
                "aria-label": "Next Page",
              },
            }}
            onPageChange={this.handleChangePage}
            style={{ flexShrink: 0, width: "100%", ...paginationStyles }}
          />
        )}
        {showExpandButton && !notExpandable && collapsedColumns && (
          <Fab
            color="primary"
            aria-label="Show More"
            size="small"
            sx={{
              position: "absolute",
              right: "1rem",
              bottom: "calc(56px + 1rem)",
              zIndex: 5,
            }}
            onClick={this.handleToggleExpand}
          >
            {expanded ? (
              <UnfoldLessIcon sx={{ transform: "rotate(90deg)" }} />
            ) : (
              <UnfoldMoreIcon sx={{ transform: "rotate(90deg)" }} />
            )}
          </Fab>
        )}
      </>
    );
  }
}

export default Root;
