import { Component } from "react";
import get from "lodash/get";
import withSizes from "react-sizes";
import { Account, SiteNavigation as Navigation } from "@tesseract/core";
import SiteNavigationLink from "./SiteNavigationLink";
import OverflowMenu from "./OverflowMenu";
import breakpoints from "utils/styles/breakpoints";
import { getFontAwesomeIcon } from "features/MainNavigation/utils";

type Props = {
  siteNavigation: Navigation.Collection;
  currentAccount: Account.Raw;
};

type ComposedProps = {
  hideMenu: boolean;
  showOverflowMenu: boolean;
};

class SiteNavigation extends Component<Props & ComposedProps> {
  static mapFunc({
    icon,
    uiLink,
    name,
    title,
    badgeRecord,
  }: {
    icon: string;
    uiLink: string;
    name: string;
    title: string;
    badgeRecord: unknown;
  }) {
    const fontAwesomeIcon = getFontAwesomeIcon(icon);
    const isIFrame = window.top !== window.self;

    // Do not display automation, analytics, or settings when in Chrome extension.
    if (
      isIFrame &&
      (name === "analytics" || name === "settings" || name === "automation")
    ) {
      return null;
    }

    return (
      <SiteNavigationLink
        key={name}
        to={uiLink}
        title={title}
        badge={badgeRecord}
        badgeId={get(badgeRecord, "id", badgeRecord)}
        icon={fontAwesomeIcon}
      />
    );
  }

  render() {
    const { siteNavigation, hideMenu, showOverflowMenu, currentAccount } =
      this.props;

    const filteredSiteNavigationMembers = siteNavigation.members.filter((m) => {
      if (currentAccount.featureFlags?.contactSegmentation) {
        return m.name !== "groups";
      }
      return true;
    });

    const baseNavRaw = filteredSiteNavigationMembers.filter((m) => {
      const baseFilter = m.name !== "analytics" && m.name !== "settings";
      return baseFilter;
    });

    // All navigation items excluding Analytics and Settings.
    const baseNavigation = baseNavRaw.map((nav) => SiteNavigation.mapFunc(nav));

    // All navigation items.
    const allNavigation = filteredSiteNavigationMembers.map((nav) =>
      SiteNavigation.mapFunc(nav),
    );

    // Do not show any navigation when screen size is 0px - 599px.
    if (hideMenu) {
      return null;
    }

    // Show navigation with overflow menu for screen sizes 600px - 767px.
    if (showOverflowMenu) {
      const isIFrame = window.top !== window.self;
      return (
        <>
          {baseNavigation}
          {!isIFrame && (
            <OverflowMenu
              siteNavigationMembers={filteredSiteNavigationMembers}
            />
          )}
        </>
      );
    }

    // Show navigation without overflow menu for screen sizes 768px+.
    return allNavigation;
  }
}

export default withSizes<ComposedProps, ComposedProps & Props>(({ width }) => {
  return {
    hideMenu: width < breakpoints.navbarMedium, // 0px - 599px
    showOverflowMenu:
      width >= breakpoints.navbarMedium && width < breakpoints.medium, // 600px - 767px
  };
})(SiteNavigation) as (p: Props) => JSX.Element; // todo: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/51460
