import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import startCase from "lodash/startCase";

import { compactInteger } from "humanize-plus";
import { Paper } from "@mui/material";

import Typography from "@mui/material/Typography";
import breakpoints from "utils/styles/breakpoints";
import SettingsDescription from "components/SettingsPageComponents/SettingsDescription";

const ANALYTIC_TYPES = ["account", "global"];
const METRICS = ["totalOptOuts", "contactCreated", "userCreated"];

const Wrapper = styled.div`
  ${SettingsDescription} {
    margin-bottom: 10px;
  }
`;

const MetricSection = styled.section`
  margin-bottom: 20px;
`;

const MetricRow = styled.div`
  padding: 20px 0;
  display: flex;
  gap: 20px;
  flex-flow: column;
  width: 100%;

  @media (min-width: ${breakpoints.medium}px) {
    flex-flow: row;
  }
`;

const Metric = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
`;

export default class Root extends Component {
  static propTypes = {
    optOutAnalytics: PropTypes.object.isRequired,
  };

  render() {
    const { optOutAnalytics } = this.props;
    return (
      <Wrapper>
        {ANALYTIC_TYPES.filter((type) => {
          return Object.values(optOutAnalytics[type]).every((val) => {
            return Number.isInteger(val);
          });
        }).map((type) => {
          const analytics = optOutAnalytics[type];
          const useType = type === "global" ? "shared" : type;
          return (
            <MetricSection key={`OptOutAnalytics-${type}`}>
              <Typography variant="h4" sx={{ marginBottom: "24px" }}>
                Manage opt-outs
              </Typography>
              <SettingsDescription>
                <Typography variant="body2">
                  Opt-outs are enforced across all accounts by default. If a
                  contact opts out of receiving texts from one account, they
                  will be opted out of receiving texts from all accounts in your
                  organization. Should you feel this is set up incorrectly,
                  please contact support.
                </Typography>

                <Typography variant="body2">
                  Below are the opt-out stats for all accounts under your
                  organization, broken down by contacts who texted the keyword
                  "STOP" and contacts manually opted out by one of your team
                  members.
                </Typography>
              </SettingsDescription>
              <MetricRow>
                {METRICS.map((metric) => {
                  return (
                    <Paper
                      variant="outlined"
                      sx={(theme) => {
                        return {
                          display: "flex",
                          borderRadius: "4px",
                          flexFlow: "column nowrap",
                          flex: "1 1 120px",
                          padding: "15px",
                          textAlign: "center",
                        };
                      }}
                      key={`OptOutAnalytics-${type}-${metric}`}
                    >
                      <Typography variant="overline">
                        {startCase(metric)}
                      </Typography>
                      <Typography variant="dataLarge">
                        {compactInteger(analytics[metric], 1)}
                      </Typography>
                    </Paper>
                  );
                })}
              </MetricRow>
            </MetricSection>
          );
        })}
      </Wrapper>
    );
  }
}
