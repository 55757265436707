import { Account } from "@tesseract/core";
import { GridRowId } from "@mui/x-data-grid-pro";
import { textUsApiV4 } from "services";

export const bulkArchiveKeywords = (
  currentAccount: Account.Raw,
  requestBody: { ids: GridRowId[]; mine: boolean },
): Promise<Response> => {
  return textUsApiV4.destroy(
    `/${currentAccount.slug}/automation_keywords/archive`,
    requestBody,
  );
};
