/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ClipboardEventHandler,
  ComponentProps,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  InputBase,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  styled,
  Theme,
} from "@mui/material";

import { Link } from "react-router-dom";
import get from "lodash/get";
import { Formik, Field, Form, getIn, FormikHelpers, FieldProps } from "formik";
import { SegmentedMessage } from "sms-segments-calculator";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { format, isValid as isValidDate } from "date-fns";
import type { Attachment } from "@tesseract/core";
import { ItemToAdd } from "../../components/MessageInputFormRow/types";
import AttachmentsOption from "./containers/AttachmentsOption";
import NoteCompose from "./NoteCompose";
import {
  FormData,
  MessageAddOns,
  Props,
  ResetForm,
  SetFieldValue,
} from "./types";
import { MessageValidationSchema } from "formHelpers/validationSchemas";
import DictationOption from "components/MessageInputOptions/DictationOption";
import DraftMessage from "components/DraftMessage";
import EmojiPickerOption from "components/MessageInputOptions/EmojiPickerOption";
import AutomaticMessageIndicator from "components/AutomaticMessageIndicator";
import ShortenedLinkOption from "components/MessageInputOptions/ShortenedLinkOption/index";
import SavedRepliesOption from "components/MessageInputOptions/SavedRepliesOption";
import SignatureOption from "components/MessageInputOptions/SignatureOption";
import TemplateVariablePickerOption from "components/MessageInputOptions/TemplateVariablePickerOption";
import { createMessageBody } from "utils/createMessageBody";
import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";

import Scheduler from "components/Scheduler";
import { SetLink } from "components/MessageInputOptions/models";
import { ErrorOutlineIcon, MoreVertIcon, SendIcon } from "icons";
import { useCurrentAccount } from "hooks/useCurrentAccount";
import { useDraft } from "hooks";

// Prevent Chrome from transitioning to a yellow background on autocomplete
const FormWrapper = styled("div")(({ theme }) => {
  return {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.customColors.enabledBorder}`,
    borderRadius: "0.5rem",
    flex: "0 0 auto",
    fontSize: "0.8rem",
    margin: "0 1rem",
    paddingTop: "1rem",
    position: "relative",
    "& input:-webkit-autofill": {
      WebkitTransitionDelay: "99999s",
    },
    "& input:-webkit-autofill:hover": {
      WebkitTransitionDelay: "99999s",
    },
    "& input:-webkit-autofill:focus": {
      WebkitTransitionDelay: "99999s",
    },
    "& input:-webkit-autofill:active": {
      WebkitTransitionDelay: "99999s",
    },
    "& .MuiButtonGroup-groupedContainedPrimary:not(:last-child).Mui-disabled": {
      borderRight: "1px solid #bdbdbd",
    },
    "& .MuiInputBase-root": {
      wordBreak: "break-word",
    },
  };
});

interface ModeButtonProps {
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

function ModeButton({
  active,
  onClick,
  children,
}: ModeButtonProps): JSX.Element {
  return (
    <Button
      sx={(theme) => {
        return {
          borderRadius: 0,
          padding: "12px 20px",
          borderBottom: `solid 2px ${active ? theme.palette.primary.main : "transparent"}`,
        };
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

const AutomatedMessageWrapper = styled("div")(({ theme }) => {
  return {
    background: theme.palette.background.paper,
    borderRadius: "0.5rem",
    flex: "0 0 auto",
    fontSize: "0.8rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    paddingBottom: "1rem",
  };
});

const AttachmentRecipientsExceeded = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flex: "0 0 auto",
    alignItems: "center",
    color: theme.palette.error.main,
    zIndex: 10,
    width: "100%",
    paddingLeft: ".25rem",
    paddingTop: ".5rem",
  };
});

const DisabledOverlay = styled("div")(({ theme }) => {
  return {
    alignItems: "center",
    display: "flex",
    flexFlow: "column",
    height: "100%",
    justifyContent: "center",
    fontSize: "0.875rem",
    borderRadius: "0 0 0.5rem 0.5rem",
    left: 0,
    opacity: 0.8,
    padding: "20px",
    position: "absolute",
    paddingTop: 0,
    textAlign: "center",
    top: 0,
    width: "100%",
    zIndex: 10,
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
  };
});

const Link10dlcNotice = styled(Link)(({ theme }) => {
  return {
    color: theme.palette.text.primary,
    fontWeight: 700,
  };
});

/**
 * THE ACTUAL COMPONENT STARTS HERE
 */
export function NewMessage(props: Props) {
  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const mdAndBelow = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("lg");
  });

  const {
    clearDraft,
    draft,
    draftKey,
    initialDraftMessage,
    setDraftMessage,
    setDraftAttachments,
    setDraftLink,
    draftLoading,
  } = useDraft(props.conversation);

  const showSignature =
    props.activateSignature && Boolean(props.currentUser.signature?.content);

  const [sendOptionsAnchorEl, setSendOptionsAnchorEl] =
    useState<null | HTMLElement>(null);
  const [expandedActionsAnchorEl, setExpandedActionsAnchorEl] =
    useState<null | HTMLElement>(null);
  const [schedulerActive, setSchedulerActive] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [isSignatureActive, setIsSignatureActive] = useState(showSignature);
  const [itemToAdd, setItemToAdd] = useState<ItemToAdd | null>(null);
  const [pastedImage, setPastedImage] = useState<File | undefined>(undefined);
  const [shortenedLink, setShortenedLink] = useState({
    shortLink: props.currentAccount.shortenedLinkPreviewUrl,
    fullLink: "",
  });
  const [exceedsRecipients, setExceedsRecipients] = useState(false);

  const setFieldValueRef = useRef<SetFieldValue>();
  const resetFormRef = useRef<ResetForm>();
  const typingStartedTimeout = useRef<NodeJS.Timeout | null>(null);

  const { isCampaignProMember } = props;

  const handleSendLater = (
    setFieldValue: SetFieldValue,
    submitForm: (() => Promise<void>) & (() => Promise<unknown>),
  ) => {
    return (scheduledAt: unknown) => {
      setFieldValue("scheduledAt", scheduledAt, false);
      submitForm().catch((error) => {
        console.error(error);
      });
    };
  };

  const handleSendAndClose = (
    setFieldValue: SetFieldValue,
    submitForm: (() => Promise<void>) & (() => Promise<unknown>),
  ) => {
    const sendAndClose = async () => {
      setFieldValue("closeConversation", true, false);
      await submitForm();
    };
    sendAndClose().catch((error) => {
      console.error(error);
    });
  };

  const closeSendMenu = () => {
    setSendOptionsAnchorEl(null);
  };

  const openSendMenu = (
    event: Parameters<NonNullable<ComponentProps<typeof Button>["onClick"]>>[0],
  ) => {
    setSendOptionsAnchorEl(event.currentTarget);
  };

  const collapseMenu =
    mobileScreen || (mdAndBelow && props.showContactDetailsSidebar);

  const openExpandedActionsMenu = (
    event: MouseEvent<HTMLElement & SVGSVGElement>,
  ) => {
    setExpandedActionsAnchorEl(event.currentTarget);
  };

  const closeExpandedActionsMenu = () => {
    setExpandedActionsAnchorEl(null);
  };

  const TEMPLATE_VARIABLE_LENGTHS: Record<string, number> = {
    "{{contact.name": 15,
    "{{contact.firstName": 7,
    "{{contact.lastName": 7,
    "{{contact.business": 15,
    "{{user.name": 15,
    "{{user.firstName": 7,
    "{{user.lastName": 7,
    "{{user.email": 25,
    "{{account.name": 20,
  };

  const getCharacterCount = (values: FormData) => {
    const currentValue = getIn(values, "body");
    if (currentValue.length === 0) {
      return 0;
    }

    // Replace template variables with their default lengths
    const templateVariableRegex = /{{[^}]+}}/g;
    let processedValue = currentValue.replaceAll(
      templateVariableRegex,
      (match: string) => {
        let defaultLength = match.length;
        Object.keys(TEMPLATE_VARIABLE_LENGTHS).some((key) => {
          if (match.includes(key)) {
            defaultLength = TEMPLATE_VARIABLE_LENGTHS[key];
            return true;
          }
          return false;
        });
        return "_".repeat(defaultLength);
      },
    );

    const { currentUser } = props;
    const signatureContent = get(currentUser, ["signature", "content"], "");

    if (isSignatureActive && signatureContent.length > 0) {
      processedValue = `${processedValue}\n\n${signatureContent}`;
    }

    return processedValue.length;
  };

  const getSegmentCount = (values: FormData) => {
    const { currentUser } = props;
    const currentValue = getIn(values, "body");
    if (currentValue.length === 0) {
      return 0;
    }
    // Replace template variables with their default lengths
    const templateVariableRegex = /{{[^}]+}}/g;
    let processedValue = currentValue.replaceAll(
      templateVariableRegex,
      (match: string) => {
        let defaultLength = match.length;
        Object.keys(TEMPLATE_VARIABLE_LENGTHS).some((key) => {
          if (match.includes(key)) {
            defaultLength = TEMPLATE_VARIABLE_LENGTHS[key];
            return true;
          }
          return false;
        });
        return "_".repeat(defaultLength);
      },
    );

    const signatureContent = get(currentUser, ["signature", "content"], "");

    if (isSignatureActive && signatureContent.length > 0) {
      processedValue = `${processedValue}\n\n${signatureContent}`;
    }

    return new SegmentedMessage(processedValue).segmentsCount;
  };

  const getExceedsRecipients = (
    attachments: object[],
    recipients: string[] | undefined,
  ) => {
    if (attachments?.length > 0 && !isCampaignProMember) {
      return recipients ? setExceedsRecipients(recipients?.length > 1) : false;
    }
    return false;
  };

  const getInitialValues = (): FormData => {
    const { conversationId, recipients, match } = props;
    return {
      body: "",
      savedReply: undefined,
      to: recipients,
      links: shortenedLink,
      attachments: [],
      scheduledAt: undefined,
      closeConversation: undefined,
      conversationId: match?.params?.activeConversationSlug
        ? `/conversations/${match?.params?.activeConversationSlug}`
        : conversationId,
    };
  };

  const initialValues = getInitialValues();

  const handleEmojis = (emoji: { native: string }) => {
    setItemToAdd({
      item: emoji.native,
      type: "emoji",
    });
  };

  const handlePersonalize = (templateVariable: string) => {
    setItemToAdd({
      item: templateVariable,
      type: "templateVariable",
    });
    closeExpandedActionsMenu();
  };

  const handleLink = (links: { shortLink: string }) => {
    setItemToAdd({
      item: links.shortLink,
      type: "shortenedLink",
    });
    // Allows time for modal transition
    setTimeout(() => {
      closeExpandedActionsMenu();
    }, 1000);
  };

  function getSelectionHandler({ optionName }: { optionName: MessageAddOns }) {
    switch (optionName) {
      case "Emojis":
        return handleEmojis;
      case "Personalize":
        return handlePersonalize;
      case "Link":
        return handleLink;
      default:
        return undefined;
    }
  }

  const closeScheduler = () => {
    setSchedulerActive(false);
  };

  const showScheduler = () => {
    setSendOptionsAnchorEl(null);
    setSchedulerActive(true);
  };

  const insertSavedReply = (setFieldValue: SetFieldValue) => {
    return (savedReply: {
      id: string;
      content: string;
      attachments?: Attachment.Collection;
    }) => {
      const { contact, currentAccount, currentUser } = props;
      const customFields = currentUser.settings?.customSettings ?? {};
      // eslint-disable-next-line unicorn/no-array-reduce
      const fieldLookup = Object.keys(customFields).reduce<
        Record<
          string,
          {
            [key: string]: Record<string, unknown>;
          }
        >
      >((lookup, field) => {
        const [, account, schema, key] = field.split("/");
        if (!lookup[account]) {
          lookup[account] = { [schema]: {} };
        }
        const currentFieldValue = customFields[field];
        const isDate = isValidDate(new Date(currentFieldValue));
        const isShortDate = isDate && currentFieldValue.includes("2000");
        const formatString = isShortDate ? "MM-dd" : "MM-dd-yyyy";

        Object.assign(lookup[account][schema], {
          [key]: isDate
            ? format(new Date(currentFieldValue), formatString)
            : currentFieldValue,
        });
        return lookup;
      }, {});

      setFieldValue("attachments", savedReply.attachments?.members).catch(
        (error) => {
          console.error(error);
        },
      );
      setFieldValue("savedReply", savedReply.id).catch((error) => {
        console.error(error);
      });
      setItemToAdd({
        item: savedReply.content,
        type: "savedReply",
      });
    };
  };

  const insertDictation = (transcript: string) => {
    setItemToAdd({
      item: transcript,
      type: "dictation",
    });
  };

  const handleConversationIdChange = (setFieldValue: SetFieldValue) => {
    return (conversationId: unknown) => {
      setFieldValue("conversationId", conversationId);
    };
  };

  const handleRecipientsChange = (setFieldValue: SetFieldValue) => {
    return (recipients: unknown) => {
      setFieldValue("to", recipients);
    };
  };

  const handleContactChange = (resetForm: ResetForm) => {
    resetForm({ values: getInitialValues() });
  };

  const handlePaste = (event: Parameters<ClipboardEventHandler>[0]) => {
    if (event.clipboardData.files.length > 0) {
      const file = event.clipboardData.files[0];
      setPastedImage(file);
    }
  };

  const handleSubmit = (values: FormData, actions: FormikHelpers<FormData>) => {
    const {
      createMessageRequest,
      currentAccount,
      currentUser,
      handleSuccess,
      handleError,
    } = props;
    const signatureContent = get(currentUser, ["signature", "content"], "");
    const body = createMessageBody(
      values.body,
      isSignatureActive,
      signatureContent,
      shortenedLink,
    );
    createMessageRequest(
      currentAccount.sendMessages,
      { ...values, body },
      {
        successCallback: () => {
          actions.resetForm({
            values: { ...initialValues, body: "", attachments: [] },
          });
          setShortenedLink({ ...shortenedLink, fullLink: "" });
          clearDraft(draftKey);
          if (handleSuccess) {
            handleSuccess();
          }
        },

        errorCallback: (errors: unknown) => {
          const validationErrors = get(
            errors,
            ["validationErrors"],
            "Something went wrong!",
          );
          actions.setSubmitting(false);
          actions.setErrors(validationErrors);
          handleError(validationErrors);
        },
      },
    );
  };

  const handleSetShortenedLink = (setFieldValue: SetFieldValue): SetLink => {
    return (link) => {
      setFieldValue("link", link);
      setShortenedLink(link);
      setItemToAdd(null);
      setDraftLink(link);
    };
  };

  const isResponseStarting = ({ attachments, body }: Partial<FormData>) => {
    return (
      !isResponding &&
      ((attachments ?? []).length > 0 || (body ?? "").length > 0)
    );
  };

  const isResponseEnding = ({ attachments, body }: Partial<FormData>) => {
    return (
      isResponding && attachments?.length === 0 && (body ?? "").length === 0
    );
  };

  const sendTypingIndicator = useCallback(
    (type: string) => {
      const {
        conversationId,
        currentAccount,
        currentUser,
        emitTypingIndicator,
      } = props;
      if (conversationId && emitTypingIndicator) {
        emitTypingIndicator({
          conversationId,
          currentAccountSlug: currentAccount.slug,
          user: currentUser.name,
          type,
        });
      }
    },
    [props],
  );

  const emitResponseStarted = () => {
    sendTypingIndicator("started");
    setIsResponding(true);
  };

  const emitResponseEnded = useCallback(() => {
    if (typingStartedTimeout.current) {
      clearTimeout(typingStartedTimeout.current);
    }
    sendTypingIndicator("ended");
    setIsResponding(false);
  }, [sendTypingIndicator]);

  const handleTypingIndicators = (updated: Partial<FormData>) => {
    if (isResponseStarting(updated)) {
      emitResponseStarted();
    }
    if (isResponseEnding(updated)) {
      emitResponseEnded();
    }
  };

  const handleAttachmentsChange = (
    setFieldValue: SetFieldValue,
    values: FormData,
  ) => {
    return (attachments: FormData["attachments"]) => {
      setFieldValue("attachments", attachments);
      setItemToAdd(null);
      setDraftAttachments(attachments);
      handleTypingIndicators({ attachments, body: values.body });
    };
  };

  const handleBodyChange = (setFieldValue: SetFieldValue, form: FormData) => {
    return (body: string) => {
      const { attachments } = form;
      setFieldValue("body", body);
      setItemToAdd(null);
      handleTypingIndicators({ attachments, body });
      setDraftMessage(body);
    };
  };

  const toggleSignature = () => {
    setIsSignatureActive(!isSignatureActive);
  };

  const mmsDisabled = props.currentAccount.settings.mmsDisabled?.value ?? false;
  const outboundMmsDisabled =
    props.currentAccount.settings.outboundMmsDisabled?.value ?? false;
  const { messagingRestricted, slug } = props.currentAccount;
  const { conversationMaxMessageLength, messagingManuallyDisabled } =
    props.currentAccount.settings;
  const maxCharacterLength =
    (conversationMaxMessageLength?.value as number) ??
    DEFAULT_MAX_MESSAGE_LENGTH;
  const { adminUserSchema } = props.currentUser ?? {};
  const targeted10dlcNotice = messagingManuallyDisabled?.value ? (
    <Box>
      Your messaging is suspended due to a lack of proper 10DLC registration.{" "}
      <br />
      Please contact an Organization Admin for resolution.
    </Box>
  ) : adminUserSchema ? (
    <Box>
      Outbound messaging is disabled. You need to update your <br />{" "}
      Organization&apos;s 10DLC Use Case by going to{" "}
      <Link10dlcNotice to={`/${slug}/settings/brand-information`}>
        Settings
      </Link10dlcNotice>
      .
    </Box>
  ) : (
    <Box>
      Outbound messaging is disabled. <br /> An Organization Admin will need to
      update your 10DLC Use Case.
    </Box>
  );

  const renderAdditionalActions = (
    values: FormData,
    setFieldValue: SetFieldValue,
  ) => {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-evenly"
        width="100%"
      >
        <TemplateVariablePickerOption
          disabled={props.disabled}
          selectionHandler={handlePersonalize}
          closeExpandedActionsMenu={closeExpandedActionsMenu}
        />
        {!props.quickComposer && !mmsDisabled && (
          <AttachmentsOption
            closeExpandedActionsMenu={closeExpandedActionsMenu}
            disabled={props.disabled || outboundMmsDisabled}
            attachments={getIn(values, "attachments")}
            handleAttachmentsChange={handleAttachmentsChange(
              setFieldValue,
              values,
            )}
            pastedImage={pastedImage}
            outboundMmsDisabled={outboundMmsDisabled}
            isCampaignProMember={isCampaignProMember}
          />
        )}
        {Boolean(props.quickComposer) && !mmsDisabled && (
          <AttachmentsOption
            closeExpandedActionsMenu={closeExpandedActionsMenu}
            disabled={props.disabled || outboundMmsDisabled}
            attachments={getIn(values, "attachments")}
            handleAttachmentsChange={handleAttachmentsChange(
              setFieldValue,
              values,
            )}
            pastedImage={pastedImage}
            outboundMmsDisabled={outboundMmsDisabled}
            isCampaignProMember={isCampaignProMember}
            recipients={props.recipients}
            getExceedsRecipients={getExceedsRecipients}
            setExceedsRecipients={setExceedsRecipients}
          />
        )}
        <DictationOption
          disabled={props.disabled}
          addSpeechTranscriptToBody={insertDictation}
        />
        <ShortenedLinkOption
          closeExpandedActionsMenu={closeExpandedActionsMenu}
          disabled={props.disabled}
          shortenedLink={shortenedLink}
          setShortenedLink={handleSetShortenedLink(setFieldValue)}
          selectionHandler={handleLink}
        />
      </Box>
    );
  };

  useEffect(() => {
    if (isResponding) {
      if (typingStartedTimeout.current) {
        clearTimeout(typingStartedTimeout.current);
      }

      typingStartedTimeout.current = setTimeout(() => {
        emitResponseEnded();
        setIsResponding(false);
      }, 30 * 1000);
    }

    return () => {
      if (typingStartedTimeout.current) {
        clearTimeout(typingStartedTimeout.current);
      }
    };
  }, [isResponding]);

  useEffect(() => {
    const { activateSignature, currentUser } = props;
    if (currentUser.signature?.content) {
      setIsSignatureActive(activateSignature);
    }
  }, [props.activateSignature]);

  useEffect(() => {
    if (handleContactChange && resetFormRef?.current) {
      handleContactChange(resetFormRef.current);
    }
  }, [props.contact?.id]);

  useEffect(() => {
    if (handleRecipientsChange && setFieldValueRef?.current) {
      handleRecipientsChange(setFieldValueRef.current)(props.recipients);
    }
  }, [props.recipients]);

  useEffect(() => {
    if (handleConversationIdChange && setFieldValueRef?.current) {
      handleConversationIdChange(setFieldValueRef.current)(
        props.conversationId,
      );
      setShortenedLink({ ...shortenedLink, fullLink: "" });
    }
  }, [props.conversationId]);

  useEffect(() => {
    return () => {
      emitResponseEnded();
    };
  }, []);

  // Sync draft shortened link, if one exists
  useEffect(() => {
    if (draft?.link.fullLink !== shortenedLink.fullLink) {
      setShortenedLink((prevLink) => {
        return draft?.link ?? prevLink;
      });
      setItemToAdd(null);
    }
  }, [draft]);

  const { featureFlags } = useCurrentAccount();
  const [composeMode, setComposeMode] = useState<"note" | "message">("message");
  const { hasNoteCompose } = props;

  function usePrevious(value: string) {
    const ref = useRef<string>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevId = usePrevious(props.conversationId);

  useEffect(() => {
    if (prevId !== props.conversationId) {
      setComposeMode("message");
    }
  }, [props.conversationId, prevId]);

  return (
    <>
      <FormWrapper
        sx={{ padding: 0, overflow: "hidden" }}
        aria-disabled={props.disabled}
        title={props.title}
      >
        {messagingRestricted && (
          <DisabledOverlay>{targeted10dlcNotice}</DisabledOverlay>
        )}
        {props.unsubscribed && !messagingRestricted && (
          <DisabledOverlay>
            <p style={{ maxWidth: "500px", margin: 0 }}>
              This phone number is opted out. In order to send a message the
              phone number needs to be opted back in.
            </p>
          </DisabledOverlay>
        )}

        {featureFlags.notesInTimeline && hasNoteCompose && props.contact && (
          <Box
            sx={(theme) => {
              return {
                bgcolor: theme.palette.customColors.backgrounds.secondary,
                borderBottom: `1px solid ${theme.palette.customColors.enabledBorder}`,
                display: "flex",
                width: "100%",
              };
            }}
          >
            <ModeButton
              active={composeMode === "message"}
              onClick={() => {
                setComposeMode("message");
              }}
            >
              Message
            </ModeButton>
            <ModeButton
              active={composeMode === "note"}
              onClick={() => {
                setComposeMode("note");
              }}
            >
              Note
            </ModeButton>
          </Box>
        )}

        {(props.contact && composeMode === "note" && (
          <NoteCompose
            conversation={props.conversationId}
            contact={props.contact.id}
            refreshConversation={props.refreshConversation}
          />
        )) ||
          (draftLoading ? null : ( // Wait for draft values, if any, before rendering the form
            <Formik
              initialValues={initialValues}
              isInitialValid={MessageValidationSchema(
                maxCharacterLength,
              ).isValidSync(initialValues)}
              validationSchema={MessageValidationSchema(maxCharacterLength)}
              onSubmit={handleSubmit}
            >
              {({
                errors = {},
                isSubmitting,
                isValid,
                resetForm,
                setFieldValue,
                submitForm,
                touched = {},
                values,
              }) => {
                setFieldValueRef.current = setFieldValue;
                resetFormRef.current = resetForm;

                // Sync draft attachment, if one exists
                if (
                  draft?.attachments &&
                  draft.attachments[0]?.key !== values.attachments[0]?.key
                ) {
                  setFieldValue("attachments", draft.attachments);
                }

                const disabled =
                  props.disabled ||
                  props.unsubscribed ||
                  !isValid ||
                  isSubmitting ||
                  getCharacterCount(values) > maxCharacterLength;

                return (
                  <>
                    <Form>
                      <Field type="text" name="body">
                        {({ field }: FieldProps) => {
                          return (
                            <InputBase
                              data-testid="form-field"
                              {...field}
                              disabled={props.disabled}
                              error={
                                getIn(touched, field.name) &&
                                Boolean(getIn(errors, field.name))
                              }
                              fullWidth
                              inputComponent={DraftMessage}
                              inputProps={{
                                conversationId: props.conversationId,
                                name: field.name,
                                initialDraftMessage,
                                handleChange: props.disabled
                                  ? undefined
                                  : handleBodyChange(setFieldValue, values),
                                handleCmdEnter: () => {
                                  return props.unsubscribed ||
                                    !isValid ||
                                    isSubmitting
                                    ? null
                                    : submitForm();
                                },
                                isSignatureActive,
                                itemToAdd,
                                minHeight: "40px",
                                placeholder: props.placeholder,
                                setShortenedLink:
                                  handleSetShortenedLink(setFieldValue),
                                shortenedLink,
                                signature: props.currentUser.signature,
                                value: field.value,
                                withSignature: true,
                              }}
                              onPaste={handlePaste}
                              type="text"
                              sx={{ padding: "1rem .5rem" }}
                            />
                          );
                        }}
                      </Field>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ opacity: props.disabled ? "0.4" : "1" }}
                      >
                        <Box
                          data-testid="character-count"
                          fontSize="0.75rem"
                          margin="0.5rem"
                          sx={(theme) => {
                            return {
                              color:
                                getIn(values, "body") !== "" &&
                                getCharacterCount(values) > maxCharacterLength
                                  ? theme.palette.error.main
                                  : theme.palette.text.secondary,
                            };
                          }}
                        >
                          {`${getCharacterCount(
                            values,
                          )} of ${maxCharacterLength} characters${props.displaySegmentCount === false ? "" : ` (~${getSegmentCount(values)} SMS messages)`}`}
                        </Box>
                      </Box>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                        padding="0.5rem 1rem"
                        borderRadius="0 0 0.5rem 0.5rem"
                        width="100%"
                        minWidth="18.75rem"
                        sx={(theme) => {
                          return {
                            backgroundColor:
                              theme.palette.customColors.backgrounds.secondary,
                            borderTop: `1px solid ${theme.palette.customColors.enabledBorder}`,
                            flexFlow: "row wrap",
                          };
                        }}
                      >
                        <Box flex="0 0 auto">
                          {props.renderToolbar ? (
                            props.renderToolbar({
                              getSelectionHandler: (...p) => {
                                return getSelectionHandler(...p);
                              },
                              handleAttachmentsChange: (...p) => {
                                return handleAttachmentsChange(...p);
                              },
                              handleSetShortenedLink: (...p) => {
                                return handleSetShortenedLink(...p);
                              },
                              insertDictation: (...p) => {
                                return insertDictation(...p);
                              },
                              insertSavedReply: (...p) => {
                                return insertSavedReply(...p);
                              },
                              isSignatureActive,
                              pastedImage,
                              quickComposer: props.quickComposer,
                              setFieldValue,
                              shortenedLink,
                              toggleSignature,
                              values,
                              disabled: props.disabled,
                            })
                          ) : (
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-evenly"
                            >
                              <SignatureOption
                                disabled={props.disabled}
                                isSignatureActive={isSignatureActive}
                                toggleSignature={toggleSignature}
                              />
                              <EmojiPickerOption
                                disabled={props.disabled}
                                selectionHandler={handleEmojis}
                              />
                              <SavedRepliesOption
                                disabled={props.disabled}
                                selectionHandler={insertSavedReply(
                                  setFieldValue,
                                )}
                              />
                              <Divider orientation="vertical" flexItem />
                              {collapseMenu ? (
                                <>
                                  <IconButton>
                                    <MoreVertIcon
                                      onClick={openExpandedActionsMenu}
                                    />
                                  </IconButton>
                                  <Menu
                                    open={Boolean(expandedActionsAnchorEl)}
                                    anchorEl={expandedActionsAnchorEl}
                                    onClose={closeExpandedActionsMenu}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    slotProps={{
                                      paper: {
                                        sx: {
                                          padding: "0rem 0.5rem",
                                        },
                                      },
                                    }}
                                    sx={{
                                      zIndex: 10,
                                    }}
                                  >
                                    {renderAdditionalActions(
                                      values,
                                      setFieldValue,
                                    )}
                                  </Menu>
                                </>
                              ) : (
                                renderAdditionalActions(values, setFieldValue)
                              )}
                            </Box>
                          )}
                        </Box>
                        <Box
                          alignItems="center"
                          display="flex"
                          flex="1 1 auto"
                          justifyContent="flex-end"
                          sx={{
                            opacity: props.disabled ? "0.4" : "1",
                          }}
                        >
                          {isValid && (
                            <Box
                              display="none"
                              mr="10px"
                              sx={(theme) => {
                                return {
                                  [theme.breakpoints.up("md")]: {
                                    display: "block",
                                  },
                                };
                              }}
                            >
                              ctrl/cmd + enter
                            </Box>
                          )}
                          {props.renderSubmitButton ? (
                            props.renderSubmitButton({
                              isValid,
                              disabled,
                              anchorEl: sendOptionsAnchorEl,
                              handleOpen: openSendMenu,
                              handleClose: closeSendMenu,
                              sendLaterHandler: handleSendLater(
                                setFieldValue,
                                submitForm,
                              ),
                            })
                          ) : (
                            <>
                              <ButtonGroup
                                variant="contained"
                                color="primary"
                                disabled={disabled || exceedsRecipients}
                                aria-label="Split button"
                                data-testid="split-button"
                              >
                                <Button
                                  type="submit"
                                  data-testid="new-message-send-button"
                                  color="primary"
                                  sx={{ padding: "0.5rem 1rem" }}
                                  variant="contained"
                                >
                                  <SendIcon />
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  aria-haspopup="true"
                                  onClick={openSendMenu}
                                  sx={{ minWidth: "35px" }}
                                  aria-label="more options"
                                >
                                  <ArrowDropDownIcon data-testid="send-options-button" />
                                </Button>
                              </ButtonGroup>
                              <Menu
                                anchorEl={sendOptionsAnchorEl}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                disableAutoFocusItem
                                MenuListProps={{ disablePadding: true }}
                                onClose={closeSendMenu}
                                open={Boolean(sendOptionsAnchorEl)}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                              >
                                {Boolean(handleSendAndClose) && (
                                  <MenuItem
                                    data-testid="send-close-option"
                                    onClick={() => {
                                      closeSendMenu();
                                      handleSendAndClose(
                                        setFieldValue,
                                        submitForm,
                                      );
                                    }}
                                  >
                                    <ListItemText>Send & Close</ListItemText>
                                  </MenuItem>
                                )}
                                <MenuItem
                                  data-testid="send-later-option"
                                  onClick={showScheduler}
                                >
                                  <ListItemText>Send Later</ListItemText>
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Form>
                    {/* ==== SEND LATER DIALOG ==== */}
                    <Scheduler
                      closeScheduler={closeScheduler}
                      isActive={schedulerActive}
                      sendLaterHandler={handleSendLater(
                        setFieldValue,
                        submitForm,
                      )}
                    />
                  </>
                );
              }}
            </Formik>
          ))}
      </FormWrapper>
      <AutomatedMessageWrapper>
        {exceedsRecipients ? (
          <AttachmentRecipientsExceeded>
            <ErrorOutlineIcon fontSize="inherit" />
            <Box sx={{ display: "flex", flex: "0 0 auto", marginLeft: "2px" }}>
              Attachment cannot be sent to multiple recipients
            </Box>
          </AttachmentRecipientsExceeded>
        ) : (
          <AutomaticMessageIndicator />
        )}
      </AutomatedMessageWrapper>
    </>
  );
}
