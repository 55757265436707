import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { KeywordObject, SubKeyword } from "../types";

function SubKeywordCard({
  hasLink,
  keyword,
  subKeyword,
  isMobile,
}: {
  keyword: KeywordObject;
  subKeyword: SubKeyword;
  isMobile: boolean;
  hasLink: (messageBody: string) => boolean;
}) {
  const [expanded, setExpanded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const mobileMaxWidth = () => {
    if (windowWidth < 335) {
      return "140px";
    }
    if (windowWidth < 390) {
      return "190px";
    }
    if (windowWidth < 450) {
      return "245px";
    }
    return "none";
  };

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const percentAnswered =
    keyword.timesReceived === 0 || subKeyword.answeredCount === 0
      ? 0
      : Math.round((subKeyword.answeredCount / keyword.timesReceived) * 100);

  const percentClicked =
    subKeyword.clickedLinkCount === 0 || subKeyword.answeredCount === 0
      ? 0
      : Math.round(
          (subKeyword.clickedLinkCount / subKeyword.answeredCount) * 100,
        );

  const subKeywordHasLink = hasLink(subKeyword.responseBody);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      disableGutters
      sx={(theme) => {
        return {
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: "4px 4px 4px 4px",
          backgroundColor: theme.palette.customColors.backgrounds.secondary,
          boxShadow: "none",
          width: "100%",
          maxWidth: "740px",
          height: "auto",
          "&::before": {
            display: "none",
          },
        };
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        sx={(theme) => {
          return {
            height: "40px",
            padding: "8px",
            display: "flex",
            flexDirection: "row-reverse",
            "&.Mui-expanded": {
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
          };
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="h6" paddingLeft="8px" alignItems="center">
            {isMobile
              ? subKeyword.keyword
              : `Keyword answer: ${subKeyword.keyword}`}
          </Typography>
          <Typography
            variant="caption"
            sx={(theme) => {
              return {
                alignItems: "flex-end",
                color: theme.palette.text.secondary,
                display: "flex",
                fontSize: "13px",
                padding: "16px 8px",
                whiteSpace: "nowrap",
              };
            }}
          >
            {subKeyword.answeredCount} answered
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => {
          return {
            backgroundColor: theme.palette.background.paper,
            display: expanded ? "block" : "none",
            transition: "display 0.3s ease",
            padding: "4px 16px",
            borderRadius: "4px 4px 4px 4px",
          };
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              paddingBottom: "4px",
              flexDirection: "column",
              height: "auto",
              paddingTop: "4px",
            }}
          >
            <Typography
              variant="body1"
              alignItems="center"
              sx={{
                paddingBottom: "0px",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                display: "-webkit-box",
                fontSize: "16px",
                lineHeight: "1.5",
                overflow: "hidden",
                width: "100%",
                color: "text.secondary",
                maxWidth: isMobile
                  ? mobileMaxWidth()
                  : windowWidth < 650
                    ? "255px"
                    : "none",
              }}
            >
              {subKeyword.responseBody}
            </Typography>
            {subKeyword?.attachments?.length > 0 ? (
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  height: "20px",
                  maxWidth: "620px",
                  overflow: "hidden",
                  width: "100%",
                  marginTop: "8px",
                }}
              >
                <AttachFileIcon
                  fontSize="small"
                  sx={{ color: "text.secondary" }}
                />
                <Typography
                  variant="caption"
                  sx={{ fontSize: "12px", color: "text.secondary" }}
                >
                  {subKeyword?.attachments[0].originalFilename}
                </Typography>
              </Box>
            ) : null}
          </Box>
          {isMobile ? <Divider sx={{ width: "100%" }} /> : null}
          <Box
            display="flex"
            paddingTop={isMobile ? "8px" : "0px"}
            justifyContent={isMobile ? "center" : "flex-end"}
            width="100%"
            maxWidth="200px"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              {subKeywordHasLink ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    paddingLeft: isMobile ? "8px" : "16px",
                  }}
                >
                  <Box textAlign="center">
                    <Typography
                      sx={(theme) => {
                        return {
                          color: theme.palette.primary.main,
                          fontSize: "16px",
                          fontWeight: 700,
                        };
                      }}
                    >
                      {percentClicked}%
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={(theme) => {
                        return {
                          alignSelf: "stretch",
                          color: theme.palette.text.secondary,
                          whiteSpace: "nowrap",
                          fontWeight: 500,
                        };
                      }}
                    >
                      Clicked links
                    </Typography>
                  </Box>
                  {isMobile ? null : (
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      sx={{ height: "8px", margin: "16px 0px" }}
                      flexItem
                    />
                  )}
                </Box>
              ) : null}
              <Box textAlign="center">
                <Typography
                  sx={(theme) => {
                    return {
                      color: theme.palette.primary.main,
                      fontSize: "16px",
                      fontWeight: 700,
                    };
                  }}
                >
                  {percentAnswered}%
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={(theme) => {
                    return {
                      alignSelf: "stretch",
                      color: theme.palette.text.secondary,
                      whiteSpace: "nowrap",
                      fontWeight: 500,
                    };
                  }}
                >
                  Responded
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default SubKeywordCard;
